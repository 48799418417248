export enum AppRoute {
  Index = '/',
  InventorySearch = '/inventory/inventorysearch',
  InventorySearchPaidIn = '/inventory/inventorysearch?paidin=1',
  InventorySearchPaidOut = '/inventory/inventorysearch?paidout=0',
  Placeholder1 = '/placeholder1',
  Placeholder2 = '/placeholder2',
  ItemDetails = '/itemDetails',
  Inventory = '/inventory/:inventoryNum',
  InventoryInformation = '/inventory/:inventoryNum/inventoryinformation',
  Details = '/inventory/:inventoryNum/details',
  PriceTag = '/inventory/:inventoryNum/pricetag',
  Service = '/inventory/:inventoryNum/service',
  RetentionService = '/inventory/:inventoryNum/service?retention=1',
  IdleInventoryAudit = '/inventory/:inventoryNum/idleinventoryaudit',
  AgreementHistory = '/inventory/:inventoryNum/agreementhistory',
  Activities = '/inventory/:inventoryNum/activities',
  PrintEditPriceTagList = '/inventory/printeditpricetag',
  PackagePriceTagList = '/inventory/packagepricetag',
  ItemSearch = '/inventory/packageitemsearch',
  InventoryTransferReceive = '/inventory/inventorytransfer',
  InventoryAuditHistory = '/inventory/audit/history',
  InventoryAuditInfo = '/inventory/audit/info/:auditid',
  InventoryAuditExplanation = '/inventory/audit/:inventorynumber/:auditid/explanation',
  InvAudExp1 = '/inventory/audit/:inventorynumber/:auditid/explanation?page=1&status=0',
  InvAudExp2 = '/inventory/audit/:inventorynumber/:auditid/explanation?page=1&status=1',
  InvAudExp3 = '/inventory/audit/:inventorynumber/:auditid/explanation?page=2&status=0',
  InvAudExp4 = '/inventory/audit/:inventorynumber/:auditid/explanation?page=2&status=1',
  InvAudExp5 = '/inventory/audit/:inventorynumber/:auditid/explanation?page=3&status=0',
  InvAudExp6 = '/inventory/audit/:inventorynumber/:auditid/explanation?page=3&status=1',
  ItemBeingServiced = '/inventory/itembeingserviced',
  InventoryServiceRequest = '/inventory/service/request/:inventoryNum',
  RetentionInventoryServiceRequest = '/inventory/service/request/:inventoryNum??type=N&retention=1',
}
