/* eslint-disable prettier/prettier */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable sonarjs/no-duplicated-branches */
/* eslint-disable sonarjs/no-collapsible-if */
/* eslint-disable sonarjs/no-small-switch */
/* eslint-disable prettier/prettier */
/* eslint-disable no-debugger */
/* eslint-disable no-console */
/* eslint-disable sonarjs/no-unused-collection */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable sonarjs/no-redundant-boolean */
/* eslint-disable prefer-const */
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-vars */

import React, { useContext, useEffect, useState } from 'react';
import { globalStyles } from "../componentstyles/globalstyles";
import { tabs } from "../componentstyles/tab";
import { makeStyles, Grid, List, ListItem, Typography, Card, CardContent } from '@rentacenter/racstrap'
import { CHARGED_OFF, HOLD, LOANER, NOT_RENT_READY, ON_RENT,SERVICE_CHARGED_OFF, PENDING_CHARGE_OFF, PENDING_LOANER_AGREEMENT, PENDING_RENTAL_AGREEMENT, RENT_READY, RESERVED, SERVICE_IDLE, SERVICE_ON_RENT, TRANSFER_IN_PROGRESS } from '../../constants/constants';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { truncString, validateObject } from './validateObject';
import { InventoryContext } from '../../context/InventoryInfoContext';
import { LoadInventoryInformation } from '../../api/user';
import {
  InventoryMajorContext,
} from '../../context/inventoryMajorContext';

enum headerTabs {
  InventoryInfo,
  DetailsInfo,
  PriceTagInfo,
  ServicesInfo,
  IdleAuditInfo,
  AgreementInfo,
  ActivitiesInfo
}

interface paramType {
  inventoryNum: string;
}

export default function InventoryHeader(props:any) {
  const {
    featureFlagResponse,
    invInfoFeature,
    invDetailsFeature,
    idleInvAuditFeature,
    invActivitiesFeature,
    agrHistoryFeature,
    pricetagFeature,
    printEditPriceTagsFeature,
    transferinvFeature,
  } = useContext(InventoryMajorContext);
  const classes = globalStyles();
  const tab = tabs();
  const history = useHistory();
  const { response, GotApiResponse, activeTab, setactiveTab } = props;

  const { inventoryNum } = useParams<paramType>();
  const { headerValue, tabDisableContext } = useContext(InventoryContext);
  const [headerContext, setheaderContext] = headerValue;
  const [tabDisable, settabDisable] = tabDisableContext;
  const [headerValues, setheaderValues] = useState({
    inventoryNumber: '-',
    description: '-',
    status: '-',
    storeNumber:''
  });
  //const [inventoryNoTab1, setinventoryNoTab1] = inventoryNoTab1ContextVal;

  const urlpath = useLocation();
  const pathname = urlpath.pathname;

  useEffect(() => {
    setCurrentPage();

    if (validateObject(headerContext) &&
      headerContext.inventoryNumber == inventoryNum) {
      setheaderValues({
        ...headerValues,
        inventoryNumber: headerContext.inventoryNumber,
        description: headerContext.description,
        status: headerContext.status
      })
    }
    else if(GotApiResponse && response!=undefined && headerContext==undefined )
    {
      if (validateObject(response.data) === true) {
        if (validateObject(response.data.inventoryHeader) === true) {
          setheaderValues({
            ...headerValues,
            inventoryNumber: response.data.inventoryHeader.itemNumber,
            description: response.data.inventoryHeader.description,
            status: response.data.inventoryHeader.status,
            storeNumber:response.data.inventoryHeader.storeNumber
          });
          setheaderContext({
            ...headerContext,
            inventoryNumber: response.data.inventoryHeader.itemNumber,
            description: response.data.inventoryHeader.description,
            status: response.data.inventoryHeader.status,
            storeNumber:response.data.inventoryHeader.storeNumber
          });
        }
      }
    }
    else if(GotApiResponse && response==undefined) {
      loadInventoryHeaderAPI(inventoryNum);
    }
  }, [headerContext,GotApiResponse,response]);

  const setCurrentPage = () => {
    if (validateObject(urlpath) === true) {
      if (validateObject(pathname) === true) {
        if (pathname.includes('inventoryinformation') === true) {
          setactiveTab(headerTabs.InventoryInfo);
        }
        else if (pathname.includes('details') === true) {
          setactiveTab(headerTabs.DetailsInfo);
        }
        else if (pathname.includes('pricetag') === true) {
          setactiveTab(headerTabs.PriceTagInfo);
        }
        else if (pathname.includes('service') === true) {
          setactiveTab(headerTabs.ServicesInfo);
        }
        else if (pathname.includes('idleinventoryaudit') === true) {
          setactiveTab(headerTabs.IdleAuditInfo);
        }
        else if (pathname.includes('agreementhistory') === true) {
          setactiveTab(headerTabs.AgreementInfo);
        }
        else if (pathname.includes('activities') === true) {
          setactiveTab(headerTabs.ActivitiesInfo);
        }
      }
    }
  }

  const loadInventoryHeaderAPI = async (inventoryNumber: any) => {
    const resp = await LoadInventoryInformation(inventoryNumber, 'Y');

    if (validateObject(resp) === true && resp.status == 200) {
      if (validateObject(resp.data) === true) {
        if (validateObject(resp.data.inventoryHeader) === true) {
          setheaderValues({
            ...headerValues,
            inventoryNumber: resp.data.inventoryHeader.itemNumber,
            description: resp.data.inventoryHeader.description,
            status: resp.data.inventoryHeader.status,
            storeNumber:resp.data.inventoryHeader.storeNumber
          });
          setheaderContext({
            ...headerContext,
            inventoryNumber: resp.data.inventoryHeader.itemNumber,
            description: resp.data.inventoryHeader.description,
            status: resp.data.inventoryHeader.status,
            storeNumber:resp.data.inventoryHeader.storeNumber
          });
        }
      }
    }
  }


  const dynamicStatusColor = (val: any) => {
    switch (val) {
      case ON_RENT: case SERVICE_ON_RENT: case LOANER: {
        return `${classes.statusBadge} ${classes.statusGreen}`
      }
      case RENT_READY: case NOT_RENT_READY: case PENDING_CHARGE_OFF: case HOLD: case SERVICE_IDLE: case RESERVED: case TRANSFER_IN_PROGRESS: {
        return `${classes.statusBadge} ${classes.statusWarning}`
      }
      case PENDING_RENTAL_AGREEMENT: case PENDING_LOANER_AGREEMENT: {
        return `${classes.statusBadge} ${classes.statusInfo}`
      }
      case CHARGED_OFF: case SERVICE_CHARGED_OFF: {
        return `${classes.statusBadge} ${classes.bgAsh} `
      }
      // default: {
      //   return `${classes.statusBadge}`
      // }
    }
  }


  const onTabSwitch = (tabActive: any) => {
    switch (tabActive) {
      case 0:
        setactiveTab(headerTabs.InventoryInfo);
        history.push({ pathname: `/inventory/${inventoryNum}/inventoryinformation` });

        break;
        case 1:
          setactiveTab(headerTabs.DetailsInfo);
          history.push({ pathname: `/inventory/${inventoryNum}/details` });
  
          break;
      case 2:
        setactiveTab(headerTabs.PriceTagInfo);
        history.push({ pathname: `/inventory/${inventoryNum}/pricetag` });

        break;
      case 3:
        setactiveTab(headerTabs.ServicesInfo);
        history.push({ pathname: `/inventory/${inventoryNum}/service` });

        break;
      case 4:
        setactiveTab(headerTabs.IdleAuditInfo);
        history.push({ pathname: `/inventory/${inventoryNum}/idleinventoryaudit` });

        break;
      case 5:
        setactiveTab(headerTabs.AgreementInfo);
        history.push({ pathname: `/inventory/${inventoryNum}/agreementhistory` });

        break;
      case 6:
        setactiveTab(headerTabs.ActivitiesInfo);
        history.push({ pathname: `/inventory/${inventoryNum}/activities` });

        break;
      default:
        setactiveTab(headerTabs.InventoryInfo);
        history.push({ pathname: `/inventory/${inventoryNum}/inventoryinformation` });
        break;

    }
  }


  return (
    <>

      <Grid className={`${classes.mt4} ${classes.headerWidth}`}>

        <Card className={`${classes.racCard} ${classes.borderRadius0}`}>
          <CardContent className={classes.headerCardPadding}>

            <Grid className={`${classes.dflex} ${classes.p0}`}>
              <Grid container className={`${classes.pl4} ${classes.pt4} ${classes.floatLeft} ${classes.widthAuto}`} >
                <Typography className={`${classes.fontSemiBold}`}>Item #</Typography>
                <Typography className={`${classes.textMildGrey} ${classes.ml2}`}>{validateObject(headerValues.inventoryNumber) ? headerValues.inventoryNumber : '-'}</Typography>
              </Grid>
              <Grid container className={`${classes.pl5} ${classes.pt4}  ${classes.widthAuto}`} >
                <Typography className={`${classes.fontSemiBold}`}>Description</Typography>
                <Typography className={`${classes.textMildGrey} ${classes.ml2} `} title={validateObject(headerValues.description) && headerValues.description.length > 40 ? headerValues.description : ''}>{validateObject(headerValues.description) ? truncString(headerValues.description, 40, '...') : '-'}</Typography>
              </Grid>
              <Grid container className={`${classes.pl5} ${classes.pt4}  ${classes.widthAuto}`} >
                <Typography className={`${classes.fontSemiBold}`}>Status</Typography>
                <Typography className={dynamicStatusColor(validateObject(headerValues.status) ? headerValues.status : '-')}>{headerValues.status !== undefined ? headerValues.status : ' -'}</Typography>
              </Grid>
            </Grid>
            <List className={`${classes.dflex} ${classes.listPadding}`}>
             <ListItem
                className={activeTab === 0 ? `${classes.widthAuto}  ${classes.listTabPadding} ${tab.tabItem} ${tab.tabItemActive}` : `${classes.widthAuto}  ${classes.listTabPadding} ${tab.tabItem}`}
                onClick={() => tabDisable == false ? onTabSwitch(headerTabs.InventoryInfo) : null}
                style={{ cursor: tabDisable == false ? 'pointer' : 'not-allowed' }}
              >
                Inventory Information
              </ListItem>
              <ListItem
                className={activeTab === 1 ? `${classes.widthAuto}  ${classes.listTabPadding} ${tab.tabItem} ${tab.tabItemActive}` : `${classes.widthAuto}  ${classes.listTabPadding} ${tab.tabItem}`}
                onClick={() => tabDisable == false ? onTabSwitch(headerTabs.DetailsInfo) : null}
                style={{ cursor: tabDisable == false ? 'pointer' : 'not-allowed' }}
              >
                Details
              </ListItem>  
              <ListItem
                className={activeTab === 2 ? `${classes.widthAuto}  ${classes.listTabPadding} ${tab.tabItem} ${tab.tabItemActive}` : `${classes.widthAuto}  ${classes.listTabPadding} ${tab.tabItem}`}
                onClick={() => tabDisable == false ? onTabSwitch(headerTabs.PriceTagInfo) : null}
                style={{ cursor: tabDisable == false ? 'pointer' : 'not-allowed' }}
              >
                Price Tag
              </ListItem>
              <ListItem
                className={activeTab === 3 ? `${classes.widthAuto}  ${classes.listTabPadding} ${tab.tabItem} ${tab.tabItemActive}` : `${classes.widthAuto}  ${classes.listTabPadding} ${tab.tabItem}`}
                onClick={() => tabDisable == false ? onTabSwitch(headerTabs.ServicesInfo) : null}
                style={{ cursor: tabDisable == false ? 'pointer' : 'not-allowed' }}
              >
                Services
              </ListItem>
              <ListItem
                className={activeTab === 4 ? `${classes.widthAuto}  ${classes.listTabPadding} ${tab.tabItem} ${tab.tabItemActive}` : `${classes.widthAuto}  ${classes.listTabPadding} ${tab.tabItem}`}
                onClick={() => tabDisable == false ? onTabSwitch(headerTabs.IdleAuditInfo) : null}
                style={{ cursor: tabDisable == false ? 'pointer' : 'not-allowed' }}
              >
                Idle Inventory Audit
              </ListItem>
              <ListItem
                className={activeTab === 5 ? `${classes.widthAuto}  ${classes.listTabPadding} ${tab.tabItem} ${tab.tabItemActive}` : `${classes.widthAuto}  ${classes.listTabPadding} ${tab.tabItem}`}
                onClick={() => tabDisable == false ? onTabSwitch(headerTabs.AgreementInfo) : null}
                style={{ cursor: tabDisable == false ? 'pointer' : 'not-allowed' }}
              >
                Agreement History
              </ListItem>
              <ListItem
                className={activeTab === 6 ? `${classes.widthAuto}  ${classes.listTabPadding} ${tab.tabItem} ${tab.tabItemActive}` : `${classes.widthAuto}  ${classes.listTabPadding} ${tab.tabItem}`}
                onClick={() => onTabSwitch(headerTabs.ActivitiesInfo)}
              >
                Activities
              </ListItem>
            </List>

          </CardContent>
        </Card>
      </Grid>

    </>

  )
}