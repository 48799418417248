/* eslint-disable prettier/prettier */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable prettier/prettier */
/* eslint-disable no-debugger */
/* eslint-disable prefer-const */
/* eslint-disable no-console */
/* eslint-disable sonarjs/no-redundant-boolean */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/no-children-prop */

import React, { useState, useEffect, useRef, useContext } from 'react';
import { useReactToPrint } from 'react-to-print';

import {
  Grid,
  Card,
  Typography,
  CardContent,
  RACButton,
  RACModalCard,
  RACSelect,
  CircularProgress,
} from '@rentacenter/racstrap';
import { InventoryContext } from "../../context/InventoryInfoContext";
import DynamicGridComponent from '../Shared/DynamicGrid';
import { columns, sortDirection } from '../Shared/DynamicGrid';
import {
  getPrintEditPricetagGrid,
  updatePriceTagStatus,
  getCurrentUser,
  getCoworkerDetails,
  getprofileaccess
} from '../../api/user';
import TwoFactorComponent from '../Shared/secondAuthen';
import { globalStyles } from '../componentstyles/globalstyles';
import { formStyles } from '../componentstyles/formstyles';
import { ADD_ON_PRICING, PrintEditPriceColumns } from '../../constants/constants';
import { useHistory } from 'react-router-dom';
import { printHTML } from '../Shared/print';
import PrintFunction from '../Shared/singleItemPriceTag';
import SIpricetagNYCA from '../Shared/SingleItemPriceTagCAandNY';
// import { validateData } from '../Shared/validateData';
import { validateData } from '../../constants/CommonMethods';
import { ReactComponent as AlertIcon } from '../../assets/images/no-records-found.svg';
import { ReactComponent as NoRecord } from '../../assets/images/No-records.svg';
import { ContainerContext } from '../../app/App';

import { CustomPropInterface } from '../../index';
import { InventoryMajorContext } from '../../context/inventoryMajorContext';
import { removeColumnHeader } from '../Shared/removeColumnHeader';


const languageArray = () => {
  return [
    {
      label: 'English',
      value: 'English',
    },
    {
      label: 'Spanish',
      value: 'Spanish',
    },
  ];
};

export default function PrintEditPriceTag() {
  const classes = globalStyles();
  const form = formStyles();
  const { headerValue } = useContext(InventoryContext);
  const {
    featureFlagDetails,
  } = useContext(InventoryMajorContext);
  const [headerContext, setheaderContext] = headerValue;
  const [orderBy, setorderBy] = useState<string>('inventoryNumber');
  const [order, setorder] = useState<string>(sortDirection.ASC);
  const [gridColumn, setGridColumn] = useState<columns[]>(PrintEditPriceColumns);
  const [tabledata, settabledata] = useState<any>();
  const [printItemData, setprintItemData] = useState<any>([]);
  const [DDValues, setDDValues] = useState<string>('');
  const [language, setlanguage] = useState<any>([]);
  const [errorMessage, seterrorMessage] = useState<any>({ noRecord: false, wentWrong: false });
  const [loader, setloader] = useState(false);
  const [itemNumber, setitemNumber] = useState<any[]>([]);
  const [priceId, setpriceId] = useState<any[]>([]);
  const [print, setprint] = useState(true);
  const [currentCWRole, setcurrentCWRole] = useState('');
  const history = useHistory();
  const itemNo: any = itemNumber;
  const priceIds: any = priceId;
  const currentStoreNumber = window.sessionStorage.getItem('storeNumber')
  const [singleItemVisble, setsingleItemVisble] = useState(false);
  const containerData = useContext(ContainerContext) as CustomPropInterface;

  const [showPopUp, setshowPopUp] = useState<any>({ pinPopUp: false, printPopUp: false, langSelectPopUp: false, apiFailedPopUp: false })
  const [profileDetails, setprofileDetails] = useState<any>();
  const [employeeId, setemployeeId] = useState<any>();
  const [singleItemNYandCA, setsingleItemNYandCA] = useState(false);
  const NYandCAtemplateArray = ["NYCSingleItemPriceTagV2", "NYSingleItemPriceTagV2", "CASingleItemPriceTagV2"];

  useEffect(() => {
    setGridColumn(removeColumnHeader(PrintEditPriceColumns, 'weeklyAddOnRate', featureFlagDetails[ADD_ON_PRICING] == '1' ? 0 : 1))
    setprint(true);
    setpriceId([]);
    setshowPopUp({ ...showPopUp, pinPopUp: false, printPopUp: false, langSelectPopUp: false, apiFailedPopUp: false });
    setlanguage(languageArray)
    PrintEditPricetagGrid();
    if (containerData !== undefined) {
      setcurrentCWRole(containerData?.GetRole())
      setemployeeId(containerData?.GetEmployeeId())
    }
    else {
      getCoWorkerRole();
    }
    const getprofileaccessDetails = async () => {
      const payload: any = {
        module: 'Print Updated Price Tag',
        storeNumber: currentStoreNumber,
        currentRole: containerData?.GetRole()
      }
      const result = await getprofileaccess(payload);
      validateData(result) ?
        setprofileDetails(result) :
        setprofileDetails(undefined);
    }
    getprofileaccessDetails();
    // getCoWorkerRole();
  }, []);

  const PrintEditPricetagGrid = async () => {
    try {
      setprint(true);
      setpriceId([]);
      setshowPopUp({ ...showPopUp, pinPopUp: false, printPopUp: false, langSelectPopUp: false, apiFailedPopUp: false });

      setloader(true);
      const res: any = await getPrintEditPricetagGrid(currentStoreNumber, '1');
      settabledata(undefined);
      setloader(false);
      if (res.status == 200 && validateData(res) && validateData(res.data) && validateData(res.data.response)) {
        if (res.data.response.length > 0) {

          res.data.response.forEach((value: any) => {

            switch (value.conditionDescEn) {
              case "Term pushed up/down by Coworker":
                value.conditionDescEn = 'Exception';
                break;
              case "Based on DOR-system push":
                value.conditionDescEn = 'Auto';
                break;
              case "New - Full Term":
                value.conditionDescEn = 'New';
                break;
            }

            value.rate = parseFloat((value.rate));
            value.turns = value?.turns ? value?.turns : parseFloat((value?.turns));
            value.depreciationPercentage = parseFloat((value.depreciationPercentage));
          })
          gridColumn.map((value: any) => {
            if (value.columnId == 'inventoryNumber') {
              value.sortOrder = sortDirection.ASC;
              value.IsSorted = true;
            }
            else {
              value.IsSorted = false;
            }
          })
          settabledata(res.data.response);
        }
        else {
          seterrorMessage({ ...errorMessage, noRecord: true });
        }

      } else {
        seterrorMessage({ ...errorMessage, wentWrong: true });
      }
    }
    catch {
      seterrorMessage({ ...errorMessage, wentWrong: true });

    }
  }
  console.log(' tabledata from getPrintEditPricetagGrid', tabledata);


  const getCoWorkerRole = async () => {
    try {

      const currentUserRes = await getCurrentUser();

      console.log('currentUserRes', currentUserRes);

      if (validateData(currentUserRes) == true && validateData(currentUserRes.status) == true && currentUserRes.status === 200) {
        const EmployeeId = validateData(currentUserRes.data) && validateData(currentUserRes.data.employeeId) ? currentUserRes.data.employeeId : '';
        console.log('EmployeeId', EmployeeId);

        const payload = {
          coworkerId: EmployeeId,
          storesRequired: true
        }
        console.log('menuandstores-payload', payload);

        const coWorkerRoleObj: any = await getCoworkerDetails(payload);
        if (
          validateData(coWorkerRoleObj) && coWorkerRoleObj.status == 200 &&
          validateData(coWorkerRoleObj.data.coworkerProfile) &&
          validateData(coWorkerRoleObj.data.coworkerProfile.role)
        ) {
          setcurrentCWRole(coWorkerRoleObj.data.coworkerProfile.role);
        }
        console.log('coWorkerRoleObj', coWorkerRoleObj);
      }

    }
    catch (e: any) {
      console.log('Error in co worker role', e);
    }
  };


  const setGridValues = (value: any) => {
    settabledata(value);
  };
  const setGridColumns = (value: any) => {
    setGridColumn(value);
  };

  const handleCheckedAll = (e: any, dataValues: any) => {
    if (e.target.checked === true) {


      dataValues.forEach((value: any) => {
        itemNo.push(value.inventoryNumber);
        priceIds.push(parseInt(value.inventoryPriceId));
      });

      setitemNumber(itemNo);
      const RemoveDuplicatepriceIds = [...new Set(priceIds)]
      setpriceId(RemoveDuplicatepriceIds);
      console.log('handleAllitem', itemNumber);
      console.log('handleAllPrice', priceId);

      setprint(false);
    } else {
      setitemNumber([]);
      setpriceId([]);
      setprint(true);
    }
  };

  const handleChecked = (e: any, index: any, values: any) => {
    if (e.target.checked === true) {

      itemNo.push(values.inventoryNumber);
      priceIds.push(parseInt(values.inventoryPriceId))
      setitemNumber(itemNo);
      const RemoveDuplicatepriceIds = [...new Set(priceIds)]
      setpriceId(RemoveDuplicatepriceIds);
      //setpriceId(priceIds);
      setprint(false);
      console.log("checkedItem", itemNumber);
      console.log("checkedpriceId", priceId);

    } else {

      itemNo.pop(values.inventoryNumber);
      priceIds.pop(parseInt(values.inventoryPriceId));
      setitemNumber(itemNo);
      const RemoveDuplicatepriceIds = [...new Set(priceIds)]
      setpriceId(RemoveDuplicatepriceIds);
      //setpriceId(priceIds);
      console.log("notcheckedItem", itemNumber)
      console.log("notcheckedpriceId", priceId)

      priceIds.length > 0 ? setprint(false) : setprint(true);

    }
  };

  const UpdatePrintEditPricetag = async () => {

    setshowPopUp({ ...showPopUp, langSelectPopUp: false, printPopUp: true });

    const res: any = await updatePriceTagStatus({ inventoryPriceIds: priceId });

    console.log('printResponse', res);
    setshowPopUp({ ...showPopUp, langSelectPopUp: false, printPopUp: false });

    if (res !== undefined && res !== null) {
      if (validateData(res.data) == true && validateData(res.data.response) == true) {
        const printPriceTagData = res.data.response;

        const PriceTagAry = res.data.response;
        console.log('PriceTagAry',PriceTagAry);
        setprintItemData(PriceTagAry);
        setpriceId([]);
        //setdesc([])
        if (NYandCAtemplateArray.includes(printPriceTagData[0]?.templateName)) {
          setsingleItemNYandCA(true);
          //handlePrint(document.getElementById('singleItemPriceTagNY'))
          printSingleItemPriceTagNYandCA();
          setsingleItemNYandCA(false);
        }
        else{
          setsingleItemVisble(true);
          printSingleItemPriceTag();
          setsingleItemVisble(false);
        }
        //setdesc([])
        PrintEditPricetagGrid();
      }
      else {
        setshowPopUp({ ...showPopUp, langSelectPopUp: false, apiFailedPopUp: true });
      }
    } else if (res.status == 400 || res.status == 500) {
      setshowPopUp({ ...showPopUp, langSelectPopUp: false, apiFailedPopUp: true });
    }

  };
  console.log('singleItemPriceData', printItemData)
  console.log('singleItemPriceDataitemNumber', itemNumber)


  const singlePriceTag = useRef<HTMLDivElement>(null);

  const printSingleItemPriceTag = useReactToPrint({
    content: () => singlePriceTag.current,
    pageStyle: `
    @media print {
      html, body {
        display: block !important;
        width: 130mm !important;
        height: 105mm !important;
        }  
    }
    `
  })
  const printSingleItemPriceTagNYandCA = useReactToPrint({
    content: () => singlePriceTag.current,
    pageStyle: `
    @media print {
      @page {
        size: 5in 7in;
      }
      body {
        width: 5in;
        height: 7in;
      }
    }
  `
  })


  const redirectPricetags = (e: any) => {
    const inventoryNum = e;
    setheaderContext({
      ...headerContext,
      inventoryNumber: '',
      description: '',
      status: '',
      storeNumber: ''
    });
    console.log('itemNumber', e);
    history.push({
      pathname: `/inventory/${inventoryNum}/pricetag`
    });
  };

  const showPopUps = () => {
    if (showPopUp.langSelectPopUp == true) {
      return (
        <Grid>
          <Typography
            style={{ textAlign: "justify" }}
            className={` ${classes.ml3} ${classes.mr3} ${classes.mb3} `}
            variant="h5"
          >
            {/* To continue please ensure that the Price Tag forms are in the paper
            tray of <br />the printer and select the language(s) in which to print the
            Price tags below:{' '} */}
            To continue please ensure that the Price Tag forms are in the  paper
            tray of the printer
          </Typography>
          {/* <Grid className={classes.componentCenter}>
            <Typography
              variant="caption"
              className={`${form.formLabel} ${classes.pr4} `}
            >
              Language
            </Typography>
            <Grid className={classes.w25}>
              <RACSelect
                classes={{ nativeSelect: classes.selectBox }}
                options={language}
                defaultValue={DDValues !== '' ? DDValues : 'English'}
                onChange={(e: any) => setDDValues(e.target.value)}
              />
            </Grid>
          </Grid> */}
          <Grid className={`${classes.componentCenter} ${classes.pt4}`}>
            <RACButton
              onClick={() => setshowPopUp({ ...showPopUp, langSelectPopUp: false })}
              variant="outlined"
              color="secondary"
              className={` ${classes.textBlack} ${classes.mr3}`}
            >
              Cancel
            </RACButton>
            <RACButton
              style={{ marginTop: '-1px' }}
              className={`${classes.px7}`}
              onClick={() => UpdatePrintEditPricetag()}
              variant="contained"
              color="primary"
            >
              OK
            </RACButton>
          </Grid>
        </Grid>
      );
    }
    else if (showPopUp.printPopUp == true) {
      return (
        <React.Fragment>
          <Grid>
            <Typography className={`${classes.componentCenter} `}>
              Printing ....
            </Typography>
            <Grid className={`${classes.componentCenter} ${classes.pt4}`}>
              <CircularProgress />
            </Grid>
            <Typography className={`${classes.componentCenter} ${classes.pt4}`}>
              Single Item Price Tag
            </Typography>
          </Grid>

        </React.Fragment>
      );
    }
    else if (showPopUp.apiFailedPopUp == true) {
      return (
        <React.Fragment>
          <Grid>
            <Typography className={`${classes.componentCenter} `}>
              <AlertIcon />
            </Typography>
            <Typography className={`${classes.componentCenter} ${classes.pt4}`}>
              Price Tag Printing Failed
            </Typography>
            <Grid className={`${classes.componentCenter} ${classes.pt4}`}>
              <RACButton
                style={{ marginTop: '-1px' }}
                className={`${classes.px7}`}
                onClick={() => setshowPopUp({ ...showPopUp, langSelectPopUp: false, apiFailedPopUp: false })}
                variant="contained"
                color="primary"
              >
                OK
              </RACButton>
            </Grid>
          </Grid>

        </React.Fragment>
      );
    }
  };


  const onCompletePin = (e: any) => {
    if (e == true) {
      setshowPopUp({ ...showPopUp, pinPopUp: false, langSelectPopUp: true })
    }
    else {
      setshowPopUp({ ...showPopUp, pinPopUp: false })
    }
  }

  return (
    <React.Fragment>
      <Grid >
        <Grid >
          <Typography variant="h4" className={` ${classes.mb3} ${classes.ml2}`} style={{ paddingTop: '12px' }}>
            {' '}
            Print - Edit Price Tags
          </Typography>
        </Grid>
        {loader === true ? (
          <Grid>
            <Grid className={classes.racLoaderPage}>
              <CircularProgress style={{ marginTop: '0px' }} />
            </Grid>
          </Grid>
        ) : (
          <Grid className={classes.mbFooterSpacing} >
            {errorMessage.wentWrong === true ? (
              <Card className={`${classes.m2} ${classes.racCard}`} style={{ minHeight: "520px" }}>
                <CardContent>
                  <Grid className={`${classes.textCenter} ${classes.mt5}`} style={{ padding: "10%" }}>
                    <AlertIcon />
                    <Typography className={`${classes.subTitle} ${classes.mb3}`}>
                      Something Went Wrong
                    </Typography>
                  </Grid>
                </CardContent>
              </Card>
            ) :
              // errorMessage.noRecord === true ?(
              //   <Card className={`${classes.m2} ${classes.racCard}`} style={{ minHeight: "520px"}}>
              //   <CardContent>
              //       <Grid className={`${classes.textCenter} ${classes.mt5}`} style={{ padding: "10%" }}>
              //         <NoRecord />
              //         <Typography className={`${classes.subTitle} ${classes.mb3}`}>
              //           No Records Found
              //         </Typography>
              //       </Grid>
              //   </CardContent>
              //   </Card>
              // ):
              (
                <Card className={`${classes.m2} ${classes.racCard}`} >
                  <CardContent>
                    <DynamicGridComponent
                      id='PrintPriceTags'
                      gridtype="withCheckBox"
                      items={tabledata}
                      type='printEditPriceTag'
                      columns={gridColumn}
                      orderBy={orderBy}
                      sortRefresh={setpriceId}
                      order={order}
                      sorting={true}
                      gridColumn={gridColumn}
                      gridValues={tabledata}
                      setGridValues={(e) => setGridValues(e)}
                      setGridColumns={(e) => setGridColumns(e)}
                      handleAnkerType={(e) => redirectPricetags(e)}
                      handleActionType={(e) => e}
                      handleCheckedAll={(e, dataValues) =>
                        handleCheckedAll(e, dataValues)
                      }
                      handleChecked={(e, index, values) =>
                        handleChecked(e, index, values)
                      }
                      statusCode={errorMessage}
                      throwStyle={classes}
                      setOrder={(e) => setorder(e)}
                      setOrderBy={(e) => setorderBy(e)}
                    ></DynamicGridComponent>
                  </CardContent>
                </Card>
              )

            }
          </Grid>
        )}


        {/* footer */}
        <Grid container className={classes.fixedFooter}>
          <Grid className={classes.floatLeft}>
            <RACButton
              variant="outlined"
              color="primary"
              className={classes.ms2}
              onClick={() => history.push('/dashboard')}
            >
              Cancel
            </RACButton>
          </Grid>
          <Grid className={classes.floatRight}>
            <RACButton
              className={`${classes.mx1} ${classes.floatRight}`}
              size="small"
              variant="contained"
              disabled={print}
              color="primary"
              onClick={() => setshowPopUp({ ...showPopUp, pinPopUp: true })}
            >
              Print
            </RACButton>
          </Grid>
        </Grid>
      </Grid>

      {/* actionPopup */}
      <Grid>
        <RACModalCard
          isOpen={showPopUp.langSelectPopUp == true || showPopUp.printPopUp == true || showPopUp.apiFailedPopUp == true ? true : false}
          title=""
          maxWidth={showPopUp.langSelectPopUp == true ? "xs" : "sm"}
          borderRadius='20px'
          closeIcon={showPopUp.langSelectPopUp == true ? true : false}
          className={showPopUp.langSelectPopUp == true ? `${classes.SelectLanguageModalWidth}` : `${classes.priceTagPrintModalWidth}`}
          onClose={() => setshowPopUp({ ...showPopUp, langSelectPopUp: false })}
          children={showPopUps()}
        ></RACModalCard>
      </Grid>

      {/* pin Popup */}
      {showPopUp.pinPopUp === true ?
        <TwoFactorComponent
          setTwoFactorCancelClick={() =>
            setshowPopUp({ ...showPopUp, pinPopUp: false })
          }
          setTwoFactorCompleted={(e: any) => onCompletePin(e)}
          moduleName={'Print Updated Price Tag'}
          CurrentRole={currentCWRole}
          employeeID={employeeId}
          getprofileaccessResponse={profileDetails}
        ></TwoFactorComponent>
        : null}

      {/* printScreen */}


      {singleItemVisble == true ?
        <div id="activities" ref={singlePriceTag}>
          <PrintFunction
            printItemNo={itemNumber}
            printLanguage={""}//{DDValues}
            printItemResp={printItemData}
          ></PrintFunction>
        </div> : null}
      {
        singleItemNYandCA === true ?
          <div id="activities" ref={singlePriceTag}>
            <SIpricetagNYCA
              printItemNo={itemNumber}
              printLanguage=''
              printItemResp={printItemData}
            />
          </div> : null
      }
    </React.Fragment>
  );
}







