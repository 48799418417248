/* eslint-disable prettier/prettier */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import { AppRoute } from '../config/route-config';
import { UserProvider } from '../context/user/UserProvider';
import { UserFetcher } from '../components/UserFetcher/UserFetcher';
import SearchResult from '../components/InventorySearch/SearchResults';
import { InventoryContextProvider } from '../context/InventoryInfoContext';
import {
  InventoryMajorContextProvider,
  InventoryMajorContext,
} from '../context/inventoryMajorContext';
import { InventorySearchContextProvider } from '../context/SearchInventoryContext';
import PrintEditPriceTag from '../components/PrintEditPriceTags/PrintEditPriceComponent';
import { InventoryInfoRoute } from '../components/InventoryTabs/InventoryInfoRoute';
import PackagePriceTags from '../components/NewPackageItemSearch/PackagePriceTags/PackagePriceTags';
import ItemSearchResult from '../components/NewPackageItemSearch/ItemSearch/searchResults';
import InventoryTransferReceive from '../components/InventoryTransferReceive/InventoryTransferReceiveComponent';
import InventoryAuditHistory from '../components/InventoryAudit/InventoryAuditHistory';
import InventoryAuditInfo from '../components/InventoryAudit/InventoryAuditInfo';
import InventoryAuditExplanation from '../components/InventoryAudit/InventoryAuditExplanation';
import { PackagePriceTagsContextProvider } from '../context/PackagePriceTagContext';
export const routesTestId = 'routesTestId';
import { useContext, useEffect } from 'react';
import { featureFlagInfo } from '../api/user';
import { CircularProgress } from '@rentacenter/racstrap';
import ComingSoonPage from '../components/ComingSoonPage';
import StoreServiceSummaryInfo from '../components/ItemBeingServiced/Services';
import InvService from '../components/InventoryTabs/Services/NewRequest';
import { transformConfigDetails } from '../components/Shared/removeColumnHeader';
import { ADD_ON_PRICING } from '../constants/constants';
export const Routes = () => {
  const {
    featureFlagResponse,
    setfeatureFlagResponse,
    searchInvFeature,
    setsearchInvFeature,
    invInfoFeature,
    setinvInfoFeature,
    invDetailsFeature,
    setinvDetailsFeature,
    idleInvAuditFeature,
    setidleInvAuditFeature,
    invActivitiesFeature,
    setinvActivitiesFeature,
    agrHistoryFeature,
    setagrHistoryFeature,
    pricetagFeature,
    setpricetagFeature,
    printEditPriceTagsFeature,
    setprintEditPriceTagsFeature,
    transferinvFeature,
    settransferinvFeature,
    newPackageFeature,
    setnewPackageFeature,
    inventoryAuditFeature,
    setInventoryAuditFeature,
    itemBeingServicedFeature,
    setItemBeingServicedFeature,
    inventoryServicingFeature,
    setInventoryServicingFeature,
    setRecoverFeature, setFeatureFlagDetails,
    setSerialNumberEditRoles,
    setCancelChargeOffRoles,
    setApproveChargeOffRoles
  } = useContext(InventoryMajorContext);
  const [gotFeatureFlagRes, setgotFeatureFlagRes] = useState(false);
  console.log('Inside Route.tsx in INV MFE');


  useEffect(() => {
    const featureFlagCall = async () => {
      const StoreId = window.sessionStorage.getItem('storeNumber')
      const featureFlagReq = {
        storeNumbers: [window.sessionStorage.getItem('storeNumber')],
        paramKeyNames: [
          'InventorySearch',
          'InventoryInformation',
          'InventoryDetails',
          'IdleInventoryAudit',
          'InventoryActivities',
          'AgreementHistory',
          'PriceTag',
          'PrintEditPriceTags',
          'TransferInventory',
          'NewPackageItemSearch',
          'InventoryAudit',
          'ItemBeingServiced',
          'InventoryServicing',
          'InventoryRecovery',
          ADD_ON_PRICING,
          'EditSerialNumbers',
          'ShowCancelChargeOff',
          'ShowApproveChargeOff'
        ],
      };

      let FeatureFlagResponse

      if (StoreId && StoreId != null && StoreId != undefined && StoreId != '' && StoreId != 'null' && StoreId != 'undefined') {
        FeatureFlagResponse = await featureFlagInfo(featureFlagReq);
      }
      console.log('FeatureFlagResponse', FeatureFlagResponse);
      setgotFeatureFlagRes(true);
      if (FeatureFlagResponse.status == 200) {
        const FeatureFlagData = FeatureFlagResponse.data;
        const FeatureFlagConfigArray =
          FeatureFlagData.storeProfileResponse.configDetails[0].configDetails;
        setfeatureFlagResponse(FeatureFlagData);
        setFeatureFlagDetails(transformConfigDetails(FeatureFlagData.storeProfileResponse.configDetails[0].configDetails));

        const serialNoEditRoles = FeatureFlagConfigArray.filter(
          (element: any) => element.paramKeyName == 'EditSerialNumbers'
        );
        setSerialNumberEditRoles(serialNoEditRoles.length > 0 ? JSON.parse(serialNoEditRoles[0].paramValue) : {});

        const cancelCORoles = FeatureFlagConfigArray.filter(
          (element: any) => element.paramKeyName == 'ShowCancelChargeOff'
        );
        setCancelChargeOffRoles(cancelCORoles.length > 0 ? JSON.parse(cancelCORoles[0].paramValue) : {});

        const approveCORoles = FeatureFlagConfigArray.filter(
          (element: any) => element.paramKeyName == 'ShowApproveChargeOff'
        );
        setApproveChargeOffRoles(approveCORoles.length > 0 ? JSON.parse(approveCORoles[0].paramValue) : {});

        const invSearchFlagDetails = FeatureFlagConfigArray.filter(
          (element: any) => element.paramKeyName == 'InventorySearch'
        );
        const invinfoFlagDetails = FeatureFlagConfigArray.filter(
          (element: any) => element.paramKeyName == 'InventoryInformation'
        );
        const invDetailsFlagDetails = FeatureFlagConfigArray.filter(
          (element: any) => element.paramKeyName == 'InventoryDetails'
        );
        const idleInvAuditFlagDetails = FeatureFlagConfigArray.filter(
          (element: any) => element.paramKeyName == 'IdleInventoryAudit'
        );
        const invActivitiesFlagDetails = FeatureFlagConfigArray.filter(
          (element: any) => element.paramKeyName == 'InventoryActivities'
        );
        const invAgrHisFlagDetails = FeatureFlagConfigArray.filter(
          (element: any) => element.paramKeyName == 'AgreementHistory'
        );
        const priceTagFlagDetails = FeatureFlagConfigArray.filter(
          (element: any) => element.paramKeyName == 'PriceTag'
        );
        const printEditPriceTagFlagDetails = FeatureFlagConfigArray.filter(
          (element: any) => element.paramKeyName == 'PrintEditPriceTags'
        );
        const transferFlagDetails = FeatureFlagConfigArray.filter(
          (element: any) => element.paramKeyName == 'TransferInventory'
        );
        const newPackageFlagDetails = FeatureFlagConfigArray.filter(
          (element: any) => element.paramKeyName == 'NewPackageItemSearch'
        );
        const inventoryAuditFlagDetails = FeatureFlagConfigArray.filter(
          (element: any) => element.paramKeyName == 'InventoryAudit'
        );
        const itemBeingServicedDetails = FeatureFlagConfigArray.filter(
          (element: any) => element.paramKeyName == 'ItemBeingServiced'
        );
        const inventoryServicingFlagDetails = FeatureFlagConfigArray.filter(
          (element: any) => element.paramKeyName == 'InventoryServicing'
        );
        const recoverFlagDetails = FeatureFlagConfigArray.filter(
          (element: any) => element.paramKeyName == 'InventoryRecovery'
        );
        setsearchInvFeature(invSearchFlagDetails[0].paramValue);
        setinvInfoFeature(invinfoFlagDetails[0].paramValue);
        setinvDetailsFeature(invDetailsFlagDetails[0].paramValue);
        setidleInvAuditFeature(idleInvAuditFlagDetails[0].paramValue);
        setinvActivitiesFeature(invActivitiesFlagDetails[0].paramValue);
        setagrHistoryFeature(invAgrHisFlagDetails[0].paramValue);
        setpricetagFeature(priceTagFlagDetails[0].paramValue);
        setprintEditPriceTagsFeature(
          printEditPriceTagFlagDetails[0].paramValue
        );
        settransferinvFeature(transferFlagDetails[0].paramValue);
        setnewPackageFeature(newPackageFlagDetails[0].paramValue);
        setInventoryAuditFeature(inventoryAuditFlagDetails[0].paramValue);
        setItemBeingServicedFeature(itemBeingServicedDetails[0].paramValue);
        setInventoryServicingFeature(
          inventoryServicingFlagDetails[0].paramValue
        );
        setRecoverFeature(recoverFlagDetails[0].paramValue);
      }
    };
    featureFlagCall();
  }, []);
  return gotFeatureFlagRes ? (
    <>
      <UserProvider>
        <UserFetcher />
        <div data-testid={routesTestId}>
          <InventoryContextProvider>
            <InventorySearchContextProvider>
              <PackagePriceTagsContextProvider>
                <Switch>
                  <Route
                    exact
                    path={[
                      AppRoute.InventorySearch,
                      AppRoute.InventorySearchPaidIn,
                      AppRoute.InventorySearchPaidOut,
                    ]}
                  >
                    {searchInvFeature == '1' ? (
                      <SearchResult />
                    ) : (
                      <ComingSoonPage />
                    )}
                  </Route>
                  <Route exact path={AppRoute.PrintEditPriceTagList}>
                    {printEditPriceTagsFeature == '1' ? (
                      <PrintEditPriceTag />
                    ) : (
                      <ComingSoonPage />
                    )}
                  </Route>
                  <Route exact path={AppRoute.ItemBeingServiced}>
                    {itemBeingServicedFeature == '1' ? (
                      <StoreServiceSummaryInfo />
                    ) : (
                      <ComingSoonPage />
                    )}
                  </Route>
                  <Route
                    exact
                    path={[
                      AppRoute.InventoryServiceRequest,
                      AppRoute.RetentionInventoryServiceRequest,
                    ]}
                  >
                    {inventoryServicingFeature == '1' ? (
                      <InvService />
                    ) : (
                      <ComingSoonPage />
                    )}
                  </Route>
                  <Route exact path={AppRoute.PackagePriceTagList}>
                    {newPackageFeature == '1' ? (
                      <PackagePriceTags />
                    ) : (
                      <ComingSoonPage />
                    )}
                  </Route>
                  <Route exact path={AppRoute.ItemSearch}>
                    {newPackageFeature == '1' ? (
                      <ItemSearchResult />
                    ) : (
                      <ComingSoonPage />
                    )}
                  </Route>
                  <Route exact path={AppRoute.InventoryTransferReceive}>
                    {transferinvFeature == '1' ? (
                      <InventoryTransferReceive />
                    ) : (
                      <ComingSoonPage />
                    )}
                  </Route>
                  <Route exact path={AppRoute.InventoryAuditHistory}>
                    {inventoryAuditFeature == '1' ? (
                      <InventoryAuditHistory />
                    ) : (
                      <ComingSoonPage />
                    )}
                  </Route>
                  <Route exact path={AppRoute.InventoryAuditInfo}>
                    {inventoryAuditFeature == '1' ? (
                      <InventoryAuditInfo />
                    ) : (
                      <ComingSoonPage />
                    )}
                  </Route>
                  <Route
                    path={[
                      AppRoute.InventoryAuditExplanation,
                      AppRoute.InvAudExp1,
                      AppRoute.InvAudExp2,
                      AppRoute.InvAudExp3,
                      AppRoute.InvAudExp4,
                      AppRoute.InvAudExp5,
                      AppRoute.InvAudExp6,
                    ]}
                  >
                    {inventoryAuditFeature == '1' ? (
                      <InventoryAuditExplanation />
                    ) : (
                      <ComingSoonPage />
                    )}
                  </Route>
                  <Route path={AppRoute.Inventory}>
                    <InventoryInfoRoute />
                  </Route>
                </Switch>
              </PackagePriceTagsContextProvider>
            </InventorySearchContextProvider>
          </InventoryContextProvider>
        </div>
      </UserProvider>
    </>
  ) : (
    <>
      <div style={{ margin: '20% 20% 20% 48%' }}>
        <CircularProgress size={30} />
      </div>
    </>
  );
};
