/* eslint-disable prettier/prettier */
/* eslint-disable sonarjs/prefer-immediate-return */
/* eslint-disable @typescript-eslint/no-unused-vars */

import { makeStyles } from '@rentacenter/racstrap';
export const searchResultStyles = () => {
  const useClasses = makeStyles((theme) => ({
    expandWigid: {
      width: '100%',
    },
    collapsedWigid: {
      width: '75%',
    },
    disableme: {
      pointerEvents: 'none',
      opacity: 0.9,
    },
     col:{
          flex: "0 0 auto",
          width: "50%"
        },
    hide:{
      display:"none"
    }
  }));
  
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const classes = useClasses();

  return classes;
};
