/* eslint-disable */
import React, { useState, createContext } from 'react';

export interface PackageProps {
  inventories: [];
  packageName: '';
}
export const PackagePriceContext = createContext<any>('');

export const PackagePriceTagsContextProvider = (props: any) => {
  const [packagePriceTagsOne, setPackagePriceTags] = useState<PackageProps>();
  const [initialAgrData, setinitialAgrData] = useState();
  const [searchCriteria, setsearchCriteria] = useState();
  const [packageDetailContext, setpackageDetailContext] = useState<any>({
    packageId: "",
    packageName: "",
    primaryItem: [],
    packageTerm: "",
    packageRate: "",
  })
  const [initialAgrTerm, setInitialAgrTerm] = useState<any>()


  return (
    <React.Fragment>
      <PackagePriceContext.Provider
        value={{
          packagePriceTagsOne,
          setPackagePriceTags,
          initialAgrData,
          setinitialAgrData,
          packageContextValue: [packageDetailContext, setpackageDetailContext],
          searchCriteria,
          setsearchCriteria,
          initialAgrTerm,
          setInitialAgrTerm

        }}
      >
        {props.children}
      </PackagePriceContext.Provider>
    </React.Fragment>
  );
};
