/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable sonarjs/prefer-immediate-return */
/* eslint-disable sonarjs/no-duplicate-string */
import { RACCOLOR, makeStyles } from "@rentacenter/racstrap";
export const tabs = () => {
    const useClasses = makeStyles((theme) => ({

        tabItem: {
            fontFamily: 'OpenSans-semibold',
            fontSize: theme.typography.pxToRem(16),
            borderBottom: '3px solid transparent',
            cursor: 'pointer',
        },
        tabItemActive: {
            color: '#2179FE',
            borderBottom: '3px solid #2179FE',
        }


    }));
    const classes = useClasses();
    return classes;
};