/* eslint-disable prettier/prettier */
/* eslint-disable react-hooks/rules-of-hooks */
import { makeStyles, RACCOLOR } from '@rentacenter/racstrap';
import arrow from '../../assets/images/arrow.svg';
export const searchBlueCardStyles = () => {
  const useClasses = makeStyles((theme) => ({
    accordionButton: {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      fontSize: '14px',
      color: '#ffffff',
      textAlign: 'left',
      border: 0,
      borderRadius: 0,
      cursor: 'pointer',
      float: 'left',
      backgroundColor: 'transparent',
      padding: 0,
      '&::after': {
        flexShrink: 0,
        width: '13px',
        height: '13px',
        marginLeft: '3px',
        content: '',
        backgroundImage: `url(${arrow})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100%',
        transition: 'transform 0.2s ease-in-out',
        backgroundPosition: 'center',
      },
    },
    accordionArrow: {
      marginLeft: 0,
      backgroundSize: '11px',
      boxShadow: 'none',
    },

    racGreen: {
      backgroundColor: ' #5ae286',
    },
    racBadge: {
      padding: '3px',
      borderRadius: '10px',
      marginLeft: '8px',
    },
    badge: {
      display: 'inline-block',
      padding: '0.35em 0.65em',
      fontSize: '0.75em',
      fontWeight: 700,
      lineHeight: 1,
      color: '#fff',
      textAlign: 'center',
      whiteSpace: 'nowrap',
      verticalAlign: 'baseline',
      borderRadius: '0.25rem',
      backgroundColor: '#198754',
    },
    agrSplitup: {
      color: `${RACCOLOR.WHITE}`,
      fontSize: theme.typography.pxToRem(13),
    },
    halfview: {
      display: 'none',
    },
    fullview: {
      display: 'block',
    },
    enableView: {
      pointerEvents: 'auto',
    },
  }));
  return useClasses();
};
