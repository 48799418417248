/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable sonarjs/no-duplicate-string */

import { makeStyles } from '@rentacenter/racstrap';
export const fixedGridStyles = () => {
    const useClasses = makeStyles((theme) => ({
        fixTableHead: {
            overflowY: 'auto',
            maxHeight: '500px',
            '& th': {
                position: 'sticky',
                top: 0,
                backgroundColor: '#ffffff',
                fontFamily: 'OpenSans-semibold',
                zIndex: 1,
                padding: '0.9375rem 0.2rem',
                whiteSpace: 'nowrap',
            },
            '& tbody': {
                '& tr': {
                    position: 'sticky',
                    top: 10,
                    backgroundColor: '#ffffff',
                },
                '& td': {
                    fontSize:theme.typography.pxToRem(14),
                    fontFamily: 'OpenSans-semibold',
                    padding: '0.9375rem 0.2rem',
                    color: '#4A5174',
                },
            },
        },
        fixTableHeadRA: {
            overflowY: 'auto',
            maxHeight: '205px',
            '& th': {
                position: 'sticky',
                top: 0,
                backgroundColor: '#ffffff',
                fontFamily: 'OpenSans-semibold',
                zIndex: 1,
                padding: '0.9375rem 0.26rem',
                whiteSpace: 'nowrap',
            },
            '& tbody': {
                '& tr': {
                    position: 'sticky',
                    top: 10,
                    backgroundColor: '#ffffff',
                },
                '& td': {
                    fontFamily: 'OpenSans-semibold',
                    padding: '0.9375rem 0.26rem',
                    color: '#4A5174',
                    fontSize:theme.typography.pxToRem(14),
                },
            },
        },
        fixTableHeight160: {
            maxHeight: '160px',
        },
        fixTableHeight300: {
            maxHeight: '300px',
        },
        fixTableHeight400: {
            maxHeight: '400px',
        },
        fixTableHeight535: {
            maxHeight: '535px',
        },
        fixTableHeight600: {
            maxHeight: '600px',
        },
        fixTableHeight275: {
            maxHeight: '275px',
        },
        fixTableHeight310: {
            maxHeight: '310px',
        },
        fixTableHeight330: {
            maxHeight: '330px',
        },
        fixTableHeight480: {
            maxHeight: '480px',
        },
        fixTableHeight390:{
            maxHeight: '390px',
        },
        //fixGrid By Charitra
        fixGrid: {
            overflowY: 'auto',
            '& th': {
                position: 'sticky',
                top: 0,
                backgroundColor: '#ffffff',
                fontFamily: 'OpenSans-semibold',
                zIndex: 1,
                padding: '0.9375rem 0.2rem',
                whiteSpace: 'nowrap',
            },
            '& tbody': {
                '& tr': {
                    position: 'sticky',
                    top: 10,
                    backgroundColor: '#ffffff',
                },
                '& td': {
                    fontFamily: 'OpenSans-semibold',
                    padding: '0.9375rem 0.2rem',
                    color: '#4A5174',
                    fontSize:theme.typography.pxToRem(14),
                },
            },
        },


    }));
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useClasses();
};