/* eslint-disable sonarjs/no-identical-functions */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable no-debugger */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/react-in-jsx-scope */
import {
  Grid,
  Typography,
  Card,
  CardContent,
  RACTable,
  RACTableCell,
  RACTableRow,
  RACButton,
  RACModalCard,
  CircularProgress,
} from '@rentacenter/racstrap';
import { useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { ReactComponent as SomethingwentwrongIcon } from '../../assets/images/no-records-found.svg';
import { ReactComponent as NoRecordFoundIcon } from '../../assets/images/No-records.svg';
import { ReactComponent as ErrorMsgIcon } from '../../assets/images/error-icon.svg';
import InfiniteScroll from 'react-infinite-scroll-component';
import { getInventoryAuditHistory } from '../../api/user';
import { InventoryAuditContext } from '../../context/InventoryAuditContext';
import { InventoryGlobalStyles } from '../../components/InventorySearch/Styles/InventoryGlobalStyles';
export default function InventoryAuditHistory() {
  const history = useHistory();
  const classes = InventoryGlobalStyles();
  const [idleBody, setIdleBody] = useState<any[]>([]);
  const [offSet, setOffSet] = useState<any>(1);
  const [noRecordsFound, setNoRecordsFound] = useState<any>(false);
  const [errorMsg, setErrorMsg] = useState<any>('');
  const [errorMsgPopup, setErrorMsgPopup] = useState<any>(false);
  const [somethingWentWrong, setSomethingWentWrong] = useState<any>(false);
  const [emptyGridIdleBody, setEmptyGridIdleBody] = useState<any>(false);
  const {
    hiddenLoader,
    setHiddenLoader,
    loaderCircle,
    setLoaderCircle,
    hasMore,
    setHasMore,
    setTabsShow,
  } = useContext(InventoryAuditContext);
  const IdleInventoryHeaderArray = [
    { itemHead: 'Audit ID' },
    { itemHead: 'Audit Date' },
    { itemHead: '# Exception' },
    { itemHead: 'Audit By' },
    { itemHead: 'Closed By' },
    { itemHead: 'Audit Status' },
  ];
  useEffect(() => {
    inventoryAuditHistoryfnc();
  }, []);
  const inventoryAuditHistoryfnc = async () => {
    const storeNumber = window.sessionStorage.getItem('storeNumber');
    setHiddenLoader(true);
    const result: any = await getInventoryAuditHistory(storeNumber, offSet);
    setHiddenLoader(false);
    if (result?.status == 200) {
      {
        const finalResult = result?.data?.storeAuditDetails;
        finalResult
          ? setIdleBody(finalResult)
          : (setNoRecordsFound(true), setEmptyGridIdleBody(true));
        console.log('result', result);
      }
    } else if (result?.status == 400) {
      setErrorMsgPopup(true);
      setErrorMsg(result?.data?.errors[0]?.error);
      console.log('finalResult', result);
    } else {
      setSomethingWentWrong(true);
      console.log('finalResult', result);
    }
    console.log('InventoryAuditHistory Result', result);
  };
  const fetchNextData = async () => {
    const storeNumber = window.sessionStorage.getItem('storeNumber');
    setLoaderCircle(true);
    setHasMore(true);
    setOffSet(offSet + 1);
    const result: any = await getInventoryAuditHistory(storeNumber, offSet + 1);
    setHiddenLoader(false);
    if (result?.status == 200) {
      {
        const finalResult = result?.data?.storeAuditDetails;
        finalResult?.length > 0
          ? setIdleBody([...idleBody, ...finalResult])
          : (setNoRecordsFound(true), setEmptyGridIdleBody(true));
        console.log('idleBody', idleBody);
        console.log('finalResult', finalResult);
      }
    } else if (result?.status == 400) {
      setErrorMsgPopup(true);
      setErrorMsg(result?.data?.errors[0]?.error);
    } else {
      setSomethingWentWrong(true);
    }
    if (result?.data?.storeAuditDetails?.length != 50) {
      setHasMore(false);
    }
    console.log('InventoryAuditHistory Result', result);
  };
  const idleInventoryHead = () => (
    <>
      {emptyGridIdleBody
        ? null
        : IdleInventoryHeaderArray?.map((obj: any, index: any) => {
            return (
              <RACTableCell key={index} style={{ fontFamily: 'OpenSans-bold' }}>
                {obj.itemHead}
              </RACTableCell>
            );
          })}
    </>
  );
  const auditIdRedirection = (auditId: any) => {
    history.push({
      pathname: `/inventory/audit/info/${auditId}`,
    });
    setTabsShow({
      ItemNotScannedShow: true,
      ItemScannedWithExceptionShow: false,
      ItemsNeedAttentionShow: false,
      ItemScanwithNoExceptionShow: false,
      DaysIdleNotInFrontShow: false,
    });
  };
  const hideLengthyText = (Text: any, MaxLength: number, Mask: any) => {
    return Text.length > MaxLength ? Text.substring(0, MaxLength) + Mask : Text;
  };
  const idleInventoryBody = () => (
    <>
      {noRecordsFound ? (
        <>
          <RACTableRow style={{ background: 'none' }}>
            <RACTableCell style={{ float: 'left', width: '72%' }}>
              <Grid className={`${classes.textCenter} ${classes.mt5}`}>
                <NoRecordFoundIcon style={{ marginLeft: '30%' }} />
                <Typography
                  className={`${classes.subTitle} ${classes.mb3}`}
                  style={{ marginLeft: '30%' }}
                >
                  No records found
                </Typography>
              </Grid>
            </RACTableCell>
          </RACTableRow>
        </>
      ) : (
        <>
          {idleBody?.map((obj: any, index: any) => {
            return (
              <RACTableRow key={index} style={{ background: 'none' }}>
                <RACTableCell
                  style={{ color: '#2179FE', cursor: 'pointer', width: '13%' }}
                  onClick={(e) => auditIdRedirection(obj?.auditId)}
                >
                  {obj?.auditId}
                </RACTableCell>
                <RACTableCell style={{ width: '14%' }}>
                  {obj?.auditDate ? (
                    obj.auditDate
                  ) : (
                    <span style={{ marginLeft: '11%' }}>-</span>
                  )}
                </RACTableCell>
                <RACTableCell style={{ width: '13%' }}>
                  {obj?.exceptionCount ? (
                    obj?.exceptionCount
                  ) : (
                    <span style={{ marginLeft: '11%' }}>-</span>
                  )}
                </RACTableCell>
                <RACTableCell style={{ width: '19%' }} title={obj.auditBy}>
                  {obj?.auditBy ? (
                    hideLengthyText(obj?.auditBy, 25, '...')
                  ) : (
                    <span style={{ marginLeft: '11%' }}>-</span>
                  )}
                </RACTableCell>
                <RACTableCell style={{ width: '15%' }} title={obj.closedBy}>
                  {obj?.closedBy ? (
                    hideLengthyText(obj?.closedBy, 25, '...')
                  ) : (
                    <span style={{ marginLeft: '11%' }}>-</span>
                  )}
                </RACTableCell>
                <RACTableCell style={{ width: '10%' }}>
                  {obj?.auditStatus}
                </RACTableCell>
              </RACTableRow>
            );
          })}
        </>
      )}
    </>
  );
  const ErrorMsgfn = () => {
    return (
      <div
        id="initialpayment"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-hidden="true"
      >
        <Grid className={classes.textCenter}>
          <ErrorMsgIcon></ErrorMsgIcon>
          <Typography className={classes.formLabel}></Typography>
          {errorMsg}
        </Grid>
        <Grid
          item
          md={12}
          className={`${classes.mt3} ${classes.pb4} ${classes.textCenter}`}
        >
          <RACButton
            className={classes.mx1}
            variant="contained"
            color="primary"
            onClick={() => {
              setErrorMsgPopup(false);
            }}
          >
            Ok
          </RACButton>
        </Grid>
      </div>
    );
  };
  const somethingWentWrongfn = () => {
    return (
      <div
        id="initialpayment"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-hidden="true"
      >
        <Grid className={classes.textCenter}>
          <SomethingwentwrongIcon />
          <Typography className={classes.formLabel} variant="h5">
            something wentWrong
          </Typography>
        </Grid>
        <Grid
          item
          md={12}
          className={`${classes.mt3} ${classes.pb4} ${classes.textCenter}`}
        >
          <RACButton
            className={classes.mx1}
            variant="contained"
            color="primary"
            onClick={() => {
              setSomethingWentWrong(false);
            }}
          >
            Ok
          </RACButton>
        </Grid>
      </div>
    );
  };
  return (
    <>
      <Grid>
        <Typography
          variant="h4"
          style={{ fontWeight: 'bold', marginTop: ' 13px', marginLeft: '9px' }}
        >
          Idle Inventory Audit History
        </Typography>
        <Card
          className={classes.cardidle}
          style={{
            overflow: 'auto',
            width: '100%',
            marginTop: ' 15px',
            height: 'auto',
          }}
        >
          <CardContent>
            {idleBody.length > 49 ? (
              <InfiniteScroll
                next={fetchNextData}
                dataLength={idleBody.length}
                hasMore={hasMore}
                height={500}
                loader={
                  loaderCircle ? (
                    <Grid className={classes.GridLoader}>
                      <CircularProgress></CircularProgress>
                    </Grid>
                  ) : null
                }
              >
                <RACTable
                  className={`${classes.fixTableHead}`}
                  renderTableHead={idleInventoryHead}
                  renderTableContent={idleInventoryBody}
                  stickyHeader
                ></RACTable>
              </InfiniteScroll>
            ) : (
              <RACTable
                className={`${classes.fixTableHead}`}
                renderTableHead={idleInventoryHead}
                renderTableContent={idleInventoryBody}
                stickyHeader
              ></RACTable>
            )}
          </CardContent>
        </Card>
        <Grid className={classes.fixedBottom}>
          <RACButton
            style={{ fontFamily: 'OpenSans-bold' }}
            variant="text"
            color="secondary"
            className={classes.ms2}
            onClick={(e: any) => {
              history.push({
                pathname: `/dashboard`,
              });
            }}
          >
            Cancel
          </RACButton>
        </Grid>
        <RACModalCard
          borderRadius="25px"
          className={classes.cardidle}
          maxWidth="xs"
          isOpen={errorMsgPopup}
          closeIcon={false}
        >
          {ErrorMsgfn()}
        </RACModalCard>
        <RACModalCard
          borderRadius="25px"
          maxWidth="xs"
          isOpen={somethingWentWrong}
          closeIcon={false}
        >
          {somethingWentWrongfn()}
        </RACModalCard>
      </Grid>
      {hiddenLoader === true ? (
        <Grid
          style={{
            position: 'fixed',
            background: '#f7f5f5',
            width: '100%',
            height: '100%',
            top: 0,
            left: 0,
            opacity: 0.6,
            zIndex: 2000,
            textAlign: 'center',
            margin: '0px 0px',
          }}
        >
          {' '}
          <Grid
            style={{
              display: 'block',
              position: 'fixed',
              zIndex: 9999999,
              top: '40%',
              right: '50%',
            }}
          >
            <CircularProgress />{' '}
          </Grid>{' '}
        </Grid>
      ) : null}
    </>
  );
}
