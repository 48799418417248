/* eslint-disable prettier/prettier */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext } from 'react';
import { ReactComponent as SortdownIcon } from '../../assets/images/sort-down-icon.svg';
import { ReactComponent as SortupIcon } from '../../assets/images/sort-up-icon.svg';
import { getInventorySearch } from '../../api/user';
import {
  Grid,
  RACTable,
  RACTableCell,
  RACTableRow,
  CircularProgress
} from '@rentacenter/racstrap';
import { fixedGridStyles } from './Styles/fixedGridStyles';
import InfiniteScroll from 'react-infinite-scroll-component';
import { InventorySearchContext } from '../../context/SearchInventoryContext';
import { removeColumnHeader } from '../Shared/removeColumnHeader';
import {
  InventoryMajorContext,
} from '../../context/inventoryMajorContext';
import { ADD_ON_PRICING } from '../../constants/constants';

export interface dynamicProps {
  id?: string;
  items: any;
  orderBy: string;
  order: string;
  ColumnLink?: boolean;
  Request?: any;
  gridtype:
  | 'Normal'
  | 'withSubChild'
  | 'withCheckBox'
  | 'withRadioBtn'
  | 'endWithCheckBox';

  handleActionType: (value: any, index: any) => void;
  handleAnkerType: (itemNo: any, description: any, status: any, storeNo: any, invId: any) => void;
  throwStyle: any;
}
export interface columns {
  columnId: string;
  sortOrder: string;
  columnTitle: string;
  IsSorted: boolean;
  IsSortable: boolean;
  columnType?: 'link';
  handleColumnClick?: () => void;

  subColumns?: columns[];
}
const sortDirection = {
  ASC: 'asc',
  DESC: 'desc',
};

export interface IDynamicPageState {
  gridValues: [];
  gridColumn: columns[] | [];
}

const packagePriceColumns: columns[] = [
  {
    columnId: 'inventoryNumber',
    sortOrder: sortDirection.ASC,
    columnTitle: 'Item #',
    IsSorted: true,
    columnType: 'link',
    IsSortable: true,
  },
  {
    columnId: 'storeNumber',
    sortOrder: sortDirection.DESC,
    columnTitle: 'Store',
    IsSorted: false,
    columnType: 'link',
    IsSortable: true,
  },
  {
    columnId: 'departmentsEn',
    sortOrder: sortDirection.DESC,
    columnTitle: 'Department',
    IsSorted: false,
    IsSortable: true,
  },
  {
    columnId: 'subDepartmentsEn',
    sortOrder: sortDirection.DESC,
    columnTitle: 'Sub Department',
    IsSorted: false,
    IsSortable: true,
  },
  {
    columnId: 'brandName',
    sortOrder: sortDirection.DESC,
    columnTitle: 'Brand',
    IsSorted: false,
    IsSortable: true,
  },

  {
    columnId: 'bracketEn',
    sortOrder: sortDirection.DESC,
    columnTitle: 'Bracket',
    IsSorted: false,
    IsSortable: true,
  },
  {
    columnId: 'modelNumber',
    sortOrder: sortDirection.DESC,
    columnTitle: 'Model #',
    IsSorted: false,
    IsSortable: false,
  },
  {
    columnId: 'serialNumber',
    sortOrder: sortDirection.DESC,
    columnTitle: 'Serial #',
    IsSorted: false,
    IsSortable: true,
  },
  {
    columnId: 'weeklyRate',
    sortOrder: sortDirection.DESC,
    columnTitle: 'Rate',
    IsSorted: false,
    IsSortable: true,
  },
  {
    columnId: 'weeklyTerm',
    sortOrder: sortDirection.DESC,
    columnTitle: 'Term',
    IsSorted: false,
    IsSortable: true,
  },
  {
    columnId: 'weeklyRateAddOn',
    sortOrder: sortDirection.DESC,
    columnTitle: 'Add-On Rate',
    IsSorted: false,
    IsSortable: true,
  },
  {
    columnId: 'statusEn',
    sortOrder: sortDirection.DESC,
    columnTitle: 'Status',
    IsSorted: false,
    IsSortable: true,
  },
  {
    columnId: 'conditionEn',
    sortOrder: sortDirection.DESC,
    columnTitle: 'Condition',
    IsSorted: false,
    IsSortable: true,
  },
  {
    columnId: 'remainingValue',
    sortOrder: sortDirection.DESC,
    columnTitle: 'RV',
    IsSorted: false,
    IsSortable: true,
  },
  {
    columnId: 'daysIdle',
    sortOrder: sortDirection.DESC,
    columnTitle: 'Days Idle',
    IsSorted: false,
    IsSortable: true,
  },
];

const DynamicGridComponent = (props: dynamicProps) => {
  const {
    featureFlagDetails,
  } = useContext(InventoryMajorContext);
  const classes = props.throwStyle;
  const { tabledata, settabledata } = useContext(InventorySearchContext)
  const fixedGridClassName = fixedGridStyles();
  // const [gridValues, setGridValues] = useState<any[]>([]);
  const [gridColumn, setGridColumn] = useState<columns[]>([]);
  const [scrollset, setScrollset] = useState<number>(0)
  const [loader, setloader] = useState<boolean>(false)
  const [hasMore, sethasMore] = useState<boolean>(true)
  useEffect(() => {
    const { order, orderBy, items } = props;
    /* eslint-disable no-console */
    console.log('In Grid Component', props.items);

    const sortedData =
      order == sortDirection.DESC
        ? items.sort((a: any, b: any) => (a[orderBy] < b[orderBy] ? -1 : 1))
        : items.sort((a: any, b: any) => (b[orderBy] < a[orderBy] ? -1 : 1));

    settabledata(sortedData);
    setGridColumn(removeColumnHeader(packagePriceColumns, 'weeklyRateAddOn', featureFlagDetails[ADD_ON_PRICING] == '1' ? 0 : 1));
  }, [props]);

  const truncString = (str: any, max: number, add: any) => {
    add = add || '...';
    return typeof str === 'string' && str.length > max
      ? str.substring(0, max) + add
      : str;
  };

  const sortArray = (
    columnId: string,
    index: number,
    datacolumn: any,
    dataValues: any
  ) => {
    const sortedData =
      datacolumn[index].sortOrder == sortDirection.DESC
        ? dataValues.sort((a: any, b: any) =>
          b[columnId] < a[columnId] ? -1 : 1
        )
        : dataValues.sort((a: any, b: any) =>
          a[columnId] < b[columnId] ? -1 : 1
        );
    const reorderColumns: columns[] = datacolumn;
    reorderColumns[index].sortOrder =
      reorderColumns[index].sortOrder == sortDirection.ASC
        ? sortDirection.DESC
        : sortDirection.ASC;
    reorderColumns.map((value: any, index1: number) => {
      reorderColumns[index1].IsSorted = index == index1 ? true : false;
    });
    settabledata([...sortedData]);
    setGridColumn([...reorderColumns]);
  };
  const sortArrayForDollar = (
    columnId: string,
    index: number,
    datacolumn: any,
    dataValues: any
  ) => {
    const sortedData =
      datacolumn[index].sortOrder == sortDirection.DESC
        ? dataValues.sort((a: any, b: any) => b[columnId] - a[columnId])
        : dataValues.sort((a: any, b: any) => a[columnId] - b[columnId]);
    const reorderColumns: columns[] = datacolumn;
    reorderColumns[index].sortOrder =
      reorderColumns[index].sortOrder == sortDirection.ASC
        ? sortDirection.DESC
        : sortDirection.ASC;
    reorderColumns.map((value: any, index1: number) => {
      reorderColumns[index1].IsSorted = index == index1 ? true : false;
    });
    settabledata([...sortedData]);
    setGridColumn([...reorderColumns]);
  };

  const renderTableHead = (dataColumns: any, dataValues: any) => {
    dataValues.map((value: any) => {

      let condition: any = '';
      if (value.conditionEn == 'Based on DOR-system push') {
        condition = 'Auto';
        value.conditionEn = condition
      } else if (value.conditionEn == 'Term pushed up/down by Coworker') {
        condition = 'Exception';
        value.conditionEn = condition
      } else if (value.conditionEn == 'New - Full Term') {
        condition = 'New';
        value.conditionEn = condition
      } else {
        condition = value.conditionEn;
      }
    })
    return (
      <>
        {gridColumn.map((value, index) => {
          return (
            <RACTableCell key={index} className={classes.bold}>

              <a
                style={{
                  color: 'black',
                  textDecoration: 'none',
                  cursor: 'pointer',
                }}
                id={value.columnId}
                onClick={() =>
                  value.columnTitle !== 'Rate' && value.columnTitle !== 'RV' && value.columnId !== 'weeklyRateAddOn'
                    ? sortArray(
                      value.columnId,
                      index,
                      dataColumns,
                      dataValues
                    )
                    : sortArrayForDollar(
                      value.columnId,
                      index,
                      dataColumns,
                      dataValues
                    )
                }
              >
                {value.columnTitle + '  '}
                {value.IsSorted === true ? (
                  value.sortOrder == sortDirection.DESC ? (
                    <SortupIcon className={classes.ms0} />
                  ) : (
                    <SortdownIcon className={classes.ms0} />
                  )
                ) : null}
              </a>

            </RACTableCell>
          );
        })}
        <RACTableCell></RACTableCell>
      </>
    );
  };


  // eslint-disable-next-line sonarjs/cognitive-complexity
  const renderTableContent = (gridBodyData: any) => {
    if (!!gridBodyData && gridBodyData.length > 0) {
      return gridBodyData.map((value: any, index1: any) => {
        const tagRate: any = value.weeklyRate?.replace(
          /\B(?=(\d{3})+(?!\d))/g,
          ','
        );
        const rv: any = value.remainingValue.replace(
          /\B(?=(\d{3})+(?!\d))/g,
          ','
        );

        let condition: any = '';
        if (value.conditionEn == 'Based on DOR-system push') {
          condition = 'Auto';
        } else if (value.conditionEn == 'Term pushed up/down by Coworker') {
          condition = 'Exception';
        } else if (value.conditionEn == 'New - Full Term') {
          condition = 'New';
        } else {
          condition = value.conditionEn;
        }

        return (
          <React.Fragment key={index1}>
            <RACTableRow>
              <RACTableCell>
                <a className={classes.racpadLink}
                  href={
                    `/inventory/${value.inventoryNumber}/inventoryinformation`
                  }
                  onClick={(e) => {

                    props.handleAnkerType(value.inventoryNumber, value.inventoryDescription, value.statusEn, value.storeNumber, value.inventoryId); e.preventDefault()

                  }
                  }
                >
                  {value.inventoryNumber}

                </a>
                {/* <a className={classes.racpadLink} onClick={() => props.handleAnkerType(value.inventoryNumber, value.inventoryDescription, value.statusEn, value.storeNumber)}>
                  {value.inventoryNumber}
                </a> */}
              </RACTableCell>
              <RACTableCell>
                {props.ColumnLink ? (
                  <a
                    className={classes.racpadLink}
                    onClick={() =>
                      props.handleActionType(value.storeNumber, index1)
                    }
                  >
                    {value.storeNumber}
                  </a>
                ) : (
                  value.storeNumber
                )}
              </RACTableCell>
              <RACTableCell
                title={
                  value.departmentsEn.length > 10 ? value.departmentsEn : null
                }
              >
                {truncString(value.departmentsEn, 20, '...')}
              </RACTableCell>
              <RACTableCell
                title={
                  value.subDepartmentsEn.length > 10
                    ? value.subDepartmentsEn
                    : null
                }
              >
                {truncString(value.subDepartmentsEn, 20, '...')}
              </RACTableCell>
              <RACTableCell
                title={value.brandName.length > 8 ? value.brandName : null}
              >
                {truncString(value.brandName, 8, '...')}
              </RACTableCell>

              <RACTableCell
                title={value.bracketEn.length > 8 ? value.bracketEn : null}
              >
                {truncString(value.bracketEn, 8, '...')}
              </RACTableCell>
              {value.modelNumber ? (
                <RACTableCell>
                  {value.modelNumber !== undefined && value.modelNumber !== null && value.modelNumber !== '' ? value.modelNumber : null}
                </RACTableCell>
              ) : (
                <RACTableCell className={classes.textCenter}> - </RACTableCell>
              )}

              {value.serialNumber ? <RACTableCell>
                {value.serialNumber !== null && value.serialNumber !== undefined && value.serialNumber !== "" ? value.serialNumber : null}
              </RACTableCell> : <RACTableCell className={classes.textCenter}>-</RACTableCell>}
              <RACTableCell style={{ whiteSpace: 'nowrap' }}>
                {tagRate ? `$ ${tagRate}` : '-'}
              </RACTableCell>
              <RACTableCell className={classes.textCenter}>
                {value.weeklyTerm ? value.weeklyTerm : '-'}
              </RACTableCell>
              {featureFlagDetails[ADD_ON_PRICING] == '1' ? <RACTableCell style={{ whiteSpace: 'nowrap' }}>
                {value.weeklyRateAddOn && (!isNaN(value.weeklyRateAddOn)) ? `$ ${value.weeklyRateAddOn}` : '-'}
              </RACTableCell> : null}
              <RACTableCell style={{ whiteSpace: 'nowrap' }}>
                {value.statusEn}
              </RACTableCell>
              <RACTableCell className={classes.textLeft}>
                {truncString(condition, 15, '...')}
              </RACTableCell>
              <RACTableCell style={{ whiteSpace: 'nowrap' }}>
                $ {rv}
              </RACTableCell>
              {value.daysIdle ? (
                <RACTableCell className={classes.textLeft}>
                  {value.daysIdle}
                </RACTableCell>) : <RACTableCell className={classes.textLeft}>-</RACTableCell>}
            </RACTableRow>
          </React.Fragment>
        );
      });
    }
  };




  const nextscroll = async () => {

    const { Request } = props
    console.log('Request', Request);
    setScrollset(scrollset + 1)
    Request['setNumber'] = scrollset + 1

    setloader(true)
    const response = await getInventorySearch(Request)
    setloader(false)
    const val = response.data.response

    settabledata([...tabledata, ...val])

    if (val.length === 0) {
      sethasMore(false)
    }

    console.log('mainResponse', tabledata);
  }

  return (

    <Grid className={`${fixedGridClassName.fixTableHead} ${fixedGridClassName.fixTableHeight400}`}   >
      {tabledata.length > 7 ?
        <InfiniteScroll
          dataLength={tabledata.length} //This is important field to render the next data
          next={nextscroll}
          hasMore={hasMore}
          height={400}
          loader={loader ? <Grid className={classes.GridLoader}><CircularProgress ></CircularProgress></Grid> : null}
        >
          <RACTable
            renderTableHead={() => renderTableHead(gridColumn, tabledata)}
            renderTableContent={() => renderTableContent(tabledata)}
            stickyHeader
          />



        </InfiniteScroll> : (

          <RACTable

            renderTableHead={() => renderTableHead(gridColumn, tabledata)}

            renderTableContent={() => renderTableContent(tabledata)}

          />

        )}
    </Grid>
  );
};

export default DynamicGridComponent;
