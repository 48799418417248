/* eslint-disable prettier/prettier */
/* eslint-disable sonarjs/no-duplicated-branches */
/* eslint-disable sonarjs/no-identical-functions */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext } from 'react';
import { useParams, Route, Switch } from 'react-router-dom';
import InventoryHeader from '../Shared/InventoryHeader';
import Inventoryinfo from './InventoryInfo/InventoryInformation';
import Details from './Details/Details';
import AgreementHistory from './AgreementHistory/AgreementHistory';
import ActivitiesInfo from '../InventoryTabs/Activities/Activities';
import IdleInventoryAudit from './IdleInventoryAudit/IdleInventoryAudit';
import PriceTag from './PriceTag/PriceTag';
import {
  LoadInventoryInformation,
  LoadIdleInventoryAudit,
  LoadAgreementHistory,
  getInventoryPriceTags,
  getActivities,
  InventoryDetails,
  getInventoryServiceSummmary
} from '../../api/user';
import ServicesInfo from './Services/Services';
import { AppRoute } from '../../config/route-config';
import { getPriceTagsReq } from './PriceTag/PriceTagProps';
import {
  InventoryMajorContext,
} from '../../context/inventoryMajorContext';
import ComingSoonPage from '../ComingSoonPage';
import { InventoryContext } from '../../context/InventoryInfoContext'
interface ParamType {
  inventoryNum: string;
}
enum headerTabs {
  InventoryInfo,
  DetailsInfo,
  PriceTagInfo,
  ServicesInfo,
  IdleAuditInfo,
  AgreementInfo,
  ActivitiesInfo
}
export const InventoryInfoRoute = () => {
  const {
    featureFlagResponse,
    invInfoFeature,
    invDetailsFeature,
    idleInvAuditFeature,
    invActivitiesFeature,
    agrHistoryFeature,
    pricetagFeature,
    inventoryServicingFeature,
    printEditPriceTagsFeature,
    transferinvFeature,
  } = useContext(InventoryMajorContext);
  const { setloanerpayload, loanerpayload, setloanCusId, loanCusId } = useContext(InventoryContext)
  const { inventoryNum } = useParams<ParamType>();
  const [activeTab, setactiveTab] = useState<headerTabs>();
  const [InventoryInfoData, setInventoryInfoData] = useState();
  const [InventoryDetailsData, setInventoryDetailsData] = useState({});
  const [IdleInvAuditData, setIdleInvAuditData] = useState();
  const [PriceTagData, setPriceTagData] = useState();
  const [AgrHistoryData, setAgrHistoryData] = useState();
  const [ActivitiesData, setActivitiesData] = useState();
  const [serviceInvData, setserviceInvData] = useState();


  const [GotApiResponse, setGotApiResponse] = useState(false);
  useEffect(() => {
    if (!GotApiResponse) {
      PageReload('initialPageload');
    }
  }, []);
  const PageReload = async (action: string) => {
    console.log('PageReload triggered');
    const InventoryInfo = new Promise(() => {
      const GetResponse = async () => {
        const InventoryInfoResponse = await LoadInventoryInformation(
          inventoryNum
        );
        setInventoryInfoData(InventoryInfoResponse);
        setloanCusId({ ...loanCusId, customerId: InventoryInfoResponse?.data?.currentAgreement?.customerId, agreementId: InventoryInfoResponse?.data?.currentAgreement?.agreementId, statusReasonDesc: InventoryInfoResponse?.data?.inventoryInformation?.statusReasonDesc, statusReasonRefCode: InventoryInfoResponse?.data?.inventoryInformation?.statusReasonType, newRequestEnabled: InventoryInfoResponse?.data?.currentAgreement })
        console.log('InventoryInfoResponse', InventoryInfoResponse);
      };
      GetResponse();
    });
    const InventoryDetailsRes = new Promise(() => {
      const GetResponse = async () => {
        const InventoryDetailsResponse = await InventoryDetails(
          inventoryNum
        );
        setInventoryDetailsData(InventoryDetailsResponse);
        console.log('InventoryInfoResponse', InventoryDetailsResponse);
      };
      GetResponse();
    });
    const Services = new Promise(() => {
      const request: any = {
        identifier: inventoryNum,
        offset: '1',
        limit: '20',
        type: 'inv'

      }
      const GetResponse = async () => {
        const InvServSummResponse = await getInventoryServiceSummmary(request);
        //setPriceTagData(InvPriceTagResponse);
        setserviceInvData(InvServSummResponse)
        console.log('InvServSummResponse', InvServSummResponse);
      };
      GetResponse();
    });
    const Activities = new Promise(() => {
      const GetResponse = async () => {
        const ActivitiesResponse = await getActivities(
          inventoryNum, 0
        );
        setActivitiesData(ActivitiesResponse);
        console.log('ActivitiesResponse', ActivitiesResponse);
      };
      GetResponse();
    });
    const InventoryAudit = new Promise(() => {
      const GetResponse = async () => {
        const request = {
          inventoryNumber: inventoryNum,
          offset: '1',
          limit: '20'
        }
        const InventoryAuditResponse = await LoadIdleInventoryAudit(request);
        setIdleInvAuditData(InventoryAuditResponse);
        console.log('InventoryAuditResponse', InventoryAuditResponse);
      };
      GetResponse();
    });
    const AgrHistory = new Promise(() => {
      const GetResponse = async () => {
        const AgrHistoryResponse = await LoadAgreementHistory(
          inventoryNum
        );
        setAgrHistoryData(AgrHistoryResponse);
        console.log('AgrHistoryResponse', AgrHistoryResponse);
      };
      GetResponse();
    });
    const PriceTags = new Promise(() => {
      const request: getPriceTagsReq = {
        inventoryNumbers: [inventoryNum],
        isCurrentPriceTag: false
      }
      const GetResponse = async () => {
        const InvPriceTagResponse = await getInventoryPriceTags(request);
        setPriceTagData(InvPriceTagResponse);
        setloanerpayload({ ...loanerpayload, depRefCode: InvPriceTagResponse.data.response[0].headerDetails.departmentRefCode, inventoryId: InvPriceTagResponse.data.response[0].headerDetails.inventoryId })
        console.log('InvPriceTagResponse', InvPriceTagResponse);
      };
      GetResponse();
    });
    if (action == 'initialPageload') {
      const apiRequestPayload: any = [];
      if (invInfoFeature == 1 || invDetailsFeature == 1) {
        apiRequestPayload.push(InventoryInfo);
        apiRequestPayload.push(InventoryDetailsRes);
      }
      if (idleInvAuditFeature == 1 || agrHistoryFeature == 1 || invActivitiesFeature == 1) {
        apiRequestPayload.push(InventoryAudit);
        apiRequestPayload.push(AgrHistory);
      }
      if (invInfoFeature == 1 || invDetailsFeature == 1 || idleInvAuditFeature == 1 || agrHistoryFeature == 1 || invActivitiesFeature == 1) {
        apiRequestPayload.push(Activities);
      }
      pricetagFeature == 1 ? apiRequestPayload.push(PriceTags) : null;
      Promise.race(apiRequestPayload);
      setGotApiResponse(true);
    }
    else if (action == 'rentReady' || action == 'chargedOff' || action == 'pendingChargeOff' || action == 'notRentReady' || action == 'cancelChargedOff' || action == 'hold' || action == 'unHold' || action == 'unReserved') {
      console.log('im in action change');
      Promise.race([InventoryInfo, InventoryDetailsRes, Activities, Services]);
      setGotApiResponse(true);
    }
  }
  return (
    <>
      <InventoryHeader response={InventoryInfoData} GotApiResponse={GotApiResponse} activeTab={activeTab} setactiveTab={setactiveTab} />
      <Switch>
        {invInfoFeature == '1' ? <Route exact path={AppRoute.InventoryInformation}>
          <Inventoryinfo response={InventoryInfoData} detailsResponse={InventoryDetailsData} activeResponse={ActivitiesData} setData={setInventoryInfoData} PageReload={PageReload} GotApiResponse={GotApiResponse} setactiveTab={setactiveTab} />
        </Route> :
          <Route exact path={AppRoute.InventoryInformation}>
            <ComingSoonPage />
          </Route>
        }
        <Route exact path={AppRoute.Details}>
          {invDetailsFeature == '1' ? <Details response={InventoryDetailsData} inventoryInfo={InventoryInfoData} activeResponse={ActivitiesData} setData={setInventoryDetailsData} GotApiResponse={GotApiResponse} setactiveTab={setactiveTab} />
            :
            <ComingSoonPage />
          }
        </Route>
        <Route exact path={AppRoute.IdleInventoryAudit}>
          {idleInvAuditFeature == '1' ?
            <IdleInventoryAudit response={IdleInvAuditData} serviceResponse={serviceInvData} inventoryInfo={InventoryInfoData} AgreeHistory={AgrHistoryData} inventoryDetails={InventoryDetailsData} activeResponse={ActivitiesData} />
            :
            <ComingSoonPage />
          }
        </Route>
        <Route exact path={AppRoute.AgreementHistory}>
          {agrHistoryFeature == '1' ?
            <AgreementHistory response={AgrHistoryData} serviceResponse={serviceInvData} inventoryInfo={InventoryInfoData} idleInventoryRes={IdleInvAuditData} activeResponse={ActivitiesData} inventoryDetails={InventoryDetailsData} />
            :
            <ComingSoonPage />
          }
        </Route>
        <Route exact path={AppRoute.Activities}>
          {invActivitiesFeature == '1' ?
            <ActivitiesInfo response={ActivitiesData} serviceResponse={serviceInvData} inventoryInfo={InventoryInfoData} inventoryDetails={InventoryDetailsData} idleInventoryRes={IdleInvAuditData} AgreeHistory={AgrHistoryData} />
            :
            <ComingSoonPage />
          }  </Route>
        <Route exact path={[AppRoute.Service, AppRoute.RetentionService]}>
          {inventoryServicingFeature == '1' ?
            <ServicesInfo activeResponse={ActivitiesData} response={serviceInvData} inventoryInfo={InventoryInfoData} inventoryDetails={InventoryDetailsData} idleInventoryRes={IdleInvAuditData} AgreeHistory={AgrHistoryData} />
            :
            <ComingSoonPage />}
        </Route>
        <Route exact path={AppRoute.PriceTag}>
          {pricetagFeature == '1' ?
            <PriceTag invenpricetaginfo={PriceTagData} SetPriceTagData={setPriceTagData} PageReload={PageReload} />
            :
            <ComingSoonPage />
          }
        </Route>
      </Switch>
    </>
  );
};