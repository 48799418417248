/* eslint-disable prettier/prettier */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable no-debugger */
import { validateObject } from '../Shared/validateObject';

import moment from 'moment';
import React, { useEffect, useState } from 'react';

const PrintTag = (props: any) => {
  const [data, setData] = useState<any>([]);

  useEffect(() => {
    debugger;
    setData(props.dataRows.inventoryTransferList);
    console.log('propsDataRows', props);
  }, [props.dataRows]);

  useEffect(() => {
    if (
      validateObject(props.dataRows) &&
      validateObject(props.dataRows.inventoryTransferList) &&
      props.dataRows.inventoryTransferList.length > 0
    ) {
      // alert();
      const printData = document?.getElementById(
        'PrintTransferRecieve'
      )?.innerHTML;
      console.log('printData', printData);
      if (printData != null && printData != undefined) {
        const a: any = window.open(
          '',
          '',
          'width=1000,height=650,scrollbars=1,menuBar=1'
        );
        console.log('windowopen', a);
        a.document.write('<html>');
        a.document.write(printData);
        a.document.write('</html>');
        a.document.close();
        a.print();
      }
    }
  }, [data]);



  const printPriceTag = () => {
    const Data = validateObject(data) ? data : [];
    console.log('dataLook', data);

    return Data.map((value: any, index: any) => {
      return (
        <div
          key={index}
          style={{
            width: '210mm',
            height: '305mm',
          }}
        >
          <div style={{ float: 'left', width: '94%', padding: '3%' }}>
            <div style={{ width: '100%', float: 'left', marginBottom: '10px' }}>
              <h2 style={{ float: 'left', margin: '0px' }}>
                Transfer Received Form
              </h2>
              <div style={{ float: 'right' }}>
                {' '}
                {moment(new Date()).format('MM/DD/YYYY')}
              </div>
            </div>

            <div style={{ width: '100%', float: 'left', marginBottom: '20px' }}>
              {/* <label style={{ float: 'left', margin: '0px', fontSize: '20px' }} > 3630(FORT WORTH, TX)</label> */}
              <label style={{ float: 'right' }}>Page: 1</label>
            </div>

            <div style={{ width: '50%', float: 'left', marginBottom: '20px' }}>
              <label style={{ margin: '0px' }}>
                Transferring Store:{value.transferringStoreNumber} (
                {value.transferringCity},{value.transferringStateAbbreviation})
              </label>
            </div>
            <div
              style={{
                width: '50%',
                float: 'left',
                marginBottom: '20px',
              }}
            >
              <label style={{ margin: '0px' }}>
                {' '}
                Receiving Store:{value.receivingStoreNumber} (
                {value.receivingCity},{value.receivingStateAbbreviation})
              </label>
            </div>

            {/* <!--table 1 starts here--> */}
            <div style={{ width: '100%', float: 'left', marginBottom: '20px' }}>
              <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                <thead>
                  <tr>
                    <th
                      style={{
                        borderBottom: '1px solid #000',
                        color: '#000',
                        textAlign: 'left',
                        padding: '7px',
                        fontSize: '14px',
                      }}
                    >
                      Item
                    </th>
                    <th
                      style={{
                        borderBottom: '1px solid #000',
                        color: '#000',
                        textAlign: 'left',
                        padding: '7px',
                        fontSize: '14px',
                      }}
                    >
                      Model
                    </th>
                    <th
                      style={{
                        borderBottom: '1px solid #000',
                        color: '#000',
                        textAlign: 'left',
                        padding: '7px',
                        fontSize: '14px',
                      }}
                    >
                      Vendor
                    </th>
                    <th
                      style={{
                        borderBottom: '1px solid #000',
                        color: '#000',
                        textAlign: 'left',
                        padding: '7px',
                        fontSize: '14px',
                      }}
                    >
                      Brand
                    </th>
                    <th
                      style={{
                        borderBottom: '1px solid #000',
                        color: '#000',
                        textAlign: 'left',
                        padding: '7px',
                        fontSize: '14px',
                      }}
                    >
                      MFG Serial #
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style={{ paddingTop: '7px', fontSize: '14px' }}>
                      {value.inventoryNumber}
                    </td>
                    <td style={{ paddingTop: '7px', fontSize: '14px' }}>
                      {value.model}
                    </td>
                    <td style={{ paddingTop: '7px', fontSize: '14px' }}>
                      {value.vendorName}
                    </td>
                    <td style={{ paddingTop: '7px', fontSize: '14px' }}>
                      {value.brand}
                    </td>
                    <td style={{ paddingTop: '7px', fontSize: '14px' }}>
                      {value.serialNumber}
                    </td>
                  </tr>
                  {/* </tr> */}
                </tbody>
              </table>
            </div>
            {/* <!--table 1 ends here--> */}

            {/* <!--text content starts here--> */}
            <div
              style={{
                width: '100%',
                float: 'left',
                textAlign: 'center',
                marginBottom: '70px',
                marginTop: '70px',
              }}
            >
              <div style={{ width: '100%', fontSize: '25px' }}>I Am Sold!</div>
              <div style={{ width: '100%', fontSize: '25px' }}>
                Please ask to see another
              </div>
              <div style={{ width: '100%', fontSize: '25px' }}>
                One just like me!
              </div>
            </div>
            {/* <!--text content ends here--> */}

            {/* <!--Form content starts here--> */}
            <div style={{ width: '100%', float: 'left' }}>
              <div
                style={{
                  width: '50%',
                  float: 'left',
                }}
              >
                <h4>Delivered By</h4>
                <div
                  style={{ marginBottom: '30px', width: '100%', float: 'left' }}
                >
                  <span
                    style={{
                      borderBottom: '2px solid #000',
                      width: '85%',
                      float: 'left',
                      marginBottom: '10px',
                    }}
                  ></span>
                  <div style={{ width: '100%', float: 'left' }}>
                    Employee Id
                  </div>
                </div>
                <div
                  style={{ marginBottom: '30px', width: '100%', float: 'left' }}
                >
                  <span
                    style={{
                      borderBottom: '2px solid #000',
                      width: '85%',
                      float: 'left',
                      marginBottom: '10px',
                    }}
                  ></span>
                  <div style={{ width: '100%', float: 'left' }}>
                    Printed Name
                  </div>
                </div>
                <div
                  style={{ marginBottom: '30px', width: '100%', float: 'left' }}
                >
                  <span
                    style={{
                      borderBottom: '2px solid #000',
                      width: '85%',
                      float: 'left',
                      marginBottom: '10px',
                    }}
                  ></span>
                  <div style={{ width: '100%', float: 'left' }}>Signature</div>
                </div>
              </div>

              <div
                style={{
                  width: '50%',
                  float: 'left',
                }}
              >
                <h4>Received By</h4>
                <div
                  style={{ marginBottom: '30px', width: '70%', float: 'left' }}
                >
                  <span
                    style={{
                      borderBottom: '2px solid #000',
                      width: '85%',
                      float: 'left',
                      marginBottom: '10px',
                    }}
                  ></span>
                  <div style={{ width: '100%', float: 'left' }}>
                    Employee Id
                  </div>
                </div>
                <div
                  style={{ marginBottom: '30px', width: '30%', float: 'left' }}
                >
                  <span
                    style={{
                      borderBottom: '2px solid #000',
                      width: '70%',
                      float: 'left',
                      marginBottom: '10px',
                    }}
                  ></span>
                  <div style={{ width: '100%', float: 'left' }}>Date</div>
                </div>

                <div
                  style={{ marginBottom: '30px', width: '100%', float: 'left' }}
                >
                  <span
                    style={{
                      borderBottom: '2px solid #000',
                      width: '91%',
                      float: 'left',
                      marginBottom: '10px',
                    }}
                  ></span>
                  <div style={{ width: '100%', float: 'left' }}>
                    Printed Name
                  </div>
                </div>
                <div
                  style={{ marginBottom: '30px', width: '100%', float: 'left' }}
                >
                  <span
                    style={{
                      borderBottom: '2px solid #000',
                      width: '91%',
                      float: 'left',
                      marginBottom: '10px',
                    }}
                  ></span>
                  <div style={{ width: '100%', float: 'left' }}>Signature</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    });
  };

  return <div>{printPriceTag()}</div>;

  // })
};
export default PrintTag;
