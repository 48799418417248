/* eslint-disable prettier/prettier */
/* eslint-disable no-debugger */
/* eslint-disable no-console */
/* eslint-disable sonarjs/prefer-immediate-return */
/* eslint-disable sonarjs/no-redundant-boolean */
/* eslint-disable prefer-const */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useState, useEffect, useContext, useRef } from "react";
import { printHTML } from '../../Shared/print'
import DynamicGridRA from "../AgreementHistory/dynamicGrids/dynamicGridRA";
import DynamicGridPA from "../AgreementHistory/dynamicGrids/dynamicGridPA";
import DynamicGridLA from "../AgreementHistory/dynamicGrids/dynamicGridLA";
import { useHistory } from 'react-router';
import { InventoryContext } from '../../../context/InventoryInfoContext';
import moment from "moment";
import { ReactComponent as AlertIcon } from '../../../assets/images/no-records-found.svg';
import {
  Grid,
  RACButton,
  Card,
  CardContent,
  Typography
} from '@rentacenter/racstrap';
import { validateObject } from "../../Shared/validateObject";
import { globalStyles } from "../../componentstyles/globalstyles"
import { RentalAgreement } from "../../componentstyles/RentalAgreement";
// import ReactToPrint from 'react-to-print';
import ActivitiesPrintInfo from "../ActivitiesPrint";
export const AgreementHistory = (props: any) => {
  const classes = globalStyles();
  const rentalagreementclasses = RentalAgreement();
  const storeNo: any = sessionStorage.getItem('storeNumber');
  const sortDirection = { ASC: 'asc', DESC: 'desc' }
  const [agreementHistorys, setagreementHistorys] = useState(
    {
      rental: [],
      loaner: [],
      promo: []
    }
  )
  const { headerValue } = useContext(InventoryContext);
  const [headerContext, setheaderContext] = headerValue;
  const [activity,setActivity]=useState<any>({})
  const[inventoryInfo,setinventoryInfo]=useState<any>({})
  const[idleInvAudit,setidleInvAudit]=useState<any>()
  const[AgrHistory,setAgrHistory] =useState<any>({})
  const[inventoryDetail,setinventoryDetail]=useState<any>({})
  const [gridLoader, setgridLoader] = useState<any>(true);
  const [somethingWentWrong, setsomethingWentWrong]: any = React.useState(false);
  const [errMsgTxt, seterrMsgTxt] = useState('');
  const[serviceRes,setserviceRes] = useState<any>({})
  const history = useHistory();
  // const componentRef = useRef(null);
  useEffect(() => {
      if (validateObject(props) && validateObject(props.response) && validateObject(props.response.data)) {
        const response = props.response;
        setgridLoader(false);
        console.log('agreementhistory-apiResponse', response);
        if (validateObject(response) && response.status == 200) {
          if (validateObject(response.data) &&
            (validateObject(response.data.rentalAgreement) &&
              response.data.rentalAgreement.length > 0) ||
            (validateObject(response.data.loanerAgreement) &&
              response.data.loanerAgreement.length > 0) ||
            (validateObject(response.data.promoAgreement) &&
              response.data.promoAgreement.length > 0)) {
            seterrMsgTxt('')
            setsomethingWentWrong(false);
            setagreementHistorys({ ...agreementHistorys, rental: response.data.rentalAgreement, loaner: response.data.loanerAgreement, promo: response.data.promoAgreement })
            setAgrHistory(response.data)
          }
          else {
            setagreementHistorys({ ...agreementHistorys, rental: [], loaner: [], promo: [] })
          }
        }
        else if (validateObject(response) && response.status == 204) {
          setagreementHistorys({ ...agreementHistorys, rental: [], loaner: [], promo: [] })
        }
        else if (validateObject(response) && response.status == 400 && validateObject(response.data) && validateObject(response.data.errors[0]) && validateObject(response.data.errors[0].error)) {
          seterrMsgTxt(response.data.errors[0].error)
          setsomethingWentWrong(true);
        }
        else {
          seterrMsgTxt('Something Went Wrong')
          setsomethingWentWrong(true);
        }
      }
      if(props?.inventoryInfo ||props?.idleInventoryRes || props?.activeResponse){
        const inventoryinfo=props.inventoryInfo?.data
        const idleInventoryRes=props.idleInventoryRes?.data
        const ActivityRes=props.activeResponse?.data
        const inventoryDetails =props.inventoryDetails?.data
        const servResoponse = props.serviceResponse?.data
        setinventoryInfo(inventoryinfo)
        setidleInvAudit(idleInventoryRes)
        setActivity(ActivityRes)
        setserviceRes(servResoponse)
        setinventoryDetail(inventoryDetails)
      }
  }, [props]);
  const onClickCancelBtn = () => {
    history.push(`/inventory/inventorysearch`)
  }
  const bindGrid = () => {
    if (somethingWentWrong) {
      return (
        <>
          <div className={`${classes.textCenter} ${classes.mt5} ${classes.ml45}`}>
            <AlertIcon />
            <Typography className={`${classes.subTitle} ${classes.mb3}`}>
              {errMsgTxt}
            </Typography>
          </div>
        </>
      );
    }
    else {
      return (
        <>
          <Grid item xs={12} sm={12} md={12} lg={12} >
            <Grid item md={12} className={classes.componentSpaceBetween}>
              <Grid className={classes.m2}>
                <Typography variant="h5">Rental Agreements</Typography>
              </Grid>
              <Grid className={`${classes.m2} ${classes.componentLeft}`}>
              <Typography component="span" className={`${classes.mr2} ${rentalagreementclasses.legendSqure} ${rentalagreementclasses.legendPending}`}></Typography>
                <Typography component="span" className={classes.mr5}>Pending</Typography>
                <Typography component="span" className={`${classes.mr2} ${rentalagreementclasses.legendSqure} ${rentalagreementclasses.legendActive}`}></Typography>
                <Typography component="span" className={classes.mr5}>Active</Typography>
                <Typography component="span" className={`${classes.mr2} ${rentalagreementclasses.legendSqure} ${rentalagreementclasses.legendPastDue}`}></Typography>
                <Typography component="span" className={classes.mr5}>Past Due</Typography>
                <Typography component="span" className={`${classes.mr2} ${rentalagreementclasses.legendSqure} ${rentalagreementclasses.legendInactive}`}></Typography>
                <Typography component="span" className={classes.mr2} style={{marginRight:'3rem'}}>Inactive</Typography>
              </Grid>
            </Grid>
            <Card className={`${classes.m2} ${classes.racCard}`}>
              <CardContent>
                <DynamicGridRA
                  gridtype="Normal"
                  id="agreementHistorys"
                  items={agreementHistorys.rental}
                  setLoader={gridLoader}
                  ColumnLink={true}
                  orderBy="rentalAgreement"
                  order={sortDirection.ASC}
                ></DynamicGridRA>
              </CardContent>
            </Card>
          </Grid>
          {validateObject(agreementHistorys.promo) && agreementHistorys.promo.length > 0 ?
            <Grid item xs={12} sm={12} md={12} lg={12} >
              <Grid item md={12} className={classes.componentSpaceBetween}>
                <Grid className={classes.m2}>
                  <Typography variant="h5">Promo Agreements</Typography>
                </Grid>
              </Grid>
              <Card className={`${classes.m2} ${classes.racCard}`}>
                <CardContent>
                  <DynamicGridPA
                    gridtype="Normal"
                    id="agreementHistorys"
                    items={agreementHistorys.promo}
                    setLoader={gridLoader}
                    ColumnLink={true}
                    orderBy="promoAgreement"
                    order={sortDirection.ASC}
                  ></DynamicGridPA>
                </CardContent>
              </Card>
            </Grid>
            : null}
          {validateObject(agreementHistorys.loaner) && agreementHistorys.loaner.length > 0 ?
            <Grid item xs={12} sm={12} md={12} lg={12} >
              <Grid item md={12} className={classes.componentSpaceBetween}>
                <Grid className={classes.m2}>
                  <Typography variant="h5">Loaner Agreements</Typography>
                </Grid>
              </Grid>
              <Card className={`${classes.m2} ${classes.racCard}`}>
                <CardContent>
                  <DynamicGridLA
                    gridtype="Normal"
                    id="agreementHistorys"
                    items={agreementHistorys.loaner}
                    setLoader={gridLoader}
                    isPrint={false}
                    ColumnLink={true}
                    orderBy="loanerAgreement"
                    order={sortDirection.ASC}
                  ></DynamicGridLA>
                </CardContent>
              </Card>
            </Grid> : null}
        </>
      )
    }
  }
  return (
    <>
      <Grid className={classes.mb100}>
        <Grid container>
          {bindGrid()}
        </Grid>
      </Grid>
      <Grid container style={{ zIndex: '1' }} className={classes.fixedFooter}>
        <Grid className={classes.floatLeft}>
          <RACButton
            variant="outlined"
            color="primary"
            className={classes.ms2}
            onClick={() => onClickCancelBtn()}
          >
            Cancel
          </RACButton>
        </Grid>
        {headerContext?.storeNumber===storeNo?
        <Grid className={classes.floatRight}>
          <RACButton
            className={classes.mx1}
            size="small"
            variant="contained"
            color="primary"
            onClick={() => printHTML(document.getElementById('InventoryPrintHTML'))}
          >
            Print
          </RACButton>
          {/* <>
            <ReactToPrint
              trigger={() =>
                <RACButton
                  className={classes.mx1}
                  size="small"
                  variant="contained"
                  color="primary"
                >
                  Print
                </RACButton>}
              content={() => componentRef.current}
              documentTitle="Agreement History"
            />
          </> */}
        </Grid>:null}
      </Grid>
      <div className={classes.hide}>
        <div id="agreementhistoryPrintHTML">
        <ActivitiesPrintInfo activeresponse={activity} serviceResponse={serviceRes} inventoryInfoRes={inventoryInfo} idleInvAudit={idleInvAudit} AgrHistory={AgrHistory} inventoryDetail={inventoryDetail}/>
          {/* <div style={{ width: '100%', float: 'left', marginBottom: '20px' }}>
            <h2 style={{ float: 'left', margin: '0px' }}>Agreement History</h2>
            <div style={{ float: 'right', marginTop: '5px', fontStyle: 'italic' }}>{moment().format('ddd,D MMM YYYY, hh:mm A')}</div>
          </div>
          <div style={{ width: '100%', float: 'left', marginBottom: '10px' }}>
            <div style={{ width: '20%', float: 'left' }}>
              <span style={{ fontWeight: 'bold', fontSize: '14px' }}>Rent-A-Center</span>
              <span style={{ fontSize: '14px', fontStyle: 'italic' }}>{' '}({storeNo})</span>
            </div>
            <div style={{ width: '30%', float: 'left' }}>
              <span style={{ fontWeight: 'bold', fontSize: '14px' }}>Item #:</span>
              <span style={{ fontSize: '14px', fontStyle: 'italic' }}>{' '}{headerContext.inventoryNumber}</span>
            </div>
            <div style={{ width: '50%', float: 'left' }}>
              <span style={{ fontWeight: 'bold', fontSize: '14px' }}>Description:</span>
              <span style={{ fontSize: '14px', fontStyle: 'italic' }}>{' '}{headerContext.description}</span>
            </div>
          </div>
          {validateObject(agreementHistorys.rental) && agreementHistorys.rental.length > 0 ?
            <div style={{ width: '100%', float: 'left', marginBottom: '20px' }}>
              <h3 style={{ borderTop: '1px solid black', borderBottom: '1px solid black', padding: '3px' }}>Rental Agreements</h3>
              <div style={{ width: '100%', float: 'left' }}>
                <DynamicGridRA
                  gridtype="Normal"
                  id="agreementHistorys"
                  items={agreementHistorys.rental}
                  setLoader={false}
                  isPrint={true}
                  ColumnLink={true}
                  orderBy="rentalAgreement"
                  order={sortDirection.ASC}
                ></DynamicGridRA>
              </div>
            </div> : null}
          {validateObject(agreementHistorys.promo) && agreementHistorys.promo.length > 0 ?
            <div style={{ width: '100%', float: 'left', marginBottom: '20px' }}>
              <h3 style={{ borderTop: '1px solid black', borderBottom: '1px solid black', padding: '3px' }}>Promo Agreements</h3>
              <div style={{ width: '100%', float: 'left' }}>
                <DynamicGridPA
                  gridtype="Normal"
                  id="agreementHistorys"
                  items={agreementHistorys.promo}
                  setLoader={false}+
                  isPrint={true}
                  ColumnLink={true}
                  orderBy="promoAgreement"
                  order={sortDirection.ASC}
                ></DynamicGridPA>
              </div>
            </div> : null}
          {validateObject(agreementHistorys.loaner) && agreementHistorys.loaner.length > 0 ?
            <div style={{ width: '100%', float: 'left', marginBottom: '20px' }}>
              <h3 style={{ borderTop: '1px solid black', borderBottom: '1px solid black', padding: '3px' }}>Loaner Agreements</h3>
              <div style={{ width: '100%', float: 'left' }}>
                <DynamicGridLA
                  gridtype="Normal"
                  id="agreementHistorys"
                  items={agreementHistorys.loaner}
                  setLoader={false}
                  isPrint={true}
                  ColumnLink={true}
                  orderBy="loanerAgreement"
                  order={sortDirection.ASC}
                ></DynamicGridLA>
              </div>
            </div> : null} */}
        </div>
      </div>
    </>
  )
}
export default AgreementHistory;