import React from 'react';
import { RACModalCard } from '@rentacenter/racstrap';
import ModalBuilder from './modalBuilder';

const ModalContainer = (props: any): any => {
  const radiusProvider = '25px !important';
  return (
    <>
      <RACModalCard
        isOpen={props.throwModalController.addAllPopUp}
        closeIcon={true}
        maxWidth="xs"
        onClose={() =>
          props.throwMethodAssigner.closeModalCaller('addAllPopUp')
        }
        borderRadius={radiusProvider}
      >
        <ModalBuilder
          displayData="Item is already on the package Do you want to add other items on this Price Tag ?"
          methodController="package data"
          requiredButton="Pair"
          triggeraddAllItem={props.throwMethodAssigner.addAllItemCaller}
          triggerdeclineAddOther={
            props.throwMethodAssigner.declineAddOtherCaller
          }
        />
      </RACModalCard>

      <RACModalCard
        isOpen={props.throwModalController.breakPopup}
        maxWidth="xs"
        closeIcon={true}
        onClose={() => props.throwMethodAssigner.closeModalCaller('breakPopup')}
        borderRadius={radiusProvider}
      >
        <ModalBuilder
          displayData="Do you want to break the Price Tag ?"
          methodController="break package"
          requiredButton="Pair"
          triggercloseGroupPop={props.throwMethodAssigner.closeGroupPopCaller}
          triggerdeclineAdding={props.throwMethodAssigner.declineAddingCaller}
        />
      </RACModalCard>
      <RACModalCard
        isOpen={props.throwModalController.breakPackageCtrl}
        maxWidth="xs"
        closeIcon={true}
        onClose={() =>
          props.throwMethodAssigner.closeModalCaller('breakPackageCtrl')
        }
        borderRadius={radiusProvider}
      >
        <ModalBuilder
          displayData="Do you want to break the Price Tag ?"
          methodController="delete package"
          requiredButton="Pair"
          triggeracceptBreakPackage={
            props.throwMethodAssigner.acceptBreakPackageCaller
          }
          triggerdeclineBreakPackage={
            props.throwMethodAssigner.declineBreakPackageCaller
          }
        />
      </RACModalCard>

      <RACModalCard
        isOpen={props.throwModalController.RateTermController}
        maxWidth="xs"
        closeIcon={false}
        borderRadius={radiusProvider}
      >
        <ModalBuilder
          displayData="Price Exception"
          methodController="price exception"
          requiredButton="Single"
          pricingExceptionData={props.throwPricingException}
          triggercloseRateTermPopUp={
            props.throwMethodAssigner.closeRateTermPopUpCaller
          }
        />
      </RACModalCard>
      <RACModalCard
        isOpen={props.throwModalController.packageExceptionController}
        maxWidth="xs"
        closeIcon={false}
        borderRadius={radiusProvider}
      >
        <ModalBuilder
          displayData="Items are not a valid component for this package type"
          methodController="package exception"
          requiredButton="Single"
          triggerclosepackageExceptionController={
            props.throwMethodAssigner.closepackageExceptionControllerCaller
          }
        />
      </RACModalCard>
      <RACModalCard
        isOpen={props.throwModalController.PackageExceptionPopup}
        maxWidth="xs"
        closeIcon={false}
        borderRadius={radiusProvider}
      >
        <ModalBuilder
          displayData={props.throwFormException}
          methodController="package criteria"
          requiredButton="Single"
          triggeracceptPackageCriteria={
            props.throwMethodAssigner.acceptPackageCriteriaCaller
          }
        />
      </RACModalCard>
      <RACModalCard
        isOpen={props.throwModalController.itemPricingFail}
        maxWidth="xs"
        closeIcon={false}
        borderRadius={radiusProvider}
      >
        <ModalBuilder
          displayData={props.throwPriceExceptionMessage}
          methodController="item pricing fail"
          requiredButton="Single"
          triggeracceptPricingFail={
            props.throwMethodAssigner.acceptPricingFailCaller
          }
        />
      </RACModalCard>
    </>
  );
};

export default ModalContainer;
