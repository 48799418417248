/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable prettier/prettier */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable sonarjs/no-duplicate-string */

import React from 'react';
import { formatter, validateData } from '../../constants/CommonMethods';
import { CAandNYPageStyles } from './CAandNYPagestyle';
import { formStyles } from '../../components/componentstyles/formstyles';

export interface itemPrintProps {
  printItemNo: any[];
  printLanguage: string;
  printItemResp: any;
  invRefCodes?: any;
}
const Barcode = require('react-barcode');
const sprintf = require('sprintf-js').sprintf;
export default function SIpricetagNYCA(props: itemPrintProps) {
  const formClasses = formStyles();
  const storeDetails = sessionStorage.getItem('storeDetails') || '';
  const encodestore = Buffer.from(storeDetails, 'base64');
  const decodestore: any = JSON.parse(encodestore.toString('ascii'));
  console.log('inside Print Function', props);
  console.log('decodestore', decodestore);

  const data = validateData(props.printItemResp) ? props.printItemResp : [];
  console.log('responsedata', data);
  const PriceTag = () => {
    return props.printItemNo.map((values: any, index: any) => {

      if (
        validateData(data[index]) &&
        validateData(data[index].weeklyRate) &&
        validateData(data[index].brand)
      ) {
        const costRental = Number(data[index].totalCost) - Number(data[index].sameAsCashPrice);
        const brand = data[index].brand.toUpperCase();
        const monthmain = (Number(data[index].totalCost)) / (Number(data[index].monthlyRate))
        if (data[index]?.templateName == "CASingleItemPriceTagV2" || data[index]?.templateName == "NYCSingleItemPriceTagV2" || data[index]?.templateName == "NYSingleItemPriceTagV2") {
          return (
            <div key={index} style={{ fontFamily: 'Arial', marginTop: '0% !important', marginBottom: '80px' }}>
              <div style={{ width: '100%' }}>
                <h1 style={{ fontSize: '25px', textAlign: 'center', marginTop: '5px' }}>
                  {validateData(brand) ? brand : '-'}
                </h1>
                <p style={{ textAlign: 'center', fontWeight: 'bold', marginBottom: '13px' }}>
                  {validateData(data[index]) &&
                    validateData(data[index].inventoryDescription)
                    ? data[index].inventoryDescription
                    : '-'}
                </p>
                <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '7px' }}>
                  <table style={{ width: '100%', border: '1px solid #000', borderCollapse: 'collapse' }}>
                    <tbody style={{ fontSize: '15.5px', width: '100%' }}>
                      <tr>
                        <td rowSpan={2} style={{ borderRight: '1px solid #000', padding: '5px', textAlign: 'center', verticalAlign: 'top', width: '29%' }}>
                          <p style={{ fontWeight: 'bold', marginTop: 0, marginBottom: '1.5px' }}>TOTAL OF</p>
                          <p style={{ fontWeight: 'bold', marginTop: 0, marginBottom: '1.5px' }}>PAYMENTS</p>
                          <p style={{ fontWeight: 'bold', marginTop: 0, marginBottom: '1.5px' }}>                {validateData(data[index]) &&
                            validateData(data[index].totalCost)
                            ? formatter.format(data[index].totalCost).replace(/^(\D+)/, '$1 ')
                            : '-'}</p>
                          <p style={{ fontStyle: 'italic', marginTop: 0, marginBottom: '2px' }}>(Tax not included)</p>
                          <p style={{ marginTop: 0, marginBottom: '1.5px' }}>You must pay this amount to own the property if you make all regular payments.</p> You can buy the property for less under the early purchase option.
                        </td>
                        <td style={{ borderRight: '1px solid #000', padding: '8px', textAlign: 'center', verticalAlign: 'top', width: '34%' }}>
                          <p style={{ fontWeight: 'bold', marginTop: 0, marginBottom: '1.5px' }}>COST OF RENTAL</p>
                          <p style={{ fontWeight: 'bold', marginTop: 0, marginBottom: '1.5px' }}>{validateData(costRental)
                            ? formatter.format(costRental).replace(/^(\D+)/, '$1 ')
                            : '-'}</p>
                          <p style={{ fontStyle: 'italic', marginTop: 0, marginBottom: '2px' }}>(Tax not included)</p>
                          Amount over cash price you will pay if you make all regular payments.
                        </td>
                        <td colSpan={2} style={{ borderRight: '1px solid #000', padding: '8px', textAlign: 'center', verticalAlign: 'top', width: '36%' }}>
                          <p style={{ fontWeight: 'bold', marginTop: 0, marginBottom: '1.5px' }}>CASH PRICE</p>
                          <p style={{ fontWeight: 'bold', marginTop: 0, marginBottom: '1.5px' }}>{validateData(data[index]) &&
                            validateData(data[index].sameAsCashPrice)
                            ? formatter.format(data[index].sameAsCashPrice).replace(/^(\D+)/, '$1 ')
                            : '-'}</p>
                          <p style={{ fontStyle: 'italic', marginTop: 0, marginBottom: '2px' }}>(Tax not included)</p>
                          Property available at this price for cash from the lessor. See about your early purchase options rights.
                        </td>
                      </tr>
                      <tr>
                        <td style={{ borderRight: '1px solid #000', borderTop: '1px solid #000', padding: '19px 5px 5px 5px', textAlign: 'center', verticalAlign: 'top' }}>
                          <p style={{ fontWeight: 'bold', textAlign: 'center', marginTop: 0, marginBottom: '1.5px' }}>AMOUNT OF EACH</p>
                          <p style={{ fontWeight: 'bold', marginTop: 0, marginBottom: '1.5px' }}>PAYMENT</p>
                          <p style={{ fontWeight: 'bold', marginTop: 0, marginBottom: '1.5px' }}>                {validateData(data[index]) &&
                            validateData(data[index].weeklyRate)
                            ? formatter.format(data[index].weeklyRate).replace(/^(\D+)/, '$1 ')
                            : '-'}{' '}</p>
                          <p style={{ marginTop: '0', marginBottom: '1.5px' }}>PER WEEK</p>
                          <p style={{ marginTop: '0', marginBottom: '1.5px' }}>or</p>
                          <p style={{ fontWeight: 'bold', marginTop: '0', marginBottom: '1.5px' }}>                          {validateData(data[index]) &&
                            validateData(data[index].monthlyRate)
                            ? formatter.format(data[index].monthlyRate).replace(/^(\D+)/, '$1 ')
                            : '-'}</p>
                          <p style={{ marginTop: '0', marginBottom: '1.5px' }}>PER MONTH </p>
                          <p style={{ fontStyle: 'italic', marginTop: 0, marginBottom: '2px' }}>(Tax not included)</p>
                        </td>
                        <td style={{ borderRight: '1px solid #000', borderTop: '1px solid #000', padding: '8px', textAlign: 'center', verticalAlign: 'top' }}>
                          <p style={{ fontWeight: 'bold', marginTop: 0, marginBottom: '1.5px' }}>NUMBER</p>
                          <p style={{ fontWeight: 'bold', marginTop: 0, marginBottom: '1.5px' }}>OF</p>
                          <p style={{ fontWeight: 'bold', marginTop: 0, marginBottom: '1.5px' }}>PAYMENTS</p>
                          <p style={{ marginTop: '0', marginBottom: '1.5px' }}><span style={{ fontWeight: 'bold' }}>                       {validateData(data[index]) &&
                            validateData(data[index].weeklyTerm)
                            ? data[index].weeklyTerm
                            : '-'}{' '}   </span>&nbsp;weekly</p>
                          <p style={{ marginTop: '0', marginBottom: '1.5px' }}>or</p>
                          <p style={{ marginTop: '0', marginBottom: '1.5px', display: 'flex' }}><span style={{ fontWeight: 'bold' }}>                          {validateData(data[index]) &&
                            validateData(monthmain)
                            ? (monthmain.toFixed(1))
                            : '-'}{' '} </span>&nbsp;monthly</p>

                        </td>
                        <td style={{ borderRight: '1px solid #000', borderTop: '1px solid #000', padding: '8px', textAlign: 'center', verticalAlign: 'top' }}>
                          <p style={{ fontWeight: 'bold', marginTop: 0, marginBottom: '1.5px' }}>RENTAL</p>
                          <p style={{ fontWeight: 'bold', marginTop: 0, marginBottom: '1.5px' }}>PERIOD</p>
                          <p style={{ marginTop: '0', marginBottom: '1.5px' }}>minimum</p>
                          <p style={{ marginTop: '0', marginBottom: '1.5px' }}>weeks: 1</p>
                          <p style={{ marginTop: '0', marginBottom: '1.5px' }}>Maximum</p>
                          <p style={{ marginTop: '0', marginBottom: '1.5px', display: 'flex' }}>weeks:&nbsp;<span style={{ fontWeight: 'bold' }}>{validateData(data[index]) &&
                            validateData(data[index].weeklyTerm)
                            ? data[index].weeklyTerm
                            : '-'}{' '} </span></p>
                          <p style={{ marginTop: '0', marginBottom: '1.5px', textAlign: 'center' }}>or</p>
                          <p style={{ marginTop: '0', marginBottom: '1.5px' }}>Minimum</p>
                          <p style={{ marginTop: '0', marginBottom: '1.5px' }}>months: 1</p>
                          <p style={{ marginTop: '0', marginBottom: '1.5px' }}>Maximum</p>
                          <p style={{ marginTop: '0', marginBottom: '1.5px', display: 'flex' }}>months:&nbsp;<span style={{ fontWeight: 'bold' }}>{validateData(data[index]) &&
                            validateData(monthmain)
                            ? (Math.round(monthmain))
                            : '-'}{' '}</span></p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div style={{ textAlign: 'center', marginBottom: '12px', fontSize: '15px' }}>
                  <p style={{ marginTop: '0px', marginBottom: '5px', fontWeight: 600, fontStyle: 'italic' }}>Early Purchase Options:</p>
                  <p style={{ marginTop: '0px', marginBottom: '5px', fontWeight: 600 }}>Same As Cash Price(SAC):                  <span style={{ paddingLeft: '15px' }}> {validateData(data[index]) &&
                    validateData(data[index].sameAsCashPrice)
                    ? formatter.format(data[index].sameAsCashPrice).replace(/^(\D+)/, '$1 ')
                    : '-'}</span></p>
                  <p style={{ marginTop: '0px', marginBottom: '9px' }}><span style={{ fontWeight: 600, paddingRight: '15px' }}>After SAC:</span>  Purchase early to save on the total of payments</p>
                </div>
                <div style={{ width: '100%', textAlign: 'center' }}>
                  <p style={{ textAlign: 'center', fontSize: '20px', fontWeight: 'bold', marginBottom: '18px', marginTop: '18px' }}>
                    {validateData(data[index]) &&
                      validateData(data[index].status)
                      ? data[index].status
                      : '-'}{'  '}
                    {/* Previously Rented */}
                  </p>
                  <div className="text-align:center;width:100%" style={{ fontSize: '12px' }}>
                    <span>
                      <span>Item: </span>
                      <span>
                        {' '}
                        {validateData(data[index]) &&
                          validateData(data[index].inventoryNumber)
                          ? data[index].inventoryNumber
                          : '-'}{'  '}
                      </span>
                    </span>
                    <span>-</span>
                    <span>
                      <span>Model: </span>
                      <span>
                        {' '}
                        {validateData(data[index]) &&
                          validateData(data[index].model)
                          ? data[index].model
                          : '-'}
                        {'  '}
                      </span>
                    </span>
                    <span>-</span>
                    <span>
                      <span>Serial: </span>
                      <span>
                        {' '}
                        {validateData(data[index]) &&
                          validateData(data[index].serialNumber)
                          ? data[index].serialNumber
                          : '-'}
                      </span>
                    </span>
                  </div>
                  <div className={formClasses.barcodeSizeFix}>
                    <Barcode
                      value={generateBarCodeContent(
                        data[index].inventoryPriceId,
                        data[index].deptRefcode,
                        data[index].subDeptRefcode
                      )}
                      displayValue={true}
                      height={48}
                      width={1.5}
                      fontSize={7}
                    />{" "}
                  </div>
                </div>
              </div>
            </div>
          );
        }
      }
    });
  };

  return <div>{PriceTag()}</div>;
};

const generateBarCodeContent = (invPriceId: any, depRefCode: any, SubDepRefCode: any) => {
  if (invPriceId && depRefCode && SubDepRefCode) {
    const BAR_CODE_FORMAT = "%-18s%1s%3s%3s";
    return sprintf(BAR_CODE_FORMAT, invPriceId, "P", depRefCode, SubDepRefCode);
  }
  else if (invPriceId && depRefCode) {
    const BAR_CODE_FORMAT = "%-18s%1s%3s%3s";
    return sprintf(BAR_CODE_FORMAT, invPriceId, "P", depRefCode, depRefCode);
  }
  else if (invPriceId) {
    return `${invPriceId}     P`;
  }
  return '';
}

// export default PrintFunction
