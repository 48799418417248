/* eslint-disable prettier/prettier */
/* eslint-disable react-hooks/rules-of-hooks */
import { makeStyles, RACCOLOR } from '@rentacenter/racstrap';
// import searchbtn from '../images/search-btn.svg';
export const searchCriteriaStyles = () => {
  const useClasses = makeStyles(() => ({
    searchBtn: {
      backgroundRepeat: 'no-repeat !important',
      backgroundPosition: '15px !important',
      paddingLeft: '24px',
      paddingRight: '20px',
      fontFamily: 'OpenSans-Bold',
      fontSize: '13px',
    },
    disableme: {
      pointerEvents: 'none',
      opacity: 0.9,
    },
    dropdownBold:{
      '& div':{
        fontFamily:'OpenSans-semibold'
      },},
    packageStructure: {
      backgroundColor: `${RACCOLOR.LIGHT_BLUE_BACKGROUND}`,
      color: '#2179fe',
      padding: '8px 10px',
      borderRadius: '5px',
      marginTop: '1.2rem',
      display: 'inline-block',
    },
    dropDownLabelAlign: {
      marginBottom: '2px',
    },
    responcol6: {
      ['@media (min-width:780px) and (max-width:1280px)']: {
        maxWidth: '50%!important',
        flexBasis: '50%',
      },
    },
    responcol12: {
      ['@media (min-width:780px) and (max-width:1280px)']: {
        maxWidth: '100%!important',
        flexBasis: '100%',
      },
    },
  }));
  return useClasses();
};
