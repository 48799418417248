/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable sonarjs/cognitive-complexity */
import React, { useEffect, useState } from 'react';
import { validateObject } from '../Shared/validateObject';
import NumberFormat from 'react-number-format';
import moment from 'moment';
import { globalStyles } from '../componentstyles/globalstyles';
import {
  ON_RENT,
  RENT_READY,
  SERVICE_ON_RENT,
  LOANER,
  NOT_RENT_READY,
  PENDING_CHARGE_OFF,
  CHARGED_OFF,
  HOLD,
  SERVICE_IDLE,
  RESERVED,
  TRANSFER_IN_PROGRESS,
  PENDING_RENTAL_AGREEMENT,
  PENDING_LOANER_AGREEMENT,
} from '../../constants/constants';
export const PrintInfo = (props: any) => {
  const classes = globalStyles();
  const [inventoryInfo, setinventoryInfo] = useState<any>({});
  const [inventoryDetails, setinventoryDetails] = useState<any>({});

  useEffect(() => {
    if (props) {
      console.log('printprops', props);

      setinventoryInfo(props.inventoryInfoRes);
      setinventoryDetails(props.inventoryDetail);
    }
  }, [props]);

  const roundOff = (num: any, places: number) => {
    const x = Math.pow(10, places);
    return Math.round(num * x) / x;
  };

  return (
    <div style={{ display: 'none' }}>
      {/* {inventoryInfo} */}
      <div id="InventoryPrintHTML">
        <div style={{ width: '96%', margin: '2%', float: 'left' }}>
          <div style={{ width: '100%', float: 'left', marginBottom: '6px' }}>
            <h2 style={{ float: 'left', margin: '0px' }}>
              Inventory Item Information
            </h2>
            <div
              style={{ float: 'right', marginTop: '5px', fontStyle: 'italic' }}
            >
              {moment().format('ddd,D MMM YYYY, hh:mm A')}
            </div>
          </div>

          <div style={{ width: '100%', float: 'left', marginBottom: '4px' }}>
            <div style={{ width: '20%', float: 'left' }}>
              <span style={{ fontWeight: 'bold', fontSize: '14px' }}>
                Rent-A-Center
              </span>
              <span style={{ fontSize: '14px', fontStyle: 'italic' }}>
                {' '}
                ({inventoryInfo?.inventoryHeaderObj?.storeNumber})
              </span>
            </div>
            <div style={{ width: '30%', float: 'left' }}>
              <span style={{ fontWeight: 'bold', fontSize: '14px' }}>
                Item #:
              </span>
              <span style={{ fontSize: '14px', fontStyle: 'italic' }}>
                {' '}
                {inventoryInfo?.inventoryHeaderObj?.itemNumber}
              </span>
            </div>
            <div style={{ width: '50%', float: 'left' }}>
              <span style={{ fontWeight: 'bold', fontSize: '14px' }}>
                Description:
              </span>
              <span style={{ fontSize: '14px', fontStyle: 'italic' }}>
                {' '}
                {inventoryInfo?.inventoryHeaderObj?.description}
              </span>
            </div>
          </div>

          {/* {validateObject(inventoryInfo?.inventoryInfoRes) && inventoryInfo?.inventoryInfoRes?.inventoryInformationObj ? */}
          <>
            <div style={{ width: '100%', float: 'left' }}>
              <h3
                style={{
                  borderTop: '1px solid black',
                  borderBottom: '1px solid black',
                  padding: '3px',
                  marginBottom: '0px',
                }}
              >
                General Information
              </h3>

              <div style={{ width: '100%', float: 'left', marginBottom: '-12px' }}>
                  <div
                    style={{
                      width: '31.3%',
                      margin: '1%',
                      float: 'left',
                      marginBottom: '10px',
                    }}
                  >
                    <span>
                      <div style={{ fontSize: '14px' }}>Status</div>
                      <div style={{ fontSize: '14px' }}>
                        {inventoryInfo?.inventoryHeaderObj?.status
                          ? inventoryInfo?.inventoryHeaderObj?.status
                          : '-'}
                      </div>
                    </span>
                  </div>
                            <div
                              style={{
                                width: '31.3%',
                                margin: '1%',
                                float: 'left',
                                marginBottom: '10px',
                              }}
                            >
                              <span>
                                <div style={{ fontSize: '14px' }}>Current Rate</div>
                                <div
                                  style={{ fontSize: '14px' }}
                                  className={classes.formLabelValue}
                                >
                                  {' '}
                                  $
                                  {(inventoryInfo?.inventoryInformationObj?.currentRate &&
                                    inventoryInfo?.inventoryInformationObj?.currentRate !==
                                      '0') ||
                                  inventoryInfo?.inventoryInformationObj?.currentRate !==
                                    '0.00'
                                    ? //   <NumberFormat
                                      //     thousandsGroupStyle="thousand"
                                      //     value={roundOff(inventoryInfo.inventoryInformationObj.currentRate, 3).toFixed(2)}
                                      //     prefix="$ "
                                      //     className={classes.formLabelValue}
                                      //     displayType="text"
                                      //     type="text"
                                      //     thousandSeparator={true}
                                      //   />
                                      roundOff(
                                        inventoryInfo?.inventoryInformationObj?.currentRate,
                                        3
                                      ).toFixed(2)
                                    : '$ 0.00'}
                                </div>
                              </span>
                            </div>
                {/* <div
                  style={{
                    width: '31.3%',
                    margin: '1%',
                    float: 'left',
                    marginBottom: '10px',
                  }}
                >
                  <span>
                    <div style={{ fontSize: '14px' }}>Sub Status</div>
                    <div style={{ fontSize: '14px' }}>{}</div>
                  </span>
                </div> */}
                    <div
                      style={{
                        width: '31.3%',
                        margin: '1%',
                        float: 'left',
                        marginBottom: '10px',
                      }}
                    >
                      <span>
                        <div style={{ fontSize: '14px' }}>Current Term</div>
                        <div style={{ fontSize: '14px' }}>
                          {inventoryInfo?.inventoryInformationObj?.currentTerm &&
                          inventoryInfo?.inventoryInformationObj?.currentTerm !== 0
                            ? inventoryInfo.inventoryInformationObj?.currentTerm
                            : '0'}
                        </div>
                      </span>
                    </div>
              </div>

              <div style={{ width: '100%', float: 'left', marginBottom: '-12px' }}>
                {/* <div
                  style={{
                    width: '31.3%',
                    margin: '1%',
                    float: 'left',
                    marginBottom: '10px',
                  }}
                >
                  <span>
                    <div style={{ fontSize: '14px' }}>Current Term</div>
                    <div style={{ fontSize: '14px' }}>
                      {inventoryInfo?.inventoryInformationObj?.currentTerm &&
                      inventoryInfo?.inventoryInformationObj?.currentTerm !== 0
                        ? inventoryInfo.inventoryInformationObj?.currentTerm
                        : '0'}
                    </div>
                  </span>
                </div> */}
                <div
                  style={{
                    width: '31.3%',
                    margin: '1%',
                    float: 'left',
                    marginBottom: '10px',
                  }}
                >
                  <span>
                    <div style={{ fontSize: '14px' }}>Department</div>
                    <div style={{ fontSize: '14px' }}>
                      {inventoryInfo?.inventoryInformationObj?.department
                        ? inventoryInfo?.inventoryInformationObj?.department
                        : '-'}
                    </div>
                  </span>
                </div>
                <div
                  style={{
                    width: '31.3%',
                    margin: '1%',
                    float: 'left',
                    marginBottom: '10px',
                  }}
                >
                  <span>
                    <div style={{ fontSize: '14px' }}>Sub Department</div>
                    <div style={{ fontSize: '14px' }}>
                      {inventoryInfo?.inventoryInformationObj?.subDepartment
                        ? inventoryInfo?.inventoryInformationObj?.subDepartment
                        : '-'}
                    </div>
                  </span>
                </div>
                <div
                  style={{
                    width: '31.3%',
                    margin: '1%',
                    float: 'left',
                    marginBottom: '10px',
                  }}
                >
                  <span>
                    <div style={{ fontSize: '14px' }}>Bracket</div>
                    <div style={{ fontSize: '14px' }}>
                      {validateObject(
                        inventoryInfo.inventoryInformationObj?.bracket
                      )
                        ? inventoryInfo.inventoryInformationObj?.bracket
                        : '-'}
                    </div>
                  </span>
                </div>
                {/* <div
                  style={{
                    width: '31.3%',
                    margin: '1%',
                    float: 'left',
                    marginBottom: '10px',
                  }}
                >
                  <span>
                    <div style={{ fontSize: '14px' }}>Purchase Date</div>
                    <div style={{ fontSize: '14px' }}>
                      {inventoryDetails?.depreciationEstimatorObj &&
                      inventoryDetails?.depreciationEstimatorObj?.purchaseDate
                        ? moment(
                            inventoryDetails.depreciationEstimatorObj
                              .purchaseDate
                          ).format('MM/DD/YYYY')
                        : '-'}
                    </div>
                  </span>
                </div> */}
              </div>

              <div style={{ width: '100%', float: 'left', marginBottom: '-12px' }}>
                {/* <div
                  style={{
                    width: '31.3%',
                    margin: '1%',
                    float: 'left',
                    marginBottom: '10px',
                  }}
                >
                  <span>
                    <div style={{ fontSize: '14px' }}>Sub Department</div>
                    <div style={{ fontSize: '14px' }}>
                      {inventoryInfo?.inventoryInformationObj?.subDepartment
                        ? inventoryInfo?.inventoryInformationObj?.subDepartment
                        : '-'}
                    </div>
                  </span>
                </div> */}
                <div
                  style={{
                    width: '31.3%',
                    margin: '1%',
                    float: 'left',
                    marginBottom: '10px',
                  }}
                >
                  <span>
                    <div style={{ fontSize: '14px' }}>Brand</div>
                    <div style={{ fontSize: '14px' }}>
                      {validateObject(
                        inventoryInfo.inventoryInformationObj?.brand
                      )
                        ? inventoryInfo.inventoryInformationObj?.brand
                        : '-'}
                    </div>
                  </span>
                </div>
                <div
                  style={{
                    width: '31.3%',
                    margin: '1%',
                    float: 'left',
                    marginBottom: '10px',
                  }}
                >
                  <span>
                    <div style={{ fontSize: '14px' }}>Purchase Cost</div>
                    <div style={{ fontSize: '14px' }}>
                      {validateObject(
                        inventoryDetails.depreciationEstimatorObj
                      ) &&
                      validateObject(
                        inventoryDetails.depreciationEstimatorObj.purchaseCost
                      ) &&
                      (inventoryDetails.depreciationEstimatorObj
                        .purchaseCost !== '0' ||
                        inventoryDetails.depreciationEstimatorObj
                          .purchaseCost !== '0.00') ? (
                        <NumberFormat
                          thousandsGroupStyle="thousand"
                          value={roundOff(
                            inventoryDetails.depreciationEstimatorObj
                              .purchaseCost,
                            3
                          ).toFixed(2)}
                          prefix="$ "
                          className={classes.formLabelValue}
                          displayType="text"
                          type="text"
                          thousandSeparator={true}
                        />
                      ) : (
                        '$ 0.00'
                      )}
                    </div>
                  </span>
                </div>
                <div
                  style={{
                    width: '31.3%',
                    margin: '1%',
                    float: 'left',
                    marginBottom: '10px',
                  }}
                >
                  <span>
                    <div style={{ fontSize: '14px' }}>Purchase Date</div>
                    <div style={{ fontSize: '14px' }}>
                      {inventoryDetails?.depreciationEstimatorObj &&
                      inventoryDetails?.depreciationEstimatorObj?.purchaseDate
                        ? moment(
                            inventoryDetails.depreciationEstimatorObj
                              .purchaseDate
                          ).format('MM/DD/YYYY')
                        : '-'}
                    </div>
                  </span>
                </div>
                {/* <div
                  style={{
                    width: '31.3%',
                    margin: '1%',
                    float: 'left',
                    marginBottom: '10px',
                  }}
                >
                  <span>
                    <div style={{ fontSize: '14px' }}>Bracket</div>
                    <div style={{ fontSize: '14px' }}>
                      {validateObject(
                        inventoryInfo.inventoryInformationObj?.bracket
                      )
                        ? inventoryInfo.inventoryInformationObj?.bracket
                        : '-'}
                    </div>
                  </span>
                </div> */}
              </div>

              <div style={{ width: '100%', float: 'left', marginBottom: '-12px' }}>
                    <div
                      style={{
                        width: '31.3%',
                        margin: '1%',
                        float: 'left',
                        marginBottom: '10px',
                      }}
                    >
                      <span>
                        <div style={{ fontSize: '14px' }}>Model #</div>
                        <div style={{ fontSize: '14px' }}>
                          {validateObject(
                            inventoryInfo.inventoryInformationObj?.modelNumber
                          )
                            ? inventoryInfo.inventoryInformationObj?.modelNumber
                            : '-'}
                        </div>
                      </span>
                    </div>

                    <div
                      style={{
                        width: '31.3%',
                        margin: '1%',
                        float: 'left',
                        marginBottom: '10px',
                      }}
                    >
                      <span>
                        <div style={{ fontSize: '14px' }}>Total Revenue</div>
                        <div style={{ fontSize: '14px' }}>
                          {validateObject(inventoryInfo.currentAgreementObj) &&
                          validateObject(
                            inventoryInfo.currentAgreementObj.totalRevenue
                          ) &&
                          (inventoryInfo.currentAgreementObj.totalRevenue !== '0' ||
                            inventoryInfo.currentAgreementObj.totalRevenue !==
                              '0.00') ? (
                            <NumberFormat
                              thousandsGroupStyle="thousand"
                              value={roundOff(
                                inventoryInfo.currentAgreementObj.totalRevenue,
                                3
                              ).toFixed(2)}
                              prefix="$ "
                              className={classes.formLabelValue}
                              displayType="text"
                              type="text"
                              thousandSeparator={true}
                            />
                          ) : (
                            '$ 0.00'
                          )}
                        </div>
                      </span>
                    </div>

                {/* <div
                  style={{
                    width: '31.3%',
                    margin: '1%',
                    float: 'left',
                    marginBottom: '10px',
                  }}
                >
                  <span>
                    <div style={{ fontSize: '14px' }}>Brand</div>
                    <div style={{ fontSize: '14px' }}>
                      {validateObject(
                        inventoryInfo.inventoryInformationObj?.brand
                      )
                        ? inventoryInfo.inventoryInformationObj?.brand
                        : '-'}
                    </div>
                  </span>
                </div> */}

                <div
                  style={{
                    width: '31.3%',
                    margin: '1%',
                    float: 'left',
                    marginBottom: '10px',
                  }}
                >
                  <span>
                    <div style={{ fontSize: '14px' }}>Revenue Turns</div>
                    <div style={{ fontSize: '14px' }}>
                      {validateObject(
                        inventoryDetails.depreciationEstimatorObj
                      ) &&
                      validateObject(
                        inventoryDetails.depreciationEstimatorObj.revenueTurns
                      ) &&
                      inventoryDetails.depreciationEstimatorObj.revenueTurns !==
                        0
                        ? inventoryDetails.depreciationEstimatorObj.revenueTurns
                        : '0.00'}
                    </div>
                  </span>
                </div>
              </div>

              <div
                style={{ width: '100%', float: 'left', marginBottom: '-12px' }}
              >
                {/* <div
                  style={{
                    width: '31.3%',
                    margin: '1%',
                    float: 'left',
                    marginBottom: '10px',
                  }}
                >
                  <span>
                    <div style={{ fontSize: '14px' }}>Model #</div>
                    <div style={{ fontSize: '14px' }}>
                      {validateObject(
                        inventoryInfo.inventoryInformationObj?.modelNumber
                      )
                        ? inventoryInfo.inventoryInformationObj?.modelNumber
                        : '-'}
                    </div>
                  </span>
                </div> */}

                <div
                  style={{
                    width: '31.3%',
                    margin: '1%',
                    float: 'left',
                    marginBottom: '10px',
                  }}
                >
                  <span>
                    <div style={{ fontSize: '14px' }}>Remaining Value</div>
                    <div style={{ fontSize: '14px' }}>
                      {validateObject(
                        inventoryDetails.depreciationEstimatorObj
                      ) &&
                      validateObject(
                        inventoryDetails.depreciationEstimatorObj.remainingValue
                      ) &&
                      (inventoryDetails.depreciationEstimatorObj
                        .remainingValue !== '0' ||
                        inventoryDetails.depreciationEstimatorObj
                          .remainingValue !== '0.00') ? (
                        <NumberFormat
                          thousandsGroupStyle="thousand"
                          value={roundOff(
                            inventoryDetails.depreciationEstimatorObj
                              ?.remainingValue,
                            3
                          ).toFixed(2)}
                          prefix="$ "
                          className={classes.formLabelValue}
                          displayType="text"
                          type="text"
                          thousandSeparator={true}
                        />
                      ) : (
                        '$ 0.00'
                      )}
                    </div>
                  </span>
                </div>
                <div
                  style={{
                    width: '31.3%',
                    margin: '1%',
                    float: 'left',
                    marginBottom: '10px',
                  }}
                >
                  <span>
                    <div style={{ fontSize: '14px' }}>Serial #</div>
                    <div style={{ fontSize: '14px' }}>
                      {validateObject(
                        inventoryInfo.inventoryInformationObj?.serialNumber
                      )
                        ? inventoryInfo.inventoryInformationObj?.serialNumber
                        : '-'}
                    </div>
                  </span>
                </div>
              </div>
            </div>
            {/* {validateObject(
              inventoryInfo.inventoryInformationObj?.auditMessage
            ) && inventoryInfo.inventoryInformationObj?.auditMessage !== '' ? (
              <div style={{ width: '100%', float: 'left' }}>
                <h3
                  style={{
                    borderTop: '1px solid black',
                    borderBottom: '1px solid black',
                    padding: '3px',
                    marginBottom: '0px',
                  }}
                >
                  Audit Message
                </h3>
                <div
                  style={{
                    // width: '31.3%',
                    margin: '1%',
                    float: 'left',
                    marginBottom: '10px',
                  }}
                >
                  <span style={{ fontSize: '14px' }}>
                    {validateObject(
                      inventoryInfo.inventoryInformationObj?.auditMessage
                    )
                      ? inventoryInfo.inventoryInformationObj?.auditMessage
                      : '-'}
                  </span>
                </div>
              </div>
            ) : null} */}
          </>

          {validateObject(inventoryInfo.inventoryHeaderObj) &&
          validateObject(inventoryInfo.currentAgreementObj) &&
          validateObject(inventoryInfo.inventoryHeaderObj.status) &&
          (inventoryInfo.inventoryHeaderObj.status == ON_RENT ||
            inventoryInfo.inventoryHeaderObj.status ===
              PENDING_RENTAL_AGREEMENT) ? (
            <div style={{ width: '100%', float: 'left' }}>
              <h3
                style={{
                  borderTop: '1px solid black',
                  borderBottom: '1px solid black',
                  padding: '3px',
                  marginBottom: '0px',
                }}
              >
                Current Agreement
              </h3>

              <div
                style={{ width: '100%', float: 'left', marginBottom: '-12px' }}
              >
                <div
                  style={{
                    width: '31.3%',
                    margin: '1%',
                    float: 'left',
                    marginBottom: '10px',
                  }}
                >
                  <span>
                    <div style={{ fontSize: '14px' }}>Agreement #</div>
                    <div style={{ fontSize: '14px' }}>
                      {validateObject(
                        inventoryInfo.currentAgreementObj.agreementNumber
                      )
                        ? inventoryInfo.currentAgreementObj.agreementNumber
                        : '-'}
                    </div>
                  </span>
                </div>
                <div
                  style={{
                    width: '31.3%',
                    margin: '1%',
                    float: 'left',
                    marginBottom: '10px',
                  }}
                >
                  <span>
                    <div style={{ fontSize: '14px' }}>Total RTO</div>
                    <div style={{ fontSize: '14px' }}>
                      {validateObject(
                        inventoryInfo.currentAgreementObj.totalRto
                      ) &&
                      (inventoryInfo.currentAgreementObj.totalRto !== '0' ||
                        inventoryInfo.currentAgreementObj.totalRto !==
                          '0.00') ? (
                        <NumberFormat
                          thousandsGroupStyle="thousand"
                          value={roundOff(
                            inventoryInfo.currentAgreementObj.totalRto,
                            3
                          ).toFixed(2)}
                          prefix="$ "
                          className={classes.formLabelValue}
                          displayType="text"
                          type="text"
                          thousandSeparator={true}
                        />
                      ) : (
                        '$ 0.00'
                      )}
                    </div>
                  </span>
                </div>
                <div
                  style={{
                    width: '31.3%',
                    margin: '1%',
                    float: 'left',
                    marginBottom: '10px',
                  }}
                >
                  <span>
                    <div style={{ fontSize: '14px' }}>Customer</div>
                    <div style={{ fontSize: '14px' }}>
                      {validateObject(
                        inventoryInfo.currentAgreementObj.customerName
                      )
                        ? inventoryInfo.currentAgreementObj.customerName
                        : '-'}
                    </div>
                  </span>
                </div>
              </div>

              <div
                style={{ width: '100%', float: 'left', marginBottom: '-12px' }}
              >
                <div
                  style={{
                    width: '31.3%',
                    margin: '1%',
                    float: 'left',
                    marginBottom: '10px',
                  }}
                >
                  <span>
                    <div style={{ fontSize: '14px' }}>Total Revenue</div>
                    <div style={{ fontSize: '14px' }}>
                      {validateObject(
                        inventoryInfo.currentAgreementObj.totalRevenue
                      ) &&
                      (inventoryInfo.currentAgreementObj.totalRevenue !== '0' ||
                        inventoryInfo.currentAgreementObj.totalRevenue !==
                          '0.00') ? (
                        <NumberFormat
                          thousandsGroupStyle="thousand"
                          value={roundOff(
                            inventoryInfo.currentAgreementObj.totalRevenue,
                            3
                          ).toFixed(2)}
                          prefix="$ "
                          className={classes.formLabelValue}
                          displayType="text"
                          type="text"
                          thousandSeparator={true}
                        />
                      ) : (
                        '$ 0.00'
                      )}
                    </div>
                  </span>
                </div>
                <div
                  style={{
                    width: '31.3%',
                    margin: '1%',
                    float: 'left',
                    marginBottom: '10px',
                  }}
                >
                  <span>
                    <div style={{ fontSize: '14px' }}>Open Date</div>
                    <div style={{ fontSize: '14px' }}>
                      {validateObject(
                        inventoryInfo.currentAgreementObj.openDate
                      )
                        ? moment(
                            inventoryInfo.currentAgreementObj.openDate
                          ).format('MM/DD/YYYY')
                        : '-'}
                    </div>
                  </span>
                </div>
                <div
                  style={{
                    width: '31.3%',
                    margin: '1%',
                    float: 'left',
                    marginBottom: '10px',
                  }}
                >
                  <span>
                    <div style={{ fontSize: '14px' }}>MTD Revenue</div>
                    <div style={{ fontSize: '14px' }}>
                      {validateObject(
                        inventoryInfo.currentAgreementObj.mtdRevenue
                      ) &&
                      (inventoryInfo.currentAgreementObj.mtdRevenue !== '0' ||
                        inventoryInfo.currentAgreementObj.mtdRevenue !==
                          '0.00') ? (
                        <NumberFormat
                          thousandsGroupStyle="thousand"
                          value={roundOff(
                            inventoryInfo.currentAgreementObj.mtdRevenue,
                            3
                          ).toFixed(2)}
                          prefix="$ "
                          className={classes.formLabelValue}
                          displayType="text"
                          type="text"
                          thousandSeparator={true}
                        />
                      ) : (
                        '$ 0.00'
                      )}
                    </div>
                  </span>
                </div>
              </div>

              <div
                style={{ width: '100%', float: 'left', marginBottom: '-12px' }}
              >
                <div
                  style={{
                    width: '31.3%',
                    margin: '1%',
                    float: 'left',
                    marginBottom: '10px',
                  }}
                >
                  <span>
                    <div style={{ fontSize: '14px' }}>Rate</div>
                    <div style={{ fontSize: '14px' }}>
                      {validateObject(inventoryInfo.currentAgreementObj.rate) &&
                      (inventoryInfo.currentAgreementObj.rate !== '0' ||
                        inventoryInfo.currentAgreementObj.rate !== '0.00') ? (
                        <NumberFormat
                          thousandsGroupStyle="thousand"
                          value={roundOff(
                            inventoryInfo.currentAgreementObj.rate,
                            3
                          ).toFixed(2)}
                          prefix="$ "
                          className={classes.formLabelValue}
                          displayType="text"
                          type="text"
                          thousandSeparator={true}
                        />
                      ) : (
                        '$ 0.00'
                      )}
                    </div>
                  </span>
                </div>
                <div
                  style={{
                    width: '31.3%',
                    margin: '1%',
                    float: 'left',
                    marginBottom: '10px',
                  }}
                >
                  <span>
                    <div style={{ fontSize: '14px' }}>Agreement RV</div>
                    <div style={{ fontSize: '14px' }}>
                      {validateObject(
                        inventoryInfo.currentAgreementObj.agreementRv
                      ) &&
                      (inventoryInfo.currentAgreementObj.agreementRv !== '0' ||
                        inventoryInfo.currentAgreementObj.agreementRv !==
                          '0.00') ? (
                        <NumberFormat
                          thousandsGroupStyle="thousand"
                          value={roundOff(
                            inventoryInfo.currentAgreementObj.agreementRv,
                            3
                          ).toFixed(2)}
                          prefix="$ "
                          className={classes.formLabelValue}
                          displayType="text"
                          type="text"
                          thousandSeparator={true}
                        />
                      ) : (
                        '$ 0.00'
                      )}
                    </div>
                  </span>
                </div>
                <div
                  style={{
                    width: '31.3%',
                    margin: '1%',
                    float: 'left',
                    marginBottom: '10px',
                  }}
                >
                  <span>
                    <div style={{ fontSize: '14px' }}>Term</div>
                    <div style={{ fontSize: '14px' }}>
                      {validateObject(inventoryInfo.currentAgreementObj.term) &&
                      inventoryInfo.currentAgreementObj.term !== 0
                        ? inventoryInfo.currentAgreementObj.term
                        : '0'}
                    </div>
                  </span>
                </div>
              </div>
            </div>
          ) : null}

          {validateObject(inventoryDetails.inventoryHeaderObj) &&
          validateObject(inventoryDetails.depreciationEstimatorObj) ? (
            <div style={{ width: '100%', float: 'left' }}>
              <h3
                style={{
                  borderTop: '1px solid black',
                  borderBottom: '1px solid black',
                  padding: '3px',
                  marginBottom: '0px',
                }}
              >
                Depreciation Estimator
              </h3>
              <div
                style={{ width: '100%', float: 'left', marginBottom: '-12px' }}
              >
                <div
                  style={{
                    width: '31.3%',
                    margin: '1%',
                    float: 'left',
                    marginBottom: '10px',
                  }}
                >
                  <span>
                    <div style={{ fontSize: '14px' }}>PO #</div>
                    <div style={{ fontSize: '14px' }}>
                      {validateObject(
                        inventoryDetails.depreciationEstimatorObj.po
                      )
                        ? inventoryDetails.depreciationEstimatorObj.po
                        : '-'}
                    </div>
                  </span>
                </div>
                <div
                  style={{
                    width: '31.3%',
                    margin: '1%',
                    float: 'left',
                    marginBottom: '10px',
                  }}
                >
                  <span>
                    <div style={{ fontSize: '14px' }}>Purchase Date</div>
                    <div style={{ fontSize: '14px' }}>
                      {validateObject(
                        inventoryDetails.depreciationEstimatorObj.purchaseDate
                      )
                        ? moment(
                            inventoryDetails.depreciationEstimatorObj
                              .purchaseDate
                          ).format('MM/DD/YYYY')
                        : '-'}
                    </div>
                  </span>
                </div>
                <div
                  style={{
                    width: '31.3%',
                    margin: '1%',
                    float: 'left',
                    marginBottom: '10px',
                  }}
                >
                  <span>
                    <div style={{ fontSize: '14px' }}>Brand</div>
                    <div style={{ fontSize: '14px' }}>
                      {validateObject(
                        inventoryDetails.depreciationEstimatorObj.brand
                      )
                        ? inventoryDetails.depreciationEstimatorObj.brand
                        : '-'}
                    </div>
                  </span>
                </div>
              </div>

              <div
                style={{ width: '100%', float: 'left', marginBottom: '-12px' }}
              >
                <div
                  style={{
                    width: '31.3%',
                    margin: '1%',
                    float: 'left',
                    marginBottom: '10px',
                  }}
                >
                  <span>
                    <div style={{ fontSize: '14px' }}>Purchase Cost</div>
                    <div style={{ fontSize: '14px' }}>
                      {validateObject(
                        inventoryDetails.depreciationEstimatorObj.purchaseCost
                      ) &&
                      (inventoryDetails.depreciationEstimatorObj
                        .purchaseCost !== '0' ||
                        inventoryDetails.depreciationEstimatorObj
                          .purchaseCost !== '0.00') ? (
                        <NumberFormat
                          thousandsGroupStyle="thousand"
                          value={roundOff(
                            inventoryDetails.depreciationEstimatorObj
                              .purchaseCost,
                            3
                          ).toFixed(2)}
                          prefix="$ "
                          className={classes.formLabelValue}
                          displayType="text"
                          type="text"
                          thousandSeparator={true}
                        />
                      ) : (
                        '$ 0.00'
                      )}
                    </div>
                  </span>
                </div>
                <div
                  style={{
                    width: '31.3%',
                    margin: '1%',
                    float: 'left',
                    marginBottom: '10px',
                  }}
                >
                  <span>
                    <div style={{ fontSize: '14px' }}>Model #</div>
                    <div style={{ fontSize: '14px' }}>
                      {validateObject(
                        inventoryDetails.depreciationEstimatorObj.modelNumber
                      )
                        ? inventoryDetails.depreciationEstimatorObj.modelNumber
                        : '-'}
                    </div>
                  </span>
                </div>
                <div
                  style={{
                    width: '31.3%',
                    margin: '1%',
                    float: 'left',
                    marginBottom: '10px',
                  }}
                >
                  <span>
                    <div style={{ fontSize: '14px' }}>Vendor</div>
                    <div style={{ fontSize: '14px' }}>
                      {validateObject(
                        inventoryDetails.depreciationEstimatorObj.vendor
                      )
                        ? inventoryDetails.depreciationEstimatorObj.vendor
                        : '-'}
                    </div>
                  </span>
                </div>
              </div>

              <div
                style={{ width: '100%', float: 'left', marginBottom: '-12px' }}
              >
                <div
                  style={{
                    width: '31.3%',
                    margin: '1%',
                    float: 'left',
                    marginBottom: '10px',
                  }}
                >
                  <span>
                    <div style={{ fontSize: '14px' }}>Serial #</div>
                    <div style={{ fontSize: '14px' }}>
                      {validateObject(
                        inventoryDetails.depreciationEstimatorObj.serialNumber
                      )
                        ? inventoryDetails.depreciationEstimatorObj.serialNumber
                        : '-'}
                    </div>
                  </span>
                </div>
              </div>
            </div>
          ) : null}

          {validateObject(inventoryDetails.inventoryHeaderObj) &&
          validateObject(inventoryDetails.idleObj) &&
          validateObject(inventoryDetails.inventoryHeaderObj.status) ? (
            <>
              <div style={{ width: '100%', float: 'left' }}>
                <h3
                  style={{
                    borderTop: '1px solid black',
                    borderBottom: '1px solid black',
                    padding: '3px',
                    marginBottom: '0px',
                  }}
                >
                  Idle
                </h3>
                <div
                  style={{
                    width: '100%',
                    float: 'left',
                    marginBottom: '-12px',
                  }}
                >
                  <div
                    style={{
                      width: '31.3%',
                      margin: '1%',
                      float: 'left',
                      marginBottom: '10px',
                    }}
                  >
                    <span>
                      <div style={{ fontSize: '14px' }}>Idle Since</div>
                      <div style={{ fontSize: '14px' }}>
                        {validateObject(inventoryDetails.idleObj.idleSince)
                          ? moment(inventoryDetails.idleObj.idleSince).format(
                              'MM/DD/YYYY'
                            )
                          : '-'}
                      </div>
                    </span>
                  </div>
                  <div
                    style={{
                      width: '31.3%',
                      margin: '1%',
                      float: 'left',
                      marginBottom: '10px',
                    }}
                  >
                    <span>
                      <div style={{ fontSize: '14px' }}>
                        # Times 90 Days Idle
                      </div>
                      <div style={{ fontSize: '14px' }}>
                        {validateObject(
                          inventoryDetails.idleObj.times90DaysIdle
                        )
                          ? inventoryDetails.idleObj.times90DaysIdle
                          : '-'}
                      </div>
                    </span>
                  </div>
                  <div
                    style={{
                      width: '31.3%',
                      margin: '1%',
                      float: 'left',
                      marginBottom: '10px',
                    }}
                  >
                    <span>
                      <div style={{ fontSize: '14px' }}>Days Idle</div>
                      <div style={{ fontSize: '14px' }}>
                        {validateObject(inventoryDetails.idleObj.daysIdle) &&
                        inventoryDetails.idleObj.daysIdle !== 0
                          ? inventoryDetails.idleObj.daysIdle
                          : '0'}
                      </div>
                    </span>
                  </div>
                </div>

                <div
                  style={{
                    width: '100%',
                    float: 'left',
                    marginBottom: '-12px',
                  }}
                >
                  <div
                    style={{
                      width: '31.3%',
                      margin: '1%',
                      float: 'left',
                      marginBottom: '10px',
                    }}
                  >
                    <span>
                      <div style={{ fontSize: '14px' }}>Total Days on Rent</div>
                      <div style={{ fontSize: '14px' }}>
                        {validateObject(
                          inventoryDetails.idleObj.totalDaysOnRent
                        ) && inventoryDetails.idleObj.totalDaysOnRent !== 0
                          ? inventoryDetails.idleObj.totalDaysOnRent
                          : '0'}
                      </div>
                    </span>
                  </div>
                  <div
                    style={{
                      width: '31.3%',
                      margin: '1%',
                      float: 'left',
                      marginBottom: '10px',
                    }}
                  >
                    <span>
                      <div style={{ fontSize: '14px' }}>
                        # Days Idle in Current Store
                      </div>
                      <div style={{ fontSize: '14px' }}>
                        {validateObject(
                          inventoryDetails.idleObj.daysIdleInCurrentStore
                        )
                          ? inventoryDetails.idleObj.daysIdleInCurrentStore
                          : '-'}
                      </div>
                    </span>
                  </div>
                </div>
              </div>

              <div
                style={{ width: '100%', float: 'left', marginBottom: '-12px' }}
              >
                <h3
                  style={{
                    borderTop: '1px solid black',
                    borderBottom: '1px solid black',
                    padding: '3px',
                    marginBottom: '0px',
                  }}
                >
                  Store #
                </h3>
                <div style={{ width: '100%', float: 'left' }}>
                  <div
                    style={{
                      width: '31.3%',
                      margin: '1%',
                      float: 'left',
                      marginBottom: '10px',
                    }}
                  >
                    <span>
                      <div style={{ fontSize: '14px' }}>Physical Store</div>
                      <div style={{ fontSize: '14px' }}>
                        {validateObject(inventoryDetails.idleObj.physicalStore)
                          ? inventoryDetails.idleObj.physicalStore
                          : '-'}
                      </div>
                    </span>
                  </div>
                  <div
                    style={{
                      width: '31.3%',
                      margin: '1%',
                      float: 'left',
                      marginBottom: '10px',
                    }}
                  >
                    <span>
                      <div style={{ fontSize: '14px' }}>Financial Store</div>
                      <div style={{ fontSize: '14px' }}>
                        {validateObject(inventoryDetails.idleObj.financialStore)
                          ? inventoryDetails.idleObj.financialStore
                          : '-'}
                      </div>
                    </span>
                  </div>
                </div>
              </div>
            </>
          ) : null}

          {validateObject(inventoryInfo.inventoryHeaderObj) &&
          validateObject(inventoryInfo.chargedOffObj) &&
          validateObject(inventoryInfo.inventoryHeaderObj.status) &&
          (inventoryInfo.inventoryHeaderObj.status === CHARGED_OFF ||
            inventoryInfo.inventoryHeaderObj.status === PENDING_CHARGE_OFF || inventoryInfo.inventoryHeaderObj.status === NOT_RENT_READY) ? (
            <div style={{ width: '100%', float: 'left' }}>
              <h3
                style={{
                  borderTop: '1px solid black',
                  borderBottom: '1px solid black',
                  padding: '3px',
                  marginBottom: '0px',
                }}
              >
                Charged Off
              </h3>
              <div
                style={{ width: '100%', float: 'left', marginBottom: '-12px' }}
              >
                <div
                  style={{
                    width: '31.3%',
                    margin: '1%',
                    float: 'left',
                    marginBottom: '10px',
                  }}
                >
                  <span>
                    <div style={{ fontSize: '14px' }}>Agr #</div>
                    <div style={{ fontSize: '14px' }}>
                      {validateObject(inventoryInfo.chargedOffObj.agreement)
                        ? inventoryInfo.chargedOffObj.agreement
                        : '-'}
                    </div>
                  </span>
                </div>
                <div
                  style={{
                    width: '31.3%',
                    margin: '1%',
                    float: 'left',
                    marginBottom: '10px',
                  }}
                >
                  <span>
                    <div style={{ fontSize: '14px' }}>Reason</div>
                    <div style={{ fontSize: '14px' }}>
                      {validateObject(inventoryInfo.chargedOffObj.reason)
                        ? inventoryInfo.chargedOffObj.reason
                        : '-'}
                    </div>
                  </span>
                </div>
                <div
                  style={{
                    width: '31.3%',
                    margin: '1%',
                    float: 'left',
                    marginBottom: '10px',
                  }}
                >
                  <span>
                    <div style={{ fontSize: '14px' }}>Receipt #</div>
                    <div style={{ fontSize: '14px' }}>
                      {validateObject(inventoryInfo.chargedOffObj.receipt)
                        ? inventoryInfo.chargedOffObj.receipt
                        : '-'}
                    </div>
                  </span>
                </div>
              </div>

              <div
                style={{ width: '100%', float: 'left', marginBottom: '-12px' }}
              >
                <div
                  style={{
                    width: '31.3%',
                    margin: '1%',
                    float: 'left',
                    marginBottom: '10px',
                  }}
                >
                  <span>
                    <div style={{ fontSize: '14px' }}>Date</div>
                    <div style={{ fontSize: '14px' }}>
                      {validateObject(inventoryInfo.chargedOffObj.date)
                        ? moment(inventoryInfo.chargedOffObj.date).format(
                            'MM/DD/YYYY'
                          )
                        : '-'}
                    </div>
                  </span>
                </div>
                <div
                  style={{
                    width: '31.3%',
                    margin: '1%',
                    float: 'left',
                    marginBottom: '10px',
                  }}
                >
                  <span>
                    <div style={{ fontSize: '14px' }}>Customer</div>
                    <div style={{ fontSize: '14px' }}>
                      {validateObject(inventoryInfo.currentAgreementObj) &&
                      validateObject(
                        inventoryInfo.currentAgreementObj.customerName
                      )
                        ? inventoryInfo.currentAgreementObj.customerName
                        : '-'}
                    </div>
                  </span>
                </div>
              </div>
            </div>
          ) : null}
          {validateObject(inventoryInfo.inventoryHeaderObj) &&
          validateObject(inventoryInfo.recoveryObj) &&
          validateObject(inventoryInfo.inventoryHeaderObj.status) &&
          (inventoryInfo.inventoryHeaderObj.status === CHARGED_OFF ||
            inventoryInfo.inventoryHeaderObj.status === PENDING_CHARGE_OFF) ? (
            <div style={{ width: '100%', float: 'left' }}>
              <h3
                style={{
                  borderTop: '1px solid black',
                  borderBottom: '1px solid black',
                  padding: '3px',
                  marginBottom: '0px',
                }}
              >
                Recovery
              </h3>
              <div
                style={{ width: '100%', float: 'left', marginBottom: '-12px' }}
              >
                <div
                  style={{
                    width: '31.3%',
                    margin: '1%',
                    float: 'left',
                    marginBottom: '10px',
                  }}
                >
                  <span>
                    <div style={{ fontSize: '14px' }}>Recovery Date</div>
                    <div style={{ fontSize: '14px' }}>
                      {validateObject(inventoryInfo.recoveryObj.recoveryDate) && inventoryInfo.recoveryObj.recoveryDate !== ""
                        ? moment(inventoryInfo.recoveryObj.recoveryDate).format(
                            'MM/DD/YYYY'
                          )
                        : '-'}
                    </div>
                  </span>
                </div>
                <div
                  style={{
                    width: '31.3%',
                    margin: '1%',
                    float: 'left',
                    marginBottom: '10px',
                  }}
                >
                  <span>
                    <div style={{ fontSize: '14px' }}>RV at Recovery</div>
                    <div style={{ fontSize: '14px' }}>
                      {validateObject(inventoryInfo.recoveryObj.rvAtRecovery) &&
                      inventoryInfo.recoveryObj.rvAtRecovery !== 0
                        ? inventoryInfo.recoveryObj.rvAtRecovery
                        : 0}
                    </div>
                  </span>
                </div>
              </div>
            </div>
          ) : null}


            {validateObject(
              inventoryInfo.inventoryInformationObj?.auditMessage
              ) && inventoryInfo.inventoryInformationObj?.auditMessage !== '' ? (
              <div style={{ width: '100%', float: 'left' }}>
                <h3
                  style={{
                    borderTop: '1px solid black',
                    borderBottom: '1px solid black',
                    padding: '3px',
                    marginBottom: '0px',
                  }}
                >
                  Audit Message
                </h3>
                <div
                  style={{
                    // width: '31.3%',
                    margin: '1%',
                    float: 'left',
                    marginBottom: '10px',
                  }}
                >
                  <span style={{ fontSize: '14px' }}>
                    {validateObject(
                      inventoryInfo.inventoryInformationObj?.auditMessage
                    )
                      ? inventoryInfo.inventoryInformationObj?.auditMessage
                      : '-'}
                  </span>
                </div>
              </div>
            ) : null}

        </div>
      </div>
    </div>
  );
};
export default PrintInfo;
