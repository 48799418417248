/* eslint-disable prettier/prettier */
/* eslint-disable sonarjs/prefer-immediate-return */
/* eslint-disable @typescript-eslint/no-unused-vars */

import { makeStyles, RACCOLOR } from '@rentacenter/racstrap';
// import searchbtn from '../../../assets/images/search-btn.svg';
export const searchCriteriaStyles = () => {
  const useClasses = makeStyles((theme) => ({
    searchBtn: {
      backgroundRepeat: 'no-repeat !important',
      backgroundPosition: '15px !important',
      fontFamily: 'OpenSans-Bold',
    },
    disableme: {
      pointerEvents: 'none',
      opacity: 0.9,
    },
    packageStructure: {
      backgroundColor: `${RACCOLOR.LIGHT_BLUE_BACKGROUND}`,
      color: '#2179fe',
      padding: '8px 10px',
      borderRadius: '5px',
      marginTop: theme.typography.pxToRem(25),
      display: 'inline-block',
    },
  }));

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const classes = useClasses();

  return classes;
};
