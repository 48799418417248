/* eslint-disable */
/* eslint-disable sonarjs/no-identical-functions */
/* eslint-disable sonarjs/no-collapsible-if */
/* eslint-disable no-console */
/* eslint-disable react/no-children-prop */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable sonarjs/prefer-single-boolean-return */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useReactToPrint } from 'react-to-print';


import { Card, CardContent, CircularProgress, Grid, RACButton, RACModalCard, RACSelect, RACTextbox, Typography } from "@rentacenter/racstrap";
import CurrencyInput from 'react-currency-input-field';

import { formStyles } from "../../componentstyles/formstyles";
import { globalStyles } from "../../componentstyles/globalstyles";

import { getInventoryPriceTags, getExceptionReasonList, updatePriceTagStatus, updatePriceTagException, PrintFaxSheet, getCurrentUser, getCoworkerDetails, getprofileaccess, featureFlagInfo } from "../../../api/user";
import { getPriceTagsReq, InventoryPriceTags, updateCurrentPriceTagReq, PriceException, paramType, updatePriceExceptionReq } from "./PriceTagProps";

import { formatter, sleep, validateData } from "../../../constants/CommonMethods";
import { InventoryContext } from "../../../context/InventoryInfoContext";
import { ContainerContext } from '../../../app/App';
import { CustomPropInterface } from '../../../index';
import PriceTagCard from "./DynamicPriceCard";
import PrintPriceTagDocument from "./PriceTagTabDoc";
import { addedItemStyles } from "../../componentstyles/addedItemStyles";
import SecondFactor from "../InventoryInfo/secondAuthen";
import SecondFactorPassWord from "./SecondAuthPassword";
import PrintFunction from "../../Shared/singleItemPriceTag";
import SIpricetagNYCA from '../../Shared/SingleItemPriceTagCAandNY';
import { ReactComponent as AlertIcon } from '../../../assets/images/no-records-found.svg';
import { ReactComponent as Erroricon } from '../../../assets/images/error-icon.svg';

import TwoFactorComponent from "../../Shared/secondAuthen";
import { ADD_ON_PRICING, makePriceOverRiderolesAllocationFn, makeTermOverRiderolesAllocationFn } from "../../../constants/constants";
import { transformConfigDetails } from "../../Shared/removeColumnHeader";
import { InventoryMajorContext } from "../../../context/inventoryMajorContext";

export interface Loaders {
  pageLoader: boolean;
  printLoader: boolean;
  btnLoader: boolean;
}

export interface buttonType {
  printBtn: boolean;
  priceExpBtn: boolean;
  saveBtn: boolean;
}

export interface lastModified {
  excepLast: boolean;
  autoLast: boolean;
}

export interface validPopUpType {
  rateFieldEmpty: boolean;
  minRateAllowed: boolean;
  secondLevelAuth: boolean;
  overrideInvalid: boolean;
  depreciationAllowed: boolean;
  allowedRates: boolean;
  maxRateAllowed: boolean;
  allowedTermRange: boolean;
  cancelPriceException: boolean;
  secondLevelPin: boolean
}

let depr: number;
let minrateAllowed: number;
let newTotal: number;

export default function PriceTag(props: any) {

  const classes = globalStyles();
  const formclasses = formStyles();
  const addedItemClassName = addedItemStyles();

  const history = useHistory();

  const { inventoryNum } = useParams<paramType>();

  const { headerValue, profileDetails, setprofileDetails, priceExceptionLimit, setPriceExceptionLimit, termExceptionLimit, settermExceptionLimit } = useContext(InventoryContext);
  const [headerContext, setheaderContext] = headerValue;
  const containerData = useContext(ContainerContext) as CustomPropInterface;
  const [currentRole, setCurrentRole] = useState('');

  const [DisableBtn, setDisableBtn] = useState<buttonType>({ printBtn: true, priceExpBtn: true, saveBtn: true });

  const priceExcepValues: PriceException =
  {
    rate: 0,
    term: '',
    excepReason: '0',
    excepExplanation: ''
  }
  const [priceExcep, setpriceExcep] = useState<PriceException>(priceExcepValues);

  const [validPopUpType, setvalidPopUpType] = useState<validPopUpType>(
    {
      rateFieldEmpty: false,
      maxRateAllowed: false,
      minRateAllowed: false,
      secondLevelAuth: false,
      secondLevelPin: false,
      overrideInvalid: false,
      depreciationAllowed: false,
      allowedRates: false,
      allowedTermRange: false,
      cancelPriceException: false,
    });
  const [validationPopUp, setvalidationPopUp] = useState<boolean>(false);

  const [priceExcepPopUp, setpriceExcepPopUp] = useState<boolean>(false);

  const [priceTags, setPriceTags] = useState<InventoryPriceTags>({
    newPriceTag: [],
    autoPriceTag: [],
    currentPriceTag: [],
    editPriceTag: []
  });

  const [errMsg, seterrMsg] = useState<any>({ wentWrong: false, norecord: false, noactivetags: false });

  const [priceTagInfo, setpriceTagInfo] = useState<any>();
  const [popUp, setpopUp] = useState(false);
  const [popUpType, setpopUpType] = useState<string>("");
  const [PriceExcepErrMsg, setPriceExcepErrMsg] = useState<string>("");
  const [loader, setloader] = useState<Loaders>({ pageLoader: false, printLoader: false, btnLoader: false });
  const [reasonList, setreasonList] = useState<any[]>([]);

  const [allowedRate, setallowedRate] = useState<any>();
  const [singleItemPrice, setsingleItemPrice] = useState<any>();
  const [singleItemVisble, setsingleItemVisble] = useState(false);
  const [singleItemNYandCA, setsingleItemNYandCA] = useState(false);
  const storeNum: any = window.sessionStorage.getItem('storeNumber') ? window.sessionStorage.getItem('storeNumber') : '';
  const [storeCity, setstoreCity] = useState("");
  const [moduleName, setmoduleName] = useState<any>('Edit Price tag');
  // const [profileDetails, setprofileDetails] = useState<any>();
  const [employeeId, setemployeeId] = useState<any>();
  const [invRefCodes, setInvRefCodes] = useState({
    depRefCode: '',
    subDepRefCode: ''
  })
  const NYandCAtemplateArray = ["NYCSingleItemPriceTagV2", "NYSingleItemPriceTagV2", "CASingleItemPriceTagV2"];


  const [thresHoldExceptionMessage, setthresHoldExceptionMessage] =
    useState('');
  const [SLAMinRole, setSLAMinRole] = useState('');
  const [openRateAlertModal, setopenRateAlertModal] = useState(false);
  const [slaErrorMessage, setslaErrorMessage] = useState('')
  const needSLAObj = {
    needSLAController: false,
  };
  const [needSLA, setneedSLA] = React.useState(needSLAObj);
  const SLAObj = {
    TwoFactorCancelClick: false,
    TwoFactorCompleted: false,
  };
  const [SLAController, setSLAController] = useState(SLAObj);

  const [SecondFactorEnabled, setSecondFactorEnabled] = useState(false);
  const [enableZeroValidate, setenableZeroValidate] = React.useState(false);
  const [initialInventoryRate, setinitialInventoryRate] = useState<any>('')
  const [initialInventoryTerm, setinitialInventoryTerm] = useState<any>('')
  const [invalidTermPopup, setInvalidTermPopup] = useState(false)
  let configDataObj = priceExceptionLimit
  const [configData, setConfigData] = useState<any>(configDataObj);
  let configTermDataObj = termExceptionLimit

  const [termValidationConfigData, settermValidationConfigData] = useState<any>(termExceptionLimit)
  const { featureFlagDetails } = useContext(InventoryMajorContext);


  // test api
  useEffect(() => {
    const featureFlagCall = async () => {
      const featureFlagReq = {
        storeNumbers: [window.sessionStorage.getItem('storeNumber')],
        paramKeyNames: [
          "FSC-CAR",
          "CAR",
          "RPMT",
          "FSC-AM",
          "AM",
          "FSC-LAM",
          "LAM",
          "FSC-SMT",
          "SMT",
          "FSC-SM",
          "SM",
          "FSC-SSM",
          "SSM",
          "CAM",
          "FSC-DMT",
          "DMT",
          "FSC-DM",
          "DM",
          "FSC-RDT",
          "RDT",
          "FSC-RD",
          "RD",
          ADD_ON_PRICING
        ]
      };

      const FeatureFlagResponse = await featureFlagInfo(featureFlagReq);

      if (FeatureFlagResponse.status == 200 && FeatureFlagResponse.data) {

        const FeatureFlagData: any = await makePriceOverRiderolesAllocationFn(FeatureFlagResponse?.data?.storeProfileResponse?.configDetails[0]?.configDetails)
        setPriceExceptionLimit(FeatureFlagData);
        configDataObj = FeatureFlagData
        setConfigData(FeatureFlagData)

        const TermOverrideData: any = await makeTermOverRiderolesAllocationFn(FeatureFlagResponse?.data?.storeProfileResponse?.configDetails[0]?.configDetails)
        settermExceptionLimit(TermOverrideData);
        configTermDataObj = TermOverrideData
        settermValidationConfigData(TermOverrideData)


      }

    };
    featureFlagCall();
  }, [])
  //  need to above




  useEffect(() => {

    if (validateData(props) && validateData(props.invenpricetaginfo) && validateData(props.invenpricetaginfo.data && headerContext.status !== '')) {
      if (validateData(props.invenpricetaginfo.data.response)) {
        if (validateData(props.invenpricetaginfo.data.response[0])) {
          setloader({ ...loader, pageLoader: true });
          if (props?.invenpricetaginfo?.data?.response[0].headerDetails?.departmentRefCode && props?.invenpricetaginfo?.data?.response[0].headerDetails?.subDepartmentRefCode) {
            setInvRefCodes({
              ...invRefCodes,
              depRefCode: props.invenpricetaginfo.data.response[0].headerDetails.departmentRefCode,
              subDepRefCode: props.invenpricetaginfo.data.response[0].headerDetails.subDepartmentRefCode
            })
          }
          //setloanerpayload({...loanerpayload,depRefCode:props.data?.response[0]?.headerDetails?.departmentRefCode,inventoryId:props.invenpricetaginfo?.data?.response[0]?.headerDetails?.inventoryId })
          props.invenpricetaginfo.data.response.map((value: any) => {
            value.headerDetails.minTerm = 10;
            //value.headerDetails.maxTerm = priceTagTermValidate(value.priceTags);
            setpriceTagInfo(value);

          });

          // setpriceTagInfo(props.invenpricetaginfo.data.response[0]);
          setloader({ ...loader, pageLoader: false });
          console.log('priceTags', priceTags);
          if (priceTags.autoPriceTag.length == 0 && priceTags.currentPriceTag.length == 0 && priceTags.editPriceTag.length == 0 && priceTags.newPriceTag.length == 0) {
            buildCards(props.invenpricetaginfo.data.response[0]);
          }
        }
        else {
          seterrMsg({ ...errMsg, norecord: true });
        }
      }
      else {
        if (props.invenpricetaginfo.data.errors[0].error == "Tags are invalid to this inventory") {
          seterrMsg({ ...errMsg, noactivetags: true });
        }
        else {
          seterrMsg({ ...errMsg, wentwrong: true });
        }
      }

    }

  }, [props, headerContext]);

  useEffect(() => {
    if (headerContext !== undefined && headerContext.status == 'Rent Ready') {
      if (containerData !== undefined) {
        setCurrentRole(containerData?.GetRole())
        setemployeeId(containerData?.GetEmployeeId())
      }
      else {
        CurrentUserDetails();
      }

      const getprofileaccessDetails = async () => {
        const payload: any = {
          module: moduleName,
          storeNumber: storeNum,
          currentRole: containerData?.GetRole()
        }
        const result = await getprofileaccess(payload);
        validateData(result) ?
          setprofileDetails(result) :
          setprofileDetails(undefined);
      }
      if (profileDetails) {
        getprofileaccessDetails();
      }
    }
    // else{
    //   CurrentUserDetails();
    // }

  }, [headerContext]);
  useEffect(() => {
    debugger

    if (SLAController.TwoFactorCompleted) {

    }

  }, [SLAController.TwoFactorCompleted]);


  const CurrentUserDetails = async () => {
    const currentUserRes = await getCurrentUser();
    if (validateData(currentUserRes) === true) {
      const EmployeeId = currentUserRes.data.employeeId;

      const payload = {
        coworkerId: EmployeeId,
        storesRequired: true
      }

      const coWorkerRoleObj: any = await getCoworkerDetails(payload);
      setCurrentRole(coWorkerRoleObj.data.coworkerProfile.role);
      const getprofileaccessDetails = async () => {

        const payload: any = {
          module: moduleName,
          storeNumber: storeNum,
          currentRole: coWorkerRoleObj.data.coworkerProfile.role
        }
        const result = await getprofileaccess(payload);
        validateData(result) ?
          setprofileDetails(result) :
          setprofileDetails(undefined);
      }
      getprofileaccessDetails();
    }
    else {
      // seterrMsg({...errMsg,wentwrong:true});

      throw new Error('cannot fetch employeeId');
    }


  }
  const priceTagTermValidate = (priceTagres: any) => {
    if (priceTagres[2].term) {
      return priceTagres[2].term
    }
    else if (priceTagres[0].term) {
      return priceTagres[0].term
    }
    else if (priceTagres[1].term) {
      return priceTagres[1].term
    }
    else {
      return priceTagres[3].term
    }
  }
  const getPriceTagAPICall = async () => {
    try {
      const request: getPriceTagsReq = {
        inventoryNumbers: [inventoryNum],
        isCurrentPriceTag: false
      }

      setloader({ ...loader, pageLoader: true, printLoader: false });

      const apiResponse = await getInventoryPriceTags(request);

      console.log("pricetagresponse1", apiResponse)

      setloader({ ...loader, pageLoader: false, printLoader: false });

      if (apiResponse !== undefined && apiResponse !== null && apiResponse.data !== undefined && apiResponse.data !== null && apiResponse.data.response !== undefined && apiResponse.data.response !== null) {

        if (apiResponse.data.response[0] !== undefined && apiResponse.data.response[0] !== null) {
          // setpriceTagInfo(apiResponse.data.response[0]);

          apiResponse.data.response.map((value: any) => {
            // if(value.headerDetails.minTerm ==undefined || value.headerDetails.minTerm ==null){
            value.headerDetails.minTerm = 10;
            // }
            // if(value.headerDetails.maxTerm == null || value.headerDetails.maxTerm ==undefined){
            value.headerDetails.maxTerm = priceTagTermValidate(value.priceTags);
            // }

            setpriceTagInfo(value);
          })

          props.SetPriceTagData(apiResponse)
          buildCards(apiResponse.data.response[0]);

        }
        else {
          seterrMsg({ ...errMsg, norecord: true });
        }

      }
      else {
        if (apiResponse.data.errors[0].error == "Tags are invalid to this inventory") {
          seterrMsg({ ...errMsg, noactivetags: true });
        }
        else {
          seterrMsg({ ...errMsg, wentwrong: true });
        }
        // seterrMsg({...errMsg,wentwrong:true});
      }

    }
    catch (error: any) {
      throw new Error('something went wrong' + error);
    }

  }

  const buildCards = (data: any) => {

    if (validateData(data) === true && headerContext.status !== '') {
      if (data.priceTags !== undefined && data.priceTags !== null) {

        const newPrice: any = buildPriceTags(data.priceTags, 'new');
        const autoPrice: any = buildPriceTags(data.priceTags, 'auto');
        const currentPrice: any = buildPriceTags(data.priceTags, 'current');
        const editPrice: any = buildPriceTags(data.priceTags, 'excep');

        setPriceTags({ ...priceTags, newPriceTag: newPrice, autoPriceTag: autoPrice, currentPriceTag: currentPrice, editPriceTag: editPrice });
      }
      console.log('data.headerDetails.priceTagQueued', data.headerDetails.priceTagQueued);
      console.log(' headerContext.status', headerContext.status);


      if (data.headerDetails.priceTagQueued === true && headerContext.status == 'Rent Ready' && (headerContext.storeNumber == storeNum)) {

        setpopUpType("pageLoad");
        setpopUp(true);
        setDisableBtn({ ...DisableBtn, printBtn: true, priceExpBtn: true, saveBtn: true });

      }
      else if (data.headerDetails.priceTagQueued === true && headerContext.status !== 'Rent Ready') {

        setDisableBtn({ ...DisableBtn, printBtn: true, priceExpBtn: true, saveBtn: true });

      }
      else if (data.headerDetails.priceTagQueued === false && headerContext.status !== 'Rent Ready' && headerContext.status !== "") {

        setDisableBtn({ ...DisableBtn, printBtn: true, priceExpBtn: true, saveBtn: true });

      }
      else {
        setDisableBtn({ ...DisableBtn, printBtn: false, priceExpBtn: false, saveBtn: true });
      }
    }
    // CurrentUserDetails();

  }


  /************Functions to map response received from API Call into corresponding PriceTags */
  const buildPriceTags = (data: any, key: string) => {
    let newP: any;
    const priceData = validateData(data) ? data : [];

    switch (key) {
      case 'new':
        priceData.filter((value: any) => {
          if (value.conditionRefCode === 'NEW') {
            newP = [{
              depreciationPercentage: value.depreciationPercentage,
              grossProfitPerPayment: value.grossProfitPerPayment,
              turns: value.turns,
              rate: value.rate,
              term: value.term,
              cashPrice: value.cashPrice,
              totalCost: value.totalCost,
              conditionRefCode: value.conditionRefCode,
              addOnRate: value.addOnRate,
              lastModified: value.lastModifiedDate,
              inventoryPriceId: value.inventoryPriceId,
              weeklyAddOnRate: value.weeklyAddOnRate
            }]
          }
        })
        break;
      case 'auto':
        priceData.filter((value: any) => {
          if (value.conditionRefCode === "ATO") {
            newP = [{
              depreciationPercentage: value.depreciationPercentage,
              grossProfitPerPayment: value.grossProfitPerPayment,
              turns: value.turns,
              rate: value.rate,
              term: value.term,
              cashPrice: value.cashPrice,
              totalCost: value.totalCost,
              conditionRefCode: value.conditionRefCode,
              addOnRate: value.addOnRate,
              lastModified: value.lastModifiedDate,
              // lastModified:"2019-03-20 15:20:54.607571+05:30",
              inventoryPriceId: value.inventoryPriceId,
              weeklyAddOnRate: value.weeklyAddOnRate
            }]
          }
        })
        break;
      case 'current':
        priceData.filter((value: any) => {
          if (value.conditionRefCode === "CURRENT") {
            newP = [{
              depreciationPercentage: value.depreciationPercentage,
              grossProfitPerPayment: value.grossProfitPerPayment,
              turns: value.turns,
              rate: value.rate,
              // rate : 10.99,
              term: value.term,
              cashPrice: value.cashPrice,
              totalCost: value.totalCost,
              conditionRefCode: value.conditionRefCode,
              addOnRate: value.addOnRate,
              lastModified: value.lastModifiedDate,
              inventoryPriceId: value.inventoryPriceId,
              weeklyAddOnRate: value.weeklyAddOnRate
            }]
          }
        })
        break;
      case 'excep':
        priceData.filter((value: any) => {
          if (value.conditionRefCode === "EXCP") {
            newP = [{
              depreciationPercentage: value.depreciationPercentage,
              grossProfitPerPayment: value.grossProfitPerPayment,
              turns: value.turns,
              rate: value.rate,
              term: value.term,
              cashPrice: value.cashPrice,
              totalCost: value.totalCost,
              conditionRefCode: value.conditionRefCode,
              addOnRate: value.addOnRate,
              inventoryPriceId: value.inventoryPriceId,
              lastModified: value.lastModifiedDate,
              weeklyAddOnRate: value.weeklyAddOnRate
              // lastModified:"2019-03-30 10:20:54.607571+05:30",

            }]
          }
        })
        break;
    }

    return newP;
  }

  /***********Function invoked inthe Onclick of Print button to Print  all the pricetags   */
  const onClickPrint = async () => {
    setloader({ ...loader, btnLoader: true });
    const response: any = await PrintFaxSheet(storeNum);
    setstoreCity(validateData(response) ? validateData(response.data) ? validateData(response.data.response) ? validateData(response.data.response[0]) ? validateData(response.data.response[0].city) ? response.data.response[0].city : '' : '' : '' : '' : '')
    setloader({ ...loader, btnLoader: false });

    printPriceTagsTab();

  }

  const printPriceTagsTab = () => {
    const printReport = document?.getElementById('printPriceTagDoc')?.innerHTML;
    if (printReport !== null && printReport !== undefined) {
      const a: any = window.open(
        '',
        '',
        'width=1000,height=650,scrollbars=1,menuBar=1'
      );
      a.document.write('<html>');
      a.document.write(printReport);
      a.document.write('</html>');
      a.document.close();
      a.print();
    }
  }


  // This function will priortize which pricetag value has to sent
  const priceTagFilterResult = (priceTagres: any) => {
    if (priceTagres.editPriceTag[0].rate) {
      return priceTagres.editPriceTag[0]
    }
    else if (priceTagres.currentPriceTag[0].rate) {
      return priceTagres.currentPriceTag[0]
    }
    else if (priceTagres.autoPriceTag[0].rate) {
      return priceTagres.autoPriceTag[0]
    }
    else {
      return priceTagres.newPriceTag[0]
    }
  }

  // Calculate the inventoryPricetag priority level
  const inventoryPriceTagFilter = (priceTagres: any) => {
    if (priceTagres.autoPriceTag[0].rate) {
      return priceTagres.autoPriceTag[0]
    }
    else {
      return priceTagres.newPriceTag[0]
    }
  }
  /***********Function invoked inthe Onclick of PrintPriceTag button    */
  const onPrintPriceTag = () => {

    setpopUp(false);
    setDisableBtn({ ...DisableBtn, priceExpBtn: false });


    // let req: any[];


    const updatePriceTag = async () => {

      // if (priceTags.editPriceTag[0].lastModified > priceTags.autoPriceTag[0].lastModified) {
      //   req = [priceTags.editPriceTag[0].inventoryPriceId]
      //   console.log("vbnm", priceTags.editPriceTag[0].inventoryPriceId)

      // }
      // else if (priceTags.editPriceTag[0].lastModified < priceTags.autoPriceTag[0].lastModified) {
      //   console.log("vbnmnnn", priceTags.editPriceTag[0].inventoryPriceId)

      //   req = [priceTags.autoPriceTag[0].inventoryPriceId]
      // }
      // else if ((priceTags.currentPriceTag[0].inventoryPriceId == priceTags.autoPriceTag[0].inventoryPriceId) && (priceTags.editPriceTag[0].inventoryPriceId == priceTags.newPriceTag[0].inventoryPriceId)) {
      //   req = [priceTags.newPriceTag[0].inventoryPriceId]
      // }
      // else {
      //   req = [priceTags.newPriceTag[0].inventoryPriceId]
      // }

      console.log('req received in pricetagStatus', [priceTags.editPriceTag[0].inventoryPriceId]);
      setpopUpType('printing');
      setpopUp(true);
      console.log('priceTagFilterResult?.inventoryPriceId', priceTagFilterResult(priceTags)?.inventoryPriceId);
      const res: any = await updatePriceTagStatus({ inventoryPriceIds: [priceTagFilterResult(priceTags)?.inventoryPriceId] });
      console.log('res received in pricetagStatus', res);



      setloader({ ...loader, printLoader: false })

      const resp: any = validateData(res) ? res : 0;
      console.log('resp received in pricetagStatus', resp);


      if (resp.status === 200) {

        const printPriceTagData = resp?.data?.response;
        setsingleItemPrice(printPriceTagData);
        setpopUp(false);
        getPriceTagAPICall();
        console.log('NYandCAtemplateArray before', NYandCAtemplateArray)
        console.log('printPriceTagData before', printPriceTagData[0]?.templateName)
        if (NYandCAtemplateArray.includes(printPriceTagData[0]?.templateName)) {
          console.log('NYandCAtemplateArray after', NYandCAtemplateArray)
          console.log('printPriceTagData after', printPriceTagData[0]?.templateName)
          setsingleItemNYandCA(true);
          printSingleItemPriceTagNYandCA();
          setsingleItemNYandCA(false);
        }
        else {
          console.log('NYandCAtemplateArray else', NYandCAtemplateArray)
          console.log('printPriceTagData else', printPriceTagData[0]?.templateName)
          setsingleItemVisble(true);
          printSingleItemPriceTag();
          setsingleItemVisble(false);
        }


      }
      else {
        setpopUpType('err_priceTagStatus');
        setpopUp(true);

        // seterrMsg({...errMsg,wentwrong:true});
      }
    }
    updatePriceTag();
    console.log('setsingleItemPrice received in pricetagStatus', singleItemPrice);

  }


  /***********Function to Print the Single Item Pricetag PDF   */
  // const printSingleItemPriceTag=()=>{

  // const singlePriceTag  = document?.getElementById('singleItemPriceTag')?.innerHTML;
  // if(singlePriceTag !==undefined && singlePriceTag !== null){
  //   const a: any = window.open(
  //     "",
  //     "",
  //     'width=384,height=576,scrollbars=1,menuBar=1'
  //   );
  //   a.document.write("<html>");
  //   a.document.write(singlePriceTag);
  //   a.document.write("</html>");
  //   a.document.close();
  //   a.print();
  // }

  // }

  const singlePriceTag = useRef<HTMLDivElement>(null);

  /***********Function to Print the Single Item Pricetag PDF   */
  const printSingleItemPriceTag = useReactToPrint({
    content: () => singlePriceTag.current,
    pageStyle: `
    @media print {
      html, body {
        display: block !important;
        width: 129mm !important;
        height: 105mm !important;
        }   
    }
    
    `
  })
  const printSingleItemPriceTagNYandCA = useReactToPrint({
    content: () => singlePriceTag.current,
    pageStyle: `
      @media print {
        html, body {
          display: block !important;
          width: 127mm !important;
          height: 177mm !important;
          }   
      }
    `
  })


  /***********Function invoked inthe Onclick of PriceException  button    */
  const onClickPriceException = async () => {

    setpriceExcepPopUp(true);
    setpopUp(false);

    if (priceExcep.rate == 0 || priceExcep.term == '' || priceExcep.term.length == 0) {
      setpriceExcep({ ...priceExcep, excepReason: '', rate: priceTagFilterResult(priceTags)?.rate, term: priceTagFilterResult(priceTags)?.term?.toString(), excepExplanation: '' });
      setloader({ ...loader, printLoader: true });
      const resp: any = await getExceptionReasonList();

      setloader({ ...loader, printLoader: false });


      const reasonsArr: any[] = (validateData(resp) ? validateData(resp.data) ? validateData(resp.data.referenceDetails) ? resp.data.referenceDetails : [] : [] : []);

      const setreasonArr: any[] = [{ referenceCode: "", description: 'Select' }, ...reasonsArr];

      //setreasonList(validateData(setreasonArr) ? setreasonArr : []);
      setreasonList(setreasonArr)
    }

  }

  /***********Function to bind  the dropdown values in Exception Reason SelectBox  */
  const buildReasonList = () => {
    if (reasonList) {
      console.log("reasonList", reasonList);
      return reasonList.map((entry: any) => {
        return { label: entry.description, value: entry.referenceCode };
      });
    } else {
      return [{ label: "Select", value: "" }];
    }
  };


  const enableSaveButton = () => {

    if (priceExcep !== undefined && priceExcep !== null
      && priceExcep.rate !== undefined
      && priceExcep.rate !== null
      && priceExcep.term !== undefined
      && priceExcep.term !== null
      && priceExcep.excepReason !== '0'
      && priceExcep.excepReason !== ''
      && priceExcep.excepExplanation !== undefined
      && priceExcep.excepExplanation.length !== 0
      && priceExcep.excepExplanation !== '') {
      return false;
    }
    else {

      return true;
    }
  }

  /***********Function invoked inthe onBlur  of term field , to validate the term entered is within the range or not    */
  const validateTerm = (e: any) => {
    console.log('pppppp', e.target.value);
    const minTermAllowed = priceTagInfo.headerDetails.minTerm;
    const maxTermAllowed = priceTagInfo.headerDetails?.maximumTerm;

    if (priceExcep.term > maxTermAllowed || (priceExcep.term) < minTermAllowed) {
      setvalidationPopUp(true);
      setvalidPopUpType({ ...validPopUpType, allowedTermRange: true });
    }
  }



  const validateRateField = (e: any) => {
    const maxRateAllow = priceTagInfo.headerDetails?.maximumRate;
    if (e.target.value == '' || e.target.value.length == 0) {
      setvalidPopUpType({ ...validPopUpType, rateFieldEmpty: true });
      setvalidationPopUp(true);
    }
    else if (parseFloat(e.target.value) > maxRateAllow) {
      setvalidPopUpType({ ...validPopUpType, maxRateAllowed: true });
      setvalidationPopUp(true);
    }

  }

  /****function invoked in the onClick of Save button , to save the PriceException  */
  const saveRateAndTerm = async () => {

    const payload: updatePriceExceptionReq = {
      rate: typeof priceExcep.rate == 'string' ? priceExcep.rate : String((priceExcep.rate)?.toFixed(2)),
      term: String(priceExcep.term),
      inventoryPriceId: String(priceTagFilterResult(priceTags)?.inventoryPriceId),
      exceptionReasonType: String(priceExcep.excepReason),
      storeNumber: window.sessionStorage.getItem('storeNumber')
    }


    setpopUp(false);
    setpriceExcepPopUp(false);
    setvalidationPopUp(false);
    setDisableBtn({ ...DisableBtn, priceExpBtn: true });
    console.log('payload in updatePriceTagException', payload)
    setloader({ ...loader, pageLoader: true });
    const resp = await updatePriceTagException(payload);
    setloader({ ...loader, pageLoader: false });
    if (validateData(resp) === false) {
      setpopUpType('err_priceException');
      setpopUp(true);
      // seterrMsg({...errMsg,wentwrong:true})
    }

    console.log('resp received in priceTagException', resp)

    if (validateData(resp) === true && validateData(resp.data) === true) {
      if (resp.status == 200) {
        setDisableBtn({ ...DisableBtn, priceExpBtn: false });
        setpriceExcep(priceExcepValues);
        getPriceTagAPICall();
        props.PageReload('initialPageload')
      }
      else if (resp.status == 400) {
        setpopUpType('PriceExcepErrMsg');
        setPriceExcepErrMsg(resp.data.errors[0].error)
        setpopUp(true);
        setDisableBtn({ ...DisableBtn, priceExpBtn: false });

      }
      else {
        setpopUpType('err_priceException');
        setpopUp(true);
        setDisableBtn({ ...DisableBtn, priceExpBtn: false });

      }
    }
    else {
      setpopUpType('err_priceException');
      setpopUp(true);
      setDisableBtn({ ...DisableBtn, priceExpBtn: false });

      // throw new Error ('something went Wrong, updatePriceTagExceptionFailed');
    }
  }
  const checkDepreciation = (currentDepr: number, sla: boolean) => {

    if (sla === false) {

      if (currentDepr > 33) {
        setvalidationPopUp(true);
        setvalidPopUpType({ ...validPopUpType, depreciationAllowed: true });
      }
      else {
        saveRateAndTerm();
      }
    }
    else {

      if (currentDepr > 33) {
        setvalidPopUpType({ ...validPopUpType, depreciationAllowed: true, secondLevelAuth: false });
      }
      else {
        setvalidationPopUp(false);
        setvalidPopUpType({ ...validPopUpType, secondLevelAuth: false });
        setpriceExcepPopUp(false);
        saveRateAndTerm();
      }
    }

  }


  // Blur function for Inventory Rate and Term changing
  const blurFunction = (functionController: any, e: any): any => {
    if (
      (priceTags.editPriceTag[0].rate !== priceTags.currentPriceTag[0].rate ||
        priceTags.editPriceTag[0].term !== priceTags.currentPriceTag[0].term ||
        priceTags.editPriceTag[0].turns !== priceTags.currentPriceTag[0].turns ||
        priceTags.editPriceTag[0].totalCost !== priceTags.currentPriceTag[0].totalCost ||
        priceTags.editPriceTag[0].cashPrice !== priceTags.currentPriceTag[0].cashPrice ||
        priceTags.editPriceTag[0].depreciationPercentage !== priceTags.currentPriceTag[0].depreciationPercentage ||
        priceTags.editPriceTag[0].grossProfitPerPayment !== priceTags.currentPriceTag[0].grossProfitPerPayment)
      &&
      (new Date(priceTags.editPriceTag[0].lastModified) > new Date(priceTags.autoPriceTag[0].lastModified))
    ) {
      setpopUpType('pageLoad');
      setpopUp(true);
    }
    else {
      debugger
      console.log('in second condiion')
      newTotal = (priceExcep?.rate) * parseInt(priceExcep?.term);
      depr = parseFloat(((parseFloat(priceTagInfo.headerDetails.remainingValue) / newTotal) * 100).toFixed(2));
      const initialInvRate = inventoryPriceTagFilter(priceTags)?.rate
      setinitialInventoryRate(initialInvRate)
      const initialInvTerm = inventoryPriceTagFilter(priceTags)?.term
      setinitialInventoryTerm(initialInvTerm)
      if (
        functionController == 'RateChange' && inventoryPriceTagFilter(priceTags)?.rate !== priceExcep.rate) {
        debugger

        const values = Object.values(configDataObj);
        const numericValues = values.filter((value): value is number => typeof value === 'number');
        const maxPercentageEdit = Math.max(...numericValues);
        const maxAllowablbeLimit = (Number(initialInvRate) * Number(maxPercentageEdit)) / 100
        let percentCalculationInBlur: any
        percentCalculationInBlur = Math.abs(
          ((initialInvRate - priceExcep.rate) * 100) / initialInvRate
        );
        percentCalculationInBlur = (Math.floor(Number(percentCalculationInBlur) * 100) / 100).toFixed(2)

        debugger
        if (Number(priceExcep.rate) != Number(initialInvRate)) {
          console.log('success reach of if in rate chage');

          let minRole = '';
          let enteredIf = false;
          let output;

          const Roles = [
            'FSC-CAR',
            'CAR',
            'RPMT',
            'FSC-AM',
            'AM',
            'FSC-LAM',
            'LAM',
            'FSC-SMT',
            'SMT',
            'FSC-SM',
            'SM',
            'FSC-SSM',
            'SSM',
            'CAM',
            'FSC-DMT',
            'DMT',
            'FSC-DM',
            'DM',
            'FSC-RDT',
            'RDT',
            'FSC-RD',
            'RD'
          ];
          const maxRateAllow = priceTagInfo.headerDetails?.maximumRate;
          if (Number(maxPercentageEdit) < Number(percentCalculationInBlur)) {

            if (currentRole == 'RD' || currentRole == 'FSC-RD' || currentRole == 'RDT' || currentRole == 'FSC-RDT') {
              setopenRateAlertModal(true)
              setslaErrorMessage(`Rate is invalid. Allowable Rate Values are $${Number(maxAllowablbeLimit).toFixed(2)} to $${Number(initialInvRate).toFixed(2)}`)
            }

            else {
              setopenRateAlertModal(true)
              setslaErrorMessage(`Override is invalid. SLA user can override from $${Number(maxAllowablbeLimit).toFixed(2)} to $${Number(initialInvRate).toFixed(2)}`)
            }
          }
          else if (e.target.value == '' || e.target.value.length == 0) {
            setvalidPopUpType({ ...validPopUpType, rateFieldEmpty: true });
            setvalidationPopUp(true);
          }
          else if (parseFloat(e.target.value) > maxRateAllow) {
            setvalidPopUpType({ ...validPopUpType, maxRateAllowed: true });
            setvalidationPopUp(true);
          }
          else {
            for (const item in configDataObj) {
              debugger
              if (
                configData[item] == Number(percentCalculationInBlur).toFixed(2) &&
                Number(percentCalculationInBlur) !== 0
              ) {
                debugger
                minRole = item;
                output = item;
                setSLAMinRole(item);
                if (Roles.indexOf(String(currentRole)) < Roles.indexOf(item)) {
                  setneedSLA({ ...needSLA, needSLAController: true });
                  enteredIf = true;
                }
                break;
              } else if (configData[item] > Number(percentCalculationInBlur)) {
                debugger
                minRole = item;
                output = item
                setSLAMinRole(item);
                if (Roles.indexOf(String(currentRole)) < Roles.indexOf(item)) {
                  setneedSLA({ ...needSLA, needSLAController: true });
                  enteredIf = true;
                }
                break;
              }
            }

            console.log('logged roles', currentRole);

            debugger
            const groupedData: { [key: string]: string[] } = {};

            for (const [key, value] of Object.entries(configData)) {
              const stringValue = String(value); // Convert value to string
              if (!groupedData[stringValue]) {
                groupedData[stringValue] = [key];
              } else {
                groupedData[stringValue].push(key);
              }
            }

            console.log("groupedData", groupedData)
            let currentRoleValue: any;
            debugger
            for (const key of Object.keys(groupedData)) {
              if (groupedData[key].includes(currentRole)) {
                // Convert key to number if needed
                currentRoleValue = parseInt(key);
                // Once we find the  role, we can break the loop
                break;
              }
            }
            debugger
            console.log("currentRoleValue", currentRoleValue)

            let allowedRate;
            let minAmount;
            allowedRate = initialInvRate * (1 * currentRoleValue / 100);
            minAmount = initialInvRate - allowedRate;
            setthresHoldExceptionMessage(
              `Coworker can only change rate upto $${Number(
                minAmount.toString()
              ).toFixed(2)} second level approval is required for this change.`
            );

            // if (enteredIf === false) {
            //   console.log('entered if pricing call', enteredIf);

            //   Number(priceExcep.rate) < 1
            //     ? setenableZeroValidate(true)
            //     : checkDepreciation(depr, false);
            // }
          }
        }
      }

      else if (
        functionController == 'TermChange' &&
        Number(inventoryPriceTagFilter(priceTags)?.term) !== Number(priceExcep.term)
      ) {
        const values = Object.values(configTermDataObj);
        const numericValues = values.filter((value): value is number => typeof value === 'number');
        const maxPercentageEdit = Math.max(...numericValues);
        const maxAllowablbeLimit = (Number(initialInvTerm) * Number(maxPercentageEdit)) / 100
        const percentCalculationInBlur = Math.abs(
          Math.ceil(((Number(initialInvTerm) - Number(priceExcep.term)) * 100) / Number(initialInvTerm))
        );
        console.log("percentCalculationInBlurCheck", percentCalculationInBlur)
        debugger

        let minRole = '';
        let enteredIf = false;
        let output;

        const Roles = [
          'FSC-CAR',
          'CAR',
          'RPMT',
          'FSC-AM',
          'AM',
          'FSC-LAM',
          'LAM',
          'FSC-SMT',
          'SMT',
          'FSC-SM',
          'SM',
          'FSC-SSM',
          'SSM',
          'CAM',
          'FSC-DMT',
          'DMT',
          'FSC-DM',
          'DM',
          'FSC-RDT',
          'RDT',
          'FSC-RD',
          'RD'
        ];
        const maxTermAllowed = priceTagInfo.headerDetails?.maximumTerm;

        if (Number(priceExcep.term) < Number(10)) {
          setInvalidTermPopup(true)
        }
        else if (priceExcep.term > maxTermAllowed) {
          setvalidationPopUp(true);
          setvalidPopUpType({ ...validPopUpType, allowedTermRange: true });
        }
        else if (Number(maxPercentageEdit) < Number(percentCalculationInBlur)) {
          if (currentRole == 'RD' || currentRole == 'FSC-RD' || currentRole == 'RDT' || currentRole == 'FSC-RDT') {
            setopenRateAlertModal(true)
            setslaErrorMessage(`Term is invalid. Allowable Term Values are ${Math.ceil(Number(maxAllowablbeLimit))} to ${Number(initialInvTerm)}`)
            return
          }
          else {
            setopenRateAlertModal(true)
            setslaErrorMessage(`Override is invalid. SLA user can override from ${Math.ceil(Number(maxAllowablbeLimit))} to ${Number(initialInvTerm)}`)
            return
          }
        }
        else {
          for (const item in configTermDataObj) {
            debugger
            if (
              termValidationConfigData[item] == Number(percentCalculationInBlur).toFixed(2) &&
              Number(percentCalculationInBlur) !== 0
            ) {
              debugger
              minRole = item;
              output = item;
              setSLAMinRole(item);
              if (Roles.indexOf(String(currentRole)) < Roles.indexOf(item)) {
                setneedSLA({ ...needSLA, needSLAController: true });
                enteredIf = true;
              }
              break;
            } else if (termValidationConfigData[item] > Number(percentCalculationInBlur)) {
              debugger
              minRole = item;
              output = item
              setSLAMinRole(item);

              if (Roles.indexOf(String(currentRole)) < Roles.indexOf(item)) {
                setneedSLA({ ...needSLA, needSLAController: true });
                enteredIf = true;
              }
              break;
            }
          }

          console.log('logged roles', currentRole);

          debugger
          const groupedData: { [key: string]: string[] } = {};

          for (const [key, value] of Object.entries(termValidationConfigData)) {
            const stringValue = String(value); // Convert value to string
            if (!groupedData[stringValue]) {
              groupedData[stringValue] = [key];
            } else {
              groupedData[stringValue].push(key);
            }
          }

          console.log("groupedData", groupedData)
          let currentRoleValue: any;
          debugger
          for (const key of Object.keys(groupedData)) {
            if (groupedData[key].includes(currentRole)) {
              // Convert key to number if needed
              currentRoleValue = parseInt(key);
              // Once we find the  role, we can break the loop
              break;
            }
          }
          debugger
          console.log("currentRoleValue", currentRoleValue)

          let allowedRate;
          let minAmount: any;
          allowedRate = initialInvTerm * (1 * currentRoleValue / 100);
          minAmount = initialInvTerm - allowedRate;
          setthresHoldExceptionMessage(
            `Coworker can only change term upto ${Number(
              Math.ceil(minAmount.toString())
            )} second level approval is required for this change.`
          )
        }
      }
    };
  }
  // Rate Term Edited in PriceException popup and click Enter button
  const RateTermChange = (stateController: any, e?: any) => {
    if (
      e.key == 'Enter' &&
      stateController == 'RateChange' &&
      Number(inventoryPriceTagFilter(priceTags)?.rate) !== Number(priceExcep.rate)) {
      (document.getElementById('InvRate') as HTMLInputElement).blur();

    } else if (
      e.key == 'Enter' &&
      stateController == 'TermChange' &&
      Number(inventoryPriceTagFilter(priceTags)?.term) !== Number(priceExcep.term)
    ) {
      (document.getElementById('InvTerm') as HTMLInputElement).blur();

    }
  };

  const onCancelPriceException = () => {
    if (priceExcep.rate !== priceTagFilterResult(priceTags)?.rate || priceExcep.term !== priceTagFilterResult(priceTags)?.term?.toString() || priceExcep.excepExplanation !== '' || priceExcep.excepReason !== '0') {
      setvalidationPopUp(true);
      setvalidPopUpType({ ...validPopUpType, cancelPriceException: true });
    }
    else {
      setpopUp(false);
      setDisableBtn({ ...DisableBtn, saveBtn: true });
      setpriceExcepPopUp(false)
      //need to add
      //setpriceExcep({...priceExcep,rate:0,term:0,excepExplanation:'',excepReason:'Select'})
    }

  }





  // const onSavePriceException = () => {
  //   debugger
  //   if (
  //     (priceTags.editPriceTag[0].rate !== priceTags.currentPriceTag[0].rate ||
  //       priceTags.editPriceTag[0].term !== priceTags.currentPriceTag[0].term ||
  //       priceTags.editPriceTag[0].turns !== priceTags.currentPriceTag[0].turns ||
  //       priceTags.editPriceTag[0].totalCost !== priceTags.currentPriceTag[0].totalCost ||
  //       priceTags.editPriceTag[0].cashPrice !== priceTags.currentPriceTag[0].cashPrice ||
  //       priceTags.editPriceTag[0].depreciationPercentage !== priceTags.currentPriceTag[0].depreciationPercentage ||
  //       priceTags.editPriceTag[0].grossProfitPerPayment !== priceTags.currentPriceTag[0].grossProfitPerPayment)
  //     &&
  //     (new Date(priceTags.editPriceTag[0].lastModified) > new Date(priceTags.autoPriceTag[0].lastModified))
  //   ) {
  //     console.log('in first condiion')
  //     setpopUpType('pageLoad');
  //     setpopUp(true);
  //   }
  //   else {
  //     debugger
  //     console.log('in second condiion')
  //     newTotal = (priceExcep?.rate) * parseInt(priceExcep?.term);
  //     depr = parseFloat(((parseFloat(priceTagInfo.headerDetails.remainingValue) / newTotal) * 100).toFixed(2));
  //     const initialInvRate = priceTagFilterResult(priceTags)?.rate
  //     setinitialInventoryRate(initialInvRate)
  //     if (inventoryPriceTagFilter(priceTags)?.rate !== priceExcep.rate) {
  //       debugger
  //       {
  //         const values = Object.values(configDataObj);
  //         const numericValues = values.filter((value): value is number => typeof value === 'number');
  //         const maxPercentageEdit = Math.max(...numericValues);
  //         const maxAllowablbeLimit = (Number(initialInvRate) * Number(maxPercentageEdit)) / 100

  //         const percentCalculationInBlur = Math.abs(
  //           ((initialInvRate - priceExcep.rate) * 100) / initialInvRate
  //         );
  //         debugger
  //         if (Number(priceExcep.rate) <= Number(initialInvRate)) {
  //           console.log('success reach of if in rate chage');

  //           let minRole = '';
  //           let enteredIf = false;
  //           let output;

  //           const Roles = [
  //             'FSC-CAR',
  //             'CAR',
  //             'RPMT',
  //             'FSC-AM',
  //             'AM',
  //             'FSC-LAM',
  //             'LAM',
  //             'FSC-SMT',
  //             'SMT',
  //             'FSC-SM',
  //             'SM',
  //             'FSC-SSM',
  //             'SSM',
  //             'CAM',
  //             'FSC-DMT',
  //             'DMT',
  //             'FSC-DM',
  //             'DM',
  //             'FSC-RDT',
  //             'RDT',
  //             'FSC-RD',
  //             'RD'
  //           ];

  //           if (Number(maxPercentageEdit) < Number(percentCalculationInBlur)) {

  //             if (currentRole == 'RD' || currentRole == 'FSC-RD' || currentRole == 'RDT' || currentRole == 'FSC-RDT') {
  //               setopenRateAlertModal(true)
  //               setslaErrorMessage(`Rate is invalid. Allowable Rate Values are $${Number(maxAllowablbeLimit).toFixed(2)} to $${Number(initialInvRate).toFixed(2)}`)
  //             }
  //             else {
  //               setopenRateAlertModal(true)
  //               setslaErrorMessage(`Override is invalid. SLA user can override from $${Number(maxAllowablbeLimit).toFixed(2)} to $${Number(initialInvRate).toFixed(2)}`)
  //             }
  //           }
  //           else {
  //             for (const item in configDataObj) {
  //               debugger
  //               if (
  //                 configData[item] == Number(percentCalculationInBlur).toFixed(2) &&
  //                 Number(percentCalculationInBlur) !== 0
  //               ) {
  //                 debugger
  //                 minRole = item;
  //                 output = item;
  //                 setSLAMinRole(item);
  //                 if (Roles.indexOf(String(currentRole)) < Roles.indexOf(item)) {
  //                   setneedSLA({ ...needSLA, needSLAController: true });
  //                   enteredIf = true;
  //                 }
  //                 break;
  //               } else if (configData[item] > Number(percentCalculationInBlur)) {
  //                 debugger
  //                 minRole = item;
  //                 output = item
  //                 setSLAMinRole(item);
  //                 if (Roles.indexOf(String(currentRole)) < Roles.indexOf(item)) {
  //                   setneedSLA({ ...needSLA, needSLAController: true });
  //                   enteredIf = true;
  //                 }
  //                 break;
  //               }
  //             }

  //             console.log('logged roles', currentRole);

  //             debugger
  //             const groupedData: { [key: string]: string[] } = {};

  //             for (const [key, value] of Object.entries(configData)) {
  //               const stringValue = String(value); // Convert value to string
  //               if (!groupedData[stringValue]) {
  //                 groupedData[stringValue] = [key];
  //               } else {
  //                 groupedData[stringValue].push(key);
  //               }
  //             }

  //             console.log("groupedData", groupedData)
  //             let currentRoleValue: any;
  //             debugger
  //             for (const key of Object.keys(groupedData)) {
  //               if (groupedData[key].includes(currentRole)) {
  //                 // Convert key to number if needed
  //                 currentRoleValue = parseInt(key);
  //                 // Once we find the  role, we can break the loop
  //                 break;
  //               }
  //             }
  //             debugger
  //             console.log("currentRoleValue", currentRoleValue)

  //             let allowedRate;
  //             let minAmount;
  //             allowedRate = initialInvRate * (1 * currentRoleValue / 100);
  //             minAmount = initialInvRate - allowedRate;
  //             setthresHoldExceptionMessage(
  //               `Coworker can only change rate upto $${Number(
  //                 minAmount.toString()
  //               ).toFixed(2)} second level approval is required for this change.`
  //             );

  //             if (enteredIf === false) {
  //               console.log('entered if pricing call', enteredIf);

  //               Number(priceExcep.rate) < 1
  //                 ? setenableZeroValidate(true)
  //                 : checkDepreciation(depr, false);
  //             }
  //           }
  //         } else {

  //           Number(priceExcep.rate) < 1
  //             ? setenableZeroValidate(true)
  //             : checkDepreciation(depr, false);
  //         }

  //       }

  //       // if (currentRole == 'CAR') {

  //       //   if ((priceExcep.rate) < (priceTagFilterResult(priceTags)?.rate)) {

  //       //     setvalidationPopUp(true);
  //       //     setvalidPopUpType({ ...validPopUpType, minRateAllowed: true });
  //       //     setallowedRate((priceTagFilterResult(priceTags)?.rate));
  //       //   }
  //       //   else {
  //       //     // saveRateAndTerm();
  //       //     checkDepreciation(depr, false);
  //       //   }
  //       // }
  //       // else if (currentRole == 'AM' || currentRole == 'LAM' || currentRole == 'SM' || currentRole == 'SMT') {

  //       //   minrateAllowed = parseFloat(((priceTagFilterResult(priceTags)?.rate) - ((priceTagFilterResult(priceTags)?.rate) * 0.1)).toFixed(2));

  //       //   if ((priceExcep.rate) < minrateAllowed) {
  //       //     setvalidationPopUp(true);
  //       //     setallowedRate(minrateAllowed);
  //       //     setvalidPopUpType({ ...validPopUpType, minRateAllowed: true });
  //       //   }
  //       //   else {

  //       //     checkDepreciation(depr, false);
  //       //   }

  //       // }
  //       // else if (currentRole == 'RD' || currentRole == 'DM' || currentRole == 'DMT') {
  //       //   minrateAllowed = parseFloat(((priceTagFilterResult(priceTags)?.rate) - ((priceTagFilterResult(priceTags)?.rate) * 0.5)).toFixed(2));

  //       //   if ((priceExcep.rate) < minrateAllowed) {
  //       //     setvalidationPopUp(true);
  //       //     setallowedRate(minrateAllowed);
  //       //     setvalidPopUpType({ ...validPopUpType, allowedRates: true });
  //       //   }
  //       //   else {
  //       //     checkDepreciation(depr, false);
  //       //   }
  //       // }
  //     }
  //     else if (inventoryPriceTagFilter(priceTags)?.term?.toString() !== priceExcep.term) {

  //       checkDepreciation(depr, false);
  //     }
  //     else {
  //       saveRateAndTerm();
  //     }

  //   }
  // }

  const onSavePriceException = async () => {
    setvalidationPopUp(true);
    let newTotal: number;
    newTotal = (priceExcep.rate) * parseInt(priceExcep.term);
    depr = parseFloat(((parseFloat(priceTagInfo.headerDetails.remainingValue) / newTotal) * 100).toFixed(2));
    checkDepreciation(depr, true);
    setSLAController({ ...SLAController, TwoFactorCompleted: false });
  }



  const onPageLoadOk = () => {
    if (priceExcepPopUp === true) {
      setpopUp(false);
      setvalidPopUpType({ ...validPopUpType, secondLevelPin: true });
      setvalidationPopUp(false);
    }
    else {
      setpopUp(false); setDisableBtn({ ...DisableBtn, printBtn: false });
    }
  }

  /****function to show the popUps needed for the screen according to the popUptype */
  const showPopup = (value: any) => {
    if (value == 'pageLoad') {
      return (
        <Grid>
          <Typography className={`${classes.fontBold} ${classes.fs6} ${classes.mx4} ${classes.textCenter}`} >
            Please print the price tag before changing price tag information
          </Typography>

          <RACButton color="primary" variant="contained"
            onClick={() => onPageLoadOk()}
            className={`${classes.marginLeftRight40} ${classes.mt4}`}>
            OK
          </RACButton>
        </Grid>
      )
    }
    else if (value == 'printPriceTag') {
      return (
        <Grid>
          <Grid>
            {/* changes */}
            <Typography className={`${classes.fontBold} ${classes.fs6} ${classes.mx4} ${classes.textCenter}`}>
              Are the Price Tag forms in the Paper tray of the printer.
            </Typography>
          </Grid>

          <Grid className={`${classes.componentCenter} ${classes.mt4} ${classes.mb3}`}>
            <RACButton color="secondary" variant="outlined" onClick={() => setpopUp(false)} className={`${classes.mr3} ${classes.btnWidth}`}>
              No
            </RACButton>

            <RACButton color="primary" variant="contained" onClick={() => onPrintPriceTag()} className={`${classes.btnWidth}`} >
              Yes
            </RACButton>
          </Grid>
        </Grid>
      )
    }
    else if (value == 'printing') {
      return (
        <Grid >
          <Grid >
            <Typography className={`${classes.textCenter} ${classes.mb2}`}>printing...</Typography>
          </Grid >
          <Grid className={`${classes.componentCenter} ${classes.mb2}`}>
            <CircularProgress />
          </Grid>
          <Grid >
            <Typography className={`${classes.textCenter} ${classes.mb2} ${classes.fontBold}`}>SingleItemPriceTag</Typography>

          </Grid>
          <Grid className={`${classes.textCenter} ${classes.mb2}`}>
          </Grid>
        </Grid>
      )
    }
    else if (value == 'err_priceTagStatus') {
      return (
        <Grid >
          <Grid className={`${classes.componentCenter} ${classes.mb1}`}>
            <AlertIcon />
          </Grid>
          <Grid>
            <Typography className={`${classes.fontSemiBold} ${classes.fs6} ${classes.mx3} ${classes.textCenter}`}>
              Current Price Tag is not updated!
              {/* to ${priceTags.editPriceTag[0].rate}. */}
            </Typography>
          </Grid>

          <Grid className={`${classes.componentCenter} ${classes.mt4} ${classes.mb3}`}>
            <RACButton color="primary" variant="contained" onClick={() => { setpopUp(false) }} className={`${classes.mr3} ${classes.btnWidth}`}>
              OK
            </RACButton>
          </Grid>
        </Grid>
      )
    }
    else if (value == 'err_priceException') {
      return (
        <Grid >
          <Grid className={`${classes.componentCenter} ${classes.mb1}`}>
            <AlertIcon></AlertIcon>
          </Grid>
          <Grid>
            <Typography className={`${classes.fontSemiBold} ${classes.fs6} ${classes.mx3} ${classes.textCenter}`}>
              Price Exception is not updated!
              {/* to ${priceTags.editPriceTag[0].rate}. */}
            </Typography>
          </Grid>

          <Grid className={`${classes.componentCenter} ${classes.mt4} ${classes.mb3}`}>
            <RACButton color="primary" variant="contained" onClick={() => { setpopUp(false) }} className={`${classes.mr3} ${classes.btnWidth}`}>
              OK
            </RACButton>
          </Grid>
        </Grid>
      )
    }
    else if (value == 'PriceExcepErrMsg') {
      return (
        <Grid >
          <Grid className={`${classes.componentCenter} ${classes.mb1}`}>
            <AlertIcon></AlertIcon>
          </Grid>
          <Grid>
            <Typography className={`${classes.fontSemiBold} ${classes.fs6} ${classes.mx3} ${classes.textCenter}`}>
              {PriceExcepErrMsg}
              {/* to ${priceTags.editPriceTag[0].rate}. */}
            </Typography>
          </Grid>

          <Grid className={`${classes.componentCenter} ${classes.mt4} ${classes.mb3}`}>
            <RACButton color="primary" variant="contained" onClick={() => { setpopUp(false) }} className={`${classes.mr3} ${classes.btnWidth}`}>
              OK
            </RACButton>
          </Grid>
        </Grid>
      )
    }
  }


  /****function to show the popUps needed for the validation of rate field  */
  const validationPopUps = (validType: any) => {
    if (validType.maxRateAllowed === true) {
      return (
        <Grid >
          <Grid>
            <Typography className={`${classes.fontSemiBold} ${classes.fs6} ${classes.mx5} ${classes.textCenter}`}>
              {/* The maximum rate that can be changed is ${(priceTagFilterResult(priceTags)?.rate).toFixed(2)}. */}
              The maximum rate that can be changed is ${priceTagInfo.headerDetails?.maximumRate}.
            </Typography>
          </Grid>

          <Grid className={`${classes.componentCenter} ${classes.mt4} ${classes.mb3}`}>
            <RACButton color="primary" variant="contained" onClick={() => { setvalidationPopUp(false), setvalidPopUpType({ ...validPopUpType, maxRateAllowed: false }), setpriceExcep({ ...priceExcep, rate: priceTagFilterResult(priceTags)?.rate }) }} className={`${classes.mr3} ${classes.btnWidth}`}>
              OK
            </RACButton>

          </Grid>
        </Grid>
      )
    }
    else if (validType.minRateAllowed === true) {
      return (
        <Grid >
          <Grid>
            <Typography className={`${classes.fontSemiBold} ${classes.fs6} ${classes.mx5} ${classes.textCenter}`}>
              Coworker can change Rate upto ${allowedRate.toFixed(2)}. Second Level is required for this change. Do you want to proceed?
            </Typography>
          </Grid>

          <Grid className={`${classes.componentCenter} ${classes.mt4} ${classes.mb3}`}>

            <RACButton color="secondary" variant="outlined" onClick={() => { setvalidationPopUp(false), setvalidPopUpType({ ...validPopUpType, minRateAllowed: false }), setpriceExcep({ ...priceExcep, rate: priceTagFilterResult(priceTags)?.rate }) }} className={`${classes.mr3} ${classes.btnWidth}`}>
              No
            </RACButton>

            <RACButton color="primary" variant="contained" onClick={() => { setvalidationPopUp(false), setvalidPopUpType({ ...validPopUpType, secondLevelAuth: true, minRateAllowed: false }), setpriceExcepPopUp(false) }} className={`${classes.btnWidth}`} >
              Yes
            </RACButton>

          </Grid>
        </Grid>
      )
    }

    else if (validType.overrideInvalid === true) {
      return (
        <Grid >
          <Grid>
            <Typography className={`${classes.fontSemiBold} ${classes.fs6} ${classes.mx5} ${classes.textCenter}`}>
              Override is invalid . SLA user can override till ${allowedRate.toFixed(2)}.
              {/* to ${priceTags.editPriceTag[0].rate}. */}
            </Typography>
          </Grid>

          <Grid className={`${classes.componentCenter} ${classes.mt4} ${classes.mb3}`}>
            <RACButton color="primary" variant="contained" onClick={() => { setvalidPopUpType({ ...validPopUpType, overrideInvalid: false, secondLevelAuth: true }), setvalidationPopUp(false), setpriceExcepPopUp(false) }} className={`${classes.mr3} ${classes.btnWidth}`}>
              OK
            </RACButton>
          </Grid>
        </Grid>
      )
    }
    else if (validType.depreciationAllowed === true) {
      return (
        <Grid >
          <Grid>
            <Typography className={`${classes.fontSemiBold} ${classes.fs6} ${classes.mx5} ${classes.textCenter}`}>
              The depreciation $({depr.toFixed(2)}) is greater than (33.00%). " This may be due to state specific Price rules ".
            </Typography>
          </Grid>

          <Grid className={`${classes.componentCenter} ${classes.mt4} ${classes.mb3}`}>
            <RACButton color="primary" variant="contained" onClick={() => { setpriceExcepPopUp(false); setvalidPopUpType({ ...validPopUpType, depreciationAllowed: false, secondLevelAuth: false }); setvalidationPopUp(false); saveRateAndTerm() }} className={`${classes.mr3} ${classes.btnWidth}`}>
              OK
            </RACButton>
          </Grid>
        </Grid>
      )
    }
    else if (validType.allowedRates === true) {
      return (
        <Grid>
          <Grid>
            <Typography className={`${classes.fontSemiBold} ${classes.fs6} ${classes.mx5} ${classes.textCenter}`}>
              {/* Rate  is invalid. Allowable rate values are ${allowedRate.toFixed(2)} to ${(priceTagFilterResult(priceTags)?.rate)?.toFixed(2) }. */}
              Rate  is invalid. Allowable rate values are ${allowedRate.toFixed(2)} to ${priceTagInfo.headerDetails?.maximumRate}.

            </Typography>
          </Grid>

          <Grid className={`${classes.componentCenter} ${classes.mt4} ${classes.mb3}`}>
            <RACButton color="primary" variant="contained" onClick={() => { setvalidPopUpType({ ...validPopUpType, allowedRates: false }), setpriceExcep({ ...priceExcep, rate: priceTagFilterResult(priceTags)?.rate }), setvalidationPopUp(false) }} className={`${classes.mr3} ${classes.btnWidth}`}>
              OK
            </RACButton>
          </Grid>
        </Grid>
      )
    }
    else if (validType.rateFieldEmpty === true) {
      return (
        <Grid>
          <Grid>
            <Typography className={`${classes.fontSemiBold} ${classes.fs6}  ${classes.textCenter}`}>
              Invalid value in the rate field.
            </Typography>
          </Grid>

          <Grid className={`${classes.componentCenter} ${classes.mt4} ${classes.mb3}`}>
            <RACButton color="primary" variant="contained" onClick={() => { setvalidPopUpType({ ...validPopUpType, rateFieldEmpty: false }), setpriceExcep({ ...priceExcep, rate: priceTagFilterResult(priceTags)?.rate }), setvalidationPopUp(false) }} className={`${classes.mr3} ${classes.btnWidth}`}>
              OK
            </RACButton>
          </Grid>
        </Grid>
      )
    }
    else if (validType.allowedTermRange === true) {
      return (
        <Grid>
          <Grid>
            <Typography className={`${classes.fontSemiBold} ${classes.fs6} ${classes.mx5} ${classes.textCenter}`}>
              Term is invalid. Allowed terms are {priceTagInfo.headerDetails.minTerm}-{priceTagInfo.headerDetails.maximumTerm}
            </Typography>
          </Grid>

          <Grid className={`${classes.componentCenter} ${classes.mt4} ${classes.mb3}`}>
            <RACButton color="primary" variant="contained" onClick={() => { setvalidPopUpType({ ...validPopUpType, allowedTermRange: false }), setpriceExcep({ ...priceExcep, term: priceTagFilterResult(priceTags)?.term?.toString() }), setvalidationPopUp(false) }} className={`${classes.mr3} ${classes.btnWidth}`}>
              OK
            </RACButton>
          </Grid>
        </Grid>
      )
    }
    else if (validType.cancelPriceException === true) {
      return (
        <Grid>
          <Grid>
            <Typography className={`${classes.fontSemiBold} ${classes.fs6} ${classes.mx5} ${classes.textCenter}`}>
              Do you want to save the edit price first?
            </Typography>
          </Grid>

          <Grid className={`${classes.componentCenter} ${classes.mt4} ${classes.mb3}`}>
            <RACButton color="secondary" variant="outlined" onClick={() => { setpopUp(false); setpriceExcepPopUp(false); setvalidationPopUp(false); setvalidPopUpType({ ...validPopUpType, cancelPriceException: false }); setDisableBtn({ ...DisableBtn, saveBtn: true }); setpriceExcep({ ...priceExcep, rate: priceTagFilterResult(priceTags)?.rate, term: priceTagFilterResult(priceTags)?.term?.toString(), excepReason: '0', excepExplanation: '' }) }} className={`${classes.mr3} ${classes.btnWidth}`}>
              No
            </RACButton>

            <RACButton color="primary" variant="contained" onClick={() => { setvalidationPopUp(false); setvalidPopUpType({ ...validPopUpType, cancelPriceException: false }) }} className={`${classes.btnWidth}`} >
              Yes
            </RACButton>
          </Grid>
        </Grid>
      )
    }

  }



  //stub need to remove after implementing authentication
  const SLACompleted = (value: any) => {
    setpriceExcepPopUp(true);
    console.log('SLACompleted VAL', value);

    if (value.status === true) {
      //const logginedRole =value?.userId ? value?.userId?.substring('4') :employeeId.substring('4');
      const logginedRole = value?.userId
      console.log('SLACompleted logginedRole', logginedRole, employeeId, value?.userId);

      setpopUp(false);
      setvalidationPopUp(true);

      newTotal = (priceExcep.rate) * parseInt(priceExcep.term);
      console.log('SLACompleted newTotal---->priceExcep', newTotal, priceExcep);
      console.log('SLACompleted priceTagInfo', priceTagInfo);

      depr = parseFloat(((parseFloat(priceTagInfo.headerDetails.remainingValue) / newTotal) * 100).toFixed(2));
      console.log('SLACompleted depr', depr);
      console.log('currentRole', currentRole);
      console.log('priceTags--->', priceTags);
      if (currentRole == 'CAR') {

        if (logginedRole == "AM" || logginedRole == "LAM" || logginedRole == "SM" || logginedRole == "SMT") {

          minrateAllowed = parseFloat(((priceTagFilterResult(priceTags)?.rate) - ((priceTagFilterResult(priceTags)?.rate) * 0.1)).toFixed(2));

          if (priceExcep.rate >= minrateAllowed) {

            checkDepreciation(depr, true);
          }
          else {
            setallowedRate(minrateAllowed);
            setvalidPopUpType({ ...validPopUpType, overrideInvalid: true, secondLevelAuth: false });
          }
        }
        else if (logginedRole == "DMT" || logginedRole == "DM" || logginedRole == "RD") {
          minrateAllowed = parseFloat(((priceTagFilterResult(priceTags)?.rate) - ((priceTagFilterResult(priceTags)?.rate) * 0.5)).toFixed(2));

          if ((priceExcep.rate) >= minrateAllowed) {
            checkDepreciation(depr, true);

          }
          else {
            setallowedRate(minrateAllowed);
            setvalidPopUpType({ ...validPopUpType, overrideInvalid: true, secondLevelAuth: false });
          }
        }
        else {
          setvalidPopUpType({ ...validPopUpType, overrideInvalid: true, secondLevelAuth: false });
        }
      }
      else if (currentRole == 'AM' || currentRole == 'SM' || currentRole == 'SMT' || currentRole == 'LAM' || currentRole == 'SSM') {

        if (logginedRole == "DMT" || logginedRole == "DM" || logginedRole == "RD") {
          minrateAllowed = parseFloat(((priceTagFilterResult(priceTags)?.rate) - ((priceTagFilterResult(priceTags)?.rate) * 0.5)).toFixed(2));

          if ((priceExcep.rate) >= minrateAllowed) {
            checkDepreciation(depr, true);

          }
          else {
            setallowedRate(minrateAllowed);
            setvalidPopUpType({ ...validPopUpType, overrideInvalid: true, secondLevelAuth: false });
          }
        }
        else {
          setvalidPopUpType({ ...validPopUpType, overrideInvalid: true, secondLevelAuth: false });
        }


      }
    }

  }


  const openPriceExceptionPopUp = () => {
    return (
      <Grid className={`${classes.ml5} ${classes.my2} `} >
        {/* top1 */}
        <Grid className={`${classes.floatLeft} ${classes.w50} ${classes.mb4}`} >
          <Grid className={`${classes.floatLeft} ${classes.mr3} `} >
            <Typography>
              Rate
            </Typography>
          </Grid>
          <Grid
            className={`${addedItemClassName.inputGroup} ${classes.w50} `} style={{ marginTop: '-8px' }}
          >
            <Typography className={addedItemClassName.inputGroupText}>
              $
            </Typography>

            <CurrencyInput

              id="InvRate"
              name="rate"
              value={priceExcep.rate}
              className={`${classes.formControl} ${addedItemClassName.currencyAlign} ${classes.fontSemiBold} `}
              decimalScale={2}
              decimalsLimit={2}
              onValueChange={(e: any) => setpriceExcep({ ...priceExcep, rate: (e) })}
              // onBlur={(e: any) => validateRateField(e)}
              onBlur={(e) => blurFunction('RateChange', e)}

              onKeyDown={(e) => RateTermChange('RateChange', e)}


            />


          </Grid>
        </Grid>
        <Grid className={`${classes.floatLeft} ${classes.w50}`}>
          <Grid className={`${classes.floatLeft} ${classes.mr3}`}>
            <Typography>
              Term
            </Typography>
          </Grid>
          <Grid className={`${classes.floatLeft}`}
            //  ${classes.mr3}
            style={{ marginTop: '-13px', fontSize: '17px !important' }}
          >
            <RACTextbox
              className={`${classes.inputBox}  ${classes.textRightAlign}`}
              type="number"
              id="InvTerm"
              name="term"
              value={(priceExcep.term)}
              maxlength={5}
              OnChange={(e) => setpriceExcep({ ...priceExcep, term: (e.target.value) })}
              // Onblur={(e) => validateTerm(e)}
              Onblur={(e) => blurFunction('TermChange', e)}
              OnKeydown={(e) => RateTermChange('TermChange', e)}


            />

          </Grid>
        </Grid>
        {/* middle*/}
        <Grid className={` ${classes.width40}`}>
          <Typography className={`${classes.fontSemiBold} ${classes.fs7} ${classes.my2}`}>
            Exception Reason <span style={{ color: 'red' }}>*</span>
          </Typography>
          <RACSelect
            loading={loader.printLoader}
            name="exceptionList"
            isDisabled={false}
            defaultValue={priceExcep.excepReason ? priceExcep.excepReason : ''}
            options={buildReasonList()}
            onChange={(e) => { setpriceExcep({ ...priceExcep, excepReason: e.target.value }) }}
          />
        </Grid>
        <Grid className={`${classes.mt4} `}>
          <Typography className={`${classes.mb2}`}>
            Explanation for Inventory Depreciation Cap Exception
          </Typography>
          <textarea
            name="explanation"
            maxLength={255}
            value={priceExcep.excepExplanation}
            className={`${formclasses.FormTextArea} `}
            onChange={(e) => setpriceExcep({ ...priceExcep, excepExplanation: e.target.value })}>
          </textarea>
        </Grid>
        {/* footer */}
        <Grid className={`${classes.mt4} ${classes.mr6} `}>
          <Grid className={classes.floatRight}>
            <RACButton color="primary" variant="contained" disabled={enableSaveButton()}
              onClick={() => onSavePriceException()}
              style={{ width: '90px' }}

            >
              Save
            </RACButton>
          </Grid>

          <Grid className={classes.floatRight}>
            <RACButton color="secondary" variant="outlined"
              className={`${classes.mr3} `}
              onClick={() => { onCancelPriceException() }}>
              Cancel
            </RACButton>
          </Grid>
        </Grid>
      </Grid>

    )
  }
  const invalidRateDiv = (displayData: any) => {
    return (
      <div
        id="decisionengine"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="decisionengine"
        aria-hidden="true"
      >
        <Grid item md={12} className={classes.textCenter}>
          <Typography className={`${classes.fontSemiBold} ${classes.fs6}`}>
            {displayData}
          </Typography>
        </Grid>

        <Grid item md={12} className={`${classes.py4} ${classes.textCenter}`}>
          <RACButton
            className={classes.mx1}
            size="large"
            variant="contained"
            color="primary"
            onClick={() => { setpopUp(false); setpriceExcepPopUp(false); setvalidationPopUp(false); setvalidPopUpType({ ...validPopUpType, cancelPriceException: false }); setDisableBtn({ ...DisableBtn, saveBtn: true }); setpriceExcep({ ...priceExcep, rate: priceTagFilterResult(priceTags)?.rate, term: priceTagFilterResult(priceTags)?.term?.toString(), excepReason: '0', excepExplanation: '' }) }}
          >
            OK
          </RACButton>
        </Grid>
      </div>
    );
  };
  const openRateAlert = () => {
    return (
      <div>
        <Grid className={classes.textCenter}>
          {slaErrorMessage.includes('Allowable') ? null : <Erroricon className={classes.racErrorIcon} />}
          <Typography className={classes.racErrorFunction} style={{ fontSize: '16px' }}>
            {slaErrorMessage}
          </Typography>
        </Grid>
        <Grid className={classes.textCenter} style={{ marginTop: '20px' }}>
          <RACButton
            autoFocus
            variant="contained"
            color="primary"
            className={classes.spacerME2}
            onClick={() => { setpopUp(false); setpriceExcepPopUp(false); setopenRateAlertModal(false); setvalidationPopUp(false); setvalidPopUpType({ ...validPopUpType, cancelPriceException: false }); setDisableBtn({ ...DisableBtn, saveBtn: true }); setpriceExcep({ ...priceExcep, rate: priceTagFilterResult(priceTags)?.rate, term: priceTagFilterResult(priceTags)?.term?.toString(), excepReason: '0', excepExplanation: '' }) }}          >
            OK
          </RACButton>
        </Grid>
      </div>
    );
  };

  const openTermAlert = () => {
    return (
      <div>
        <Grid className={classes.textCenter}>

          <Typography className={classes.racErrorFunction} style={{ fontSize: '16px' }}>
            Term is invalid. Allowable terms are 10 - {initialInventoryTerm}
          </Typography>
        </Grid>
        <Grid className={classes.textCenter} style={{ marginTop: '20px' }}>
          <RACButton
            autoFocus
            variant="contained"
            color="primary"
            className={classes.spacerME2}
            onClick={() => { setpopUp(false); setpriceExcepPopUp(false); setopenRateAlertModal(false); setvalidationPopUp(false); setvalidPopUpType({ ...validPopUpType, cancelPriceException: false }); setDisableBtn({ ...DisableBtn, saveBtn: true }); setpriceExcep({ ...priceExcep, rate: priceTagFilterResult(priceTags)?.rate, term: priceTagFilterResult(priceTags)?.term?.toString(), excepReason: '0', excepExplanation: '' }); setInvalidTermPopup(false) }}          >
            OK
          </RACButton>
        </Grid>
      </div>
    );
  };


  /***********Function invoked inthe Onclick of continue button in the PinPopUp   *///need rework on this
  const onClickContinuePin = (e: any) => {
    if (e === true) {
      setvalidPopUpType({ ...validPopUpType, secondLevelPin: false });
      setvalidationPopUp(false);
      setpopUpType('printPriceTag');
      setpopUp(true);
    }
  }

  const showLoader = () => {
    return (
      <Grid>
        <Grid className=
          {loader.printLoader === true ? classes.loaderStyle : classes.racLoaderPage}
        >
          <CircularProgress />
        </Grid>
      </Grid>
    )
  }


  const twoFactorCancel = () => {
    setSLAController({ ...SLAController, TwoFactorCancelClick: false });
    setSecondFactorEnabled(false);
    setinitialInventoryTerm(priceTagFilterResult(priceTags)?.term)
    setinitialInventoryRate(priceTagFilterResult(priceTags)?.rate)
    setpriceExcep({ ...priceExcep, excepReason: '', rate: priceTagFilterResult(priceTags)?.rate, term: priceTagFilterResult(priceTags)?.term?.toString(), excepExplanation: '' });

  };
  const twoFactorCompleted = (value: any) => {
    setSLAController({ ...SLAController, TwoFactorCompleted: value });
    setSecondFactorEnabled(false);
  };
  const acceptSLA = () => {
    setneedSLA({ ...needSLA, needSLAController: false });
    setSecondFactorEnabled(true);
  };
  const SLAModal = (displayData: any) => {
    return (
      <div id="decisionengine">
        <Grid item md={12} className={classes.textCenter}>
          <Typography className={`${classes.fontSemiBold} ${classes.fs6}`}>
            {displayData}
          </Typography>
          <Typography className={`${classes.fontSemiBold} ${classes.fs6}`}>
            Do you want to proceed ?
          </Typography>
        </Grid>

        <Grid item md={12} className={`${classes.py4} ${classes.textCenter}`}>
          <RACButton
            className={classes.me3}
            size="large"
            variant="outlined"
            color="secondary"
            onClick={() => {
              setneedSLA({ ...needSLA, needSLAController: false });
              setinitialInventoryTerm(priceTagFilterResult(priceTags)?.term)
              setinitialInventoryRate(priceTagFilterResult(priceTags)?.rate)
              setpriceExcep({ ...priceExcep, excepReason: '', rate: priceTagFilterResult(priceTags)?.rate, term: priceTagFilterResult(priceTags)?.term?.toString(), excepExplanation: '' });
            }}
          >
            No
          </RACButton>
          <RACButton
            className={classes.mx1}
            size="large"
            variant="contained"
            color="primary"
            onClick={() => acceptSLA()}
          >
            Yes
          </RACButton>
        </Grid>
      </div>
    );
  };
  return (
    <React.Fragment>

      {/**Component starts */}
      {
        errMsg.wentwrong === true || errMsg.norecord === true || errMsg.noactivetags === true ?
          <Grid className={`${classes.textCenter} ${classes.mt5}`}>
            <AlertIcon />
            <Typography className={`${classes.subTitle} ${classes.mb3}`}>
              {errMsg.wentwrong === true ? "Something Went Wrong" : errMsg.noactivetags === true ? "No Active Price Tags" : "No Record Found"}

            </Typography>
          </Grid> :

          // errMsg.norecord === true ?
          // <Grid className={`${classes.textCenter} ${classes.mt5}`}>
          //     <AlertIcon />
          //     <Typography className={`${classes.subTitle} ${classes.mb3}`}>
          //       No Record Found       
          //     </Typography>
          //   </Grid> :

          priceTagInfo == undefined || loader.pageLoader === true ?
            <Grid className={`${classes.textCenter} ${classes.mt5}`}>
              {/* <AlertIcon /> */}
              <Typography className={`${classes.subTitle} ${classes.mb3}`}>
                {showLoader()}
              </Typography>
            </Grid>
            :

            /**headerDetails */
            <Grid>
              <Grid container>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Card className={`${classes.mx1} ${classes.mt4} ${classes.racCard}`}>
                    <CardContent  >
                      <Grid container spacing={2} className={classes.alignCenter}>
                        <Grid item xs={12} sm={4} md={3} lg={2}>
                          <Typography component="span" className={formclasses.formLabel}>Remaining Value : </Typography>
                          <Typography component="span" className={`${formclasses.formValue} ${classes.mt2}`}>
                            {validateData(priceTagInfo) ? validateData(priceTagInfo.headerDetails) ? formatter.format(priceTagInfo.headerDetails.remainingValue) : '-' : []}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={4} md={3} lg={2}>
                          <Typography component="span" className={formclasses.formLabel}>Purchase Cost : </Typography>
                          <Typography component="span" className={`${formclasses.formValue} ${classes.mt2}`}>
                            {validateData(priceTagInfo) ? validateData(priceTagInfo.headerDetails) ? formatter.format(priceTagInfo.headerDetails.purchaseCost) : '-' : []}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={4} md={3} lg={2}>
                          <Typography component="span" className={formclasses.formLabel}>Price Tag Queued : </Typography>
                          <Typography component="span" className={`${formclasses.formValue} ${classes.mt2}`}>
                            {validateData(priceTagInfo) ? validateData(priceTagInfo.headerDetails) ? priceTagInfo.headerDetails.priceTagQueued === true ? 'Yes' : 'No' : '-' : {}}
                          </Typography>
                        </Grid>

                        {/* <Grid item xs="auto" sm="auto" md="auto" lg="auto" >
                                <Grid container className={`${classes.p3} ${classes.bgLightBlue} ${classes.borderRadius5}`}>
                                  <Grid item xs="auto">
                                    <Typography className={`${classes.textBlue} ${classes.fs6}`}>Term : &nbsp;&nbsp;</Typography>
                                  </Grid>
                                  <Grid item xs="auto">
                                    <Typography className={`${classes.textBlue} ${classes.fs6} ${classes.fontBold}`} >{
                                        validateData(priceTagInfo) ? 
                                          validateData(priceTagInfo.headerDetails) ? 
                                            ((priceTagInfo.headerDetails.maxTerm)!== undefined && (priceTagInfo.headerDetails.maxTerm)!== null) ?
                                                  priceTagInfo.headerDetails.maxTerm + " - " +priceTagInfo.headerDetails.minTerm+"  Weeks"
                                            :""
                                          :''
                                        :""}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Grid> */}
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>

              {/**PriceTags */}
              <Grid container className={classes.mbFooterSpacing}>
                <Grid item md={3} className={`${classes.width10}`}>
                  <Card className={
                    `${classes.bgTransparent} ${classes.shadow0}`}>
                    <CardContent>
                      <Grid container spacing={2}>
                        <Grid item md={12}>
                          <Typography variant="h5" className={classes.textIndent}>Price Tag Title</Typography>
                          <Card className={`${classes.bgTransparent} ${classes.shadow0} ${classes.mt1}`}>
                            <CardContent className={`${classes.pt2} ${classes.px2}`}>
                              <Typography className={`${classes.py3} ${classes.pl2} ${classes.borderBottom} ${classes.fs6}`}>Depreciation Percentage</Typography>
                              <Typography className={`${classes.py3} ${classes.pl2} ${classes.borderBottom} ${classes.fs6}`}>Gross Profit Per Payment</Typography>
                              <Typography className={`${classes.py3} ${classes.pl2} ${classes.borderBottom} ${classes.fs6}`}>Turns</Typography>
                              <Typography className={`${classes.py3} ${classes.pl2} ${classes.borderBottom} ${classes.fs6}`}>Rate</Typography>
                              <Typography className={`${classes.py3} ${classes.pl2} ${classes.borderBottom} ${classes.fs6}`}>Term</Typography>
                              {featureFlagDetails && featureFlagDetails[ADD_ON_PRICING] == '1' ? <Typography className={`${classes.py3} ${classes.pl2} ${classes.borderBottom} ${classes.fs6}`}>Add-On Rate</Typography> : null}
                              <Typography className={`${classes.py3} ${classes.pl2} ${classes.borderBottom} ${classes.fs6}`}>Cash Price</Typography>
                              <Typography className={`${classes.py3} ${classes.pl2} ${classes.fs6}`}>Total Cost</Typography>
                            </CardContent>
                          </Card>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>

                <Grid item md={9}>
                  <Card className={`${classes.m2} ${classes.bgTransparent} ${classes.shadow0}`}>
                    <CardContent>
                      <Grid container spacing={2}>

                        {/**newPriceTag */}
                        <Grid item md={3}>
                          <PriceTagCard
                            priceTagDetails={priceTags.newPriceTag} featureFlagDetails={featureFlagDetails} />
                        </Grid>

                        {/**autoPriceTag */}
                        <Grid item md={3}>
                          <PriceTagCard
                            priceTagDetails={priceTags.autoPriceTag} featureFlagDetails={featureFlagDetails} />
                        </Grid>

                        {/**currentPriceTag */}
                        <Grid item md={3}>
                          <PriceTagCard
                            priceTagDetails={priceTags.currentPriceTag} featureFlagDetails={featureFlagDetails} />
                        </Grid>

                        {/**editPriceTag */}
                        <Grid item md={3}>
                          <PriceTagCard
                            priceTagDetails={priceTags.editPriceTag} featureFlagDetails={featureFlagDetails} />
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>

              {/**Footer */}
              <Grid container className={classes.fixedFooter}>
                <Grid>
                  <RACButton
                    variant="outlined"
                    color="primary"
                    className={`${classes.textBlack}`}
                    onClick={() => history.push({ pathname: `/inventory/inventorysearch` })}
                  >
                    Cancel
                  </RACButton>
                </Grid>

                {(headerContext.storeNumber == storeNum) ?

                  <Grid>
                    <RACButton
                      variant="contained"
                      color="primary"
                      className={classes.mr2}
                      loading={loader.btnLoader}
                      onClick={() => onClickPrint()}
                    >
                      Print
                    </RACButton>

                    <RACButton
                      variant="contained"
                      color="primary"
                      className={classes.mr2}
                      disabled={
                        DisableBtn.printBtn
                      }
                      onClick={() => { validateData(currentRole) ? setvalidPopUpType({ ...validPopUpType, secondLevelPin: true }) : setvalidPopUpType({ ...validPopUpType, secondLevelPin: false }); setvalidationPopUp(false) }}
                    >
                      Print Price Tag
                    </RACButton>

                    <RACButton
                      variant="contained"
                      color="primary"
                      disabled={DisableBtn.priceExpBtn}
                      onClick={() => onClickPriceException()}
                    >
                      Price Exception
                    </RACButton>
                  </Grid>
                  : null
                }

              </Grid>

            </Grid>
      }

      {/**Component ends  */}


      {/**Screen popups*/}
      <RACModalCard
        className={popUpType === 'pageLoad' ? classes.priceTagPageLoadModalWidth : classes.priceTagPrintModalWidth}
        closeIcon={popUpType == 'printPriceTag' ? true : false}
        onClose={() => setpopUp(false)}
        maxWidth="lg"
        borderRadius={'20px !important'}
        isOpen={popUp}
        children={showPopup(popUpType)}

      />
      {/**validation popups*/}
      <RACModalCard
        title=''
        isOpen={validationPopUp}
        maxWidth="xs"
        className={validPopUpType.rateFieldEmpty === true ? `${classes.priceTagPrintModalWidth}` : `${classes.mAuto}`}
        borderRadius={'20px !important'}
        children={validationPopUps(validPopUpType)}
      />


      {/**pinPopUp */}
      {/* changes */}
      {validPopUpType.secondLevelPin === true ?

        <SecondFactor
          setTwoFactorCancelClick={() => { setvalidPopUpType({ ...validPopUpType, secondLevelPin: false }) }}
          setTwoFactorCompleted={(e: any) => onClickContinuePin(e)}
          moduleName={'Edit Price tag'}
          CurrentRole={currentRole}
          employeeID={employeeId}
          getprofileaccessResponse={profileDetails}
        ></SecondFactor>

        : null
      }

      {/* Second Factor For Price Exception */}
      {SecondFactorEnabled === true ? (
        <TwoFactorComponent
          setTwoFactorCancelClick={twoFactorCancel}
          setTwoFactorCompleted={twoFactorCompleted}
          CurrentRole={currentRole}
          minRole={SLAMinRole}
          throwCurrentPage="addedItem"
          agrRate={Number(priceExcep.rate) != Number(initialInventoryRate) ? initialInventoryRate : null}
          agrTerm={Number(priceExcep.term) != Number(initialInventoryTerm) ? initialInventoryTerm : null}
        />
      ) : null}

      {/* Asking SLA confirmation popup */}
      <RACModalCard
        isOpen={needSLA.needSLAController}
        maxWidth="xs"
        borderRadius={'25px !important'}
      //children={enableZeroValidatediv()}
      >
        {SLAModal(thresHoldExceptionMessage)}
      </RACModalCard>
      {/* Price Exception needs greater than 0 popup */}
      <RACModalCard
        isOpen={enableZeroValidate}
        maxWidth="xs"
        borderRadius={'25px !important'}
      >
        {invalidRateDiv('Invalid rate. Rate should be greater than $0.00')}
      </RACModalCard>
      {/* Inve Rate edit confirmation popup */}
      <RACModalCard
        isOpen={openRateAlertModal}
        maxWidth={'sm'}
        borderRadius="25px !important"
        //eslint-disable-next-line react/no-children-prop
        children={openRateAlert()}
      ></RACModalCard>
      {/* Invalid Term Popup */}
      <RACModalCard
        isOpen={invalidTermPopup}
        maxWidth={'sm'}
        borderRadius="25px !important"
        //eslint-disable-next-line react/no-children-prop
        children={openTermAlert()}
      ></RACModalCard>
      {/* Invalid Term user popup */}


      {/**Username Password PopUP */}
      {validPopUpType.secondLevelAuth === true ?
        <SecondFactorPassWord
          setTwoFactorCancelClick={() => { setvalidationPopUp(false), setvalidPopUpType({ ...validPopUpType, secondLevelAuth: false }), setpriceExcepPopUp(true) }}
          setTwoFactorCompleted={(e: any) => SLACompleted(e)}
          moduleName={'Edit PriceTag by Exception'}
          CurrentRole={currentRole}
        />

        : null
      }

      <RACModalCard
        title='&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Price Exception '
        className={`${classes.priceExceptionModalWidth}`}
        isOpen={priceExcepPopUp}
        maxWidth="xs"
        borderRadius={'20px !important'}
        children={openPriceExceptionPopUp()}
      />


      {/**printPriceTag Tab entire Screen */}
      <div id="printPriceTagDoc" style={{ display: 'none' }}>
        <PrintPriceTagDocument
          header={validateData(priceTagInfo) ? validateData(priceTagInfo.headerDetails) ? priceTagInfo.headerDetails : {} : {}}
          priceDetails={priceTags}
          invenDetails={headerContext}
          storeDetail={storeCity}
        />
      </div>

      {/**singleItemPriceTag */}
      {
        singleItemVisble === true ?
          <div id="singleItemPriceTag" ref={singlePriceTag} >
            <PrintFunction
              printItemNo={[headerContext.inventoryNumber]}
              printLanguage=''
              printItemResp={singleItemPrice}
              invRefCodes={invRefCodes} />
          </div> : null
      }

      {
        singleItemNYandCA === true ?
          <div id="singleItemPriceTag" ref={singlePriceTag} >
            <SIpricetagNYCA
              printItemNo={[headerContext.inventoryNumber]}
              printLanguage=''
              printItemResp={singleItemPrice}
              invRefCodes={invRefCodes} />
          </div> : null
      }


    </React.Fragment>

  )
}


