/* eslint-disable */
export function removeColumnHeader(inputArray: any, columnId: any, deleteItems: any) {
    const inputArr = JSON.parse(JSON.stringify(inputArray))

    if ((inputArr.findIndex(((item: any) => item.columnId == columnId))) >= 0) {
        inputArr.splice(inputArr.findIndex(((item: any) => item.columnId == columnId)), deleteItems);
        return inputArr
    }
    return inputArr
}

export function transformConfigDetails(configDetails: any[]): { [key: string]: string } {
    return configDetails.reduce((acc, item) => {
        if (item.paramKeyName && item.paramValue !== undefined) {
            acc[item.paramKeyName] = item.paramValue;
        }
        return acc;
    }, {});
}