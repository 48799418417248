/* eslint-disable prettier/prettier */
/* eslint-disable sonarjs/no-redundant-boolean */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
import {
  RACTableCell,
  RACTableRow,
  RACTable,
  Grid,
  RACButton,
  RACCheckBox,
  Typography,
  CircularProgress,
} from '@rentacenter/racstrap';
import React, { useContext, useEffect, useState } from 'react';
import { getPrintEditPricetagGrid } from '../../api/user'
import { ReactComponent as SortdownIcon } from '../../assets/images/sort-down-icon.svg';
import { ReactComponent as SortupIcon } from '../../assets/images/sort-up-icon.svg';
import { ReactComponent as AlertIcon } from '../../assets/images/no-records-found.svg';
import { ReactComponent as NoRecord } from '../../assets/images/No-records.svg';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
  InventoryMajorContext,
} from '../../context/inventoryMajorContext';

// import { fixedGridStyles } from './Styles/fixedGridStyles';
import { globalStyles } from '../componentstyles/globalstyles';
import { fixedGridStyles } from '../componentstyles/fixedGridStyles';
import moment from 'moment';
import { convertDate } from '../../constants/CommonMethods';
import { ADD_ON_PRICING } from '../../constants/constants';
export interface dynamicProps {
  id?: string;
  className?: string;
  items: any;
  columns: columns[];
  orderBy: string;
  order: string;
  isSelectAll?: boolean;
  isPrint?: boolean;
  statusCode?: boolean;
  gridtype:
  | 'Normal'
  | 'withSubChild'
  | 'withCheckBox'
  | 'withRadioBtn'
  | 'endWithCheckBox';
  maxHeight?: '600' | '330' | '400' | '535' | '480' | '390';
  gridAction?: 1 | 2;
  gridActionList?: 'plus' | 'edit' | 'delete' | 'view' | 'process' | 'log';
  noRecordMessage?: string;
  columnColor?: string;
  handleActionType: (e: any) => void;
  handleAnkerType: (e: any, value: any) => void;
  isAction?: boolean;
  handleCheckedAll?: (e: any, dataValues: any, check: boolean) => void;
  handleChecked?: (e: any, index: any, value: any, checkedArray: []) => void;
  isCheckedAll?: boolean;
  isChecked?: boolean;
  setGridValues: (value: any) => void;
  setGridColumns: (value: any) => void;
  gridValues: any;
  gridColumn: any;
  throwStyle: any;
  sorting?: boolean;
  sortRefresh?: any;
  type?: any;
  setOrder: (e: any) => void;
  setOrderBy: (e: any) => void;
}
export interface columns {
  columnId: string;
  sortOrder: string;
  columnTitle: string;
  IsSorted: boolean;
  IsSortable: boolean;
  columnType?: 'link' | 'rate';
  IsSortAmount?: boolean;
  elipse?: boolean;
  IsCheckbox?: boolean;
  handleColumnClick1?: () => void;
  handleColumnClick2?: () => void;
  subColumns?: columns[];
  index?: string;
  isAction?: boolean;
}
export const sortDirection = {
  ASC: 'asc',
  DESC: 'desc',
};

export interface IDynamicPageState {
  gridValues: [];
  gridColumn: columns[] | [];
}

export const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

const DynamicGridComponent = (props: dynamicProps) => {
  const classes = globalStyles();
  const fixedGridClassName = fixedGridStyles();
  const [checkedAl, setcheckedAl] = useState<any>([]);
  const [checkedAll, setcheckedAll] = useState<any>(false);
  const [scrollset, setScrollset] = useState<number>(1);
  const [loader, setloader] = useState<boolean>(false);
  const [hasMore, sethasMore] = useState<boolean>(true);
  const {
    featureFlagDetails,
  } = useContext(InventoryMajorContext);
  useEffect(() => {
    const { order, orderBy, items, columns, sorting } = props;
    // console.log('In Grid Component', props.items.length);
    //   const checkedArray = new Array(items.length).fill(false);
    //   setcheckedAl(checkedArray);
    //   console.log('checkedArray', checkedArray);
    if (items !== undefined) {
      if (!sorting) {
        const sortedData =
          order == sortDirection.DESC
            ? items.sort((a: any, b: any) => (a[orderBy] < b[orderBy] ? -1 : 1))
            : items.sort((a: any, b: any) => (b[orderBy] < a[orderBy] ? -1 : 1));

        props.setGridValues(sortedData);
      }
      else {
        props.setGridValues(items);

      }
      props.setGridColumns(columns);
    }
  }, [props]);

  useEffect(() => {
    const { order, orderBy, items, columns } = props;
    // console.log('In Grid Component', props.items.length);
    if (items !== undefined) {
      const checkedArray = new Array(items.length).fill(false);
      setcheckedAl(checkedArray);
      console.log('checkedArray', checkedArray);
    }
    setcheckedAll(false);

  }, [props.items]);
  const handleChecked = (event: any, index: any, value: any) => {
    // const { value } = event.target;
    console.log('event', event);

    const TempArr = checkedAl;
    const UpdateArr = TempArr.map((item: any, position: any) =>
      index === position ? !item : item
    );

    console.log('UpdateArr', UpdateArr);
    if (UpdateArr.includes(false) === true) {
      setcheckedAll(false);
    }
    else {
      setcheckedAll(true);
    }
    setcheckedAl(UpdateArr);
    props.handleChecked ? props.handleChecked(event, index, value, UpdateArr) : null;
    // if(checkedAl[index]===true)
    // {
    //     const ReplaceArr=TempArr.splice(index,1,false)
    //     // TempArr[index]=false;
    //     setcheckedAl(ReplaceArr);
    // }
    // else
    // {
    //     const ReplaceArr=TempArr.splice(index,1,true)
    //     //TempArr[index]=true;
    //     setcheckedAl(ReplaceArr);
    // }

    // if(event.target.checked){
    // checkedAl.map((value:any,index:any)=>{

    // })
    // setcheckedAl(checkedArray1)}
    // else{
    //     checkedAl.map((value:any,index:any)=>{
    //         checkedArray1.push(false)
    //     })
    //     setcheckedAl(checkedArray1)
    // }
  };

  const handleCheckedAll = (event: any, dataValues: any) => {
    console.log(checkedAl);
    if (!checkedAll) {
      setcheckedAll(true);
      const checkedArray: any = props.items.map((item: any, position: any) => {
        return true;
      });
      // const checkedArray = new Array(props.items.length).fill(true)
      setcheckedAl(checkedArray);
      props.handleCheckedAll ? props.handleCheckedAll(event, dataValues, checkedAll) : null;
    } else {
      setcheckedAll(false);

      //const checkedArray = new Array(props.items.length).fill(false)
      const checkedArray: any = props.items.map((item: any, position: any) => {
        return false;
      });
      setcheckedAl(checkedArray);
      props.handleCheckedAll ? props.handleCheckedAll(event, dataValues, checkedAll) : null;
    }
  };

  const buildGridHeader = (dataColumns: any, dataValues: any) => {
    return dataColumns.map((value: any, index: any) => {
      console.log('val', value);
      console.log('index', index);

      return (
        <>
          {value.IsCheckbox === true ? (
            <RACTableCell className={classes.p2}>
              <Grid>
                <RACCheckBox
                  checked={checkedAll}
                  name="CheckAll"
                  disabled={dataValues?.length > 0 ? false : true}
                  onClick={(e) => handleCheckedAll(e, dataValues)}
                />
              </Grid>
            </RACTableCell>
          ) : null}
          {/* <RACTableCell key={index} className={classes.p2}> */}
          <RACTableCell key={index} className={props.id == 'TransferReceive' || props.id == 'PrintPriceTags' || props.id == 'PackagePriceTag' ? `${classes.p1} ${classes.fs7}` : `${classes.p2}`}>
            {value.IsSortable ? (
              <a
                style={{
                  color: 'black',
                  textDecoration: 'none',
                  cursor: 'pointer',
                }}
                id={value.columnId}
                onClick={() =>
                  value.IsSortAmount === true
                    ? sortArrayForDollar(
                      value.columnId,
                      index,
                      dataColumns,
                      dataValues
                    )
                    : sortArray(value.columnId, index, dataColumns, dataValues)
                }
              >
                {value.columnTitle + '  '}
                {value.IsSorted === true ? (
                  value.sortOrder == sortDirection.DESC ? (
                    <SortupIcon />
                  ) : (
                    <SortdownIcon />
                  )
                ) : null}
              </a>
            ) : (
              <a
                style={{
                  color: 'black',
                  textDecoration: 'none',
                }}
                id={value.columnId}
              >
                {value.columnTitle + '  '}
              </a>
            )}
          </RACTableCell>

          {props.isAction === true ? (
            index == dataColumns.length - 1 ? (
              <RACTableCell className={classes.p2}>Action</RACTableCell>
            ) : null
          ) : null}
        </>
      );
    });
  };

  const buildGridBody = (gridBodyData: any, gridColumns: any) => {
    const updateStyle =
      props.gridActionList == 'edit' ||
        props.gridActionList == 'delete' ||
        props.gridActionList == 'view' ||
        props.gridActionList == 'process' ||
        props.gridActionList == 'log'
        ? 'form-check-input2'
        : 'form-check-input1';
    console.log('gridBodyData', gridBodyData);
    if (gridBodyData !== undefined && gridBodyData.length > 0) {

      return gridBodyData.map((value: any, index1: any) => {
        return (
          <React.Fragment key={index1}>
            <RACTableRow key={index1} className={classes.bgTransparent}>
              {props.gridtype == 'withCheckBox' ? (
                <RACTableCell
                  className={`${classes.fs6} ${classes.fontSemiBold} ${classes.p2}`}
                >
                  <Grid>
                    <RACCheckBox
                      checked={checkedAl[index1] === true ? true : false}
                      value={value}
                      name="Checkbox"
                      onClick={(e) =>
                        handleChecked(e, index1, gridBodyData[index1])
                      }
                    />
                  </Grid>
                </RACTableCell>
              ) : null}

              {props.gridtype == 'withRadioBtn' &&
                (props.isSelectAll === true || props.isSelectAll == undefined) ? (
                <Grid className="form-check">
                  <input
                    className={updateStyle}
                    type="radio"
                    name="flexRadioDefault"
                    id=""
                  />
                </Grid>
              ) : null}

              {buildGridItems(gridColumns, value)}

              {props.gridActionList !== undefined ? (
                props.gridActionList == 'plus' ? (
                  <RACTableCell>
                    {/* <img className="cursor-pointer" src={ItemAddition} alt="Add-icon"  onClick={()=>props.handleActionType("Activated")}/> */}
                  </RACTableCell>
                ) : (
                  <RACTableCell
                    className={`${classes.fs6} ${classes.fontSemiBold} ${classes.p2}`}
                  >
                    <RACButton
                      className={classes.actionBtn}
                      onClick={() =>
                        props.handleActionType
                          ? props.handleActionType(value)
                          : null
                      }
                    >
                      {props.gridActionList == 'edit'
                        ? 'Edit'
                        : props.gridActionList == 'delete'
                          ? 'Delete'
                          : props.gridActionList == 'view'
                            ? 'View'
                            : props.gridActionList == 'process'
                              ? 'Process'
                              : props.gridActionList == 'log'
                                ? 'Log'
                                : null}
                    </RACButton>
                  </RACTableCell>
                )
              ) : null}
              {props.gridtype == 'endWithCheckBox' ? (
                <Grid className="form-check">
                  <input
                    className="form-check-input1"
                    type="checkbox"
                    value=""
                  />
                </Grid>
              ) : null}
            </RACTableRow>
          </React.Fragment>
        );
      });
    }
    else {
      const length = gridColumns.length + 1;
      return (
        <RACTableRow className={classes.hiddenRow} key="0">
          <RACTableCell colSpan={length} className={`${classes.textCenter} ${classes.mt4}`}>
            <Grid item md={12} className={classes.textCenter}>
              <NoRecord></NoRecord>
              <Typography className={classes.RACPOPMsg}>
                No Records Found
              </Typography>
            </Grid>
          </RACTableCell>
        </RACTableRow>

      );
    }

  };
  const truncString = (str: any, max: number, add: any) => {
    add = add || '...';
    return typeof str === 'string' && str.length > max
      ? str.substring(0, max) + add
      : str;
  };

  const sortArray = (
    columnId: string,
    index: any,
    datacolumn: any,
    dataValues: any
  ) => {
    try {
      const sortedData =
        datacolumn[index].sortOrder == sortDirection.DESC
          ? dataValues.sort((a: any, b: any) =>
            b[columnId] < a[columnId] ? -1 : 1
          )
          : dataValues.sort((a: any, b: any) =>
            a[columnId] < b[columnId] ? -1 : 1
          );
      const reorderColumns: columns[] = datacolumn;
      reorderColumns[index].sortOrder =
        reorderColumns[index].sortOrder == sortDirection.ASC
          ? sortDirection.DESC
          : sortDirection.ASC;
      reorderColumns.map((value: any, index1: any) => {
        reorderColumns[index1].IsSorted = index == index1 ? true : false;
      });
      // this.setState({ gridValues: sortedData, gridColumn: reorderColumns })
      props.setOrder(
        datacolumn[index].sortOrder == sortDirection.DESC
          ? sortDirection.ASC
          : sortDirection.DESC
      );
      props.setOrderBy(columnId);
      props.sortRefresh([])
      props.setGridValues([...sortedData]);
      props.setGridColumns([...reorderColumns]);
    } catch (ex) {
      console.log(ex);
    }
  };
  const sortArrayForDollar = (
    columnId: string,
    index: number,
    datacolumn: any,
    dataValues: any
  ) => {
    const sortedData =
      datacolumn[index].sortOrder == sortDirection.DESC
        ? dataValues.sort((a: any, b: any) => b[columnId] - a[columnId])
        : dataValues.sort((a: any, b: any) => a[columnId] - b[columnId]);
    const reorderColumns: columns[] = datacolumn;
    reorderColumns[index].sortOrder =
      reorderColumns[index].sortOrder == sortDirection.ASC
        ? sortDirection.DESC
        : sortDirection.ASC;
    reorderColumns.map((value: any, index1: number) => {
      reorderColumns[index1].IsSorted = index == index1 ? true : false;
    });
    // props.setOrder(
    //   datacolumn[index].sortOrder == sortDirection.DESC
    //     ? sortDirection.ASC
    //     : sortDirection.DESC
    // );
    // props.setOrderBy(columnId);
    props.setGridValues([...sortedData]);
    props.setGridColumns([...reorderColumns]);
    // setState({ gridValues: sortedData, gridColumn: reorderColumns });
  };
  // const sortArrayForDate = (
  //   columnId: string,
  //   index: number,
  //   datacolumn: any,
  //   dataValues: any
  // ) => {
  //   const sortedData =
  //     datacolumn[index].sortOrder == sortDirection.DESC
  //       ? dataValues.sort(
  //           (a: any, b: any) =>
  //             parseInt(moment(b[columnId]).format('YYYYMMDD')) -
  //             parseInt(moment(a[columnId]).format('YYYYMMDD'))
  //         )
  //       : dataValues.sort(
  //           (a: any, b: any) =>
  //             parseInt(moment(a[columnId]).format('YYYYMMDD')) -
  //             parseInt(moment(b[columnId]).format('YYYYMMDD'))
  //         );
  //   const reorderColumns: columns[] = datacolumn;
  //   reorderColumns[index].sortOrder =
  //     reorderColumns[index].sortOrder == sortDirection.ASC
  //       ? sortDirection.DESC
  //       : sortDirection.ASC;
  //   reorderColumns.map((value: any, index1: number) => {
  //     reorderColumns[index1].IsSorted = index == index1 ? true : false;
  //   });
  //   props.setOrder(
  //     datacolumn[index].sortOrder == sortDirection.DESC
  //       ? sortDirection.ASC
  //       : sortDirection.DESC
  //   );
  //   props.setOrderBy(columnId);
  //   props.setGridValues([...sortedData]);
  //   props.setGridColumns([...reorderColumns]);
  //   // setState({ gridValues: sortedData, gridColumn: reorderColumns });
  // };
  const buildGridItems = (gridColumns: any, data: any) => {
    return gridColumns.map((val: any, index2: any) => {
      return data[val.columnId] !== null &&
        data[val.columnId] !== undefined &&
        data[val.columnId] !== '' ? (
        <RACTableCell
          key={index2}
          // className={`${classes.fs6} ${classes.fontSemiBold} ${classes.p2} ${classes.cursorDefault}`}
          // className={`${classes.fs6} ${classes.fontSemiBold} ${classes.p2}`}
          className={props.id == 'TransferReceive' || props.id == 'PrintPriceTags' || props.id == 'PackagePriceTag' ? ` ${classes.fs8}  ${classes.textMildGrey} ${classes.makeBold}` : `${classes.fs6} ${classes.fontSemiBold} ${classes.p2}`}

          title={
            data[val.columnId] !== null && data[val.columnId] !== undefined
              ? data[val.columnId].length > 10
                ? data[val.columnId]
                : null
              : null
          }
        >
          {val.columnType == 'link' ? (
            <a
              onClick={() =>
                props.handleAnkerType(data[val.columnId], val.columnId)
              }
              className={`${classes.racpadLink}`}
            >
              {data[val.columnId]}
            </a>
          ) : val.columnType == 'rate' ? (
            formatter.format(data[val.columnId])
          ) : val.elipse === true ? (
            (props.id == 'TransferReceive' ? truncString(data[val.columnId], 35, '...') : truncString(data[val.columnId], 10, '...'))
          ) : val.columnId == 'purchaseDate' ? (
            convertDate(data[val.columnId], "MM / DD / YYYY")
          ) : props.id == 'primaryItemNumber' && val.columnId == 'depreciation' ?

            (data[val.columnId] + "%")
            : props.id == 'PackagePriceTag' && val.columnId == 'depreciation' ?

              (data[val.columnId] + "%")
              :
              props.id == 'PackagePriceTag' && val.columnId == 'packageRate' ?

                ("$ " + data[val.columnId])
                : featureFlagDetails[ADD_ON_PRICING] == '1' && val.columnId == 'weeklyAddOnRate' ?
                  ("$" + data[val.columnId])
                  : (
                    data[val.columnId]
                  )}
        </RACTableCell>
      ) : (
        <RACTableCell key={index2}>-</RACTableCell>
      );
    });
  };
  const nextscroll = async () => {
    setScrollset(scrollset + 1);
    setloader(true);
    const response = await getPrintEditPricetagGrid(window.sessionStorage.getItem('storeNumber'), scrollset + 1);
    setloader(false);

    response.data.response.forEach((value: any) => {

      switch (value.conditionDescEn) {
        case "Term pushed up/down by Coworker":
          value.conditionDescEn = 'Exception';
          break;
        case "Based on DOR-system push":
          value.conditionDescEn = 'Auto';
          break;
        case "New - Full Term":
          value.conditionDescEn = 'New';
          break;
      }

      value.rate = parseFloat((value.rate));
      value.turns = parseFloat((value.turns));
      value.depreciationPercentage = parseFloat((value.depreciationPercentage));
    })
    const val = response.data.response;
    console.log('valresponse', val);
    props.setGridValues([...props.gridValues, ...val]);
    if (val.length === 0) {
      sethasMore(false);
    }
    console.log('mainResponse', props.gridValues);
  };
  // if (!!props.gridValues && props.gridValues.length > 0) {
  if (props.type == 'printEditPriceTag') {
    return (<Grid className={`${fixedGridClassName.fixTableHead} ${fixedGridClassName.fixTableHeight400}`}>
      {props?.gridValues?.length > 7 ? (
        <InfiniteScroll
          dataLength={props.gridValues.length} //This is important field to render the next data
          next={nextscroll}
          hasMore={hasMore}
          height={400}
          loader={
            loader ? (
              <Grid className={classes.GridLoader}>
                <CircularProgress></CircularProgress>
              </Grid>
            ) : null
          }
        >
          <RACTable
            renderTableHead={() => buildGridHeader(props.gridColumn, props.gridValues)}
            renderTableContent={() =>
              buildGridBody(props.gridValues, props.gridColumn)
            }
            stickyHeader
          />
        </InfiniteScroll>
      ) : (
        <RACTable
          renderTableHead={() => buildGridHeader(props.gridColumn, props.gridValues)}
          renderTableContent={() => buildGridBody(props.gridValues, props.gridColumn)}
        />
      )}
    </Grid>)
  }
  else {
    return (
      <Grid
        className={`${fixedGridClassName.fixGrid} ${props.maxHeight == '600'
          ? fixedGridClassName.fixTableHeight600
          : props.maxHeight == '330'
            ? fixedGridClassName.fixTableHeight330
            : props.maxHeight == '400'
              ? fixedGridClassName.fixTableHeight400
              : props.maxHeight == '535'
                ? fixedGridClassName.fixTableHeight535
                : props.maxHeight == '480' ?
                  fixedGridClassName.fixTableHeight480
                  : props.maxHeight == '390' ?
                    fixedGridClassName.fixTableHeight390
                    : null
          } `}
      >
        <RACTable
          renderTableHead={() =>
            buildGridHeader(props.gridColumn, props.gridValues)
          }
          renderTableContent={() =>
            buildGridBody(props.gridValues, props.gridColumn)
          }
        />
      </Grid>
      //     <RACTableCell>
      //         <RACTableRow>

      //             { props.gridtype == "withCheckBox" && ( props.isSelectAll === true || props.isSelectAll == undefined ) ?
      //                 <RACTableCell>
      //                     <input className="form-check-input1 test1" type="checkbox" value="" />
      //                 </RACTableCell>
      //             : null }

      //              { props.gridtype == "withRadioBtn" && ( props.isSelectAll === true || props.isSelectAll == undefined ) ?
      //                 <RACTableCell/>
      //             : null }

      //             {buildGridHeader(gridColumn, gridValues)}
      //         </RACTableRow>
      //     </RACTableCell>

      //         {buildGridBody(gridValues, gridColumn)}

      // </RACTable>
    );
  }
  // }
  //  else if (props.statusCode == true) {
  //   return (
  //     <React.Fragment>
  //       <Typography className={classes.textCenter}>
  //         Something went Wrong
  //       </Typography>
  //     </React.Fragment>
  //   );
  // } else {
  //   return (
  //     <Grid className={`${classes.textCenter} ${classes.mt5}`}>
  //       <NoRecord />
  //       <Typography className={`${classes.subTitle} ${classes.mb3}`}>
  //         No Records Found
  //       </Typography>
  //     </Grid>
  //   );
  // }
};

export default DynamicGridComponent;
