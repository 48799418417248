/* eslint-disable prettier/prettier */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable sonarjs/no-identical-functions */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/react-in-jsx-scope */
import {
  Card,
  CardContent,
  Grid,
  RACTable,
  Typography,
  RACTableCell,
  RACTableRow,
  RACButton,
  ListItem,
  List,
  CircularProgress,
  RACModalCard,
} from '@rentacenter/racstrap';
import { useEffect, useState, useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { ReactComponent as SomethingwentwrongIcon } from '../../assets/images/no-records-found.svg';
import { ReactComponent as NoRecordFoundIcon } from '../../assets/images/No-records.svg';
import { ReactComponent as ErrorMsgIcon } from '../../assets/images/error-icon.svg';
import { InventoryGlobalStyles } from '../../components/InventorySearch/Styles/InventoryGlobalStyles';
import { getInventoryAuditExplanation } from '../../api/user';
import { InventoryAuditContext } from '../../context/InventoryAuditContext';
import { fixedGridStyles } from '../componentstyles/fixedGridStyles';
interface params {
  inventorynumber: string;
  auditid: string;
}
export default function InventoryAuditExplanation() {
  // eslint-disable-next-line prettier/prettier
  const history = useHistory();
  const { inventorynumber, auditid } = useParams<params>();
  const FGS = fixedGridStyles();
  const urlParams = new URLSearchParams(window.location.search);
  const auditStatusFlag = urlParams.get('status');
  const pageInfo = urlParams.get('page');
  const classes = InventoryGlobalStyles();
  const [itemInfoBody, setItemInfoBody] = useState<any>([]);
  const [lastScanAuditInfoBody, setLastScanAuditInfoBody] = useState<any>([]);
  const [tagInfoBody, setTagInfoBody] = useState<any>([]);
  const [exceptionInfoBody, setExceptionInfoBody] = useState<any>([]);
  const [inventoryAuditsInfo, setInventoryAuditsInfo] = useState<any>([]);
  const [comment, setComment] = useState<any>('');
  const [itemInfoHyphen, setItemInfoHyphen] =
    useState<any>(false);
  const [lastScanAuditHyphen, setLastScanAuditHyphen] =
    useState<any>(false);
  const [exceptionInfoHyphen, setExceptionInfoHyphen] =
    useState<any>(false);
  const [tagInfoHyphen, setTagInfoHyphen] =
    useState<any>(false);
  const [
    inventoryAuditsInfoNoRecordsFound,
    setInventoryAuditsInfoNoRecordsFound,
  ] = useState<any>(false);
  const [emptyGridInventoryAuditInfo, setEmptyGridInventoryAuditInfo] =
    useState<any>(false);
  const [errorMsg, setErrorMsg] = useState<any>('');
  const [errorMsgPopup, setErrorMsgPopup] = useState<any>(false);
  const [somethingWentWrong, setSomethingWentWrong] = useState<any>(false);
  const {
    hiddenLoader,
    setHiddenLoader,
    viewArray,
    itemNotScannedBody,
    setItemNotScannedBody,
    itemwithExceptionBody,
    setItemwithExceptionBody,
    itemNeedAttentionBody,
    setItemNeedAttentionBody,
    tabsShow,
    setTabsShow,
    colors,
    setColors,
    explanationStoring,
    setExplanationStoring,
  } = useContext(InventoryAuditContext);
  const ItemInfoHeadArray = [
    { ItemHead: 'Item #' },
    { ItemHead: 'Department' },
    { ItemHead: 'Sub Department' },
    { ItemHead: 'Bracket' },
    { ItemHead: 'Brand' },
    { ItemHead: 'Model' },
    { ItemHead: 'Serial' },
  ];
  const lastScanAuditInfoHeadArray = [
    { ItemHead: 'Audit ID' },
    { ItemHead: 'Date Scanned' },
    { ItemHead: 'Time Scanned' },
    { ItemHead: 'Scan Area' },
  ];
  const TagInfoHeadArray = [
    { ItemHead: 'Price Tag Sequence' },
    { ItemHead: 'Barcode Content' },
  ];
  const ExceptionInfoHeadArray = [
    { ItemHead: 'Exception' },
    { ItemHead: 'Additional Information' },
    { ItemHead: 'Action Needed' },
  ];
  const inventoryAuditsInfoHeadArray = [
    { ItemHead: 'Activity Date' },
    { ItemHead: 'Coworker' },
    { ItemHead: 'Comments' },
    { ItemHead: 'Scan Area' },
  ];
  useEffect(() => {
    if (auditStatusFlag == '1') {
      InventoryAuditExplanation();
    } else if (itemwithExceptionBody?.length > 0 || itemNotScannedBody?.length > 0) {
      InventoryAuditExplanation();
    }
    else {
      history.push({
        pathname: `/inventory/audit/info/${auditid}`
      });
    }
  }, []);
  const InventoryAuditExplanation = async () => {
    setHiddenLoader(true);
    const explanationResponse: any = await getInventoryAuditExplanation(
      inventorynumber
    );
    setHiddenLoader(false);
    if (explanationResponse?.status == 200) {
      const itemInfoResponse = explanationResponse?.data?.itemInfo;
      itemInfoResponse.length !== 0 ?
      setItemInfoBody(itemInfoResponse):
      setItemInfoHyphen(true);
      const lastScanAuditInfoResponse =
        explanationResponse?.data?.lastScannedAuditInfo ;
        lastScanAuditInfoResponse.length !== 0?
      setLastScanAuditInfoBody(lastScanAuditInfoResponse):
      setLastScanAuditHyphen(true);
      const tagInfoResponse = explanationResponse?.data?.tagInfo;
      tagInfoResponse.length !== 0?
      setTagInfoBody(tagInfoResponse):
      setTagInfoHyphen(true);
      const exceptionInfoResponse = explanationResponse?.data?.exceptionInfo;
      exceptionInfoResponse.length !== 0 ?
       setExceptionInfoBody(exceptionInfoResponse):
       setExceptionInfoHyphen(true);
      const inventoryAuditsInfoResponse =
        explanationResponse?.data?.inventoryAuditsInformation;
      inventoryAuditsInfoResponse?.length>0
        ? setInventoryAuditsInfo(inventoryAuditsInfoResponse)
        : (setInventoryAuditsInfoNoRecordsFound(true),
          setEmptyGridInventoryAuditInfo(true));
    } else if (explanationResponse?.status == 400) {
      setErrorMsgPopup(true);
      setErrorMsg(explanationResponse?.data?.errors[0]?.error);
      console.log('explanationResponse', explanationResponse);
    } else {
      setSomethingWentWrong(true);
    }
  };
  const itemInfoHeadFnc = () => (
    <>
      {ItemInfoHeadArray?.map((obj: any, index: any) => {
          return <RACTableCell key={index}>{obj.ItemHead}</RACTableCell>;
        })}
    </>
  );
  const itemInfoBodyFnc = () => (
    <>
    {itemInfoHyphen ? (
      <>
        <RACTableRow style={{ background: 'none' }}>
          <RACTableCell>
            <span style={{ marginLeft: '11%' }}>-</span>
          </RACTableCell>
          <RACTableCell>
            <span style={{ marginLeft: '11%' }}>-</span>
          </RACTableCell>
          <RACTableCell>
            <span style={{ marginLeft: '11%' }}>-</span>
          </RACTableCell>
          <RACTableCell>
            <span style={{ marginLeft: '11%' }}>-</span>
          </RACTableCell>
          <RACTableCell>
            <span style={{ marginLeft: '11%' }}>-</span>
          </RACTableCell>
          <RACTableCell>
            <span style={{ marginLeft: '11%' }}>-</span>
          </RACTableCell>
          <RACTableCell>
            <span style={{ marginLeft: '11%' }}>-</span>
          </RACTableCell>
        </RACTableRow>
      </>
    ) : (
        <>
          {itemInfoBody?.map((obj: any, index: any) => {
            return (
              <RACTableRow key={index} style={{ background: 'none' }}>
                <RACTableCell style={{ width: '15%' }}>
                  {obj.inventoryNumber ? obj.inventoryNumber : <span style={{ marginLeft: '11%' }}>-</span>}
                </RACTableCell>
                <RACTableCell style={{ width: '15%' }}>
                  {obj.department ? obj.department : <span style={{ marginLeft: '11%' }}>-</span>}
                </RACTableCell>
                <RACTableCell style={{ width: '16%' }}>
                  {obj.subDepartment ? obj.subDepartment : <span style={{ marginLeft: '11%' }}>-</span>}
                </RACTableCell>
                <RACTableCell style={{ width: '16%' }}>
                  {obj.bracket ? obj.bracket : <span style={{ marginLeft: '11%' }}>-</span>}
                </RACTableCell>
                <RACTableCell style={{ width: '15%' }}>
                  {obj.brand ? obj.brand : <span style={{ marginLeft: '11%' }}>-</span>}
                </RACTableCell>
                <RACTableCell style={{ width: '15%' }}>
                  {obj.modelNumber ? obj.modelNumber : <span style={{ marginLeft: '11%' }}>-</span>}
                </RACTableCell>
                <RACTableCell style={{ width: '15%' }}>
                  {obj.serialNumber ? obj.serialNumber : <span style={{ marginLeft: '11%' }}>-</span>}
                </RACTableCell>
              </RACTableRow>
            );
          })}
        </>
         )}
         </>
  );

  const lastScanHeadFnc = () => (
    <>
      {lastScanAuditInfoHeadArray?.map((obj: any, index: any) => {
          return <RACTableCell key={index}>{obj.ItemHead}</RACTableCell>;
        })}
    </>
  );
  const lastScanBodyFnc = () => (
    <>
    {lastScanAuditHyphen ? (
      <>
        <RACTableRow style={{ background: 'none' }}>
          <RACTableCell>
            <span style={{ marginLeft: '11%' }}>-</span>
          </RACTableCell>
          <RACTableCell>
            <span style={{ marginLeft: '11%' }}>-</span>
          </RACTableCell>
          <RACTableCell>
            <span style={{ marginLeft: '11%' }}>-</span>
          </RACTableCell>
          <RACTableCell>
            <span style={{ marginLeft: '11%' }}>-</span>
          </RACTableCell>
        </RACTableRow>
      </>
        ) : (
        <>
          {lastScanAuditInfoBody?.map((obj: any, index: any) => {
            return (
              <RACTableRow key={index} style={{ background: 'none' }}>
                <RACTableCell>
                  {obj.auditId ? obj.auditId : <span style={{ marginLeft: '11%' }}>-</span>}
                </RACTableCell>
                <RACTableCell>
                  {obj.dateScanned ? obj.dateScanned : <span style={{ marginLeft: '11%' }}>-</span>}
                </RACTableCell>
                <RACTableCell>
                  {obj.timeScanned ? obj.timeScanned : <span style={{ marginLeft: '11%' }}>-</span>}
                </RACTableCell>
                <RACTableCell>
                  {obj.scanArea ? obj.scanArea : <span style={{ marginLeft: '11%' }}>-</span>}
                </RACTableCell>
              </RACTableRow>
            );
          })}
        </>
         )}
         </>
  );
  const tagInfoHeadFnc = () => (
    <>
      {TagInfoHeadArray?.map((obj: any, index: any) => {
          return <RACTableCell key={index}>{obj.ItemHead}</RACTableCell>;
        })}
    </>
  );
  const tagInfoBodyFnc = () => (
    <>
    {tagInfoHyphen ? (
      <>
        <RACTableRow style={{ background: 'none' }}>
          <RACTableCell>
            <span style={{ marginLeft: '19%' }}>-</span>
          </RACTableCell>
          <RACTableCell>
            <span style={{ marginLeft: '18%' }}>-</span>
          </RACTableCell>
        </RACTableRow>
      </>
    ) : (
        <>
          {tagInfoBody?.map((obj: any, index: any) => {
            return (
              <RACTableRow key={index} style={{ background: 'none' }}>
                <RACTableCell>{obj.priceTag ? obj.priceTag : <span style={{ marginLeft: '19%' }}>-</span>}</RACTableCell>
                <RACTableCell>{obj.barcodeContent ? obj.barcodeContent : <span style={{ marginLeft: '18%' }}>-</span>}</RACTableCell>
              </RACTableRow>
            );
          })}
        </>
         )}
         </>
  );

  const exceptionInfoHeadFnc = () => (
    <>
      {ExceptionInfoHeadArray?.map((obj: any, index: any) => {
          return <RACTableCell key={index}>{obj.ItemHead}</RACTableCell>;
        })}
    </>
  );
  const exceptionInfoBodyFnc = () => (
    <>
      {exceptionInfoHyphen ? (
        <>
          <RACTableRow style={{ background: 'none' }}>
            <RACTableCell>
              <span style={{ marginLeft: '14%' }}>-</span>
            </RACTableCell>
            <RACTableCell style={{ marginRight: '80px' }}>
              <span style={{ marginLeft: '21%' }}>-</span>
            </RACTableCell>
            <RACTableCell>
              <span style={{ marginLeft: '20%' }}>-</span>
            </RACTableCell>
          </RACTableRow>
        </>
      ) : (
        <>
          {exceptionInfoBody?.map((obj: any, index: any) => {
            return (
              <RACTableRow key={index} style={{ background: 'none' }}>
                <RACTableCell>
                  {obj.exceptionDescription ? (
                    obj.exceptionDescription
                  ) : (
                    <span style={{ marginLeft: '14%' }}>-</span>
                  )}
                </RACTableCell>
                <RACTableCell style={{ marginRight: '80px' }}>
                  {obj.addtionalInformation ? (
                    obj.addtionalInformation
                  ) : (
                    <span style={{ marginLeft: '21%' }}>-</span>
                  )}
                </RACTableCell>
                <RACTableCell>
                  {obj.actionNeededDescription ? (
                    obj.actionNeededDescription
                  ) : (
                    <span style={{ marginLeft: '20%' }}>-</span>
                  )}
                </RACTableCell>
              </RACTableRow>
            );
          })}
        </>
         )}
         </>
  );
  const inventoryAuditsInfoHeadFnc = () => (
    <>
      {emptyGridInventoryAuditInfo ? null :
        inventoryAuditsInfoHeadArray?.map((obj: any, index: any) => {
          return <RACTableCell key={index}>{obj.ItemHead}</RACTableCell>;
        })}
    </>
  );
  const inventoryAuditsInfoBodyFnc = () => (
    <>
      {inventoryAuditsInfoNoRecordsFound ? (
        <>
          <RACTableRow style={{ background: 'none' }}>
            <RACTableCell style={{ float: 'left', width: '97%', marginTop: '-4%' }}>
              <Grid className={`${classes.textCenter} ${classes.mt5}`}>
                <NoRecordFoundIcon />
                <Typography className={`${classes.subTitle} ${classes.mb3}`}>
                  No records found
                </Typography>
              </Grid>
            </RACTableCell>
          </RACTableRow>
        </>
      ) : (
        <>
          {inventoryAuditsInfo?.map((obj: any, index: any) => {
            return (
              <RACTableRow key={index} style={{ background: 'none' }}>
                <RACTableCell style={{ width: '24%' }}>
                  {obj.activityDate ? obj.activityDate : <span style={{ marginLeft: '7%' }}>-</span>}
                </RACTableCell >
                <RACTableCell style={{ width: '24%' }}>
                  {obj.coWorker ? obj.coWorker : <span style={{ marginLeft: '7%' }}>-</span>}
                </RACTableCell>
                <RACTableCell style={{ width: '28%' }}>
                  {obj.comment ? obj.comment : <span style={{ marginLeft: '8%' }}>-</span>}
                </RACTableCell>
                <RACTableCell style={{ width: '9%' }}>
                  {obj.scanArea ? obj.scanArea : <span style={{ marginLeft: '8%' }}>-</span>}
                </RACTableCell>
              </RACTableRow>
            );
          })}
        </>
      )}
    </>
  );

  const handleContinue = (fieldInfo: any) => {
    if (fieldInfo == '1') {
      console.log("itemNotScannedBody", itemNotScannedBody);
      const tempExplanationStoring = explanationStoring;
      const tempItemNotScannedBody = itemNotScannedBody;
      const updatedView = tempExplanationStoring.findIndex((obj: any) => obj.inventoryNumber === itemNotScannedBody[viewArray].inventoryNumber);

      if (updatedView == -1) {
        tempExplanationStoring.push({
          inventoryNumber: itemNotScannedBody[viewArray].inventoryNumber,
          explanation: comment,
          inventoryAuditDetailId: itemNotScannedBody[viewArray].inventoryAuditDetailId,
        });
      } else {
        tempExplanationStoring[updatedView] = {
          inventoryNumber: itemNotScannedBody[viewArray].inventoryNumber,
          explanation: comment,
          inventoryAuditDetailId: itemNotScannedBody[viewArray].inventoryAuditDetailId,
        };     
      }
      setExplanationStoring([...tempExplanationStoring]);
      tempItemNotScannedBody[viewArray].explanation = comment;
      setItemNotScannedBody([...tempItemNotScannedBody]);
      setTabsShow({
        ...tabsShow,
        ItemNotScannedShow: true,
        ItemScannedWithExceptionShow: false,
        ItemsNeedAttentionShow: false,
      });
      setColors({
        ...colors,
        color1: true,
        color2: false,
        color3: false,
      });
    } else if (fieldInfo == '2') {
      console.log("itemwithExceptionBody", itemwithExceptionBody);
      const tempExplanationStoring = explanationStoring;
      const tempItemwithExceptionBody = itemwithExceptionBody;
      const updatedView = tempExplanationStoring.findIndex((obj: any) => obj.inventoryNumber === itemwithExceptionBody[viewArray].inventoryNumber);

      if (updatedView === -1) {
        tempExplanationStoring.push({
          inventoryNumber: itemwithExceptionBody[viewArray].inventoryNumber,
          explanation: comment,
          inventoryAuditDetailId: itemwithExceptionBody[viewArray].inventoryAuditDetailId,

        });
      } else {
        tempExplanationStoring[updatedView] = {
          inventoryNumber: itemwithExceptionBody[viewArray].inventoryNumber,
          explanation: comment,
          inventoryAuditDetailId: itemwithExceptionBody[viewArray].inventoryAuditDetailId,
        };
      }
      setExplanationStoring([...tempExplanationStoring]);
      tempItemwithExceptionBody[viewArray].explanation = comment;
      setItemwithExceptionBody([...tempItemwithExceptionBody]);
      setTabsShow({
        ...tabsShow,
        ItemNotScannedShow: false,
        ItemScannedWithExceptionShow: true,
        ItemsNeedAttentionShow: false,
      });
      setColors({
        ...colors,
        color1: false,
        color2: true,
        color3: false,
      });
    } else if (fieldInfo == '3') {
      const tempExplanationStoring = explanationStoring;
      const tempitemNeedAttentionBody = itemNeedAttentionBody;
      const updatedView = tempExplanationStoring.findIndex((obj: any) => obj.inventoryNumber === itemNeedAttentionBody[viewArray].inventoryNumber);

      if (updatedView == -1) {
        tempExplanationStoring.push({
          inventoryNumber: itemNeedAttentionBody[viewArray].inventoryNumber,
          explanation: comment,
          inventoryAuditDetailId: itemNeedAttentionBody[viewArray].inventoryAuditDetailId,
        });
      } else {
        tempExplanationStoring[updatedView] = {
          inventoryNumber: itemNeedAttentionBody[viewArray].inventoryNumber,
          explanation: comment,
          inventoryAuditDetailId: itemNeedAttentionBody[viewArray].inventoryAuditDetailId,
        };       
      }
      tempitemNeedAttentionBody[viewArray].explanation = comment;
      setItemNeedAttentionBody([...tempitemNeedAttentionBody]);
      setExplanationStoring([...tempExplanationStoring]);
      setTabsShow({
        ...tabsShow,
        ItemNotScannedShow: false,
        ItemScannedWithExceptionShow: false,
        ItemsNeedAttentionShow: true,
      });
      setColors({
        ...colors,
        color1: false,
        color2: false,
        color3: true,
      });
    }
    console.log('explanationStoring', explanationStoring);
    history.push({
      pathname: `/inventory/audit/info/${auditid}`
    });
  };

  const ErrorMsgfn = () => {
    return (
      <div
        id="initialpayment"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-hidden="true"
      >
        <Grid className={classes.textCenter}>
          <ErrorMsgIcon></ErrorMsgIcon>
          <Typography className={classes.formLabel}></Typography>
          {errorMsg}
        </Grid>
        <Grid
          item
          md={12}
          className={`${classes.mt3} ${classes.pb4} ${classes.textCenter}`}
        >
          <RACButton
            className={classes.mx1}
            variant="contained"
            color="primary"
            onClick={() => {
              setErrorMsgPopup(false);
            }}
          >
            Ok
          </RACButton>
        </Grid>
      </div>
    );
  };
  const somethingWentWrongfn = () => {
    return (
      <div
        id="initialpayment"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-hidden="true"
      >
        <Grid className={classes.textCenter}>
          <SomethingwentwrongIcon />
          <Typography className={classes.formLabel} variant="h5">
            something wentWrong
          </Typography>
        </Grid>
        <Grid
          item
          md={12}
          className={`${classes.mt3} ${classes.pb4} ${classes.textCenter}`}
        >
          <RACButton
            className={classes.mx1}
            variant="contained"
            color="primary"
            onClick={() => {
              setSomethingWentWrong(false);
            }}
          >
            Ok
          </RACButton>
        </Grid>
      </div>
    );
  };
  return (
    <>
      <Grid>
        <Grid>
          <List className={`${classes.breadcrumb}`}>
            <ListItem
              className={`${classes.breadcrumbItemActive}`}
              style={{ cursor: 'pointer' }}
              onClick={(e) => {
                history.push({
                  pathname: `/dashboard`
                });
              }}
            >
              DashBoard
            </ListItem>
            <ListItem
              className={`${classes.breadcrumbItem} ${classes.breadcrumbArrow} ${classes.breadcrumbItemActive} `}
              style={{ cursor: 'pointer' }}
              onClick={(e) => {
                history.push({
                  pathname: `/inventory/audit/history`
                });
              }}
            >
              Idle Inventory Audit History
            </ListItem>
            <ListItem
              className={` ${classes.breadcrumbItemActive} ${classes.breadcrumbItem} ${classes.breadcrumbArrow} `}
              style={{ cursor: 'pointer' }}
              onClick={(e) => {
                history.push({
                  pathname: `/inventory/audit/info/${auditid}`
                });
              }}
            >
              Audit ID:{auditid}
            </ListItem>
            <ListItem
              className={`  ${classes.racpadLinkCustomer} ${classes.breadcrumbItem} ${classes.breadcrumbArrow}   `}
              style={{ cursor: 'pointer' }}
            >
              {pageInfo == '1' ? (
                <span>Items Not Scanned</span>
              ) : null}
              {pageInfo == '2' ? (
                <span>Items Scanned With Exceptions</span>
              ) : null}
              {pageInfo == '3' ? (
                <span>Items Needing Attention</span>
              ) : null}
            </ListItem>
          </List>
        </Grid>
        {pageInfo == '1' ? (
          <Typography variant="h4" style={{ marginLeft: '6px' }}>
            Idle Inventory Audit Explanations - Items Not Scanned
          </Typography>
        ) : null}
        {pageInfo == '2' ? (
          <Typography variant="h4" style={{ marginLeft: '6px' }}>
            Idle Inventory Audit Explanations - Items Scanned With Exceptions
          </Typography>
        ) : null}
        {pageInfo == '3' ? (
          <Typography variant="h4" style={{ marginLeft: '6px' }}>
            Idle Inventory Audit Explanations - Items Needing Attention
          </Typography>
        ) : null}
        <Typography
          variant="h5"
          style={{ marginBottom: '15px', marginLeft: '6px' }}
        >
          Item Info
        </Typography>
        <Card className={classes.cardidle}>
          <CardContent>
            <RACTable
              className={`${classes.racGrid} ${classes.racAgrViewGrid}${classes.formControl}`}
              renderTableHead={itemInfoHeadFnc}
              renderTableContent={itemInfoBodyFnc}
            ></RACTable>
          </CardContent>
        </Card>
        <Grid container spacing={2} style={{ marginTop: '20px' }}>
          <Grid item md={7}>
            <Typography
              variant="h5"
              style={{ marginBottom: '15px', marginLeft: '6px' }}
            >
              Last Scanned Audit Info
            </Typography>
            <Card className={classes.cardidle}>
              <CardContent>
                <RACTable
                  className={`${classes.racGrid} ${classes.racAgrViewGrid}${classes.formControl}`}
                  renderTableHead={lastScanHeadFnc}
                  renderTableContent={lastScanBodyFnc}
                ></RACTable>
              </CardContent>
            </Card>
          </Grid>
          <Grid item md={5}>
            <Typography
              variant="h5"
              style={{ marginBottom: '15px', marginLeft: '6px' }}
            >
              Tag Info
            </Typography>
            <Card className={classes.cardidle}>
              <CardContent>
                <RACTable
                  className={`${classes.racGrid} ${classes.racAgrViewGrid}${classes.formControl}`}
                  renderTableHead={tagInfoHeadFnc}
                  renderTableContent={tagInfoBodyFnc}
                ></RACTable>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <Grid style={{ marginTop: '20px' }}>
          <Typography
            variant="h5"
            style={{ marginBottom: '15px', marginLeft: '6px' }}
          >
            Exception Info
          </Typography>
          <Card className={classes.cardidle}>
            <CardContent style={{ width: '50%' }}>
              <RACTable
                className={`${classes.racGrid} ${classes.racAgrViewGrid}${classes.formControl}`}
                renderTableHead={exceptionInfoHeadFnc}
                renderTableContent={exceptionInfoBodyFnc}
              ></RACTable>
            </CardContent>
          </Card>
        </Grid>
        <Grid style={{ marginTop: '20px', marginBottom: '10%' }}>
          <Typography
            variant="h5"
            style={{ marginBottom: '15px', marginLeft: '6px' }}
          >
            Additional Information
          </Typography>
          <Card className={classes.cardidle} style={{
            overflow: 'auto',
            width: '100%',
            marginTop: ' 15px',
            height: 'auto',
          }}>
            <CardContent>
            <Grid className={`${FGS.fixTableHead} ${FGS.fixTableHeight400}`}>
              <RACTable
                //className={`${classes.racGrid} ${classes.racAgrViewGrid}${classes.formControl}`}
                renderTableHead={inventoryAuditsInfoHeadFnc}
                renderTableContent={inventoryAuditsInfoBodyFnc}
              ></RACTable>
              </Grid>
              <label className={classes.commentlabel}>Comments</label>
              <input
                type="textarea"
                disabled={auditStatusFlag == '1' ? true : false}
                maxLength={30}
                className={classes.commentText}
                value={comment.trimStart()}
                onChange={(e: any) => {
                  setComment(e.target.value.trimStart());
                }}
              ></input>
            </CardContent>
          </Card>
        </Grid>
        <Grid style={{ marginTop: '80px' }} className={classes.fixedBottom}>
          <RACButton
            style={{ fontFamily: 'OpenSans-bold' }}
            variant="text"
            color="secondary"
            onClick={(e) => {
              history.push({
                pathname: `/inventory/audit/info/${auditid}`
              });
            }}
          >
            Cancel
          </RACButton>
          <RACButton
            variant="contained"
            disabled={comment ? false : true}
            color="primary"
            style={{ float: 'right' }}
            onClick={(e) => {
              handleContinue(pageInfo);
            }}
          >
            Continue
          </RACButton>
        </Grid>
      </Grid>
      <RACModalCard
        borderRadius="25px"
        className={classes.cardidle}
        maxWidth="xs"
        isOpen={errorMsgPopup}
        closeIcon={false}
      >
        {ErrorMsgfn()}
      </RACModalCard>
      <RACModalCard
        borderRadius="25px"
        maxWidth="xs"
        isOpen={somethingWentWrong}
        closeIcon={false}
      >
        {somethingWentWrongfn()}
      </RACModalCard>
      {hiddenLoader === true ? (
        <Grid
          style={{
            position: 'fixed',
            background: '#f7f5f5',
            width: '100%',
            height: '100%',
            top: 0,
            left: 0,
            opacity: 0.6,
            zIndex: 2000,
            textAlign: 'center',
            margin: '0px 0px',
          }}
        >
          {' '}
          <Grid
            style={{
              display: 'block',
              position: 'fixed',
              zIndex: 9999999,
              top: '40%',
              right: '50%',
            }}
          >
            <CircularProgress />{' '}
          </Grid>{' '}
        </Grid>
      ) : null}
    </>
  );
}
