/* eslint-disable prettier/prettier */
/* eslint-disable no-extra-boolean-cast */

export const printHTML = (html) => {
    if (html!==undefined && html !==null) {
        var divContents = html.innerHTML;
        var a = window.open('', '', 'height=1000, width=1200');
        a.document.write('<html>');
        a.document.write(divContents);
        a.document.write('</html>');
        a.document.close();
        a.print();
    }
}