/* eslint-disable prettier/prettier */
/* eslint-disable no-console */

import React, { createContext } from 'react';
import { Router } from 'react-router';
// import { History } from 'history';
// eslint-disable-next-line @typescript-eslint/no-var-requires
const History = require('history');
import {
  RACThemeProvider, makeStyles, StylesProvider,
  createGenerateClassName,
} from '@rentacenter/racstrap';
import clsx from 'clsx';

import { ErrorBoundary } from '../components/ErrorBoundary/ErrorBoundary';
import { InContainerAuthProvider } from '../context/auth/InContainerAuthProvider';
import { StandaloneAuthProvider } from '../context/auth/StandaloneAuthProvider';
import { AuthGuard } from '../components/AuthGuard/AuthGuard';
import { CustomPropInterface } from '../../src/index';
import {
  InventoryMajorContextProvider,
} from '../context/inventoryMajorContext';
import { InventoryAuditContextprovider } from '../context/InventoryAuditContext';

interface Props {
  history: typeof History;
  customProps?: CustomPropInterface;
}

export const appTestId = 'appTestId';

const useStyles = makeStyles(() => ({
  container: {
    width: '100%',
    paddingLeft: '0.75rem',
    paddingRight: '0.75rem',
  },
}));

const generateClassName = createGenerateClassName({
  seed: 'RAC-INVENTORY-',
});
export const ContainerContext = createContext<CustomPropInterface | undefined>(
  undefined
);

export const App = ({ history, customProps }: Props) => {

  console.log('Indide Inventory MFE App.tsx');

  const { isRenderedByContainer } = window;
  const classes = useStyles();

  return (
    <div data-testid={appTestId}>
      <StylesProvider generateClassName={generateClassName}>        
        <InventoryMajorContextProvider>
          <InventoryAuditContextprovider>
        <RACThemeProvider>
        <ContainerContext.Provider value={customProps}>
          <div className={clsx(classes.container)}>
            <ErrorBoundary>
              <Router history={history}>
                {isRenderedByContainer ? (
                  <InContainerAuthProvider>
                    <AuthGuard />
                  </InContainerAuthProvider>
                ) : (
                  <StandaloneAuthProvider>
                    <AuthGuard />
                  </StandaloneAuthProvider>
                )}
              </Router>
            </ErrorBoundary>
          </div>
          </ContainerContext.Provider>
        </RACThemeProvider>
          </InventoryAuditContextprovider>
        </InventoryMajorContextProvider>
      </StylesProvider>
    </div>
  );
};
