/* eslint-disable prettier/prettier */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unused-vars */

import React, { useState, createContext } from 'react';

export const InventoryContext = createContext<any>('');

export function InventoryContextProvider(props: any) {

  const [headerContext, setheaderContext] = useState<any>({
    inventoryNumber: '',
    description: '',
    status: '',
    storeNumber:'',
  });

  const [priceTagContext, setpriceTagContext] = useState<any>();
  const [tabDisable, settabDisable] = useState(false);

  const [chargeOffRecoveryContext,setchargeOffRecoveryContext]=useState<any>({chargeOffVal:[],recoveryVal:[],radiusValues:[]});
  const [profileDetails, setprofileDetails] = useState<any>();
  const [loanerpayload, setloanerpayload] = useState<any>()
  const [loanCusId, setloanCusId] = useState<any>();
  const [priceExceptionLimit, setPriceExceptionLimit] = useState<any>()
  const [termExceptionLimit, settermExceptionLimit] = useState<any>()

  return (
    <div>
      <InventoryContext.Provider
        value={{
          headerValue: [headerContext, setheaderContext],
          tabDisableContext: [tabDisable, settabDisable],
          inventoryPriceTag:[priceTagContext, setpriceTagContext],
          chargeOffRecoveryContextVal:[chargeOffRecoveryContext,setchargeOffRecoveryContext],
          setprofileDetails,
          profileDetails,
          loanerpayload,
          setloanerpayload,
          loanCusId,
          setloanCusId,
          priceExceptionLimit,
          setPriceExceptionLimit,
          termExceptionLimit, settermExceptionLimit
        }}
      >
        {props.children}
      </InventoryContext.Provider>
    </div>
  );
}