/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable sonarjs/prefer-immediate-return */
/* eslint-disable sonarjs/no-duplicate-string */
import { RACCOLOR, makeStyles } from "@rentacenter/racstrap";
export const RentalAgreement = () => {
    const useClasses = makeStyles((theme) => ({

        legendSqure: {
            height: '20px',
            width: '20px',
            borderRadius: '5px',
        },

        legendActive: {
            backgroundColor: '#5AE286',
        },

        legendPastDue: {
            backgroundColor: '#FD6A63',
        },

        legendInactive: {
            backgroundColor: '#BBC1CE',
        },
        legendPending:{
            backgroundColor: 'Yellow'
        },

        borderLeftActive: {
            borderLeft: '4px solid #5AE286',
            borderRadius: '4px !important',
        },

        borderLeftPastDue: {
            borderLeft: '4px solid #FD6A63',
            borderRadius: '4px !important',
        },

        borderLeftInactive: {
            borderLeft: '4px solid #BBC1CE',
            borderRadius: '4px',

        },

    }));
    const classes = useClasses();
    return classes;
};