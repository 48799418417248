/* eslint-disable prettier/prettier */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable prettier/prettier */
/* eslint-disable no-console */
/* eslint-disable sonarjs/prefer-immediate-return */
/* eslint-disable sonarjs/no-redundant-boolean */
/* eslint-disable prefer-const */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */

import React, { useState, useEffect, FC, useContext } from 'react';
import DynamicGridComponent from './grid';
import { InventoryGlobalStyles } from '../InventorySearch/Styles/InventoryGlobalStyles';
import { globalStyles } from '../componentstyles/globalstyles';
import { ReactComponent as AlertIcon } from '../../assets/images/no-records-found.svg';
import { ReactComponent as NoRecordsFound } from '../../assets/images/No-records.svg';
import { ReactComponent as Logo } from '../../assets/images/Rac-Inventory-printLogo.svg';
import { printHTML } from '../../components/Shared/print';
import NumberFormat from "react-number-format";
import { searchResultStyles } from '../InventorySearch/Styles/searchResultStyles';
import { useHistory } from 'react-router';
import { validateObject } from "../Shared/validateObject";
import { InventoryContext } from '../../context/InventoryInfoContext';
import { InventoryMajorContext } from '../../context/inventoryMajorContext';
import moment from 'moment';
import {
  Grid,
  Card,
  CardContent,
  Typography,
  RACButton,
} from '@rentacenter/racstrap';
import ActivitiesPrintInfo from '../InventoryTabs/ActivitiesPrint'
import { formStyles } from '../componentstyles/formstyles';
import { getInventoryServiceSummmary } from '../../api/user';

const sortDirection = {
  ASC: 'asc',
  DESC: 'desc',
};

export const StoreServiceSummaryInfo = (props: any) => {
  const Invclasses = InventoryGlobalStyles();
  const classes = globalStyles();
  const form = formStyles()
  const agrComponent = searchResultStyles();
  const { serviceSummaryAry, setserviceSummaryAry } = useContext(InventoryMajorContext)
  const [controlRecord, setcontrolRecord] = React.useState<any>(false);
  const [updateitemadded, setupdateitemadded] = React.useState(false);
  const [Gridloader, setGridloader]: any = React.useState(false);
  const [loaderController, setloaderController]: any = React.useState(true);
  const [internalServerController, setinternalServerController]: any =
    React.useState(false);
  const [storeDetailsObj, setStoreDetailsObj] = useState<any>([]);
  const [controlViewMode, setcontrolViewMode] = useState(false);
  const { headerValue } = useContext(InventoryContext);
  const [headerContext, setheaderContext] = headerValue;
  const [activity, setActivity] = useState<any>({})
  const [inventoryInfo, setinventoryInfo] = useState<any>({})
  const [idleInvAudit, setidleInvAudit] = useState<any>()
  const [AgrHistory, setAgrHistory] = useState<any>({})
  const [inventoryDetail, setinventoryDetail] = useState<any>({})
  const history = useHistory();
  const dynamicViewClass = controlViewMode
    ? agrComponent.disableme
    : 'enableme';
  const altersearch = updateitemadded
    ? agrComponent.collapsedWigid
    : agrComponent.expandWigid;
  const DateVar = new Date();
  const days = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];
  const currentDay = days[DateVar.getDay()];
  const itemBeingServicedHeaderArray = [
    {
      DateToService: 'Date To Service',
      DaysSinceSent: 'Days Since Sent',
      ServiceTicket: 'Service Ticket #',
      CustomerName: 'Customer Name',
      Dept: 'Dept',
      SubDept: 'Sub Dept',
      brand: 'Brand',
      model: 'Model #',
      item: 'Item #',
      serial: 'Serial #',
      lnrItem: 'Lnr Item #',
      orgra: 'Org RA #',
      problem: 'Problem',
      solution: 'Solution',
      type: 'Type',
      cost: 'Cost',
      createdby: 'Created By',
    },
  ];
  useEffect(() => {
    const getServiceSummary = async () => {
      setGridloader(true);
      const request: any = {
        identifier: window.sessionStorage.getItem('storeNumber'),
        offset: '1',
        limit: '20',
        type: 'store'

      }
      const ServiceResponse = await getInventoryServiceSummmary(request)
      console.log('ServiceResponse', ServiceResponse);
      setloaderController(false);
      setGridloader(false);

      if (
        validateObject(ServiceResponse) &&
        validateObject(ServiceResponse.data) &&
        ServiceResponse.data.serviceDetails.length > 0
      ) {
        console.log('ServiceResponse1', ServiceResponse);
        if (ServiceResponse.status == 200) {
          const serviceRes = ServiceResponse.data.serviceDetails;
          setserviceSummaryAry(serviceRes);
          const storeDetailService = ServiceResponse?.data?.storeDetails;
          setStoreDetailsObj(storeDetailService);
        }
      }

      if (ServiceResponse.status == 400 || ServiceResponse.status == 500) {
        setinternalServerController(true)
      }
      else if (ServiceResponse.data.serviceDetails.length == 0) {
        setcontrolRecord(true)
      }
    }
    getServiceSummary();
  }, []);
  useEffect(() => {
    console.log("serviceSummaryAry", serviceSummaryAry);

  }, [serviceSummaryAry])
  useEffect(() => {
    if (props?.inventoryInfo || props?.idleInventoryRes || props?.AgreeHistory) {

      const inventoryinfo = props.inventoryInfo?.data
      const idleInventoryRes = props.idleInventoryRes?.data
      const AgreeHistory = props.AgreeHistory?.data
      const inventoryDetails = props.inventoryDetails?.data
      setinventoryInfo(inventoryinfo)
      setidleInvAudit(idleInventoryRes)
      setAgrHistory(AgreeHistory)
      setinventoryDetail(inventoryDetails)

    }
  }, [props])

  const gridCallMethod = () => {
    console.log('Came inside Grid Method');
    if (validateObject(serviceSummaryAry) && serviceSummaryAry.length > 0) {
      console.log('Inside table bind', serviceSummaryAry);
      return (
        <div>
          <Card className={`${classes.m2} ${classes.racCard}`}>
            <CardContent >
              <DynamicGridComponent
                gridtype="Normal"
                id="packagePriceItems"
                items={serviceSummaryAry}
                orderBy="Date"
                order={sortDirection.ASC}
                throwStyle={classes}
              ></DynamicGridComponent>
            </CardContent>
          </Card>
        </div>
      );
    }
    else if (loaderController) {
      return (
        <div>

          <Card className={`${classes.m2} ${classes.racCard}`}>
            <CardContent>
              <DynamicGridComponent
                gridtype="Normal"
                id="packagePriceItems"
                items={serviceSummaryAry}
                loaderController={loaderController}
                orderBy="loanerAgreement"
                order={sortDirection.ASC}
                throwStyle={classes}
              ></DynamicGridComponent>
            </CardContent>
          </Card>
        </div>
      );
    }

    else if (internalServerController) {
      return (
        <div>
          <div className={`${Invclasses.textCenter} ${Invclasses.mt5}`}>
            <AlertIcon />
            <Typography className={`${Invclasses.subTitle} ${Invclasses.mb3}`}>
              Something Went Wrong
            </Typography>
          </div>
        </div>
      );
    } else if (controlRecord) {
      return (
        <div>
          <div className={`${Invclasses.textCenter} ${Invclasses.mt5}`}>
            <NoRecordsFound />
            <Typography className={`${Invclasses.subTitle} ${Invclasses.mb3}`}>
              No Records Found
            </Typography>
          </div>
        </div>
      );
    }
  };

  const roundOff = (num: any, places: number) => {
    const x = Math.pow(10, places);
    return Math.round(num * x) / x;
  }

  function dateFormat(params: any) {
    if (params) {
      const test = params.split('-');
      return test[2] + '/' + test[1] + '/' + test[0];
    } else {
      return '-';
    }
  }

  return (
    <>
      <div className={dynamicViewClass} style={{ marginBottom: '98px' }}>
        <Grid>
          <Typography
            variant="h4"
            style={{
              fontWeight: 'bold',
              marginLeft: '6px',
              marginTop: '10px',
            }}
          >
            {' '}
            Items Being Serviced
          </Typography>
        </Grid>
        <Grid container spacing={2}>
          <Grid item className={altersearch}>
            <div>
              {gridCallMethod()}
            </div>
          </Grid>
        </Grid>
      </div>

      <Grid style={{ zIndex: '1' }} className={Invclasses.fixedBottom}>
        <Grid className={Invclasses.floatLeft}>
          <RACButton
            color="primary"
            variant="outlined"
            className={Invclasses.ms2}
            onClick={() => history.push(`/inventory/inventorysearch`)}
          >
            Cancel
          </RACButton>
        </Grid>
        <Grid className={Invclasses.floatRight}>
          <RACButton
            color="primary"
            variant="contained"
            onClick={() => printHTML(document.getElementById('ItemsBeingServiced'))}
            style={{ marginRight: '4px' }}
          >
            Print
          </RACButton>
        </Grid>
      </Grid>



      {/*Print Html startes here*/}
      <div className={agrComponent.hide}>
        <div style={{ width: '96%', margin: '2%', float: 'left' }} id="activities">
          <ActivitiesPrintInfo activeresponse={activity} inventoryInfoRes={inventoryInfo} idleInvAudit={idleInvAudit} AgrHistory={AgrHistory} inventoryDetail={inventoryDetail} />

          {/* <div style={{ width: '100%', float: 'left', marginBottom: '20px' }}>
            <h2 style={{ float: 'left', margin: '0px' }}>Inventory Activities</h2>
            <div style={{ float: 'right', marginTop: '5px' }}>{moment().format('ddd,D MMM YYYY hh:mm A')}</div>
          </div>

          <div style={{ width: '100%', float: 'left', marginBottom: '10px' }}>
            <div style={{ width: '20%', float: 'left' }}>
              <span style={{ fontSize: '14px' }}>Rent-A-Center</span>
              <span style={{ fontSize: '14px' }}>({sessionStorage.getItem('storeNumber')})</span>
            </div>
            <div style={{ width: '30%', float: 'left' }}>
              <span style={{ fontWeight: 'bold', fontSize: '14px' }}>Item #:</span>
              <span style={{ fontSize: '14px' }}>{headerContext.inventoryNumber}</span>
            </div>
            <div style={{ width: '100%', float: 'left', marginTop: '10px' }}>
              <span style={{ fontWeight: 'bold', fontSize: '14px' }}>Description:</span>
              <span style={{ fontSize: '14px' }}>{headerContext.description}</span>
            </div>
          </div>

          <div style={{ width: '100%', float: 'left', marginBottom: '20px' }}>
            <h3 style={{ borderTop: '1px solid black', borderBottom: '1px solid black', padding: '3px' }}>Miscellanous</h3>
            <table style={{ borderCollapse: 'collapse', width: '100%' }}>
              <thead>
                <tr>
                  <th style={{ border: '1px solid #000', backgroundColor: '#ccc', padding: '5px', fontSize: '14px' }}>Date </th>
                  <th style={{ border: '1px solid #000', backgroundColor: '#ccc', padding: '5px', fontSize: '14px' }}>Description</th>
                </tr>
              </thead>
              <tbody>
                {BindGrid()}
              </tbody>
            </table>
          </div> */}

        </div>
      </div>
      {/*Print Html ends here*/}

      <div id="ItemsBeingServiced" style={{ display: 'none' }}>
        <div>
          <Logo
            style={{
              width: '60px',
              height: '60px',
              marginLeft: '7%',
            }}
          ></Logo>
        </div>
        <table style={{ marginBottom: '35%' }}>
          <thead>
            <tr>
              <th style={{ borderBottom: '1px solid black' }}>
                <div>
                  <div
                    style={{
                      width: '20%',
                      float: 'left',
                      fontFamily: 'sans-serif',
                      marginTop: '66px',
                    }}
                  >
                    <div style={{ width: '100%' }}>
                      <label>Rent-A-Center,Inc</label>
                    </div>
                    <div style={{ width: '100%' }}>
                      <label>
                        {' '}
                        {storeDetailsObj.addressLine1
                          ? storeDetailsObj.addressLine1
                          : storeDetailsObj.addressLine2}
                      </label>
                    </div>
                  </div>
                  <div
                    style={{
                      textAlign: 'center',
                      width: '45%',
                      float: 'left',
                      fontFamily: 'sans-serif',
                      marginTop: '46px',
                      fontSize: 'bold',
                    }}
                  >
                    <h3>Items Being Serviced</h3>
                  </div>
                  <div
                    style={{
                      width: '35%',
                      float: 'left',
                      textAlign: 'right',
                      fontFamily: 'sans-serif',
                      marginTop: '66px',
                    }}
                  >
                    <div>
                      {currentDay ? currentDay : <span>-</span>}{' '}
                      {moment().format('L')}
                    </div>
                    <div style={{ width: '100%' }}>
                      Report requested by STORE{storeDetailsObj.storeNumber},
                      <div>COWORKER5</div>
                    </div>
                  </div>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>
                <div style={{ marginTop: '1%' }}>
                  <div style={{ width: '100%' }}>
                    <div>
                      <div
                        style={{
                          width: '100%',
                          float: 'left',
                          marginTop: '5px',
                        }}
                      >
                        <table
                          style={{
                            borderCollapse: 'collapse',
                            width: '100%',
                            fontFamily: 'sans-serif',
                          }}
                        >
                          <thead>
                            {itemBeingServicedHeaderArray.map((obj: any) => {
                              return (
                                <>
                                  <tr>
                                    <th
                                      style={{
                                        color: '#000',
                                        textAlign: 'left',
                                        width: '7%',
                                        fontSize: '10px',
                                        borderBottom: '1px solid #000',
                                      }}
                                    >
                                      {obj.DateToService}
                                    </th>

                                    <th
                                      style={{
                                        color: '#000',
                                        textAlign: 'left',
                                        width: '3%',
                                        fontSize: '10px',
                                        borderBottom: '1px solid #000',
                                      }}
                                    >
                                      {obj.DaysSinceSent}
                                    </th>

                                    <th
                                      style={{
                                        color: '#000',
                                        textAlign: 'left',
                                        width: '7%',
                                        fontSize: '10px',
                                        borderBottom: '1px solid #000',
                                      }}
                                    >
                                      {obj.ServiceTicket}
                                    </th>

                                    <th
                                      style={{
                                        color: '#000',
                                        textAlign: 'left',
                                        width: '7%',
                                        fontSize: '10px',
                                        borderBottom: '1px solid #000',
                                      }}
                                    >
                                      {obj.CustomerName}
                                    </th>

                                    <th
                                      style={{
                                        color: '#000',
                                        textAlign: 'left',
                                        width: '3%',
                                        fontSize: '10px',
                                        borderBottom: '1px solid #000',
                                      }}
                                    >
                                      {obj.Dept}
                                    </th>

                                    <th
                                      style={{
                                        color: '#000',
                                        textAlign: 'left',
                                        width: '3%',
                                        fontSize: '10px',
                                        borderBottom: '1px solid #000',
                                      }}
                                    >
                                      {obj.SubDept}
                                    </th>

                                    <th
                                      style={{
                                        color: '#000',
                                        textAlign: 'left',
                                        width: '3%',
                                        fontSize: '10px',
                                        borderBottom: '1px solid #000',
                                      }}
                                    >
                                      {obj.brand}
                                    </th>

                                    <th
                                      style={{
                                        color: '#000',
                                        textAlign: 'left',
                                        width: '7%',
                                        fontSize: '10px',
                                        borderBottom: '1px solid #000',
                                      }}
                                    >
                                      {obj.model}
                                    </th>
                                    <th
                                      style={{
                                        color: '#000',
                                        textAlign: 'left',
                                        width: '7%',
                                        fontSize: '10px',
                                        borderBottom: '1px solid #000',
                                      }}
                                    >
                                      {obj.item}
                                    </th>
                                    <th
                                      style={{
                                        color: '#000',
                                        textAlign: 'left',
                                        width: '7%',
                                        fontSize: '10px',
                                        borderBottom: '1px solid #000',
                                      }}
                                    >
                                      {obj.serial}
                                    </th>
                                    <th
                                      style={{
                                        color: '#000',
                                        textAlign: 'left',
                                        width: '7%',
                                        fontSize: '10px',
                                        borderBottom: '1px solid #000',
                                      }}
                                    >
                                      {obj.lnrItem}
                                    </th>
                                    <th
                                      style={{
                                        color: '#000',
                                        textAlign: 'left',
                                        width: '7%',
                                        fontSize: '10px',
                                        borderBottom: '1px solid #000',
                                      }}
                                    >
                                      {obj.orgra}
                                    </th>
                                    <th
                                      style={{
                                        color: '#000',
                                        textAlign: 'left',
                                        width: '8%',
                                        fontSize: '10px',
                                        borderBottom: '1px solid #000',
                                      }}
                                    >
                                      {obj.problem}
                                    </th>
                                    <th
                                      style={{
                                        color: '#000',
                                        textAlign: 'left',
                                        width: '8%',
                                        fontSize: '10px',
                                        borderBottom: '1px solid #000',
                                      }}
                                    >
                                      {obj.solution}
                                    </th>
                                    <th
                                      style={{
                                        color: '#000',
                                        textAlign: 'left',
                                        width: '7%',
                                        fontSize: '10px',
                                        borderBottom: '1px solid #000',
                                      }}
                                    >
                                      {obj.type}
                                    </th>
                                    <th
                                      style={{
                                        color: '#000',
                                        textAlign: 'left',
                                        width: '3%',
                                        fontSize: '10px',
                                        borderBottom: '1px solid #000',
                                      }}
                                    >
                                      {obj.cost}
                                    </th>
                                    <th
                                      style={{
                                        color: '#000',
                                        textAlign: 'left',
                                        width: '7%',
                                        fontSize: '10px',
                                        borderBottom: '1px solid #000',
                                      }}
                                    >
                                      {obj.createdby}
                                    </th>
                                  </tr>
                                </>
                              );
                            })}
                          </thead>
                          <tbody>
                            {serviceSummaryAry?.map(
                              (obj: any, index: any) => {
                                return (
                                  <tr
                                    key={index}
                                    style={{
                                      width: '50px',
                                      borderBottom: '1.5px dotted black',
                                    }}
                                  >
                                    <th
                                      style={{
                                        paddingTop: '7px',
                                        fontSize: '10px',
                                        textAlign: 'left',
                                      }}
                                    >
                                      {obj.dateToService ? (
                                        dateFormat(obj.dateToService)
                                      ) : (
                                        <span>-</span>
                                      )}
                                    </th>
                                    <th
                                      style={{
                                        paddingTop: '7px',
                                        fontSize: '10px',
                                        textAlign: 'left',
                                      }}
                                    >
                                      {obj.daysSinceSent ? (
                                        obj.daysSinceSent
                                      ) : (
                                        <span>-</span>
                                      )}
                                    </th>
                                    <th
                                      style={{
                                        paddingTop: '7px',
                                        fontSize: '10px',
                                        textAlign: 'left',
                                      }}
                                    >
                                      {obj.serviceTicketId ? (
                                        obj.serviceTicketId
                                      ) : (
                                        <span>-</span>
                                      )}
                                    </th>
                                    <th
                                      style={{
                                        paddingTop: '7px',
                                        fontSize: '10px',
                                        textAlign: 'left',
                                      }}
                                    >
                                      {obj.customerFirstName ? (
                                        obj.customerFirstName
                                      ) : (
                                        <span>-</span>
                                      )}
                                    </th>
                                    <th
                                      style={{
                                        paddingTop: '7px',
                                        fontSize: '10px',
                                        textAlign: 'left',
                                      }}
                                    >
                                      {obj.deptRefCode ? (
                                        obj.deptRefCode
                                      ) : (
                                        <span>-</span>
                                      )}
                                    </th>
                                    <th
                                      style={{
                                        paddingTop: '7px',
                                        fontSize: '10px',
                                        textAlign: 'left',
                                      }}
                                    >
                                      {obj.subDeptRefcode ? (
                                        obj.subDeptRefcode
                                      ) : (
                                        <span>-</span>
                                      )}
                                    </th>
                                    <th
                                      style={{
                                        paddingTop: '7px',
                                        fontSize: '10px',
                                        textAlign: 'left',
                                      }}
                                    >
                                      {obj.brandName ? (
                                        obj.brandName
                                      ) : (
                                        <span>-</span>
                                      )}
                                    </th>
                                    <th
                                      style={{
                                        paddingTop: '7px',
                                        fontSize: '10px',
                                        textAlign: 'left',
                                      }}
                                    >
                                      {obj.modelNumber ? (
                                        obj.modelNumber
                                      ) : (
                                        <span>-</span>
                                      )}
                                    </th>
                                    <th
                                      style={{
                                        paddingTop: '7px',
                                        fontSize: '10px',
                                        textAlign: 'left',
                                      }}
                                    >
                                      {obj.inventoryNumber ? (
                                        obj.inventoryNumber
                                      ) : (
                                        <span>-</span>
                                      )}
                                    </th>
                                    <th
                                      style={{
                                        paddingTop: '7px',
                                        fontSize: '10px',
                                        textAlign: 'left',
                                      }}
                                    >
                                      {obj.serialNumber ? (
                                        obj.serialNumber
                                      ) : (
                                        <span></span>
                                      )}
                                    </th>
                                    <th
                                      style={{
                                        paddingTop: '7px',
                                        fontSize: '10px',
                                        textAlign: 'left',
                                      }}
                                    >
                                      {obj.loanerInvNumber ? (
                                        obj.loanerInvNumber
                                      ) : (
                                        <span>-</span>
                                      )}
                                    </th>
                                    <th
                                      style={{
                                        paddingTop: '7px',
                                        fontSize: '10px',
                                        textAlign: 'left',
                                      }}
                                    >
                                      {obj.loanerAgreementNumber ? (
                                        obj.loanerAgreementNumber
                                      ) : (
                                        <span>-</span>
                                      )}
                                    </th>
                                    <th
                                      style={{
                                        paddingTop: '7px',
                                        fontSize: '10px',
                                        textAlign: 'left',
                                      }}
                                    >
                                      {obj.problem ? (
                                        obj.problem
                                      ) : (
                                        <span>-</span>
                                      )}
                                    </th>
                                    <th
                                      style={{
                                        paddingTop: '7px',
                                        fontSize: '10px',
                                        textAlign: 'left',
                                      }}
                                    >
                                      {obj.solution ? (
                                        obj.solution
                                      ) : (
                                        <span>-</span>
                                      )}
                                    </th>

                                    <th
                                      style={{
                                        paddingTop: '7px',
                                        fontSize: '10px',
                                        textAlign: 'left',
                                      }}
                                    >
                                      {obj.inventoryStatus ? (
                                        obj.inventoryStatus
                                      ) : (
                                        <span>-</span>
                                      )}
                                    </th>

                                    <th
                                      style={{
                                        paddingTop: '7px',
                                        fontSize: '10px',
                                        textAlign: 'left',
                                      }}
                                    >
                                      {obj.serviceCost ? (
                                        obj.serviceCost
                                      ) : (
                                        <span>-</span>
                                      )}
                                    </th>
                                    <th
                                      style={{
                                        paddingTop: '7px',
                                        fontSize: '10px',
                                        textAlign: 'left',
                                      }}
                                    >
                                      {obj.createdByFirstName ||
                                        obj.createdByLastName ? (
                                        obj.createdByFirstName ? (
                                          obj.createdByFirstName + ','
                                        ) : <span>""</span> &&
                                          obj.createdByLastName ? (
                                          obj.createdByLasttName
                                        ) : (
                                          <span>""</span>
                                        )
                                      ) : (
                                        <span>-</span>
                                      )}
                                    </th>
                                  </tr>
                                );
                              }
                            )}
                          </tbody>
                        </table>
                        <div></div>
                      </div>
                    </div>
                  </div>
                </div>
              </th>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
}

export default StoreServiceSummaryInfo;
