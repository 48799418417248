/* eslint-disable */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable sonarjs/no-duplicate-string */
import React, { useEffect, useState } from 'react';
import { ReactComponent as SortdownIcon } from '../../../assets/images/sort-down-icon.svg';
import { ReactComponent as SortupIcon } from '../../../assets/images/sort-up-icon.svg';
import { ReactComponent as PDFImages } from '../../../assets/images/pdf-icon.svg';
// import { ReactComponent as ItemAddition } from '../../assets/images/ItemAddition.svg';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useParams } from 'react-router-dom';
import {
  Grid,
  RACTable,
  RACTableCell,
  RACTableRow,
  CircularProgress
} from '@rentacenter/racstrap';
import { getActivities } from '../../../api/user';
import { fixedGridStyles } from '../../componentstyles/fixedGridStyles';
import moment from 'moment';

export interface dynamicProps {
  id?: string;

  items: any;
  // columns: columns[];
  orderBy: string;
  order: string;
  ColumnLink?: boolean;
  gridtype:
  | 'Normal'
  | 'withSubChild'
  | 'withCheckBox'
  | 'withRadioBtn'
  | 'endWithCheckBox';

  handleActionType?: (value: any, index: any) => void;
  throwStyle: any;
  loaderController?: boolean;
  inventoryInfo?: any;
}
interface ParamType {
  inventoryNum: string;
}
export interface columns {
  columnId: string;
  sortOrder: string;
  columnTitle: string;
  IsSorted: boolean;
  IsSortable: boolean;
  columnType?: 'link';
  handleColumnClick?: () => void;

  subColumns?: columns[];
}
const sortDirection = {
  ASC: 'asc',
  DESC: 'desc',
};

export interface IDynamicPageState {
  gridValues: [];
  gridColumn: columns[] | [];
}

const packagePriceColumns: columns[] = [
  {
    columnId: 'activityDate',
    sortOrder: sortDirection.ASC,
    columnTitle: 'Date',
    IsSorted: true,
    columnType: 'link',
    IsSortable: true,
  },
  {
    columnId: 'description',
    sortOrder: sortDirection.DESC,
    columnTitle: 'Description',
    IsSorted: false,
    columnType: 'link',
    IsSortable: true,
  },
  {
    columnId: 'action',
    sortOrder: 'none',
    columnTitle: 'Action',
    IsSorted: false,
    columnType: 'link',
    IsSortable: false,
  },
];

const DynamicGridComponent = (props: dynamicProps) => {
  const storeNumber = window.sessionStorage.getItem('storeNumber');
  const classes = props.throwStyle;
  const FGS = fixedGridStyles();
  const { inventoryNum } = useParams<ParamType>();
  const [gridValues, setGridValues] = useState<any[]>([]);
  const [gridColumn, setGridColumn] = useState<columns[]>([]);
  const [scrollset, setScrollset] = useState<number>(0);
  const [loader, setloader] = useState<boolean>(false);
  const [hasMore, sethasMore] = useState<boolean>(true);
  let [fromStoreNumber, setFromStoreNumber] = useState('');
  let [toStoreNumber, setToStoreNumber] = useState('');

  useEffect(() => {
    const { order, orderBy, items, inventoryInfo } = props;
    /* eslint-disable no-console */
    console.log('In Grid Component', props.items);
    console.log('In Grid Component inventoryInfo', inventoryInfo);

    const sortedData =
      order == sortDirection.DESC ?
        (items.sort((a: any, b: any) => {
          const c: any = new Date(a[orderBy]);
          const d: any = new Date(b[orderBy]);
          return d - c
        })) : (items.sort((a: any, b: any) => {
          const c: any = new Date(a[orderBy]);
          const d: any = new Date(b[orderBy]);
          return c - d
        }));

    setGridValues(sortedData);
    setGridColumn(packagePriceColumns);
  }, [props]);

  const sortArray = (
    columnId: string,
    index: number,
    datacolumn: any,
    dataValues: any
  ) => {
    const sortedData =
      datacolumn[index].sortOrder == sortDirection.DESC
        ? dataValues.sort((a: any, b: any) => {
          if (a[columnId] === null) {
            return 1;
          }
          if (b[columnId] === null) {
            return -1;
          }
          if (a[columnId] === b[columnId]) {
            return 0;
          }
          return b[columnId] < a[columnId] ? -1 : 1;
        })
        : dataValues.sort((a: any, b: any) => {
          if (a[columnId] === null) {
            return 1;
          }
          if (b[columnId] === null) {
            return -1;
          }
          if (a[columnId] === b[columnId]) {
            return 0;
          }
          return a[columnId] < b[columnId] ? -1 : 1;
        });
    const reorderColumns: columns[] = datacolumn;
    reorderColumns[index].sortOrder =
      reorderColumns[index].sortOrder == sortDirection.ASC
        ? sortDirection.DESC
        : sortDirection.ASC;
    reorderColumns.map((value: any, index1: number) => {
      reorderColumns[index1].IsSorted = index == index1 ? true : false;
    });
    setGridValues([...sortedData]);
    setGridColumn([...reorderColumns]);
    // setState({ gridValues: sortedData, gridColumn: reorderColumns });
  };

  const sortArrayDate = (
    columnId: string,
    index: number,
    datacolumn: any,
    dataValues: any
  ) => {
    const sortedData =
      datacolumn[index].sortOrder == sortDirection.DESC
        ? dataValues.sort((a: any, b: any) => {
          const c: any = new Date(a[columnId]);
          const d: any = new Date(b[columnId]);
          return d - c;

        })
        : dataValues.sort((a: any, b: any) => {
          const c: any = new Date(a[columnId]);
          const d: any = new Date(b[columnId]);
          return c - d;

        })
    const reorderColumns: columns[] = datacolumn;
    reorderColumns[index].sortOrder =
      reorderColumns[index].sortOrder == sortDirection.ASC
        ? sortDirection.DESC
        : sortDirection.ASC;
    reorderColumns.map((value: any, index1: number) => {
      reorderColumns[index1].IsSorted = index == index1 ? true : false;
    });
    setGridValues([...sortedData]);
    setGridColumn([...reorderColumns]);
    // setState({ gridValues: sortedData, gridColumn: reorderColumns });
  };

  const renderTableHead = (dataColumns: any, dataValues: any) => {

    return (
      <>
        {gridColumn.map((value: any, index: any) => {
          return (
            <RACTableCell key={index} className={classes.p2}>
              <a
                style={{
                  color: 'black',
                  textDecoration: 'none',
                  cursor: 'pointer',
                }}
                id={value.columnId}
                onClick={() => {
                  value.columnTitle !== 'Date' ?
                    sortArray(value.columnId, index, dataColumns, dataValues) :
                    sortArrayDate(value.columnId, index, dataColumns, dataValues)
                }
                }
              >
                {value.columnTitle + '  '}
                {value.IsSorted === true ? (
                  value.sortOrder == sortDirection.DESC ? (
                    <SortupIcon className={classes.ms0} />
                  ) : (
                    <SortdownIcon className={classes.ms0} />
                  )
                ) : null}
              </a>
            </RACTableCell>
          );
        })}
        <RACTableCell></RACTableCell>
      </>
    );
  };

  // eslint-disable-next-line sonarjs/cognitive-complexity
  const renderTableContent = (gridBodyData: any, gridColumns: any) => {
    if (props.loaderController === true) {
      return (
        <RACTableRow className={classes.bgTransparent} key="0">
          <RACTableCell colSpan={4}>
            <Grid className={classes.GridLoader}>
              <CircularProgress />
            </Grid>
          </RACTableCell>
        </RACTableRow>
      );
    } else if (!!gridBodyData && gridBodyData.length > 0) {
      return gridBodyData.map((value: any, index1: any) => {
        const isTransferActivity = value.description != null ? value.description.includes("Pending Transfer") : false;
        return (
          <React.Fragment key={index1}>
            <RACTableRow className={classes.bgTransparent}>
              <RACTableCell
                className={`${classes.fs6} ${classes.fontSemiBold} ${classes.p2}`}
                style={{ width: '20%' }}
              >
                {/* <a className={classes.racpadLink}> */}
                {moment(value.activityDate).format('MM/DD/YYYY')}
                {/* </a> */}
              </RACTableCell>

              <RACTableCell
                className={`${classes.fs6} ${classes.fontSemiBold} ${classes.p2}`}
                style={{ width: '30.33%' }}
              >
                {/* {valueAppend(value.description, value.reason)} */}
                {value.description == null ? "-" : value.description}
              </RACTableCell>

              {isTransferActivity ? (
                <RACTableCell style={{ width: '33.33%' }}>
                  <PDFImages className={classes.me2}></PDFImages>
                  {(() => {
                    const splitDescription = value.description.split(" ")
                    const fromStoreIndex = splitDescription.indexOf('From');
                    const toStoreIndex = splitDescription.indexOf('to');
                    const formType = fromStoreIndex != -1 ? splitDescription[fromStoreIndex + 1].slice(1, 6) == storeNumber ? 'TransferOutFrom' : 'TransferInForm' : `-`;
                    
                    return (
                      <a style={{
                        color: '#2179FE',
                        textDecoration: 'none',
                        cursor: 'pointer',
                      }}
                        onClick={async () => {

                          const fromStore = fromStoreIndex != -1 ? (splitDescription[fromStoreIndex + 1].slice(1, 6)) : '-';
                          const toStore = toStoreIndex != -1 ? (splitDescription[toStoreIndex + 1].slice(1, 6)) : '-';
                          await handleClick(fromStore, toStore);

                          const formToPrint = formType == 'TransferOutFrom' ? 'transferOutFormInnerHtml' : 'transferInFormInnerHtml';
                          printHTML(
                            document.getElementById(formToPrint))
                        }}>
                        {formType === 'TransferOutFrom' ? 'Transfer Out Form' : 'Transfer In Form'}
                      </a>);
                  })()}
                </RACTableCell>
              ) : (
                <RACTableCell>
                </RACTableCell>
              )}
            </RACTableRow>
          </React.Fragment>

        );
      });
    }
  };
  const printHTML = (html: any) => {
    if (html !== undefined && html !== null) {
      const divContents = html.innerHTML;
      const a: any = window.open('/h', '_blank', 'height=1000, width=1200');
      a.document.write('<html>');
      a.document.write(divContents);
      a.document.write('</html>');
      a.document.close();
      a.print();
    }
  };
  const handleClick = async (fromStore: string, toStore: string) => {
    fromStoreNumber = fromStore
    setFromStoreNumber(fromStoreNumber);
    toStoreNumber = toStore
    setToStoreNumber(toStoreNumber);
    console.log('fromStoreNumber', fromStoreNumber, 'toStoreNumber', toStoreNumber)
  };
  const nextscroll = async () => {
    setScrollset(scrollset + 1);
    setloader(true);
    const response = await getActivities(inventoryNum, scrollset + 1);
    setloader(false);
    const val = response.data.activities;
    console.log('valresponse', val);
    setGridValues([...gridValues, ...val]);
    if (val.length === 0) {
      sethasMore(false);
    }
    console.log('mainResponse', gridValues);
  };
  /* eslint-disable sonarjs/prefer-single-boolean-return */
  const validateObject = (validateObj: any) => {
    if (validateObj != undefined && validateObj !== null) {
      return true;
    }
    else {
      return false;
    }
  }
  return (
    <>
      <Grid className={`${FGS.fixTableHead} ${FGS.fixTableHeight400}`}>
        {gridValues.length > 7 ? (
          <InfiniteScroll
            dataLength={gridValues.length} //This is important field to render the next data
            next={nextscroll}
            hasMore={hasMore}
            height={400}
            loader={
              loader ? (
                <Grid className={classes.GridLoader}>
                  <CircularProgress></CircularProgress>
                </Grid>
              ) : null
            }
          >
            <RACTable
              renderTableHead={() => renderTableHead(gridColumn, gridValues)}
              renderTableContent={() =>
                renderTableContent(gridValues, gridColumn)
              }
              stickyHeader
            />
          </InfiniteScroll>
        ) : (
          <RACTable
            renderTableHead={() => renderTableHead(gridColumn, gridValues)}
            renderTableContent={() => renderTableContent(gridValues, gridColumn)}
          />
        )}
      </Grid>
      {/* Printing docs */}
      {/* Transfer Out Form start */}
      <div className={classes.hide}>
        <div id="transferOutFormInnerHtml">
          <div style={{ float: 'left', width: '94%', padding: '3%' }}>

            <div style={{ width: '100%', float: 'left', marginBottom: '10px' }}>
              <h2 style={{ float: 'left', margin: '0px' }}>Transfer Out Form</h2>
              <div style={{ float: 'right' }}>{moment().format('ddd,D MMM YYYY, hh:mm A')}</div>
            </div>

            <div style={{ width: '100%', float: 'left', marginBottom: '20px' }}>
              <label style={{ float: 'right' }}>Page: 1</label>
            </div>

            <div style={{ width: '50%', float: 'left', marginBottom: '20px' }}>
              <label style={{ margin: '0px' }}>Transferring Store: {fromStoreNumber}</label>
            </div>
            <div style={{ width: '50%', float: 'left', marginBottom: '20px' }}>
              <label style={{ margin: '0px' }}>Receiving Store: {toStoreNumber}</label>
              {/* <label style={{ margin: '0px' }}>Receiving Store: {validateObject(transferState) && transferState != 'Select' ? transferState : validateObject(transfer2Store) && transfer2Store != '' ? transfer2Store : ''}</label> */}
            </div>

            <div style={{ width: '100%', float: 'left', marginBottom: '20px' }}>
              <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                <thead>
                  <tr>
                    <th style={{ borderBottom: '1px solid #000', color: '#000', textAlign: 'left', padding: '7px', fontSize: '14px' }}>Item</th>
                    <th style={{ borderBottom: '1px solid #000', color: '#000', textAlign: 'left', padding: '7px', fontSize: '14px' }}>Model</th>
                    <th style={{ borderBottom: '1px solid #000', color: '#000', textAlign: 'left', padding: '7px', fontSize: '14px' }}>Vendor</th>
                    <th style={{ borderBottom: '1px solid #000', color: '#000', textAlign: 'left', padding: '7px', fontSize: '14px' }}>Brand</th>
                    <th style={{ borderBottom: '1px solid #000', color: '#000', textAlign: 'left', padding: '7px', fontSize: '14px' }}>MFG Serial #</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style={{ paddingTop: '7px', fontSize: '14px' }}>{validateObject(props.inventoryInfo?.inventoryHeader) && validateObject(props.inventoryInfo?.inventoryHeader.itemNumber) ? props.inventoryInfo?.inventoryHeader.itemNumber : '-'}</td>
                    <td style={{ paddingTop: '7px', fontSize: '14px' }}>{validateObject(props.inventoryInfo?.inventoryInformation) && validateObject(props.inventoryInfo?.inventoryInformation.modelNumber) ? props.inventoryInfo?.inventoryInformation.modelNumber : '-'}</td>
                    <td style={{ paddingTop: '7px', fontSize: '14px' }}>{validateObject(props.inventoryInfo?.inventoryInformation) && validateObject(props.inventoryInfo?.inventoryInformation.vendor) ? props.inventoryInfo?.inventoryInformation.vendor : '-'}</td>
                    <td style={{ paddingTop: '7px', fontSize: '14px' }}>{validateObject(props.inventoryInfo?.inventoryInformation) && validateObject(props.inventoryInfo?.inventoryInformation.brand) ? props.inventoryInfo?.inventoryInformation.brand : '-'}</td>
                    <td style={{ paddingTop: '7px', fontSize: '14px' }}>{validateObject(props.inventoryInfo?.inventoryInformation) && validateObject(props.inventoryInfo?.inventoryInformation.serialNumber) ? props.inventoryInfo?.inventoryInformation.serialNumber : '-'}</td>
                  </tr>
                  <tr />
                </tbody>
              </table>
            </div>
            <div style={{ width: '100%', float: 'left', textAlign: 'center', marginBottom: '70px', marginTop: '70px' }}>
              <div style={{ width: '100%', fontSize: '25px' }}>I Am Sold!</div>
              <div style={{ width: '100%', fontSize: '25px' }}>Please ask to see another</div>
              <div style={{ width: '100%', fontSize: '25px' }}>One just like me!</div>
            </div>
            <div style={{ width: '100%', float: 'left' }}>
              <div style={{ width: '50%', float: 'left' }}>
                <h4>Delivered By</h4>
                <div style={{ marginBottom: '30px', width: '100%', float: 'left' }}>
                  <span style={{ borderBottom: '2px solid #000', width: '85%', float: 'left', marginBottom: '10px' }}></span>
                  <div style={{ width: '100%', float: 'left' }}>Employee Id</div>
                </div>
                <div style={{ marginBottom: '30px', width: '100%', float: 'left' }}>
                  <span style={{ borderBottom: '2px solid #000', width: '85%', float: 'left', marginBottom: '10px' }}></span>
                  <div style={{ width: '100%', float: 'left' }}>Printed Name</div>
                </div>
                <div style={{ marginBottom: '30px', width: '100%', float: 'left' }}>
                  <span style={{ borderBottom: '2px solid #000', width: '85%', float: 'left', marginBottom: '10px' }}></span>
                  <div style={{ width: '100%', float: 'left' }}>Signature</div>
                </div>
              </div>

              <div style={{ width: '50%', float: 'left' }}>
                <h4>Received By</h4>
                <div style={{ width: '70%', float: 'left', marginBottom: '30px' }}>
                  <span style={{ borderBottom: '2px solid #000', width: '85%', float: 'left', marginBottom: '10px' }}></span>
                  <div style={{ width: '100%', float: 'left' }}>Employee Id</div>
                </div>
                <div style={{ width: '30%', float: 'left', marginBottom: '30px' }}>
                  <span style={{ borderBottom: '2px solid #000', width: '70%', float: 'left', marginBottom: '10px' }}></span>
                  <div style={{ width: '100%', float: 'left' }}>Date</div>
                </div>

                <div style={{ marginBottom: '30px', width: '100%', float: 'left' }}>
                  <span style={{ borderBottom: '2px solid #000', width: '91%', float: 'left', marginBottom: '10px' }}></span>
                  <div style={{ width: '100%', float: 'left' }}>Printed Name</div>
                </div>
                <div style={{ marginBottom: '30px', width: '100%', float: 'left' }}>
                  <span style={{ borderBottom: '2px solid #000', width: '91%', float: 'left', marginBottom: '10px' }}></span>
                  <div style={{ width: '100%', float: 'left' }}>Signature</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Transfer Out Form end*/}
      {/* Transfer Receiving Form start */}
      <div className={classes.hide}>
        <div id="transferInFormInnerHtml">
          <div style={{ float: 'left', width: '94%', padding: '3%' }}>

            <div style={{ width: '100%', float: 'left', marginBottom: '10px' }}>
              <h2 style={{ float: 'left', margin: '0px' }}>Transfer Received Form</h2>
              <div style={{ float: 'right' }}>{moment().format('ddd,D MMM YYYY, hh:mm A')}</div>
            </div>

            <div style={{ width: '100%', float: 'left', marginBottom: '20px' }}>
              <label style={{ float: 'right' }}>Page: 1</label>
            </div>

            <div style={{ width: '50%', float: 'left', marginBottom: '20px' }}>
              <label style={{ margin: '0px' }}>Transferring Store: {fromStoreNumber} </label>
            </div>
            <div style={{ width: '50%', float: 'left', marginBottom: '20px' }}>
              <label style={{ margin: '0px' }}>Receiving Store: {toStoreNumber} </label>
              {/* <label style={{ margin: '0px' }}>Receiving Store: {validateObject(transferState) && transferState != 'Select' ? transferState : validateObject(transfer2Store) && transfer2Store != '' ? transfer2Store : ''}</label> */}
            </div>

            <div style={{ width: '100%', float: 'left', marginBottom: '20px' }}>
              <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                <thead>
                  <tr>
                    <th style={{ borderBottom: '1px solid #000', color: '#000', textAlign: 'left', padding: '7px', fontSize: '14px' }}>Item</th>
                    <th style={{ borderBottom: '1px solid #000', color: '#000', textAlign: 'left', padding: '7px', fontSize: '14px' }}>Model</th>
                    <th style={{ borderBottom: '1px solid #000', color: '#000', textAlign: 'left', padding: '7px', fontSize: '14px' }}>Vendor</th>
                    <th style={{ borderBottom: '1px solid #000', color: '#000', textAlign: 'left', padding: '7px', fontSize: '14px' }}>Brand</th>
                    <th style={{ borderBottom: '1px solid #000', color: '#000', textAlign: 'left', padding: '7px', fontSize: '14px' }}>MFG Serial #</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style={{ paddingTop: '7px', fontSize: '14px' }}>{validateObject(props.inventoryInfo?.inventoryHeader) && validateObject(props.inventoryInfo?.inventoryHeader.itemNumber) ? props.inventoryInfo?.inventoryHeader.itemNumber : '-'}</td>
                    <td style={{ paddingTop: '7px', fontSize: '14px' }}>{validateObject(props.inventoryInfo?.inventoryInformation) && validateObject(props.inventoryInfo?.inventoryInformation.modelNumber) ? props.inventoryInfo?.inventoryInformation.modelNumber : '-'}</td>
                    <td style={{ paddingTop: '7px', fontSize: '14px' }}>{validateObject(props.inventoryInfo?.inventoryInformation) && validateObject(props.inventoryInfo?.inventoryInformation.vendor) ? props.inventoryInfo?.inventoryInformation.vendor : '-'}</td>
                    <td style={{ paddingTop: '7px', fontSize: '14px' }}>{validateObject(props.inventoryInfo?.inventoryInformation) && validateObject(props.inventoryInfo?.inventoryInformation.brand) ? props.inventoryInfo?.inventoryInformation.brand : '-'}</td>
                    <td style={{ paddingTop: '7px', fontSize: '14px' }}>{validateObject(props.inventoryInfo?.inventoryInformation) && validateObject(props.inventoryInfo?.inventoryInformation.serialNumber) ? props.inventoryInfo?.inventoryInformation.serialNumber : '-'}</td>
                  </tr>
                  <tr />
                </tbody>
              </table>
            </div>
            <div style={{ width: '100%', float: 'left', textAlign: 'center', marginBottom: '70px', marginTop: '70px' }}>
              <div style={{ width: '100%', fontSize: '25px' }}>I Am Sold!</div>
              <div style={{ width: '100%', fontSize: '25px' }}>Please ask to see another</div>
              <div style={{ width: '100%', fontSize: '25px' }}>One just like me!</div>
            </div>
            <div style={{ width: '100%', float: 'left' }}>
              <div style={{ width: '50%', float: 'left' }}>
                <h4>Delivered By</h4>
                <div style={{ marginBottom: '30px', width: '100%', float: 'left' }}>
                  <span style={{ borderBottom: '2px solid #000', width: '85%', float: 'left', marginBottom: '10px' }}></span>
                  <div style={{ width: '100%', float: 'left' }}>Employee Id</div>
                </div>
                <div style={{ marginBottom: '30px', width: '100%', float: 'left' }}>
                  <span style={{ borderBottom: '2px solid #000', width: '85%', float: 'left', marginBottom: '10px' }}></span>
                  <div style={{ width: '100%', float: 'left' }}>Printed Name</div>
                </div>
                <div style={{ marginBottom: '30px', width: '100%', float: 'left' }}>
                  <span style={{ borderBottom: '2px solid #000', width: '85%', float: 'left', marginBottom: '10px' }}></span>
                  <div style={{ width: '100%', float: 'left' }}>Signature</div>
                </div>
              </div>

              <div style={{ width: '50%', float: 'left' }}>
                <h4>Received By</h4>
                <div style={{ width: '70%', float: 'left', marginBottom: '30px' }}>
                  <span style={{ borderBottom: '2px solid #000', width: '85%', float: 'left', marginBottom: '10px' }}></span>
                  <div style={{ width: '100%', float: 'left' }}>Employee Id</div>
                </div>
                <div style={{ width: '30%', float: 'left', marginBottom: '30px' }}>
                  <span style={{ borderBottom: '2px solid #000', width: '70%', float: 'left', marginBottom: '10px' }}></span>
                  <div style={{ width: '100%', float: 'left' }}>Date</div>
                </div>

                <div style={{ marginBottom: '30px', width: '100%', float: 'left' }}>
                  <span style={{ borderBottom: '2px solid #000', width: '91%', float: 'left', marginBottom: '10px' }}></span>
                  <div style={{ width: '100%', float: 'left' }}>Printed Name</div>
                </div>
                <div style={{ marginBottom: '30px', width: '100%', float: 'left' }}>
                  <span style={{ borderBottom: '2px solid #000', width: '91%', float: 'left', marginBottom: '10px' }}></span>
                  <div style={{ width: '100%', float: 'left' }}>Signature</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Transfer Receiving Form end*/}
    </>
  );
};

export default DynamicGridComponent;
