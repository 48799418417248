/* eslint-disable prettier/prettier */
/* eslint-disable no-console */
/* eslint-disable sonarjs/prefer-immediate-return */
/* eslint-disable sonarjs/no-duplicated-branches */
/* eslint-disable sonarjs/no-redundant-boolean */
/* eslint-disable prefer-const */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */

import React, { useState, useEffect, FC, useContext } from 'react';
import { getActivities } from '../../../api/user';
import DynamicGridComponent from './grid';
import { InventoryGlobalStyles } from '../../InventorySearch/Styles/InventoryGlobalStyles';
import { globalStyles } from '../../componentstyles/globalstyles';
import { ReactComponent as AlertIcon } from '../../../assets/images/no-records-found.svg';
import { ReactComponent as NoRecordsFound } from '../../../assets/images/No-records.svg';
import { printHTML } from '../../Shared/print';
import NumberFormat from "react-number-format";
import { searchResultStyles } from '../../InventorySearch/Styles/searchResultStyles';
import { useHistory } from 'react-router';
import { validateObject } from "../../Shared/validateObject";
import { InventoryContext } from '../../../context/InventoryInfoContext';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import {
  Grid,
  Card,
  CardContent,
  Typography,
  RACButton,
} from '@rentacenter/racstrap';
import ActivitiesPrintInfo from '../ActivitiesPrint'
import { formStyles } from '../../componentstyles/formstyles';

const sortDirection = {
  ASC: 'asc',
  DESC: 'desc',
};

export const ServicesInfo = (props: any) => {
  const search = useLocation().search;
  const retention = new URLSearchParams(search).get('retention');
  const Invclasses = InventoryGlobalStyles();
  const classes = globalStyles();
  const form = formStyles()
  const agrComponent = searchResultStyles();
  const [controlRecord, setcontrolRecord] = React.useState<any>(false);
  const [updateitemadded, setupdateitemadded] = React.useState(false);
  const [Gridloader, setGridloader]: any = React.useState(false);
  const [loaderController, setloaderController]: any = React.useState(true);
  const [internalServerController, setinternalServerController]: any =
    React.useState(false);
  const [serviceSummaryAry, setserviceSummaryAry] = useState<any>([])
  const [controlViewMode, setcontrolViewMode] = useState(false);
  const { headerValue, loanCusId } = useContext(InventoryContext);
  const [headerContext, setheaderContext] = headerValue;
  const [activity, setActivity] = useState<any>({})
  const [inventoryInfo, setinventoryInfo] = useState<any>({})
  const [idleInvAudit, setidleInvAudit] = useState<any>()
  const [AgrHistory, setAgrHistory] = useState<any>({})
  const [inventoryDetail, setinventoryDetail] = useState<any>({})
  const [servprintcontainer, setservprintcontainer] = useState<any>()
  const history = useHistory();
  const dynamicViewClass = controlViewMode
    ? agrComponent.disableme
    : 'enableme';
  const altersearch = updateitemadded
    ? agrComponent.collapsedWigid
    : agrComponent.expandWigid;

  useEffect(() => {
    const getServiceSummary = () => {
      if (props?.response?.data) {
        setGridloader(true);
        const ServiceResponse = props.response;
        console.log('ActivitiesResponse', ServiceResponse);
        setloaderController(false);
        setGridloader(false);

        if (
          validateObject(ServiceResponse) &&
          validateObject(ServiceResponse.data) &&
          ServiceResponse?.data?.serviceDetails?.length > 0
        ) {
          console.log('ActivitiesResponse1', ServiceResponse);
          if (ServiceResponse.status == 200) {
            const serviceRes = ServiceResponse.data?.serviceDetails;
            setserviceSummaryAry(serviceRes);
            setservprintcontainer(ServiceResponse.data);

          }
        }

        if (ServiceResponse.status == 400 || ServiceResponse.status == 500) {
          setinternalServerController(true)
        }
        else if (ServiceResponse.data?.serviceDetails?.length == 0) {
          setcontrolRecord(true)
        }
      };
      if (props?.inventoryInfo || props?.idleInventoryRes || props?.AgreeHistory) {

        const inventoryinfo = props.inventoryInfo?.data
        const idleInventoryRes = props.idleInventoryRes?.data
        const AgreeHistory = props.AgreeHistory?.data
        const ActivityRes = props.activeResponse?.data
        const inventoryDetails = props.inventoryDetails?.data
        setinventoryInfo(inventoryinfo)
        setActivity(ActivityRes);
        setidleInvAudit(idleInventoryRes)
        setAgrHistory(AgreeHistory)
        setinventoryDetail(inventoryDetails)

      }
    }
    getServiceSummary();
  }, [props]);

  const gridCallMethod = () => {
    console.log('Came inside Grid Method');

    if (validateObject(serviceSummaryAry) && serviceSummaryAry.length > 0) {
      console.log('Inside table bind', serviceSummaryAry);
      return (
        <div>
          <Card className={`${classes.m2} ${classes.racCard}`}>
            <CardContent >
              <DynamicGridComponent
                gridtype="Normal"
                id="packagePriceItems"
                items={serviceSummaryAry}
                orderBy="Date"
                order={sortDirection.ASC}
                throwStyle={classes}
              ></DynamicGridComponent>
            </CardContent>
          </Card>
        </div>
      );

    }
    else if (loaderController) {
      return (
        <div>

          <Card className={`${classes.m2} ${classes.racCard}`}>
            <CardContent>
              <DynamicGridComponent
                gridtype="Normal"
                id="packagePriceItems"
                items={serviceSummaryAry}
                loaderController={loaderController}
                orderBy="loanerAgreement"


                order={sortDirection.ASC}
                throwStyle={classes}
              ></DynamicGridComponent>
            </CardContent>
          </Card>
        </div>
      );
    }

    else if (internalServerController) {
      return (
        <div>
          <div className={`${Invclasses.textCenter} ${Invclasses.mt5}`}>
            <AlertIcon />
            <Typography className={`${Invclasses.subTitle} ${Invclasses.mb3}`}>
              Something Went Wrong
            </Typography>
          </div>
        </div>
      );
    } else if (controlRecord) {
      return (
        <div>
          <div className={`${Invclasses.textCenter} ${Invclasses.mt5}`}>
            <NoRecordsFound />
            <Typography className={`${Invclasses.subTitle} ${Invclasses.mb3}`}>
              No Records Found
            </Typography>
          </div>
        </div>
      );
    }
  };

  const roundOff = (num: any, places: number) => {
    const x = Math.pow(10, places);
    return Math.round(num * x) / x;
  }
  const newRequestClick = () => {
    let newRequestUrl = `/inventory/service/request/${headerContext?.inventoryNumber}?type=N`;
    if (retention == '1') {
      newRequestUrl += `&retention=1`
    };
    history.push(newRequestUrl);
  }
  const newEnableDeliveryBase = (value: any) => {
    if (value) {
      if (value?.isSwitchoutEnable == 0 && value?.isDeliveryConfirmed == 1) {
        return false
      }
      return true
    }
    return false
  }
  const NewRequestDisableFunc = () => {
    console.log('loanCusId', loanCusId?.newRequestEnabled);

    if (['Not Rent Ready', 'On Rent'].includes(headerContext.status)) {
      if (headerContext.status == 'On Rent' && newEnableDeliveryBase(loanCusId?.newRequestEnabled)) {
        return true
      }
      return false
    }

    // refcode code for Status Description
    // 5-  Cash Sale
    // 1-Payout
    // 2-Purchase Option,
    // SAC- SAME AS CASH
    else if (['Charged Off'].includes(headerContext.status) && ['5', '1', '2', 'SAC'].includes(loanCusId?.statusReasonRefCode)) {
      return false
    }
    return true
  }

  return (
    <>

      <div className={dynamicViewClass} style={{ marginBottom: '98px' }}>
        <Grid container spacing={2}>
          <Grid item className={altersearch}>
            <div>

              {gridCallMethod()}

            </div>
          </Grid>
        </Grid>
      </div>

      <Grid style={{ zIndex: '1' }} className={Invclasses.fixedBottom}>
        <Grid className={Invclasses.floatLeft}>
          <RACButton
            color="primary"
            variant="outlined"
            className={Invclasses.ms2}
            onClick={() => history.push(`/inventory/inventorysearch`)}
          >
            Cancel
          </RACButton>
        </Grid>
        {headerContext?.storeNumber === sessionStorage.getItem('storeNumber') ?
          <Grid className={Invclasses.floatRight}>
            <RACButton
              color="primary"
              variant="contained"
              onClick={() => printHTML(document.getElementById('InventoryPrintHTML'))}
              style={{ marginRight: '4px' }}
            >
              Print
            </RACButton>
          </Grid> : null}
        {headerContext?.storeNumber === sessionStorage.getItem('storeNumber') ?
          // <Grid className={Invclasses.floatRight}>
          <Grid
            title={ loanCusId?.newRequestEnabled?.isDeliveryConfirmed == 0 ? "Delivery not confirmed for this inventory. Please confirm the delivery to start servicing" : ''}
            className={Invclasses.floatRight}
          >
            <RACButton
              color="primary"
              variant="contained"
              disabled={NewRequestDisableFunc()}
              // disabled={ ['Not Rent Ready','On Rent','Charged Off'].includes(headerContext.status) && loanCusId?.statusReasonDesc == 'Payout'?false:true}
              onClick={() => newRequestClick()}
              style={{ marginRight: '4px' }}
            >
              New Request
            </RACButton>
          </Grid> : null}
      </Grid>



      {/*Print Html startes here*/}
      <div className={agrComponent.hide}>
        <div style={{ width: '96%', margin: '2%', float: 'left' }} id="activities">
          <ActivitiesPrintInfo activeresponse={activity} serviceResponse={servprintcontainer} inventoryInfoRes={inventoryInfo} idleInvAudit={idleInvAudit} AgrHistory={AgrHistory} inventoryDetail={inventoryDetail} />

          {/* <div style={{ width: '100%', float: 'left', marginBottom: '20px' }}>
            <h2 style={{ float: 'left', margin: '0px' }}>Inventory Activities</h2>
            <div style={{ float: 'right', marginTop: '5px' }}>{moment().format('ddd,D MMM YYYY hh:mm A')}</div>
          </div>

          <div style={{ width: '100%', float: 'left', marginBottom: '10px' }}>
            <div style={{ width: '20%', float: 'left' }}>
              <span style={{ fontSize: '14px' }}>Rent-A-Center</span>
              <span style={{ fontSize: '14px' }}>({sessionStorage.getItem('storeNumber')})</span>
            </div>
            <div style={{ width: '30%', float: 'left' }}>
              <span style={{ fontWeight: 'bold', fontSize: '14px' }}>Item #:</span>
              <span style={{ fontSize: '14px' }}>{headerContext.inventoryNumber}</span>
            </div>
            <div style={{ width: '100%', float: 'left', marginTop: '10px' }}>
              <span style={{ fontWeight: 'bold', fontSize: '14px' }}>Description:</span>
              <span style={{ fontSize: '14px' }}>{headerContext.description}</span>
            </div>
          </div>

          <div style={{ width: '100%', float: 'left', marginBottom: '20px' }}>
            <h3 style={{ borderTop: '1px solid black', borderBottom: '1px solid black', padding: '3px' }}>Miscellanous</h3>
            <table style={{ borderCollapse: 'collapse', width: '100%' }}>
              <thead>
                <tr>
                  <th style={{ border: '1px solid #000', backgroundColor: '#ccc', padding: '5px', fontSize: '14px' }}>Date </th>
                  <th style={{ border: '1px solid #000', backgroundColor: '#ccc', padding: '5px', fontSize: '14px' }}>Description</th>
                </tr>
              </thead>
              <tbody>
                {BindGrid()}
              </tbody>
            </table>
          </div> */}

        </div>
      </div>
      {/*Print Html ends here*/}

    </>
  );
}

export default ServicesInfo;
