/* eslint-disable prettier/prettier */
/* eslint-disable sonarjs/prefer-single-boolean-return */
export const validateObject = (validateObj: any) => {
    if (validateObj != undefined && validateObj !==null) {
        return true;
    }
    else {
        return false;
    }
}

export const truncString = (str: any, max: number, add: any) => {
    add = add || '...';
    return (typeof str === 'string' && str.length > max ? str.substring(0, max) + add : str);
};
