/* eslint-disable prettier/prettier */
import React, { useState, createContext } from 'react';
import { SearchInventoryObj } from '../components/InventorySearch/inventorySearchProps'
export const InventorySearchContext = createContext<any>('');

export function InventorySearchContextProvider(props: any) {
    const [conditionAry, setconditionAry] = useState<any>([]);
    const [departmentAry, setdepartmentAry] = useState<any>([]);
    const [subdepartmentAry, setsubdepartmentAry] = useState<any>([]);
    const [statusType, setStatusType] = useState<any>([]);
    const [neighborStore, setNeighborstore] = useState<any>([]);
    const [locationRadius, setlocationRadius] = useState<any>();
    const [tabledata, settabledata] = useState<any>();
    const [request, setRequest] = useState<any>()
    const initialTxtValues: SearchInventoryObj['itemObject'] = {
      itemNo: '',
      serialNo: '',
      bracket: '',
      brand: '',
      model: '',
      poNo: '',
      locationTypeVal: 'Store',
      locationval: '986',
      statusId: '1',
      subStatusId: null,
      conditionId: '0',
      department: '',
      subDepartment: '',
      packageTypeval: '',
      locationNumber: '',
      currentStoreNumber: '',
  };
  const [TxtValues, setTxtValues] =
      useState<SearchInventoryObj['itemObject']>(initialTxtValues);
    return (
        <div>
            <InventorySearchContext.Provider
                value={{
                    conditionAry,
                    setconditionAry,
                    departmentAry,
                    setdepartmentAry,
                    subdepartmentAry,
                    setsubdepartmentAry,
                    statusType,
                    setStatusType,
                    neighborStore,
                    setNeighborstore,
                    locationRadius,
                    setlocationRadius,
                    tabledata,
                    settabledata,
                    request,
                    setRequest,
                    TxtValues,
                    setTxtValues,

                }}
            >
                {props.children}
            </InventorySearchContext.Provider>
        </div>
    );
}
