/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/jsx-no-undef */
import { useState, createContext } from 'react';
export const InventoryAuditContext = createContext<any>('');
export function InventoryAuditContextprovider(props: any) {
  const [loaderCircle, setLoaderCircle] = useState<any>(false);
  const [hasMore, setHasMore] = useState<any>(true);
  const [viewArray, setViewArray] = useState<any>('');
  const [tabsShow, setTabsShow] = useState<any>({
    ItemNotScannedShow: true,
    ItemScannedWithExceptionShow: false,
    ItemsNeedAttentionShow: false,
    ItemScanwithNoExceptionShow: false,
    DaysIdleNotInFrontShow: false,
  });
  const [colors, setColors] = useState<any>({
    color1: true,
    color2: false,
    color3: false,
    color4: false,
    color5: false,
  });
  const [hiddenLoader, setHiddenLoader] = useState<any>(false);
  const [itemNotScannedBody, setItemNotScannedBody] = useState<any>([]);
  const [itemwithExceptionBody, setItemwithExceptionBody] = useState<any>([]);
  const [itemNeedAttentionBody, setItemNeedAttentionBody] = useState<any>([]);
  const [daysIdleNotInFrontBody, setDaysIdleNotInFrontBody] = useState<any>();
  const [itemScanwithNoExceptionBody, setItemScanwithNoExceptionBody] =
    useState<any>();
  const [idleExceptionBody, setIdleExceptionBody] = useState<any>({
    AuditStatus: '',
    AuditDate: '',
    AuditBy: '',
    ScanStarted: '',
    ScanEnded: '',
    TotalScanHours: '',
    ClosedBy: '',
    StoreNumber: '',
    AuditCloseDate: '',
  });
  const [invAuditInfoResponse, setInvAuditInfoResponse] = useState();
  const [explanationStoring, setExplanationStoring] = useState<any>([]);
  return (
    <InventoryAuditContext.Provider
      value={{
        loaderCircle,
        setLoaderCircle,
        hasMore,
        setHasMore,
        hiddenLoader,
        setHiddenLoader,
        viewArray,
        setViewArray,
        tabsShow,
        setTabsShow,
        colors,
        setColors,
        itemNotScannedBody,
        setItemNotScannedBody,
        itemwithExceptionBody,
        setItemwithExceptionBody,
        itemNeedAttentionBody,
        setItemNeedAttentionBody,
        itemScanwithNoExceptionBody,
        setItemScanwithNoExceptionBody,
        daysIdleNotInFrontBody,
        setDaysIdleNotInFrontBody,
        idleExceptionBody,
        setIdleExceptionBody,
        invAuditInfoResponse,
        setInvAuditInfoResponse,
        explanationStoring,
        setExplanationStoring,
      }}
    >
      {props.children}
    </InventoryAuditContext.Provider>
  );
}
