/* eslint-disable prettier/prettier */
/* eslint-disable no-useless-escape */

export interface columns {
  columnId: string;
  sortOrder: string;
  columnTitle: string;
  IsSorted: boolean;
  IsSortable: boolean;
  columnType?: 'link' | 'rate';
  elipse?: boolean;
  actionhide?: boolean;
  handleColumnClick1?: () => void;
  handleColumnClick2?: () => void;
  subColumns?: columns[];
  index?: string;
  IsCheckbox?: boolean;
  IsSortAmount?: boolean;
}
export const ProductType = 'RPDINV';

export enum ProductPermissions {
  INVENTORY = 'INVENTORY',
}
export const sortDirection = {
  ASC: 'asc',
  DESC: 'desc',
};
export const PrintEditPriceColumns: columns[] = [
  {
    columnId: 'inventoryNumber',
    sortOrder: sortDirection.ASC,
    columnTitle: 'Item# ',
    IsSorted: true,
    IsSortable: true,
    IsCheckbox: true,
    columnType: 'link',
  },
  {
    columnId: 'inventoryDescription',
    sortOrder: sortDirection.DESC,
    columnTitle: 'Description',
    IsSorted: false,
    IsSortable: true,
    elipse: true,
  },
  {
    columnId: 'conditionDescEn',
    sortOrder: sortDirection.DESC,
    columnTitle: 'Condition',
    IsSorted: false,
    IsSortable: true,
  },
  {
    columnId: 'rate',
    sortOrder: sortDirection.DESC,
    columnTitle: 'Rate ',
    IsSorted: false,
    IsSortable: true,
    columnType: 'rate',
    // IsSortAmount: false,
    // IsSortAmount: true,

  },
  {
    columnId: 'term',
    sortOrder: sortDirection.DESC,
    columnTitle: 'Term ',
    IsSorted: false,
    IsSortable: true,
  },
  {
    columnId: 'weeklyAddOnRate',
    sortOrder: sortDirection.DESC,
    columnTitle: 'Add-On Rate',
    IsSorted: false,
    IsSortable: true,
    columnType: 'rate',
    IsSortAmount: true,
  },
  {
    columnId: 'purchaseOptionAmount',
    sortOrder: sortDirection.DESC,
    columnTitle: 'POA ',
    IsSorted: false,
    IsSortable: true,
  },
  {
    columnId: 'turns',
    sortOrder: sortDirection.DESC,
    columnTitle: 'Turns ',
    IsSorted: false,
    IsSortable: true,
  },
  {
    columnId: 'depreciationPercentage',
    sortOrder: sortDirection.DESC,
    columnTitle: 'Depreciation ',
    IsSorted: false,
    IsSortable: true,
  },
];
export const PriceModifiedDD = [
  {
    label: 'Select',
    value: '0',
  },
  {
    label: 'Yes',
    value: '1',
  },
  {
    label: 'No',
    value: '2',
  },
];

export const languageDD = [
  {
    label: 'English',
    value: 'English',
  },
  {
    label: 'Spanish',
    value: 'Spanish',
  },
];

export const rejectReasons = [
  {
    label: 'No Need - Customer',
    value: '1',
  },
  {
    label: 'No Need - Store',
    value: '2',
  },
  {
    label: 'Not Rent Ready',
    value: '3',
  },
];

export const AcceptTransferReceive: columns[] = [
  {
    columnId: 'transferringStoreNumber',
    sortOrder: sortDirection.ASC,
    columnTitle: 'Store',
    IsSorted: true,
    IsSortable: true,
    actionhide: false,
    IsCheckbox: true,
    IsSortAmount: false,
  },
  {
    columnId: 'inventoryNumber',
    sortOrder: sortDirection.DESC,
    columnTitle: 'Item #',
    IsSorted: false,
    IsSortable: true,
    actionhide: false,
    IsSortAmount: false,
  },
  {
    columnId: 'inventoryDesc',
    sortOrder: sortDirection.DESC,
    columnTitle: 'Description',
    IsSorted: false,
    IsSortable: true,
    actionhide: false,
    IsSortAmount: false,
  },
  {
    columnId: 'purchaseDate',
    sortOrder: sortDirection.DESC,
    columnTitle: 'Purchase Date',
    IsSorted: false,
    IsSortable: true,
    actionhide: false,
    IsSortAmount: false,
  },
  {
    columnId: 'purchaseCost',
    sortOrder: sortDirection.DESC,
    columnTitle: 'Cost',
    IsSorted: false,
    IsSortable: true,
    actionhide: false,
    IsSortAmount: false,
  },
  {
    columnId: 'remainingValue',
    sortOrder: sortDirection.DESC,
    columnTitle: 'RV',
    IsSorted: false,
    IsSortable: true,
    actionhide: false,
    IsSortAmount: false,
  },
  {
    columnId: 'conditionDescEn',
    sortOrder: sortDirection.DESC,
    columnTitle: 'Condition',
    IsSorted: false,
    IsSortable: true,
    actionhide: false,
    IsSortAmount: false,
  },
  {
    columnId: 'daysIdle',
    sortOrder: sortDirection.DESC,
    columnTitle: 'Days Idle',
    IsSorted: false,
    IsSortable: true,
    actionhide: false,
    IsSortAmount: false,
  },
];

// Status Constants

export const ON_RENT = 'On Rent';
export const RENT_READY = 'Rent Ready';
export const SERVICE_ON_RENT = 'Service On Rent';
export const LOANER = 'Loaner';
export const NOT_RENT_READY = 'Not Rent Ready';
export const PENDING_CHARGE_OFF = 'Pending Charge-off';
export const CHARGED_OFF = 'Charged Off';
export const HOLD = 'Hold';
export const SERVICE_CHARGED_OFF = 'Service Charged Off'
export const SERVICE_IDLE = 'Service Idle';
export const RESERVED = 'Reserved';
export const TRANSFER_IN_PROGRESS = 'Transfer In-Progress';
export const PENDING_RENTAL_AGREEMENT = 'Pending Rental Agreement';
export const PENDING_LOANER_AGREEMENT = 'Pending Loaner Agreement';
export const API_ERROR_MESSAGE = 'unable to fetch the data';
export const ADD_ON_PRICING = 'AddOnPricing'
// Inventory status reasons hardcoded values

export const Inventory_Status_Reasons =
{
  "data": {
    "referenceDetails":
      [
        {
          referenceCode: 'G',
          description: 'Sale of store - c\o of inv',
        },
        {
          referenceCode: '5',
          description: 'Cash Sale',
        }, {
          referenceCode: 'N',
          description: 'Installment note creation',
        }, {
          referenceCode: 'D',
          description: 'Note Default',
        }, {
          referenceCode: '1',
          description: 'Payout',
        }, {
          referenceCode: '2',
          description: 'Purchase option',
        }, {
          referenceCode: 'EP',
          description: 'Employee Purchase',
        }, {
          referenceCode: 'K',
          description: 'HURRICANE DAMAGE OR NATURAL DISASTER',
        }, {
          referenceCode: 'Q',
          description: 'Service Est Refused',
        }, {
          referenceCode: 'M',
          description: 'Coworker Theft ',
        }, {
          referenceCode: 'S',
          description: 'Inventory Shortage',
        }, {
          referenceCode: 'i',
          description: 'MISSING',
        }, {
          referenceCode: 'U',
          description: 'BED BUGS',
        }, {
          referenceCode: 'I',
          description: 'REGROUPING',
        }, {
          referenceCode: 'INVREV',
          description: 'Reverse Purchase Order Receipt',
        }, {
          referenceCode: '150',
          description: '150 Day Automated Charge off - Bankruptcy ',
        }, {
          referenceCode: '90',
          description: '90 Day Automated Charge off ',
        }, {
          referenceCode: 'b',
          description: 'BANKRUPTCY',
        }, {
          referenceCode: 'H',
          description: 'Hard Account',
        }, {
          referenceCode: 'm',
          description: 'KNOWN THEFT',
        }, {
          referenceCode: 'L',
          description: 'Legal',
        }, {
          referenceCode: '3',
          description: 'Skip',
        }, {
          referenceCode: '4',
          description: 'Stolen',
        }, {
          referenceCode: 'Y',
          description: 'Store Burglary(Break-In)',
        }, {
          referenceCode: 'BF',
          description: 'Burned/Fire - SIMS',
        }, {
          referenceCode: 'J',
          description: 'Junk',
        }, {
          referenceCode: 'T',
          description: 'Store Theft',
        }, {
          referenceCode: 'TR',
          description: 'Tire Return',
        }, {
          referenceCode: 'V',
          description: 'WRITE OFF TO SERVICE',
        }, {
          referenceCode: 'W',
          description: 'Waiver',
        }, {
          referenceCode: 'A',
          description: 'Advertising',
        }, {
          referenceCode: 'B',
          description: 'Burned/Fire',
        }, {
          referenceCode: 'C',
          description: 'Donation/Contribution',
        }, {
          referenceCode: 'SR',
          description: 'Skip Recovery',
        }, {
          referenceCode: 'PLR',
          description: 'Police Recovery',
        }, {
          referenceCode: 'PWR',
          description: 'Pawn Recovery',
        }, {
          referenceCode: 'CR',
          description: 'Customer Recovery',
        }, {
          referenceCode: 'COR',
          description: 'Court Ordered Recovery',
        }, {
          referenceCode: 'COWI',
          description: 'Charged_off wrong item',
        }
      ]
  }
}
export const ADD_ON_PACKAGE = 'Add-On Package';


export function makePriceOverRiderolesAllocationFn(req: any) {
  const paramGroupName = 'PriceOverrideLimit';
  const paramCategoryName = 'PriceOverride';
  const priceOverRideRoleLimitObj: any = {};

  for (let i = 0; i < req.length; i++) {
    if (
      req[i]?.paramCategoryName == paramCategoryName &&
      req[i]?.paramGroupName == paramGroupName
    ) {
      priceOverRideRoleLimitObj[req[i]?.paramKeyName] = Number(req[i]?.paramValue) * 100;
    }
  }
  const PriceOverrideRoles = [
    'FSC-CAR',
    'CAR',
    'RPMT',
    'FSC-AM',
    'AM',
    'FSC-LAM',
    'LAM',
    'FSC-SMT',
    'SMT',
    'FSC-SM',
    'SM',
    'FSC-SSM',
    'SSM',
    'CAM',
    'FSC-DMT',
    'DMT',
    'FSC-DM',
    'DM',
    'FSC-RDT',
    'RDT',
    'FSC-RD',
    'RD'
  ];
  const sortedData: any = {};
  for (let i = 0; i < PriceOverrideRoles.length; i++) {
    sortedData[PriceOverrideRoles[i]] = priceOverRideRoleLimitObj[PriceOverrideRoles[i]];
  }

  return sortedData;
}
export function makeTermOverRiderolesAllocationFn(req: any) {
  const paramGroupName = 'TermOverrideLimit';
  const paramCategoryName = 'PriceOverride';
  const priceOverRideRoleLimitObj: any = {};

  for (let i = 0; i < req.length; i++) {
    if (
      req[i]?.paramCategoryName == paramCategoryName &&
      req[i]?.paramGroupName == paramGroupName
    ) {
      priceOverRideRoleLimitObj[req[i]?.paramKeyName] = Number(req[i]?.paramValue) * 100;
    }
  }
  const PriceOverrideRoles = [
    'FSC-CAR',
    'CAR',
    'RPMT',
    'FSC-AM',
    'AM',
    'FSC-LAM',
    'LAM',
    'FSC-SMT',
    'SMT',
    'FSC-SM',
    'SM',
    'FSC-SSM',
    'SSM',
    'CAM',
    'FSC-DMT',
    'DMT',
    'FSC-DM',
    'DM',
    'FSC-RDT',
    'RDT',
    'FSC-RD',
    'RD'
  ];
  const sortedData: any = {};
  for (let i = 0; i < PriceOverrideRoles.length; i++) {
    sortedData[PriceOverrideRoles[i]] = priceOverRideRoleLimitObj[PriceOverrideRoles[i]];
  }

  return sortedData;
}