/* eslint-disable sonarjs/no-redundant-boolean */
/* eslint-disable no-var */
/* eslint-disable prettier/prettier */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable sonarjs/no-identical-functions */
/* eslint-disable sonarjs/no-duplicated-branches */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Grid,
  Typography,
  Card,
  CardContent,
  RACTable,
  RACTableCell,
  RACTableRow,
  RACButton,
  List,
  ListItem,
  CircularProgress,
  RACModalCard,
} from '@rentacenter/racstrap';
import { useEffect, useState, useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { ReactComponent as ErrorMsgIcon } from '../../assets/images/error-icon.svg';
import { ReactComponent as Logo } from '../../assets/images/Rac-Inventory-printLogo.svg';
import { ReactComponent as SomethingwentwrongIcon } from '../../assets/images/no-records-found.svg';
import { ReactComponent as NoRecordFoundIcon } from '../../assets/images/No-records.svg';
import { InventoryGlobalStyles } from '../../components/InventorySearch/Styles/InventoryGlobalStyles';
import { getInventoryAuditInfo, ManageInventoryAudit, GetEmployeeID, GetRole } from '../../api/user';
import TwoFactorComponent from '../Shared/secondAuthen';
import { InventoryAuditContext } from '../../context/InventoryAuditContext';
import { ContainerContext } from '../../app/App';

interface params {
  auditid: string;
}
export default function IdleInventoryAuditInfo() {
  const history = useHistory();
  const { auditid } = useParams<params>();
  const classes = InventoryGlobalStyles();
  const [dateBinding, setDateBinding] = useState<any>([]);
  const [noRecordFoundItemNotScan, setNoRecordFoundItemNotScan] =
  useState<any>(false);
  const [
    noRecordFoundItemScanWithException,
    setNoRecordFoundItemScanWithException,
  ] = useState<any>(false);
  const [noRecordFoundItemNeedAttention, setNoRecordFoundItemNeedAttention] =
    useState<any>(false);
  const [
    noRecordFoundItemScanWithNoException,
    setNoRecordFoundItemScanWithNoException,
  ] = useState<any>(false);
  const [noRecordFoundDaysIdle, setNoRecordFoundDaysIdle] =
  useState<any>(false);
  const [lengthItemNotScanned, setLengthItemNotScanned] = useState<any>('');
  const [lengthItemNeedAttention, setLengthItemNeedAttention] =
    useState<any>('');
  const [lengthItemScannedWithException, setLengthItemScannedWithException] =
  useState<any>('');
  const [
    lengthItemScannedWithNoException,
    setLengthItemScannedWithNoException,
  ] = useState<any>('');

  const [lengthDaysIdleNotInFront, setLengthDaysIdleNotInFront] =
    useState<any>('');
  const [lengthTotalItemScanned, setLengthTotalItemScanned] = useState<any>('');
  const [noRecordsFound, setNoRecordsFound] = useState<any>(false);
  const [errorMsg, setErrorMsg] = useState<any>('');
  const [errorMsgPopup, setErrorMsgPopup] = useState<any>(false);
  const [somethingWentWrong, setSomethingWentWrong] = useState<any>(false);
  const [finalizeString, setFinalizeString] = useState<any>("Y")
  const [headColor, setHeadColor] = useState<any>({
    itemNotScanHead: false,
    itemScannedWithExceptionHead: false,
    itemNeedAttentionHead: false,
    itemScannedWithNoExceptionHead: false,
    daysIdleHead: false,
  });
  const [emptyGridItemNotScanned, setEmptyGridItemNotScanned] = useState<any>(false);
  const [emptyGridItemWithException, setEmptyGridItemWithException] = useState<any>(false);
  const [emptyGridItemNeedAttention, setEmptyGridItemNeedAttention] = useState<any>(false);
  const [emptyGridItemWithNoException, setEmptyGridItemWithNoException] = useState<any>(false);
  const [emptyGridDaysIdle, setEmptyGridDaysIdle] = useState<any>(false);
  const [saveAlertPopup, setSaveAlertPopup] = useState<any>(false)
  const [saveSuccessPopup, setSaveSuccessPopup] = useState<any>(false)
  const [secondFactorEnabled, setsecondFactorEnabled] = useState(false);
  const [TwoFactorCompleted, setTwoFactorCompleted] = useState(false);
  const [TwoFactorCancelClick, setTwoFactorCancelClick] = useState(false);
  const [moduleName, setmoduleName] = useState('');
  const [employeeName, setEmployeeName] = useState('-');
  const [currentRole, setcurrentRole] = useState('');
  const DateVar = new Date();
  var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  const currentDay = days[DateVar.getDay()]
  const containerData = useContext(ContainerContext);
  const storeDetails = sessionStorage.getItem('storeDetails') || '';
  const encodestore = Buffer.from(storeDetails, 'base64');
  const decodestore: any = JSON.parse(encodestore.toString('ascii'));
  const currentStoreAddress = decodestore.addressLine1 ? decodestore.addressLine1 : decodestore.addressLine2
  const {
    hiddenLoader,
    setHiddenLoader,
    setViewArray,
    tabsShow,
    setTabsShow,
    colors,
    setColors,
    idleExceptionBody,
    setIdleExceptionBody,
    itemNotScannedBody,
    setItemNotScannedBody,
    itemwithExceptionBody,
    setItemwithExceptionBody,
    itemNeedAttentionBody,
    setItemNeedAttentionBody,
    itemScanwithNoExceptionBody,
    setItemScanwithNoExceptionBody,
    daysIdleNotInFrontBody,
    setDaysIdleNotInFrontBody,
    invAuditInfoResponse,
    setInvAuditInfoResponse,
    explanationStoring,
    setExplanationStoring
  } = useContext(InventoryAuditContext);
  const ScannedArray = {
    itemsNotScanned: 'Items Not Scanned :',
    itemScannedWithException: 'Items Scanned With Exception:',
    itemsNeddingAttention: 'Items Needing Attentions:',
    itemScannedNoException: 'Items Scanned No Exception :',
    item90IdleNotInFront: 'Items 90+ Days Idle Not In Front :',
    totalItemScanned: 'Total Items Scanned :',
  };
  const IdleExceptionHeaderArray = [
    { IdleHead: 'Audit Status' },
    { IdleHead: 'Audit Opened' },
    { IdleHead: 'Audit Opened By' },
    { IdleHead: 'Scan Started' },
    { IdleHead: 'Scan Ended' },
    { IdleHead: 'Time to Complete Scan' },
  ];
  const ItemNotScannedHeadArray = [
    { ItemHead: 'Item #' },
    { ItemHead: 'Serial #' },
    { ItemHead: 'Brand' },
    { ItemHead: 'Model #' },
    { ItemHead: 'Product Id' },
    { ItemHead: 'Exception  Reason' },
    { ItemHead: 'Explanation' },
    { ItemHead: 'Action' },
  ];
  const ItemScannedWithExceptionHeadArray = [
    { ItemHead: 'Item #' },
    { ItemHead: 'Serial #' },
    { ItemHead: 'Brand' },
    { ItemHead: 'Model #' },
    { ItemHead: 'Product Id' },
    { ItemHead: 'Exception Reason' },
    { ItemHead: 'Explanation' },
    { ItemHead: 'Date/Time' },
    { ItemHead: 'ScanArea' },
    { ItemHead: 'Action' },
  ];
  const ItemNeedAttentionHeadArray = [
    { ItemHead: 'Item #' },
    { ItemHead: 'Serial #' },
    { ItemHead: 'Brand' },
    { ItemHead: 'Model #' },
    { ItemHead: 'Product Id' },
    { ItemHead: 'Date/Time' },
    { ItemHead: 'Explanation' },
    { ItemHead: 'ScanArea' },
    { ItemHead: 'Action' },
  ];
  const itemScanwithNoExceptionHeadArray = [
    { ItemHead: 'Item #' },
    { ItemHead: 'Serial #' },
    { ItemHead: 'Brand' },
    { ItemHead: 'Model #' },
    { ItemHead: 'Product Id' },
    { ItemHead: 'Explanation' },
    { ItemHead: 'Date/Time' },
    { ItemHead: 'ScanArea' },
  ];
  const daysIdleNotInFrontHeadArray = [
    { ItemHead: 'Item #' },
    { ItemHead: 'Serial #' },
    { ItemHead: 'Brand' },
    { ItemHead: 'Model #' },
    { ItemHead: 'Product Id' },
    { ItemHead: 'Exception Reason' },
    { ItemHead: 'Explanation' },
    { ItemHead: 'Date/Time' },
    { ItemHead: 'ScanArea' },
  ];
  const ItemScannedPrintHeaderArray = [
    {
      scanArea: 'Scan Area',
      dep: 'Dep',
      subDep: 'SubDep',
      brand: 'Brand',
      model: 'Model #',
      item: 'Item#',
      serial: 'Serial #',
      barCodeContent: 'Barcode Content',
      scanned: 'Scanned',
      date: 'Date',
      time: 'Time',
      exception: 'Exception',
      information: 'Information',
      explanation: 'Explanation'
    }
  ]
  const ItemNotScannedHeadArrayprint = [
    {
      dep: 'Dep',
      subDep: 'Sub Dep',
      brand: 'Brand',
      model: 'Model #',
      item: 'Item#',
      serial: 'Serial #',
      exception: 'Exception',
      explanation: 'Explanation'
    }
  ];
  const ItemNeedAttentionHeadArrayPrint = [
    {
      scanArea: 'Scan Area',
      dep: 'Dep',
      subDep: 'Sub Dep',
      brand: 'Brand',
      model: 'Model #',
      item: 'Item #',
      serial: 'Serial #',
      barCodeContent: 'BarcodeContent',
      date: 'Date',
      time: 'Time',
      ActionNeeded: 'Action Needed',
      Information: 'Information',
    },

  ];
  const daysIdleNotInFrontHeadArrayPrint = [

    {
      scanArea: 'Scan Area',
      daysIdle: 'Days Idle',
      dep: 'Dep',
      subDep: 'Sub Dep',
      brand: 'Brand',
      model: 'Model #',
      serial: 'Serial #',
      barCodeContent: 'Serial #',
      Accumrent: 'Accum Rent',
      remaningValue: 'R/V'
    }
  ];

  useEffect(() => {
    setmoduleName('FinalizeAudit')
    const GettingRole = async () => {
      const GetcurrentUser = await GetEmployeeID();
      if (GetcurrentUser.status == 200) {
        const GetcurrentUserResponse = GetcurrentUser.data;
        const EmployeeId = GetcurrentUserResponse.employeeId;
        setEmployeeName(GetcurrentUserResponse?.firstName + ' ' + GetcurrentUserResponse?.lastName);
        const GetMenuPayload = {
          coworkerId: EmployeeId,
        };
        const GetMenuDetails: any = await GetRole(GetMenuPayload);

        if (GetMenuDetails.status == 200) {
          const GetMenuDetailsResponse = GetMenuDetails.data;
          const currentRoleRes = GetMenuDetailsResponse.coworkerProfile.role;

          setcurrentRole(currentRoleRes);
        }
      }
    };
    if (containerData) {
      setcurrentRole(containerData.GetRole());
      setEmployeeName(containerData.GetName());
    } else {
      GettingRole();
    }
  }, []);
  useEffect(() => {

    if (TwoFactorCompleted && !TwoFactorCancelClick) {
      setTwoFactorCancelClick(false);
      setTwoFactorCompleted(false);
      setsecondFactorEnabled(false);
      handleFinalizeAudit();
    }

    if (TwoFactorCancelClick && !TwoFactorCompleted) {
      setsecondFactorEnabled(false);
      setTwoFactorCancelClick(false);
      setTwoFactorCompleted(false);
    }
  }, [TwoFactorCompleted, TwoFactorCancelClick]);


  useEffect(() => {
    if (invAuditInfoResponse && invAuditInfoResponse?.auditId == auditid) {
      setIdleExceptionBody({
        ...idleExceptionBody,
        AuditStatus: invAuditInfoResponse?.auditStatus,
        AuditDate: invAuditInfoResponse?.auditDate,
        AuditBy: invAuditInfoResponse?.auditBy,
        ScanStarted: invAuditInfoResponse?.scanStarted,
        ScanEnded: invAuditInfoResponse?.scanEnded,
        TotalScanHours: invAuditInfoResponse?.totalScanHours,
        ClosedBy: invAuditInfoResponse?.closedBy,
        StoreNumber: invAuditInfoResponse?.storeNumber,
        AuditCloseDate: invAuditInfoResponse?.auditCloseDate,
      });
      console.log("invAusditInfnoResponse", invAuditInfoResponse)
      const InfoResponseItemNotScanned = invAuditInfoResponse.auditedInventories.filter(
        (obj: { categoryCode: string; }) => obj.categoryCode == 'NNS'
      );

      if (InfoResponseItemNotScanned.length > 0) {
        setItemNotScannedBody(InfoResponseItemNotScanned);

      } else {
        setNoRecordFoundItemNotScan(true);
        setEmptyGridItemNotScanned(true);
      }
      const lenItemNotScan = InfoResponseItemNotScanned.length;
      setLengthItemNotScanned(lenItemNotScan);

      const InfoResponseItemScannedWithException = invAuditInfoResponse.auditedInventories.filter(
        (obj: { categoryCode: string; }) => obj.categoryCode == 'ISWE'
      );
      if (InfoResponseItemScannedWithException.length > 0) {
        setItemwithExceptionBody(InfoResponseItemScannedWithException);

      } else {
        setNoRecordFoundItemScanWithException(true);
        setEmptyGridItemWithException(true);
      }
      const lenException = InfoResponseItemScannedWithException.length;
      setLengthItemScannedWithException(lenException);

      const InfoResponseItemsNeedAttention = invAuditInfoResponse.auditedInventories.filter(
        (obj: { categoryCode: string; }) => obj.categoryCode == 'INA'
      );
      if (InfoResponseItemsNeedAttention.length > 0) {
        setItemNeedAttentionBody(InfoResponseItemsNeedAttention);
      } else {
        setNoRecordFoundItemNeedAttention(true);
        setEmptyGridItemNeedAttention(true);
      }
      const lenNeedAttention = InfoResponseItemsNeedAttention.length;
      setLengthItemNeedAttention(lenNeedAttention);
      const InfoResponseItemScannedWithNoException = invAuditInfoResponse.auditedInventories.filter(
        (obj: { categoryCode: string; }) => obj.categoryCode == 'ISNE'
      );
      if (InfoResponseItemScannedWithNoException.length > 0) {
        setItemScanwithNoExceptionBody(
          InfoResponseItemScannedWithNoException
        );
      } else {
        setNoRecordFoundItemScanWithNoException(true);
        setEmptyGridItemWithNoException(true);
      }

      const lenNoException = InfoResponseItemScannedWithNoException.length;
      setLengthItemScannedWithNoException(lenNoException);
      const InfoResponseDaysIdleNotInFront = invAuditInfoResponse.auditedInventories.filter(
        (obj: { categoryCode: string; }) => obj.categoryCode == 'NNIS'
      );
      if (InfoResponseDaysIdleNotInFront.length > 0) {
        setDaysIdleNotInFrontBody(InfoResponseDaysIdleNotInFront);
      } else {
        setNoRecordFoundDaysIdle(true);
        setEmptyGridDaysIdle(true);
      }
      const lenDaysIdle = InfoResponseDaysIdleNotInFront.length;
      setLengthDaysIdleNotInFront(lenDaysIdle);
      const totalScan =
        lenException + lenNeedAttention + lenNoException + lenDaysIdle;
      setLengthTotalItemScanned(totalScan);
      console.log("explanationStoring", explanationStoring);

    }

    else {
      InventoryAuditInfoFnc();
    }
    console.log('auditId', auditid);
  }, []);

  const InventoryAuditInfoFnc = async () => {
    setIdleExceptionBody({
      ...idleExceptionBody,
      AuditStatus: '',
      AuditDate: '',
      AuditBy: '',
      ScanStarted: '',
      ScanEnded: '',
      TotalScanHours: '',
      ClosedBy: '',
      StoreNumber: '',
      AuditCloseDate: '',
    })
    setItemNotScannedBody([]);
    setItemwithExceptionBody([]);
    setItemNeedAttentionBody([]);
    setItemScanwithNoExceptionBody([]);
    setDaysIdleNotInFrontBody([]);
    setHiddenLoader(true);
    const InfoResponse: any = await getInventoryAuditInfo(auditid);
    setHiddenLoader(false);
    if (InfoResponse.status == 200) {
      {
        const finalInfoResponse = InfoResponse?.data;
        console.log('finalInfoResponse', finalInfoResponse);
        setInvAuditInfoResponse(finalInfoResponse);
        finalInfoResponse.length == 0
          ? setNoRecordsFound(true)
          : setIdleExceptionBody({
            ...idleExceptionBody,
            AuditStatus: InfoResponse?.data?.auditStatus,
            AuditDate: InfoResponse?.data?.auditDate,
            AuditBy: InfoResponse?.data?.auditBy,
            ScanStarted: InfoResponse?.data?.scanStarted,
            ScanEnded: InfoResponse?.data?.scanEnded,
            TotalScanHours: InfoResponse?.data?.totalScanHours,
            ClosedBy: InfoResponse?.data?.closedBy,
            StoreNumber: InfoResponse?.data?.storeNumber,
            AuditCloseDate: InfoResponse?.data?.auditCloseDate,
          });
        const InfoResponseItemNotScanned = finalInfoResponse.auditedInventories.filter(
          (obj: { categoryCode: string; }) => obj.categoryCode == 'NNS'
        );
        if (InfoResponseItemNotScanned.length > 0) {
          setItemNotScannedBody(InfoResponseItemNotScanned);
        } else {
          setNoRecordFoundItemNotScan(true);
          setEmptyGridItemNotScanned(true);
        }
        const lenItemNotScan = InfoResponseItemNotScanned.length;
        setLengthItemNotScanned(lenItemNotScan);

        const InfoResponseItemScannedWithException = finalInfoResponse.auditedInventories.filter(
          (obj: { categoryCode: string; }) => obj.categoryCode == 'ISWE'
        );
        // const dateTime = finalInfoResponse.auditedInventories.map((obj: { inventoryAuditDate: string; }) => {
        //   const split = obj.inventoryAuditDate?.split(' ');
        //   const InventoryDate = split[0];
        //   const InventoryTime = split[1].concat(split[2]);
        //   setDateBinding(InventoryDate);
        //   setTimeBinding(InventoryTime);
        // });
        if (InfoResponseItemScannedWithException.length > 0) {
          setItemwithExceptionBody(InfoResponseItemScannedWithException);
        } else {
          setNoRecordFoundItemScanWithException(true);
          setEmptyGridItemWithException(true);

        }
        const lenException = InfoResponseItemScannedWithException.length;
        setLengthItemScannedWithException(lenException);

        const InfoResponseItemsNeedAttention = finalInfoResponse.auditedInventories.filter(
          (obj: { categoryCode: string; }) => obj.categoryCode == 'INA'
        );
        if (InfoResponseItemsNeedAttention.length > 0) {
          setItemNeedAttentionBody(InfoResponseItemsNeedAttention);
        } else {
          setNoRecordFoundItemNeedAttention(true);
          setEmptyGridItemNeedAttention(true);
        }
        const lenNeedAttention = InfoResponseItemsNeedAttention.length;
        setLengthItemNeedAttention(lenNeedAttention);
        const InfoResponseItemScannedWithNoException = finalInfoResponse.auditedInventories.filter(
          (obj: { categoryCode: string; }) => obj.categoryCode == 'ISNE'
        );
        if (InfoResponseItemScannedWithNoException.length > 0) {
          setItemScanwithNoExceptionBody(
            InfoResponseItemScannedWithNoException
          );
        } else {
          setNoRecordFoundItemScanWithNoException(true);
          setEmptyGridItemWithNoException(true);
        }

        const lenNoException = InfoResponseItemScannedWithNoException.length;
        setLengthItemScannedWithNoException(lenNoException);
        const InfoResponseDaysIdleNotInFront = finalInfoResponse.auditedInventories.filter(
          (obj: { categoryCode: string; }) => obj.categoryCode == 'NNIS'
        );
        if (InfoResponseDaysIdleNotInFront.length > 0) {
          setDaysIdleNotInFrontBody(InfoResponseDaysIdleNotInFront);
        } else {
          setNoRecordFoundDaysIdle(true);
          setEmptyGridDaysIdle(true);
        }
        const lenDaysIdle = InfoResponseDaysIdleNotInFront.length;
        setLengthDaysIdleNotInFront(lenDaysIdle);
        const totalScan =
          lenException + lenNeedAttention + lenNoException + lenDaysIdle;
        setLengthTotalItemScanned(totalScan);
      }
    } 
    else if (InfoResponse?.status == 400) {
      setErrorMsgPopup(true);
      setErrorMsg(InfoResponse?.data?.errors[0]?.error);
    } else {
      setSomethingWentWrong(true);
    }
  };

  const datecheck1 = (value:any) => {
    if(value)
    {const ampm = idleExceptionBody?.AuditDate;
    const dateObj = new Date(ampm);
    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    };
    return dateObj.toLocaleString('en-US', options).replace(',', '');}
  };
const datecheck2 = (value:any)=>{
if(value){
  const ampm = value;
  const dateObj = new Date(ampm);
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true
  };
  return dateObj.toLocaleString('en-US', options).replace(',', '');
}    
};

 const datecheck3 = (value:any) => {
    if(value)
    {const ampm = value;
    const dateObj = new Date(ampm);
    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    };
    return dateObj.toLocaleString('en-US', options)}
  };

  const handleSave = async () => {
    setHiddenLoader(true);
    const resUniqueValue: any = explanationStoring?.filter(
      (obj:any, index:any) => {
        return (
          index ===
          explanationStoring.findIndex(
            (o:any) => obj.inventoryNumber === o.inventoryNumber
          )
        );
      }
    );
    const save = {
      auditId: Number(auditid),
      inventoryInfo: resUniqueValue
    }
    const saveResponse: any = await ManageInventoryAudit(save)
    setHiddenLoader(false);
    if (saveResponse?.status == 200) {

      setSaveSuccessPopup(true)
    }
    else if (saveResponse?.status == 400) {
      setErrorMsgPopup(true);
      setErrorMsg(saveResponse?.data?.errors[0]?.error);
    } else {
      setSomethingWentWrong(true);
    }
  };

  const handleFinalizeAudit = async () => {
    if (explanationStoring?.length > 0) {
      setSaveAlertPopup(true)
    }
    else {
      setSaveAlertPopup(false)
      const finalize = {
        auditId: Number(auditid),
        isFinalizeAudit: finalizeString
      }
      setHiddenLoader(true);
      const finalizeResponse: any = await ManageInventoryAudit(finalize)
      setHiddenLoader(false);
      if (finalizeResponse.status == 200) {
        finalizePrint(document.getElementById('finalizeprint'))
        InventoryAuditInfoFnc();
      }
      else if (finalizeResponse?.status == 400) {
        setErrorMsgPopup(true);
        setErrorMsg(finalizeResponse?.data?.errors[0]?.error);
      } else {
        setSomethingWentWrong(true);
      }
    }

  }

  const hideLengthyText = (Text: any, MaxLength: number, Mask: any) => {
    return Text?.length > MaxLength
      ? Text.substring(0, MaxLength) + Mask
      : Text;
  };
  const idleInventoryExceptionHead = () => (
    <>
      {IdleExceptionHeaderArray?.map((obj: any, index: any) => {
        return <RACTableCell key={index} className={classes.fontStyle}>{obj.IdleHead}</RACTableCell>;
      })}
    </>
  );
  function timeCalculation(params: any) {
    if (params) {
      return params.split(":")[0] + " hr " + params.split(":")[1] + " min "
    }
    else {
      return "-"
    }
  };
  const reportDateFormation = (value: any) => {
    const valueArray = value?.split(' ');
    return valueArray?.length > 0 ? valueArray[0] : '';
  }
  const reportTimeFormation = (value: any) => {
    const valueArray = value?.split(' ');
    return valueArray?.length > 2 ? valueArray[1].concat(valueArray[2]) : '';
  }
  const idleInventoryExceptionBody = () => (
    <>
      <RACTableRow style={{ background: 'none' }}>
        <RACTableCell style={{ width: '16%' }}>{idleExceptionBody?.AuditStatus ? idleExceptionBody?.AuditStatus : <span style={{ marginLeft: '11%' }}>-</span>}</RACTableCell>
        <RACTableCell style={{ width: '17%' }}>{idleExceptionBody?.AuditDate ? idleExceptionBody?.AuditDate : <span style={{ marginLeft: '11%' }}>-</span>}</RACTableCell>
        <RACTableCell style={{ width: '17%' }}>{idleExceptionBody?.AuditBy ? idleExceptionBody?.AuditBy : <span style={{ marginLeft: '11%' }}>-</span>}</RACTableCell>
        <RACTableCell style={{ width: '18%' }}>{idleExceptionBody?.ScanStarted ? idleExceptionBody?.ScanStarted : <span style={{ marginLeft: '11%' }}>-</span>}</RACTableCell>
        <RACTableCell style={{ width: '20%' }}>{idleExceptionBody?.ScanEnded ? idleExceptionBody?.ScanEnded : <span style={{ marginLeft: '11%' }}>-</span>}</RACTableCell>
        <RACTableCell style={{ width: '20%' }}>{idleExceptionBody?.TotalScanHours ? timeCalculation(idleExceptionBody?.TotalScanHours):<span style={{ marginLeft: '33%' }}>-</span> }</RACTableCell>
      </RACTableRow>
    </>
  );

  const itemNotScannedHeadFnc = () => (
    <>
      {emptyGridItemNotScanned ? null :
        ItemNotScannedHeadArray?.map((obj: any, index: any) => {
          return (
            <RACTableCell key={index} className={classes.fontStyle}>
              {obj.ItemHead}
            </RACTableCell>
          );
        })}
    </>
  );
  const itemNotScannedBodyFnc = () => (
    <>
      {noRecordFoundItemNotScan ? (
        <>
          <RACTableRow style={{ background: 'none' }}>
            <RACTableCell style={{ float: 'left', width: '97%' }}>
              <Grid className={`${classes.textCenter} ${classes.mt5}`}>
                <NoRecordFoundIcon />
                <Typography className={`${classes.subTitle} ${classes.mb3}`}>
                  No records found
                </Typography>
              </Grid>
            </RACTableCell>
          </RACTableRow>
        </>
      ) : (
        <>
          {itemNotScannedBody?.map((obj: any, index: any) => {
            return (
              <RACTableRow key={index} style={{ background: 'none', fontFamily: 'OpenSans-semibold' }}>
                <RACTableCell
                  style={{ color: '#2179FE', cursor: 'pointer', fontFamily: 'OpenSans-semibold', width: '12%' }}
                  onClick={(e) => {
                    history.push({
                      pathname: `/inventory/${obj.inventoryNumber}/inventoryinformation`,
                    });
                  }}
                >
                  {obj.inventoryNumber}
                </RACTableCell>
                <RACTableCell style={{ fontFamily: 'OpenSans-semibold', color: '#4A5174', width: '14%' }}>{obj.serialNumber ? obj.serialNumber : <span style={{ marginLeft: '11%' }}>-</span>}</RACTableCell>
                <RACTableCell style={{ fontFamily: 'OpenSans-semibold', color: '#4A5174', width: '15%' }}>{obj.brand ? obj.brand : <span style={{ marginLeft: '11%' }}>-</span>}</RACTableCell>
                <RACTableCell style={{ fontFamily: 'OpenSans-semibold', color: '#4A5174', width: '14%' }}>{obj.modelNumber ? obj.modelNumber : <span style={{ marginLeft: '11%' }}>-</span>}</RACTableCell>
                <RACTableCell style={{ fontFamily: 'OpenSans-semibold', color: '#4A5174', width: '12%' }}>{obj.productId ? obj.productId : <span style={{ marginLeft: '11%' }}>-</span>}</RACTableCell>
                <RACTableCell style={{ fontFamily: 'OpenSans-semibold', color: '#4A5174', width: '17%' }} title={obj.exceptionReason}>
                  {obj.exceptionReason ? hideLengthyText(obj.exceptionReason, 25, '...') : <span style={{ marginLeft: '11%' }}>-</span>}
                </RACTableCell>
                <RACTableCell style={{ fontFamily: 'OpenSans-semibold', color: '#4A5174', width: '14%' }} title={obj.explanation}>
                  {obj.explanation ? hideLengthyText(obj.explanation, 25, '...') : <span style={{ marginLeft: '11%' }}>-</span>}
                </RACTableCell>
                <RACTableCell>
                  <RACButton
                    variant="outlined"
                    color="secondary"
                    className={classes.btnColor}
                    onClick={(e) => {
                      handleViewItemNotScannedfnc(index, obj);
                    }}
                  >
                    View
                  </RACButton>
                </RACTableCell>
              </RACTableRow>
            );
          })}
        </>
      )}
    </>
  );

  const itemScannedWithExceptionHeadFnc = () => (
    <>
      {emptyGridItemWithException ? null :
        ItemScannedWithExceptionHeadArray?.map((obj: any, index: any) => {
          return (
            <RACTableCell key={index} className={classes.fontStyle}>
              {obj.ItemHead}
            </RACTableCell>
          );
        })}
    </>
  );

  const itemScannedWithExceptionBodyFnc = () => (
    <>
      {noRecordFoundItemScanWithException ? (
        <>
          <RACTableRow style={{ background: 'none' }}>
            <RACTableCell style={{ float: 'left', width: '97%' }}>
              <Grid className={`${classes.textCenter} ${classes.mt5}`}>
                <NoRecordFoundIcon />
                <Typography className={`${classes.subTitle} ${classes.mb3}`}>
                  No records found
                </Typography>
              </Grid>
            </RACTableCell>
          </RACTableRow>
        </>
      ) : (
        <>
          {itemwithExceptionBody?.map((obj: any, index: any) => {
            return (
              <RACTableRow key={index} style={{ background: 'none' }}>
                <RACTableCell style={{ color: '#2179FE', cursor: 'pointer', fontFamily: 'OpenSans-semibold', width: '11%' }}
                  onClick={(e) => {
                    history.push({
                      pathname: `/inventory/${obj.inventoryNumber}/inventoryinformation`,
                    });
                  }}>
                  {obj.inventoryNumber}
                </RACTableCell>
                <RACTableCell style={{ fontFamily: 'OpenSans-semibold', color: '#4A5174', width: '10%' }}>{obj.serialNumber ? obj.serialNumber : <span style={{ marginLeft: '11%' }}>-</span>}</RACTableCell>
                <RACTableCell style={{ fontFamily: 'OpenSans-semibold', color: '#4A5174', width: '9%' }}>{obj.brand ? obj.brand : <span style={{ marginLeft: '11%' }}>-</span>}</RACTableCell>
                <RACTableCell style={{ fontFamily: 'OpenSans-semibold', color: '#4A5174', width: '9%' }}>{obj.modelNumber ? obj.modelNumber : <span style={{ marginLeft: '11%' }}>-</span>}</RACTableCell>
                <RACTableCell style={{ fontFamily: 'OpenSans-semibold', color: '#4A5174', width: '10%' }}>{obj.productId ? obj.productId : <span style={{ marginLeft: '16%' }}>-</span>}</RACTableCell>
                <RACTableCell style={{ fontFamily: 'OpenSans-semibold', color: '#4A5174', width: '12%' }} title={obj.exceptionReason}>
                  {obj.exceptionReason ? hideLengthyText(obj.exceptionReason, 25, '...') : <span style={{ marginLeft: '27%' }}>-</span>}
                </RACTableCell>
                <RACTableCell style={{ fontFamily: 'OpenSans-semibold', color: '#4A5174', width: '11%' }} title={obj.explanation}>
                  {obj.explanation ? hideLengthyText(obj.explanation, 25, '...') : <span style={{ marginLeft: '21%' }}>-</span>}
                </RACTableCell>
                <RACTableCell style={{ fontFamily: 'OpenSans-semibold', color: '#4A5174', width: '12%' }}>{obj.inventoryAuditDate ? obj.inventoryAuditDate : <span style={{ marginLeft: '18%' }}>-</span>}</RACTableCell>
                <RACTableCell style={{ fontFamily: 'OpenSans-semibold', color: '#4A5174', width: '10%' }}>{obj.scanArea ? obj.scanArea : <span style={{ marginLeft: '19%' }}>-</span>}</RACTableCell>
                <RACTableCell>
                  <RACButton
                    variant="outlined"
                    color="secondary"
                    className={classes.btnColor}
                    onClick={(e) => {
                      handleViewItemWithExceptionfnc(index, obj);
                    }}
                  >
                    View
                  </RACButton>
                </RACTableCell>
              </RACTableRow>
            );
          })}
        </>
      )}
    </>
  );

  const itemsNeedAttentionHeadFnc = () => (
    <>
      {emptyGridItemNeedAttention ? null :
        ItemNeedAttentionHeadArray?.map((obj: any, index: any) => {
          return (
            <RACTableCell key={index} className={classes.fontStyle}>
              {obj.ItemHead}
            </RACTableCell>
          );
        })}
    </>
  );
  const itemsNeedAttentionBodyFnc = () => (
    <>
      {noRecordFoundItemNeedAttention ? (
        <>
          <RACTableRow style={{ background: 'none' }}>
            <RACTableCell style={{ float: 'left', width: '97%' }}>
              <Grid className={`${classes.textCenter} ${classes.mt5}`}>
                <NoRecordFoundIcon />
                <Typography className={`${classes.subTitle} ${classes.mb3}`}>
                  No records found
                </Typography>
              </Grid>
            </RACTableCell>
          </RACTableRow>
        </>
      ) : (
        <>
          {itemNeedAttentionBody?.map((obj: any, index: any) => {
            return (
              <RACTableRow key={index} style={{ background: 'none' }}>
                <RACTableCell style={{ color: '#2179FE', cursor: 'pointer', fontFamily: 'OpenSans-semibold', width: '11%' }} onClick={(e) => {
                  history.push({
                    pathname: `/inventory/${obj.inventoryNumber}/inventoryinformation`,
                  });
                }}>
                  {obj.inventoryNumber}
                </RACTableCell>
                <RACTableCell style={{ fontFamily: 'OpenSans-semibold', color: '#4A5174', width: '11%' }}>{obj.serialNumber ? obj.serialNumber : <span style={{ marginLeft: '11%' }}>-</span>}</RACTableCell>
                <RACTableCell style={{ fontFamily: 'OpenSans-semibold', color: '#4A5174', width: '11%' }}>{obj.brand ? obj.brand : <span style={{ marginLeft: '11%' }}>-</span>}</RACTableCell>
                <RACTableCell style={{ fontFamily: 'OpenSans-semibold', color: '#4A5174', width: '11%' }}>{obj.modelNumber ? obj.modelNumber : <span style={{ marginLeft: '11%' }}>-</span>}</RACTableCell>
                <RACTableCell style={{ fontFamily: 'OpenSans-semibold', color: '#4A5174', width: '11%' }}>{obj.productId ? obj.productId : <span style={{ marginLeft: '17%' }}>-</span>}</RACTableCell>
                <RACTableCell style={{ fontFamily: 'OpenSans-semibold', color: '#4A5174', width: '13%' }}>{obj.inventoryAuditDate ? obj.inventoryAuditDate : <span style={{ marginLeft: '16%' }}>-</span>}</RACTableCell>
                <RACTableCell style={{ fontFamily: 'OpenSans-semibold', color: '#4A5174', width: '13%' }} title={obj.explanation}>
                  {obj.explanation ? hideLengthyText(obj.explanation, 25, '...') : <span style={{ marginLeft: '21%' }}>-</span>}
                </RACTableCell>
                <RACTableCell style={{ fontFamily: 'OpenSans-semibold', color: '#4A5174', width: '10%' }}>{obj.scanArea ? obj.scanArea : <span style={{ marginLeft: '18%' }}>-</span>}</RACTableCell>
                <RACTableCell>
                  <RACButton
                    variant="outlined"
                    color="secondary"
                    className={classes.btnColor}
                    onClick={(e) => {
                      handleViewItemNeedAttentionfnc(index, obj);
                    }}
                  >
                    View
                  </RACButton>
                </RACTableCell>
              </RACTableRow>
            );
          })}
        </>
      )}
    </>
  );
  const itemScanWithNoExceptionHeadFnc = () => (
    <>
      {emptyGridItemWithNoException ? null :
        itemScanwithNoExceptionHeadArray?.map((obj: any, index: any) => {
          return (
            <RACTableCell key={index} className={classes.fontStyle}>
              {obj.ItemHead}
            </RACTableCell>
          );
        })}
    </>
  );
  const itemScanWithNoExceptionBodyFnc = () => (
    <>
      {noRecordFoundItemScanWithNoException ? (
        <>
          <RACTableRow style={{ background: 'none' }}>
            <RACTableCell style={{ float: 'left', width: '97%' }}>
              <Grid className={`${classes.textCenter} ${classes.mt5}`}>
                <NoRecordFoundIcon />
                <Typography className={`${classes.subTitle} ${classes.mb3}`}>
                  No records found
                </Typography>
              </Grid>
            </RACTableCell>
          </RACTableRow>
        </>
      ) : (
        <>
          {itemScanwithNoExceptionBody?.map((obj: any, index: any) => {
            return (
              <RACTableRow key={index} style={{ background: 'none' }}>
                <RACTableCell style={{ color: '#2179FE', cursor: 'pointer', fontFamily: 'OpenSans-semibold', width: '13%' }} onClick={(e) => {
                  history.push({
                    pathname: `/inventory/${obj.inventoryNumber}/inventoryinformation`,
                  });
                }}>
                  {obj.inventoryNumber}
                </RACTableCell>
                <RACTableCell style={{ fontFamily: 'OpenSans-semibold', color: '#4A5174', width: '13%' }}>{obj.serialNumber ? obj.serialNumber : <span style={{ marginLeft: '11%' }}>-</span>}</RACTableCell>
                <RACTableCell style={{ fontFamily: 'OpenSans-semibold', color: '#4A5174', width: '13%' }}>{obj.brand ? obj.brand : <span style={{ marginLeft: '11%' }}>-</span>}</RACTableCell>
                <RACTableCell style={{ fontFamily: 'OpenSans-semibold', color: '#4A5174', width: '13%' }}>{obj.modelNumber ? obj.modelNumber : <span style={{ marginLeft: '11%' }}>-</span>}</RACTableCell>
                <RACTableCell style={{ fontFamily: 'OpenSans-semibold', color: '#4A5174', width: '13%' }}>{obj.productId ? obj.productId : <span style={{ marginLeft: '11%' }}>-</span>}</RACTableCell>
                <RACTableCell style={{ fontFamily: 'OpenSans-semibold', color: '#4A5174', width: '13%' }} title={obj.explanation}>
                  {obj.explanation ? hideLengthyText(obj.explanation, 25, '...') : <span style={{ marginLeft: '11%' }}>-</span>}
                </RACTableCell>
                <RACTableCell style={{ fontFamily: 'OpenSans-semibold', color: '#4A5174', width: '13%' }}>{obj.inventoryAuditDate ? obj.inventoryAuditDate : <span style={{ marginLeft: '11%' }}>-</span>}</RACTableCell>
                <RACTableCell style={{ fontFamily: 'OpenSans-semibold', color: '#4A5174', width: '13%' }}>{obj.scanArea ? obj.scanArea : <span style={{ marginLeft: '11%' }}>-</span>}</RACTableCell>
              </RACTableRow>
            );
          })}
        </>
      )}
    </>
  );

  const daysIdleNotInFrontHeadFnc = () => (
    <>
      {emptyGridDaysIdle ? null :
        daysIdleNotInFrontHeadArray?.map((obj: any, index: any) => {
          return (
            <RACTableCell key={index} className={classes.fontStyle}>
              {obj.ItemHead}
            </RACTableCell>
          );
        })}
    </>
  );
  const daysIdleNotInFrontBodyFnc = () => (
    <>
      {noRecordFoundDaysIdle ? (
        <>
          <RACTableRow style={{ background: 'none' }}>
            <RACTableCell style={{ float: 'left', width: '97%' }}>
              <Grid className={`${classes.textCenter} ${classes.mt5}`}>
                <NoRecordFoundIcon />
                <Typography className={`${classes.subTitle} ${classes.mb3}`}>
                  No records found
                </Typography>
              </Grid>
            </RACTableCell>
          </RACTableRow>
        </>
      ) : (
        <>
          {daysIdleNotInFrontBody?.map((obj: any, index: any) => {
            return (
              <RACTableRow key={index} style={{ background: 'none' }}>
                <RACTableCell style={{ color: '#2179FE', cursor: 'pointer', fontFamily: 'OpenSans-semibold', width: '11%' }} onClick={(e) => {
                  history.push({
                    pathname: `/inventory/${obj.inventoryNumber}/inventoryinformation`,
                  });
                }}>
                  {obj.inventoryNumber}
                </RACTableCell>
                <RACTableCell style={{ fontFamily: 'OpenSans-semibold', color: '#4A5174', width: '11%' }}>{obj.serialNumber ? obj.serialNumber : <span style={{ marginLeft: '11%' }}>-</span>}</RACTableCell>
                <RACTableCell style={{ fontFamily: 'OpenSans-semibold', color: '#4A5174', width: '11%' }}>{obj.brand ? obj.brand : <span style={{ marginLeft: '11%' }}>-</span>}</RACTableCell>
                <RACTableCell style={{ fontFamily: 'OpenSans-semibold', color: '#4A5174', width: '11%' }}>{obj.modelNumber ? obj.modelNumber : <span style={{ marginLeft: '11%' }}>-</span>}</RACTableCell>
                <RACTableCell style={{ fontFamily: 'OpenSans-semibold', color: '#4A5174', width: '11%' }}>{obj.productId ? obj.productId : <span style={{ marginLeft: '11%' }}>-</span>}</RACTableCell>
                <RACTableCell style={{ fontFamily: 'OpenSans-semibold', color: '#4A5174', width: '13%' }} title={obj.exceptionReason}>
                  {obj.exceptionReason ? hideLengthyText(obj.exceptionReason, 25, '...') : <span style={{ marginLeft: '25%' }}>-</span>}
                </RACTableCell>
                <RACTableCell style={{ fontFamily: 'OpenSans-semibold', color: '#4A5174', width: '11%' }} title={obj.explanation}>
                  {obj.explanation ? hideLengthyText(obj.explanation, 25, '...') : <span style={{ marginLeft: '20%' }}>-</span>}
                </RACTableCell>
                <RACTableCell style={{ fontFamily: 'OpenSans-semibold', color: '#4A5174', width: '11%' }}>{obj.inventoryAuditDate ? obj.inventoryAuditDate : <span style={{ marginLeft: '11%' }}>-</span>}</RACTableCell>
                <RACTableCell style={{ fontFamily: 'OpenSans-semibold', color: '#4A5174', width: '11%' }}>{obj.scanArea ? obj.scanArea : <span style={{ marginLeft: '11%' }}>-</span>}</RACTableCell>
              </RACTableRow>
            );
          })}
        </>
      )}
    </>
  );
  const handleViewItemNeedAttentionfnc = (index: any, obj: any) => {
    setViewArray(index);
    idleExceptionBody?.AuditStatus == "Close" ?
      history.push({
        pathname: `/inventory/audit/${obj.inventoryNumber}/${auditid}/explanation?page=3&status=1`,
      }) :
      history.push({
        pathname: `/inventory/audit/${obj.inventoryNumber}/${auditid}/explanation?page=3&status=0`,
      })
  };
  const handleViewItemWithExceptionfnc = (index: any, obj: any) => {
    setViewArray(index);
    idleExceptionBody?.AuditStatus == "Close" ?
      history.push({
        pathname: `/inventory/audit/${obj.inventoryNumber}/${auditid}/explanation?page=2&status=1`,
      }) :
      history.push({
        pathname: `/inventory/audit/${obj.inventoryNumber}/${auditid}/explanation?page=2&status=0`,
      })
    console.log('inventoryNumber');

  };
  const handleViewItemNotScannedfnc = (index: any, obj: any) => {
    setViewArray(index);
    idleExceptionBody?.AuditStatus == "Close" ?
      history.push({
        pathname: `/inventory/audit/${obj.inventoryNumber}/${auditid}/explanation?page=1&status=1`,
      }) :
      history.push({
        pathname: `/inventory/audit/${obj.inventoryNumber}/${auditid}/explanation?page=1&status=0`,
      })
  };


  const handlePrint = (html: any) => {
    if (html !== undefined && html !== null) {

      const divContents = html.innerHTML;
      const a: any = window.open("", '_blank');
      a?.document.write('<html>');
      a?.document.write(divContents);
      a?.document.write('</html>');
      a?.document.close();
      a?.print();
    }
  };
  const finalizePrint = (html: any) => {
    if (html !== undefined && html !== null) {

      const divContents = html.innerHTML;
      const a: any = window.open("", '_blank');
      a?.document.write('<html>');
      a?.document.write(divContents);
      a?.document.write('</html>');
      a?.document.close();
      a?.print();
    }
  };

  const handleItemNotScanned = (clicked1: any) => {
    if (clicked1 == 'ItemNotScanned') {
      setTabsShow({
        ...tabsShow,
        ItemNotScannedShow: true,
        ItemScannedWithExceptionShow: false,
        ItemsNeedAttentionShow: false,
        ItemScanwithNoExceptionShow: false,
        DaysIdleNotInFrontShow: false,
      });
      setHeadColor({
        ...headColor, itemNotScanHead: true,
        itemScannedWithExceptionHead: false,
        itemNeedAttentionHead: false,
        itemScannedWithNoExceptionHead: false,
        daysIdleHead: false,
      })
      setColors({
        ...colors,
        color1: true,
        color2: false,
        color3: false,
        color4: false,
        color5: false,
      });
    }
  };
  const handleItemScannedWithException = (clicked2: any) => {
    if (clicked2 == 'ItemScannedWithException') {
      setTabsShow({
        ...tabsShow,
        ItemNotScannedShow: false,
        ItemScannedWithExceptionShow: true,
        ItemsNeedAttentionShow: false,
        ItemScanwithNoExceptionShow: false,
        DaysIdleNotInFrontShow: false,
      });
      setHeadColor({
        ...headColor, itemNotScanHead: false,
        itemScannedWithExceptionHead: true,
        itemNeedAttentionHead: false,
        itemScannedWithNoExceptionHead: false,
        daysIdleHead: false,
      })
      setColors({
        ...colors,
        color1: false,
        color2: true,
        color3: false,
        color4: false,
        color5: false,
      });
    }
  };
  const handleItemsNeedAttention = (clicked3: any) => {
    if (clicked3 == 'ItemsNeedAttention') {
      setTabsShow({
        ...tabsShow,
        ItemNotScannedShow: false,
        ItemScannedWithExceptionShow: false,
        ItemsNeedAttentionShow: true,
        ItemScanwithNoExceptionShow: false,
        DaysIdleNotInFrontShow: false,
      });
      setHeadColor({
        ...headColor, itemNotScanHead: false,
        itemScannedWithExceptionHead: false,
        itemNeedAttentionHead: true,
        itemScannedWithNoExceptionHead: false,
        daysIdleHead: false,
      })
      setColors({
        ...colors,
        color1: false,
        color2: false,
        color3: true,
        color4: false,
        color5: false,
      });
    }
  };
  const handleItemScanWithNoException = (clicked4: any) => {
    if (clicked4 == 'itemScanWithNoException') {
      setTabsShow({
        ...tabsShow,
        ItemNotScannedShow: false,
        ItemScannedWithExceptionShow: false,
        ItemsNeedAttentionShow: false,
        ItemScanwithNoExceptionShow: true,
        DaysIdleNotInFrontShow: false,
      });
      setHeadColor({
        ...headColor, itemNotScanHead: false,
        itemScannedWithExceptionHead: false,
        itemNeedAttentionHead: false,
        itemScannedWithNoExceptionHead: true,
        daysIdleHead: false,
      })
      setColors({
        ...colors,
        color1: false,
        color2: false,
        color3: false,
        color4: true,
        color5: false,
      });
    }
  };
  const handleDaysIdleNotInFront = (clicked5: any) => {
    if (clicked5 == 'daysIdleNotInFront') {
      setTabsShow({
        ...tabsShow,
        ItemNotScannedShow: false,
        ItemScannedWithExceptionShow: false,
        ItemsNeedAttentionShow: false,
        ItemScanwithNoExceptionShow: false,
        DaysIdleNotInFrontShow: true,
      });
      setHeadColor({
        ...headColor, itemNotScanHead: false,
        itemScannedWithExceptionHead: false,
        itemNeedAttentionHead: false,
        itemScannedWithNoExceptionHead: false,
        daysIdleHead: true,
      })
      setColors({
        ...colors,
        color1: false,
        color2: false,
        color3: false,
        color4: false,
        color5: true,
      });
    }
  };
  const ErrorMsgfn = () => {
    return (
      <div
        id="initialpayment"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-hidden="true"
      >
        <Grid className={classes.textCenter}>
          <ErrorMsgIcon></ErrorMsgIcon>
          <Typography className={classes.formLabel}></Typography>
          {errorMsg}
        </Grid>
        <Grid
          item
          md={12}
          className={`${classes.mt3} ${classes.pb4} ${classes.textCenter}`}
        >
          <RACButton
            className={classes.mx1}
            variant="contained"
            color="primary"
            onClick={() => {
              setErrorMsgPopup(false);
            }}
          >
            Ok
          </RACButton>
        </Grid>
      </div>
    );
  };
  const somethingWentWrongfn = () => {
    return (
      <div
        id="initialpayment"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-hidden="true"
      >
        <Grid className={classes.textCenter}>
          <SomethingwentwrongIcon />
          <Typography className={classes.formLabel} variant="h5">
            something wentWrong
          </Typography>
        </Grid>
        <Grid
          item
          md={12}
          className={`${classes.mt3} ${classes.pb4} ${classes.textCenter}`}
        >
          <RACButton
            className={classes.mx1}
            variant="contained"
            color="primary"
            onClick={() => {
              setSomethingWentWrong(false);
            }}
          >
            Ok
          </RACButton>
        </Grid>
      </div>
    );
  };
  const saveSuccessfn = () => {
    return (
      <div
        id="initialpayment"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-hidden="true"
      >
        <Grid className={classes.textCenter}>
          <Typography className={classes.formLabel} variant="h5">
            save successfully
          </Typography>
        </Grid>
        <Grid
          item
          md={12}
          className={`${classes.mt3} ${classes.pb4} ${classes.textCenter}`}
        >
          <RACButton
            className={classes.mx1}
            variant="contained"
            color="primary"
            onClick={() => {
              setSaveSuccessPopup(false);
              setExplanationStoring([]);
              InventoryAuditInfoFnc();
            }}
          >
            Ok
          </RACButton>
        </Grid>
      </div>
    );
  };
  const saveAlertfn = () => {
    return (
      <div
        id="initialpayment"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-hidden="true"
      >
        <Grid className={classes.textCenter}>
          <Typography className={classes.formLabel} variant="h5">
            There exists UnSavedChanges data. please SAVE the changes to continue.
          </Typography>
        </Grid>
        <Grid
          item
          md={12}
          className={`${classes.mt3} ${classes.pb4} ${classes.textCenter}`}
        >
          <RACButton
            className={classes.mx1}
            variant="contained"
            color="primary"
            onClick={() => {
              setSaveAlertPopup(false);
            }}
          >
            Ok
          </RACButton>
        </Grid>
      </div>
    );
  };

  const finalizeAuditDisableCheck = () => {
    if (itemNotScannedBody?.length == 0 && itemwithExceptionBody?.length == 0) {
      return false
    }
    else if (itemNotScannedBody.every((item: any) => item.explanation) && itemwithExceptionBody.every((item: any) => item.explanation)) {
      return false
    }
    else {
      return true
    }
  }
  const timeTakenFormat = (scanHour: any) => {
    const scanHourArray = scanHour.split(':');
    if (Number(scanHourArray[0]) > 0) {
      return `${scanHourArray[0]} hrs ${scanHourArray[1]} mins`
    }
    else if (scanHourArray[1] > 0) {
      return `00 hrs ${scanHourArray[1]} mins`
    }
    else if (scanHourArray[2] > 0) {
      return `${scanHourArray[2]} sec`
    }
  }
  return (
    <>
      {secondFactorEnabled ? (
        <TwoFactorComponent
          setTwoFactorCancelClick={setTwoFactorCancelClick}
          setTwoFactorCompleted={setTwoFactorCompleted}
          moduleName={moduleName}
          CurrentRole={currentRole}
        />
      ) : null}
      <Grid style={{ display: '' }}>
        <Grid>
          <List className={`${classes.breadcrumb}`}>
            <ListItem
              className={`${classes.breadcrumbItemActive}`}
              style={{ cursor: 'pointer' }}
              onClick={(e) => {
                history.push({
                  pathname: `/dashboard`,
                });
              }}
            >
              DashBoard
            </ListItem>
            <ListItem
              className={`${classes.breadcrumbItem} ${classes.breadcrumbArrow} ${classes.breadcrumbItemActive} `}
              style={{ cursor: 'pointer' }}
              onClick={(e) => {
                history.push({
                  pathname: '/inventory/audit/history',
                });
              }}
            >
              Idle Inventory Audit History
            </ListItem>
            <ListItem
              className={`${classes.breadcrumbItem} ${classes.breadcrumbArrow} ${classes.racpadLinkCustomer}  `}
              style={{ cursor: 'pointer' }}
            >
              Audit ID :{auditid}
            </ListItem>
          </List>
        </Grid>
        <Typography
          variant="h4"
          style={{ fontWeight: 'bold', marginLeft: '6px' }}
        >
          Idle Inventory Audit Exceptions
        </Typography>
        <Card className={classes.cardidle}>
          <CardContent>
            <RACTable
              className={`${classes.racGrid} ${classes.racAgrViewGrid}${classes.formControl}`}
              renderTableHead={idleInventoryExceptionHead}
              renderTableContent={idleInventoryExceptionBody}
            ></RACTable>
            <Grid
              container
              spacing={2}
              style={{ marginTop: '10px', display: 'flex', width: '100%', marginLeft: '.2%' }}
            >
              <Grid style={{ width: '13%', marginLeft: '3px', borderTopLeftRadius: '4%', borderBottomLeftRadius: '4%' }} className={classes.head}>
                <Typography style={{ marginLeft: '1%' }}
                  className={classes?.agrGenaralInfoColourLabel}
                >
                  {ScannedArray.itemsNotScanned}
                  <span style={{ marginLeft: '4%' }}>{lengthItemNotScanned}</span>
                </Typography>
              </Grid>
              <Grid style={{ width: '18%', paddingLeft: '1px' }} className={classes.head}>
                <Typography style={{ marginLeft: '1%' }}
                  className={classes?.agrGenaralInfoColourLabel}
                >
                  {ScannedArray.itemScannedWithException}
                  <span style={{ marginLeft: '4%' }}>{lengthItemScannedWithException}</span>
                </Typography>
              </Grid>
              <Grid style={{ width: '16%', paddingLeft: '1px' }} className={classes.head}>
                <Typography style={{ marginLeft: '1%' }}
                  className={classes?.agrGenaralInfoColourLabel}
                >
                  {ScannedArray.itemsNeddingAttention}
                  <span style={{ marginLeft: '4%' }}>{lengthItemNeedAttention}</span>
                </Typography>
              </Grid>
              <Grid style={{ width: '17%', paddingLeft: '1px' }} className={classes.head}>
                <Typography style={{ marginLeft: '1%' }}
                  className={classes?.agrGenaralInfoColourLabel}
                >
                  {ScannedArray.itemScannedNoException}
                  <span style={{ marginLeft: '4%' }}>{lengthItemScannedWithNoException}</span>
                </Typography>
              </Grid>
              <Grid style={{ width: '19%', paddingLeft: '1px' }} className={classes.head}>
                <Typography style={{ marginLeft: '4%' }}
                  className={classes?.agrGenaralInfoColourLabel}
                >
                  {ScannedArray.item90IdleNotInFront}
                  <span style={{ marginLeft: '4%' }}>{lengthDaysIdleNotInFront}</span>
                </Typography>
              </Grid>
              <Grid style={{ width: '16%', paddingLeft: '1px', borderTopRightRadius: '4%', borderBottomRightRadius: '4%' }} className={classes.head}>
                <Typography style={{ marginLeft: '9%' }}
                  className={classes?.agrGenaralInfoColourLabel}

                >
                  {ScannedArray.totalItemScanned}
                  <span style={{ marginLeft: '4%' }}>{lengthTotalItemScanned}</span>
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <Grid style={{ marginTop: '30px', marginBottom: '10%' }}>
          <Card className={classes.cardidle}>
            <CardContent style={{ padding: '0%' }}>
              <Grid container style={{ marginTop: '20px', paddingLeft: "9px" }} >
                <Grid

                  style={{ color: '#4A5174', width: '15%', fontFamily: 'OpenSans-bold', marginTop: '1px', cursor: 'pointer' }}
                >
                  <Typography
                    className={
                      headColor.itemNotScanHead
                        ? `${classes.headcolortab}`
                        : `${classes.fontStyle}`
                    }
                    style={{ marginBottom: '10px', marginLeft: '12%' }}
                    onClick={(e) => {
                      handleItemNotScanned('ItemNotScanned');
                    }}
                  >
                    Items Not Scanned
                  </Typography>
                  <List
                    className={
                      colors.color1
                        ? `${classes.headcolor1}`
                        : `${classes.border}`

                    }


                  ></List>

                </Grid>
                <Grid

                  style={{ color: '#4A5174', width: '17%', fontFamily: 'OpenSans-bold', marginTop: '1px', cursor: 'pointer' }}
                >
                  <Typography
                    className={
                      headColor.itemScannedWithExceptionHead
                        ? `${classes.headcolortab}`
                        : `${classes.fontStyle}`
                    }
                    style={{ marginBottom: '10px', marginLeft: '5%' }}
                    onClick={(e) => {
                      handleItemScannedWithException(
                        'ItemScannedWithException'
                      );
                    }}
                  >
                    Items Scanned With Exceptions
                  </Typography>
                  <List
                    className={
                      colors.color2
                        ? `${classes.headcolor2}`
                        : `${classes.border}`
                    }

                  ></List>
                </Grid>
                <Grid

                  style={{ color: '#4A5174', width: '14%', fontFamily: 'OpenSans-bold', marginTop: '1px', cursor: 'pointer' }}
                >
                  <Typography
                    className={
                      headColor.itemNeedAttentionHead
                        ? `${classes.headcolortab}`
                        : `${classes.fontStyle}`
                    }
                    style={{ marginBottom: '10px', marginLeft: '20%' }}
                    onClick={(e) => {
                      handleItemsNeedAttention('ItemsNeedAttention');
                    }}
                  >
                    Items Need Attention
                  </Typography>
                  <List
                    className={
                      colors.color3
                        ? `${classes.headcolor3}`
                        : `${classes.border}`
                    }
                  ></List>
                </Grid>
                <Grid
                  style={{ color: '#4A5174', width: '19%', marginLeft: '4%', fontFamily: 'OpenSans-bold', marginTop: '1px', cursor: 'pointer' }} >
                  <Typography
                    className={
                      headColor.itemScannedWithNoExceptionHead
                        ? `${classes.headcolortab}`
                        : `${classes.fontStyle}`
                    }
                    style={{ marginBottom: '10px', marginLeft: '5%' }}
                    onClick={(e) => {
                      handleItemScanWithNoException('itemScanWithNoException');
                    }}
                  >
                    Items Scanned With No Exceptions
                  </Typography>
                  <List
                    className={
                      colors.color4
                        ? `${classes.headcolor4}`
                        : `${classes.border}`
                    }

                  ></List>
                </Grid>
                <Grid
                  style={{ color: '#4A5174', width: '17%', fontFamily: 'OpenSans-bold', marginTop: '1px', cursor: 'pointer' }} >
                  <Typography
                    className={
                      headColor.daysIdleHead
                        ? `${classes.headcolortab}`
                        : `${classes.fontStyle}`
                    }
                    style={{ marginBottom: '10px', marginLeft: '30%' }}
                    onClick={(e) => {
                      handleDaysIdleNotInFront('daysIdleNotInFront');
                    }}
                  >
                    90+ Days Idle Not in Front
                  </Typography>
                  <List
                    className={
                      colors.color5
                        ? `${classes.headcolor5}`
                        : `${classes.border}`
                    }
                  ></List>
                </Grid>
                <Grid ></Grid>
                {tabsShow.ItemNotScannedShow ? (
                  <RACTable
                    style={{
                      zIndex: '1',
                      background: 'transparent',
                      boxShadow: 'none',
                      borderTop: 'thin solid #EFEFEF',
                      padding: '2%',
                      marginBottom: '5%',
                      height: 'auto',
                    }}
                    className={`${classes.racAgrViewGrid}${classes.formControl}`}
                    renderTableHead={itemNotScannedHeadFnc}
                    renderTableContent={itemNotScannedBodyFnc}
                  ></RACTable>
                ) : null}
                {tabsShow.ItemScannedWithExceptionShow ? (
                  <RACTable
                    style={{
                      zIndex: '1',
                      background: 'transparent',
                      boxShadow: 'none',
                      borderTop: 'thin solid #EFEFEF',
                      padding: '2%',
                      marginBottom: '5%',
                      height: 'auto',
                    }}
                    className={` ${classes.racAgrViewGrid}${classes.formControl}`}
                    renderTableHead={itemScannedWithExceptionHeadFnc}
                    renderTableContent={itemScannedWithExceptionBodyFnc}
                  ></RACTable>
                ) : null}
                {tabsShow.ItemsNeedAttentionShow ? (
                  <RACTable
                    style={{
                      zIndex: '1',
                      background: 'transparent',
                      boxShadow: 'none',
                      borderTop: 'thin solid #EFEFEF',
                      padding: '2%',
                      marginBottom: '5%',
                      height: 'auto',
                    }}
                    className={`${classes.racAgrViewGrid}${classes.formControl}`}
                    renderTableHead={itemsNeedAttentionHeadFnc}
                    renderTableContent={itemsNeedAttentionBodyFnc}
                  ></RACTable>
                ) : null}
                {tabsShow.ItemScanwithNoExceptionShow ? (
                  <RACTable
                    style={{
                      zIndex: '1',
                      background: 'transparent',
                      boxShadow: 'none',
                      borderTop: 'thin solid #EFEFEF',
                      padding: '2%',
                      marginBottom: '5%',
                      height: 'auto',
                    }}
                    className={`${classes.racAgrViewGrid}${classes.formControl}`}
                    renderTableHead={itemScanWithNoExceptionHeadFnc}
                    renderTableContent={itemScanWithNoExceptionBodyFnc}
                  ></RACTable>
                ) : null}
                {tabsShow.DaysIdleNotInFrontShow ? (
                  <RACTable
                    style={{
                      zIndex: '1',
                      background: 'transparent',
                      boxShadow: 'none',
                      borderTop: 'thin solid #EFEFEF',
                      padding: '2%',
                      marginBottom: '5%',
                      height: 'auto',
                    }}
                    className={`${classes.racAgrViewGrid}${classes.formControl}`}
                    renderTableHead={daysIdleNotInFrontHeadFnc}
                    renderTableContent={daysIdleNotInFrontBodyFnc}
                  ></RACTable>
                ) : null}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid>
          <Grid className={classes.fixedBottom}>
            <RACButton
              style={{ fontFamily: 'OpenSans-bold' }}
              variant="text"
              color="secondary"
              className={classes.ms2}
              onClick={() => {
                history.push({
                  pathname: '/inventory/audit/history',
                });

              }}
            >
              Cancel
            </RACButton>
            <Grid style={{ float: 'right' }}>
              <RACButton
                disabled={idleExceptionBody?.AuditStatus == "Close" ? true : finalizeAuditDisableCheck()}
                variant="contained"
                color="primary"
                style={{ marginRight: '10px' }}
                onClick={() => {
                  setsecondFactorEnabled(true);
                }}
              >
                Finalize Audit
              </RACButton>
              <RACButton
                disabled={idleExceptionBody?.AuditStatus == 'Close' ? true : explanationStoring?.length > 0 ? false : true}
                variant="contained"
                color="primary"
                style={{ marginRight: '10px' }}
                onClick={(e) => {
                  handleSave();
                }}
              >
                Save
              </RACButton>
              <RACButton
                variant="contained"
                color="primary"
                onClick={(e) => {
                  handlePrint(document.getElementById('printExcep'));
                }}
              >
                Print Exception Report
              </RACButton>
            </Grid>
          </Grid>
        </Grid>
        <RACModalCard
          borderRadius="25px"
          className={classes.cardidle}
          maxWidth="xs"
          isOpen={errorMsgPopup}
          closeIcon={false}
        >
          {ErrorMsgfn()}
        </RACModalCard>
        <RACModalCard
          borderRadius="25px"
          maxWidth="xs"
          isOpen={somethingWentWrong}
          closeIcon={false}
        >
          {somethingWentWrongfn()}
        </RACModalCard>
        <RACModalCard
          borderRadius="25px"
          maxWidth="xs"
          isOpen={saveAlertPopup}
          closeIcon={false}
        >
          {saveAlertfn()}
        </RACModalCard>
        <RACModalCard
          borderRadius="25px"
          maxWidth="xs"
          isOpen={saveSuccessPopup}
          closeIcon={false}
        >
          {saveSuccessfn()}
        </RACModalCard>
      </Grid>

      {hiddenLoader === true ? (
        <Grid
          style={{
            position: 'fixed',
            background: '#f7f5f5',
            width: '100%',
            height: '100%',
            top: 0,
            left: 0,
            opacity: 0.6,
            zIndex: 2000,
            textAlign: 'center',
            margin: '0px 0px',
          }}
        >
          {' '}
          <Grid
            style={{
              display: 'block',
              position: 'fixed',
              zIndex: 9999999,
              top: '40%',
              right: '50%',
            }}
          >
            <CircularProgress />{' '}
          </Grid>{' '}
        </Grid>
      ) : null}

     <div id="printExcep" style={{ display: 'none' }}>
        <div >
          <Logo style={{ width: '60px', height: '60px' }}></Logo>
        </div>
        <table style={{ marginBottom: '35%' }} >
          <thead >
            <tr><td style={{ borderBottom: '1px solid black' }}>
              <div>

                <div
                  style={{
                    width: '20%',
                    float: 'left',
                    fontFamily: 'sans-serif',
                    marginTop: '66px',
                  }}
                >
                  <div style={{ width: '100%' }}>
                    <label>Rent-A-Center,Inc</label>
                  </div>
                  <div style={{ width: '100%' }}>
                    <label> {idleExceptionBody?.StoreNumber ? idleExceptionBody?.StoreNumber : <span>-</span>} {currentStoreAddress ? currentStoreAddress : <span>-</span>}</label>
                  </div>
                </div>
                <div
                  style={{
                    textAlign: 'center',
                    width: '45%',
                    float: 'left',
                    fontFamily: 'sans-serif',
                    marginTop: '46px',
                    fontSize: 'bold'
                  }}
                >
                  <h3>Idle Inventory Audit Exception Report - {idleExceptionBody.AuditStatus == 'Open' ? 'Preliminary' : 'Final'}</h3>
                </div>
                <div
                  style={{
                    width: '35%',
                    float: 'left',
                    textAlign: 'right',
                    fontFamily: 'sans-serif',
                    marginTop: '66px',
                  }}
                >
                  <div style={{ width: '100%' }}>{currentDay ? currentDay : <span>-</span>} {idleExceptionBody?.AuditDate ? datecheck3(idleExceptionBody.AuditDate) : <span>-</span>}</div>
                  <div style={{ width: '100%' }}>
                    Report requested by  {employeeName}
                  </div>
                </div>
              </div>
            </td></tr>
          </thead>
          <tbody>
            <tr><td>
              <div style={{ marginTop: '1%' }}>
                <div style={{ width: '100%' }}>
                  <div>
                    <div style={{ width: '100%', fontFamily: 'sans-serif' }}>
                      <p>
                        Idle Audit ID:
                        <span style={{ marginLeft: '20px' }}>{auditid ? auditid : <span>-</span>}</span>
                      </p>
                      <p>
                        Audit Opened:
                        <span style={{ marginLeft: '20px' }}>
                        {idleExceptionBody?.AuditDate ? datecheck1(idleExceptionBody.AuditDate) : <span>-</span>}
                        </span>{' '}
                      </p>
                      <p>
                        Audit Closed:
                        <span style={{ marginLeft: '20px' }}>
                          {idleExceptionBody?.AuditCloseDate ? datecheck3(idleExceptionBody?.AuditCloseDate) : <span>-</span>}
                        </span>
                      </p>

                      <div>
                        Scan Started:
                        <span style={{ marginLeft: '20px' }}>
                        {idleExceptionBody?.ScanStarted ? datecheck2(idleExceptionBody?.ScanStarted): <span>-</span>}
                        </span>
                        <span style={{ marginLeft: '20px' }}>Scan Ended:</span>
                        <span style={{ marginLeft: '20px' }}>
                          {idleExceptionBody?.ScanEnded ? datecheck2(idleExceptionBody?.ScanEnded) : <span>-</span>}
                        </span>
                      </div>

                      <p>
                        Time to Complete:
                        <span style={{ marginLeft: '20px' }}>
                          {idleExceptionBody?.TotalScanHours ? timeTakenFormat(idleExceptionBody?.TotalScanHours) : <span>-</span>}
                        </span>
                      </p>
                      <p>
                        Employee:
                        <span style={{ marginLeft: '20px' }}>
                          {idleExceptionBody?.ClosedBy ? idleExceptionBody?.ClosedBy : <span>-</span>}
                        </span>
                      </p>
                    </div>


                    <div style={{ width: '100%', marginTop: '5px' }}>

                      <table style={{ borderCollapse: 'collapse', width: '100%', marginTop: '40px' }}>
                        <thead style={{ width: '100%' }}>

                          {ItemScannedPrintHeaderArray?.map((obj: any, index: any) => {
                            return (
                              <>
                                <tr>
                                  <td>
                                    <div
                                      style={{
                                        fontFamily: 'sans-serif',
                                        marginTop: '1%',
                                        width: '125%',
                                      }}
                                    >
                                      <h4
                                        style={{
                                          borderBottom: '.5px solid black',
                                          marginTop: '40px',
                                          width: '190%'
                                        }}
                                      >
                                        Exceptions- Item Scanned
                                      </h4>
                                    </div>
                                  </td>
                                </tr>

                                <tr>

                                  <th style={{

                                    color: '#000',
                                    textAlign: 'left',
                                    padding: '7px',
                                    fontSize: '14px',
                                    width: '7%',
                                  }}>{obj.scanArea}
                                  </th>

                                  <th style={{

                                    color: '#000',
                                    textAlign: 'left',
                                    padding: '7px',
                                    fontSize: '14px',
                                    width: '7%',
                                  }}>{obj.dep}
                                  </th>

                                  <th style={{

                                    color: '#000',
                                    textAlign: 'left',
                                    padding: '7px',
                                    fontSize: '14px',
                                    width: '9%',
                                  }}>{obj.subDep}
                                  </th>

                                  <th style={{

                                    color: '#000',
                                    textAlign: 'left',
                                    padding: '7px',
                                    fontSize: '14px',
                                    width: '7%',
                                  }}>{obj.brand}
                                  </th>

                                  <th style={{

                                    color: '#000',
                                    textAlign: 'left',
                                    padding: '7px',
                                    fontSize: '14px',
                                    width: '7%',
                                  }}>{obj.model}
                                  </th>

                                  <th style={{

                                    color: '#000',
                                    textAlign: 'left',
                                    padding: '7px',
                                    fontSize: '14px',
                                    width: '8%',
                                  }}>{obj.item}
                                  </th>

                                  <th style={{

                                    color: '#000',
                                    textAlign: 'left',
                                    padding: '7px',
                                    fontSize: '14px',
                                    width: '9%',
                                  }}>{obj.serial}
                                  </th>

                                  <th style={{

                                    color: '#000',
                                    textAlign: 'left',
                                    padding: '7px',
                                    fontSize: '14px',
                                    width: '7%',
                                  }}>{obj.barCodeContent}
                                  </th>
                                  <th
                                    colSpan={2}
                                    style={{
                                      borderBottom: '1px solid #000',
                                      color: '#000',
                                      padding: '1px',
                                      marginBottom: '30%',
                                      fontSize: '14px',
                                      fontFamily: 'sans-serif',
                                      textAlign: 'center',
                                    }}
                                  >
                                    {obj.scanned}
                                  </th>
                                  <th style={{

                                    color: '#000',
                                    textAlign: 'left',
                                    padding: '7px',
                                    fontSize: '14px',
                                    width: '8%'
                                  }}>{obj.exception}
                                  </th><th style={{

                                    color: '#000',
                                    textAlign: 'left',
                                    padding: '7px',
                                    fontSize: '14px',
                                    width: '7%',
                                  }}>{obj.information}
                                  </th>
                                  <th style={{

                                    color: '#000',
                                    textAlign: 'left',
                                    padding: '7px',
                                    fontSize: '14px',
                                    width: '7%',
                                  }}>{obj.explanation}
                                  </th></tr>
                                <tr style={{ marginTop: '10%' }}>
                                  <td rowSpan={8} style={{ borderBottom: '1px solid #000' }}></td>
                                  <td rowSpan={8} style={{ borderBottom: '1px solid #000' }}></td>
                                  <td rowSpan={8} style={{ borderBottom: '1px solid #000' }}></td>
                                  <td rowSpan={8} style={{ borderBottom: '1px solid #000' }}></td>
                                  <td rowSpan={8} style={{ borderBottom: '1px solid #000' }}></td>
                                  <td rowSpan={8} style={{ borderBottom: '1px solid #000' }}></td>
                                  <td rowSpan={8} style={{ borderBottom: '1px solid #000' }}></td>
                                  <td rowSpan={8} style={{ borderBottom: '1px solid #000' }}></td>
                                  <th
                                    style={{
                                      color: '#000',
                                      padding: '1px',
                                      fontSize: '14px',
                                      fontFamily: 'sans-serif',
                                      borderBottom: '1px solid #000',
                                      width: '3%',
                                      marginTop: '2%',
                                    }}
                                  >
                                    {obj.date}
                                  </th>
                                  <th
                                    style={{
                                      color: '#000',
                                      padding: '1px',
                                      fontSize: '14px',
                                      fontFamily: 'sans-serif',
                                      borderBottom: '1px solid #000',
                                      width: '3%',
                                      marginTop: '2%',
                                    }}
                                  >
                                    {obj.time}
                                  </th>
                                  <td rowSpan={8} style={{ borderBottom: '1px solid #000' }}></td>
                                  <td rowSpan={8} style={{ borderBottom: '1px solid #000' }}></td>
                                  <td rowSpan={8} style={{ borderBottom: '1px solid #000' }}></td>

                                </tr></>
                            )
                          })}
                        </thead>
                        <tbody style={{ width: '100%' }}>
                          {itemwithExceptionBody?.map((obj: any, index: any) => {
                            return (
                              <tr key={index} style={{ width: '35%' }}>
                                <td style={{
                                  paddingTop: '7px',
                                  fontSize: '14px',
                                  textAlign: 'left',

                                }}>{obj.scanArea ? obj.scanArea : <span style={{ marginLeft: '25%' }}>-</span>}
                                </td>

                                <td style={{
                                  paddingTop: '7px',
                                  fontSize: '14px',
                                  textAlign: 'left',

                                }}> {obj.department ? obj.department : <span style={{ marginLeft: '15%' }}>-</span>}
                                </td>

                                <td style={{
                                  paddingTop: '7px',
                                  fontSize: '14px',
                                  textAlign: 'left',

                                }}> {obj.subDepartment ? obj.subDepartment : <span style={{ marginLeft: '15%' }}>-</span>}
                                </td>

                                <td style={{
                                  paddingTop: '7px',
                                  fontSize: '14px',
                                  textAlign: 'left',

                                }}> {obj.brand ? obj.brand : <span style={{ marginLeft: '20%' }}>-</span>}
                                </td>

                                <td style={{
                                  paddingTop: '7px',
                                  fontSize: '14px',
                                  textAlign: 'left',

                                }}>  {obj.modelNumber ? obj.modelNumber : <span style={{ marginLeft: '15%' }}>-</span>}
                                </td>

                                <td style={{
                                  paddingTop: '7px',
                                  fontSize: '14px',
                                  textAlign: 'left',

                                }}> {obj.inventoryNumber ? obj.inventoryNumber : <span style={{ marginLeft: '20%' }}>-</span>}
                                </td>

                                <td style={{
                                  paddingTop: '7px',
                                  fontSize: '14px',
                                  textAlign: 'left',

                                }}>{obj.serialNumber ? obj.serialNumber : <span style={{ marginLeft: '15%' }}>-</span>}
                                </td>

                                <td style={{
                                  paddingTop: '7px',
                                  fontSize: '14px',
                                  textAlign: 'left',

                                }}> {obj.barcode ? obj.barcode : <span style={{ marginLeft: '25%' }}>-</span>}
                                </td>
                                <div>
                                  <td style={{
                                    paddingTop: '7px',
                                    fontSize: '14px',
                                    textAlign: 'left',

                                  }}> {obj.inventoryAuditDate ? reportDateFormation(obj.inventoryAuditDate) : <span style={{ marginLeft: '15%' }}>-</span>}
                                  </td>

                                  <td style={{
                                    paddingTop: '7px',
                                    fontSize: '14px',
                                    textAlign: 'left',

                                  }}> {obj.inventoryAuditDate ? reportTimeFormation(obj.inventoryAuditDate) : <span style={{ marginLeft: '15%' }}>-</span>}
                                  </td>
                                </div>

                                <td style={{
                                  paddingTop: '7px',
                                  fontSize: '14px',
                                  textAlign: 'left',

                                }}> {obj.exceptionReason ? obj.exceptionReason : <span style={{ marginLeft: '25%' }}>-</span>}
                                </td>
                                <td style={{
                                  paddingTop: '7px',
                                  fontSize: '14px',
                                  textAlign: 'left',

                                }}>   {obj.additionalInformation ? obj.additionalInformation : <span style={{ marginLeft: '30%' }}>-</span>}
                                </td>

                                <td style={{
                                  paddingTop: '7px',
                                  fontSize: '14px',
                                  textAlign: 'left',

                                }}>{obj.explanation ? obj.explanation : <span style={{ marginLeft: '25%' }}>-</span>}
                                </td>
                              </tr>

                            );
                          })}
                        </tbody>
                      </table>
                    </div>




                    <div style={{ width: '100%', float: 'left', marginTop: '5px' }}>

                      <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                        <thead>


                          {ItemNotScannedHeadArrayprint.map((obj: any, index: any) => {
                            return (
                              <>
                                <tr>
                                  <td>
                                    <div
                                      style={{
                                        fontFamily: 'sans-serif',
                                        marginTop: '1%',
                                        width: '125%',
                                      }}
                                    >
                                      <h4
                                        style={{
                                          borderBottom: '.5px solid black',
                                          marginTop: '40px',
                                          width: '150%'
                                        }}
                                      >
                                        Exceptions- Item  Not Scanned
                                      </h4>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <th style={{

                                    color: '#000',
                                    textAlign: 'left',
                                    padding: '7px',
                                    fontSize: '14px',
                                    width: '7%',
                                    borderBottom: '1px solid #000'
                                  }}>{obj.dep}
                                  </th>

                                  <th style={{

                                    color: '#000',
                                    textAlign: 'left',
                                    padding: '7px',
                                    fontSize: '14px',
                                    width: '7%',
                                    borderBottom: '1px solid #000'
                                  }}>{obj.subDep}
                                  </th>

                                  <th style={{

                                    color: '#000',
                                    textAlign: 'left',
                                    padding: '7px',
                                    fontSize: '14px',
                                    width: '9%',
                                    borderBottom: '1px solid #000'
                                  }}>{obj.brand}
                                  </th>

                                  <th style={{

                                    color: '#000',
                                    textAlign: 'left',
                                    padding: '7px',
                                    fontSize: '14px',
                                    width: '7%',
                                    borderBottom: '1px solid #000'
                                  }}>{obj.model}
                                  </th>

                                  <th style={{

                                    color: '#000',
                                    textAlign: 'left',
                                    padding: '7px',
                                    fontSize: '14px',
                                    width: '7%',
                                    borderBottom: '1px solid #000'
                                  }}>{obj.item}
                                  </th>

                                  <th style={{

                                    color: '#000',
                                    textAlign: 'left',
                                    padding: '7px',
                                    fontSize: '14px',
                                    width: '8%',
                                    borderBottom: '1px solid #000'
                                  }}>{obj.serial}
                                  </th>

                                  <th style={{

                                    color: '#000',
                                    textAlign: 'left',
                                    padding: '7px',
                                    fontSize: '14px',
                                    width: '9%',
                                    borderBottom: '1px solid #000'
                                  }}>{obj.exception}
                                  </th>

                                  <th style={{

                                    color: '#000',
                                    textAlign: 'left',
                                    padding: '7px',
                                    fontSize: '14px',
                                    width: '7%',
                                    borderBottom: '1px solid #000'
                                  }}>{obj.explanation}
                                  </th>
                                </tr>
                              </>
                            )
                          })}

                        </thead>
                        <tbody>
                          {itemNotScannedBody?.map((obj: any, index: any) => {
                            return (
                              <tr key={index} style={{ width: '35px' }}>
                                <td
                                  style={{
                                    paddingTop: '7px',
                                    fontSize: '14px',
                                    textAlign: 'left',

                                  }}
                                >
                                  {obj.department ? obj.department : <span style={{ marginLeft: '15%' }}>-</span>}
                                </td>
                                <td
                                  style={{
                                    paddingTop: '7px',
                                    fontSize: '14px',
                                    textAlign: 'left',

                                  }}
                                >
                                  {obj.subDepartment ? obj.subDepartment : <span style={{ marginLeft: '15%' }}>-</span>}
                                </td>
                                <td
                                  style={{
                                    paddingTop: '7px',
                                    fontSize: '14px',
                                    textAlign: 'left',

                                  }}
                                >
                                  {obj.brand ? obj.brand : <span style={{ marginLeft: '15%' }}>-</span>}
                                </td>
                                <td
                                  style={{
                                    paddingTop: '7px',
                                    fontSize: '14px',
                                    textAlign: 'left',

                                  }}
                                >
                                  {obj.modelNumber ? obj.modelNumber : <span style={{ marginLeft: '15%' }}>-</span>}
                                </td>
                                <td
                                  style={{
                                    paddingTop: '7px',
                                    fontSize: '14px',
                                    textAlign: 'left',

                                  }}
                                >
                                  {obj.inventoryNumber ? obj.inventoryNumber : <span style={{ marginLeft: '15%' }}>-</span>}
                                </td>
                                <td
                                  style={{
                                    paddingTop: '7px',
                                    fontSize: '14px',
                                    textAlign: 'left',

                                  }}
                                >
                                  {obj.serialNumber ? obj.serialNumber : <span style={{ marginLeft: '15%' }}>-</span>}
                                </td>
                                <td
                                  style={{
                                    paddingTop: '7px',
                                    fontSize: '14px',
                                    textAlign: 'left',

                                  }}
                                >
                                  {obj.exceptionReason ? obj.exceptionReason : <span style={{ marginLeft: '15%' }}>-</span>}
                                </td>
                                <td
                                  style={{
                                    paddingTop: '7px',
                                    fontSize: '14px',
                                    textAlign: 'left',

                                  }}
                                >
                                  {obj.explanation ? obj.explanation : <span style={{ marginLeft: '15%' }}>-</span>}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>

                    <div style={{ width: '100%', float: 'left', marginTop: '5px' }}>

                      <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                        <thead>


                          {ItemNeedAttentionHeadArrayPrint.map((obj: any, index: any) => {
                            return (
                              <>
                                <tr>
                                  <td>
                                    <div
                                      style={{
                                        fontFamily: 'sans-serif',
                                        marginTop: '1%',
                                        width: '125%',
                                      }}
                                    >
                                      <h4
                                        style={{
                                          borderBottom: '.5px solid black',
                                          marginTop: '40px',
                                          width: '250%'
                                        }}
                                      >
                                        Items Needing Attention
                                      </h4>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <th style={{

                                    color: '#000',
                                    textAlign: 'left',
                                    padding: '7px',
                                    fontSize: '14px',
                                    width: '7%',
                                    borderBottom: '1px solid #000'
                                  }}>{obj.scanArea}
                                  </th>

                                  <th style={{

                                    color: '#000',
                                    textAlign: 'left',
                                    padding: '7px',
                                    fontSize: '14px',
                                    width: '7%',
                                    borderBottom: '1px solid #000'
                                  }}>{obj.dep}
                                  </th>

                                  <th style={{

                                    color: '#000',
                                    textAlign: 'left',
                                    padding: '7px',
                                    fontSize: '14px',
                                    width: '9%',
                                    borderBottom: '1px solid #000'
                                  }}>{obj.subDep}
                                  </th>

                                  <th style={{

                                    color: '#000',
                                    textAlign: 'left',
                                    padding: '7px',
                                    fontSize: '14px',
                                    width: '7%',
                                    borderBottom: '1px solid #000'
                                  }}>{obj.brand}
                                  </th>

                                  <th style={{

                                    color: '#000',
                                    textAlign: 'left',
                                    padding: '7px',
                                    fontSize: '14px',
                                    width: '7%',
                                    borderBottom: '1px solid #000'
                                  }}>{obj.model}
                                  </th>

                                  <th style={{

                                    color: '#000',
                                    textAlign: 'left',
                                    padding: '7px',
                                    fontSize: '14px',
                                    width: '8%',
                                    borderBottom: '1px solid #000'
                                  }}>{obj.item}
                                  </th>

                                  <th style={{

                                    color: '#000',
                                    textAlign: 'left',
                                    padding: '7px',
                                    fontSize: '14px',
                                    width: '9%',
                                    borderBottom: '1px solid #000'
                                  }}>{obj.serial}
                                  </th>

                                  <th style={{

                                    color: '#000',
                                    textAlign: 'left',
                                    padding: '7px',
                                    fontSize: '14px',
                                    width: '7%',
                                    borderBottom: '1px solid #000'
                                  }}>{obj.barCodeContent}
                                  </th>
                                  <th style={{

                                    color: '#000',
                                    textAlign: 'left',
                                    padding: '7px',
                                    fontSize: '14px',
                                    width: '7%',
                                    borderBottom: '1px solid #000'
                                  }}>{obj.date}
                                  </th>
                                  <th style={{

                                    color: '#000',
                                    textAlign: 'left',
                                    padding: '7px',
                                    fontSize: '14px',
                                    width: '7%',
                                    borderBottom: '1px solid #000'
                                  }}>{obj.time}
                                  </th>
                                  <th style={{

                                    color: '#000',
                                    textAlign: 'left',
                                    padding: '7px',
                                    fontSize: '14px',
                                    width: '7%',
                                    borderBottom: '1px solid #000'
                                  }}>{obj.ActionNeeded}
                                  </th>
                                  <th style={{

                                    color: '#000',
                                    textAlign: 'left',
                                    padding: '7px',
                                    fontSize: '14px',
                                    width: '7%',
                                    borderBottom: '1px solid #000'
                                  }}>{obj.Information}
                                  </th>
                                </tr>
                              </>
                            )
                          })}

                        </thead>
                        <tbody>
                          {itemNeedAttentionBody?.map((obj: any, index: any) => {
                            return (
                              <tr key={index} style={{ width: '35px' }}>
                                <td
                                  style={{
                                    paddingTop: '7px',
                                    fontSize: '14px',
                                    textAlign: 'left',

                                  }}
                                >
                                  {obj.scanArea ? obj.scanArea : <span style={{ marginLeft: '15%' }}>-</span>}
                                </td>
                                <td
                                  style={{
                                    paddingTop: '7px',
                                    fontSize: '14px',
                                    textAlign: 'left',

                                  }}
                                >
                                  {obj.department ? obj.department : <span style={{ marginLeft: '15%' }}>-</span>}
                                </td>
                                <td
                                  style={{
                                    paddingTop: '7px',
                                    fontSize: '14px',
                                    textAlign: 'left',

                                  }}
                                >
                                  {obj.subDepartment ? obj.subDepartment : <span style={{ marginLeft: '15%' }}>-</span>}
                                </td>
                                <td
                                  style={{
                                    paddingTop: '7px',
                                    fontSize: '14px',
                                    textAlign: 'left',
                                  }}
                                >
                                  {obj.brand ? obj.brand : <span style={{ marginLeft: '15%' }}>-</span>}
                                </td>
                                <td
                                  style={{
                                    paddingTop: '7px',
                                    fontSize: '14px',
                                    textAlign: 'left',

                                  }}
                                >
                                  {obj.modelNumber ? obj.modelNumber : <span style={{ marginLeft: '15%' }}>-</span>}
                                </td>
                                <td
                                  style={{
                                    paddingTop: '7px',
                                    fontSize: '14px',
                                    textAlign: 'left',

                                  }}
                                >
                                  {obj.inventoryNumber ? obj.inventoryNumber : <span style={{ marginLeft: '15%' }}>-</span>}
                                </td>
                                <td
                                  style={{
                                    paddingTop: '7px',
                                    fontSize: '14px',
                                    textAlign: 'left',

                                  }}
                                >
                                  {obj.serialNumber ? obj.serialNumber : <span style={{ marginLeft: '15%' }}>-</span>}
                                </td>
                                <td
                                  style={{
                                    paddingTop: '7px',
                                    fontSize: '14px',
                                    textAlign: 'left',

                                  }}
                                >
                                  {obj.barcode ? obj.barcode : <span style={{ marginLeft: '15%' }}>-</span>}
                                </td>
                                <td
                                  style={{
                                    paddingTop: '7px',
                                    fontSize: '14px',
                                    textAlign: 'left',

                                  }}
                                >
                                  {obj.inventoryAuditDate ? reportDateFormation(obj.inventoryAuditDate) : <span style={{ marginLeft: '15%' }}>-</span>}
                                </td>
                                <td
                                  style={{
                                    paddingTop: '7px',
                                    fontSize: '14px',
                                    textAlign: 'right',

                                  }}
                                >
                                  {obj.inventoryAuditDate ? reportTimeFormation(obj.inventoryAuditDate) : <span>-</span>}
                                </td>
                                <td
                                  style={{
                                    paddingTop: '7px',
                                    fontSize: '14px',
                                    textAlign: 'left',

                                  }}
                                >
                                  {obj.actionNeeded ? obj.actionNeeded : <span style={{ marginLeft: '15%' }}>-</span>}
                                </td>
                                <td
                                  style={{
                                    paddingTop: '7px',
                                    fontSize: '14px',
                                    textAlign: 'left',

                                  }}
                                >
                                  {obj.additionalInformation ? obj.additionalInformation : <span style={{ marginLeft: '15%' }}>-</span>}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>


                    <div style={{ width: '100%', float: 'left', marginTop: '5px' }}>

                      <table style={{ borderCollapse: 'collapse', width: '100%', }}>
                        <thead>
                          {daysIdleNotInFrontHeadArrayPrint.map((obj: any, index: any) => {
                            return (
                              <>
                                <tr>
                                  <td>
                                    <div
                                      style={{
                                        fontFamily: 'sans-serif',
                                        marginTop: '20%',
                                        width: '125%',

                                      }}
                                    >
                                      <h4
                                        style={{
                                          borderBottom: '.5px solid black',
                                          marginTop: '40px',
                                          width: '250%'
                                        }}
                                      >
                                        90+Days(Not In ShowRoom)
                                      </h4>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <th style={{

                                    color: '#000',
                                    textAlign: 'left',
                                    padding: '7px',
                                    fontSize: '14px',
                                    borderBottom: '1px solid #000',
                                    width: '7%'
                                  }}>{obj.scanArea}
                                  </th>

                                  <th style={{

                                    color: '#000',
                                    textAlign: 'left',
                                    padding: '7px',
                                    fontSize: '14px',
                                    borderBottom: '1px solid #000'
                                  }}>{obj.daysIdle}
                                  </th>

                                  <th style={{

                                    color: '#000',
                                    textAlign: 'left',
                                    padding: '7px',
                                    fontSize: '14px',
                                    borderBottom: '1px solid #000'
                                  }}>{obj.dep}
                                  </th>

                                  <th style={{

                                    color: '#000',
                                    textAlign: 'left',
                                    padding: '7px',
                                    fontSize: '14px',
                                    borderBottom: '1px solid #000'
                                  }}>{obj.subDep}
                                  </th>

                                  <th style={{

                                    color: '#000',
                                    textAlign: 'left',
                                    padding: '7px',
                                    fontSize: '14px',
                                    borderBottom: '1px solid #000'
                                  }}>{obj.brand}
                                  </th>

                                  <th style={{

                                    color: '#000',
                                    textAlign: 'left',
                                    padding: '7px',
                                    fontSize: '14px',
                                    borderBottom: '1px solid #000'
                                  }}>{obj.model}
                                  </th>

                                  <th style={{

                                    color: '#000',
                                    textAlign: 'left',
                                    padding: '7px',
                                    fontSize: '14px',
                                    borderBottom: '1px solid #000'
                                  }}>{obj.serial}
                                  </th>

                                  <th style={{

                                    color: '#000',
                                    textAlign: 'left',
                                    padding: '7px',
                                    fontSize: '14px',
                                    borderBottom: '1px solid #000'
                                  }}>{obj.barCodeContent}
                                  </th>
                                  <th style={{

                                    color: '#000',
                                    textAlign: 'left',
                                    padding: '7px',
                                    fontSize: '14px',
                                    borderBottom: '1px solid #000'
                                  }}>{obj.Accumrent}
                                  </th>
                                  <th style={{

                                    color: '#000',
                                    textAlign: 'left',
                                    padding: '7px',
                                    fontSize: '14px',
                                    borderBottom: '1px solid #000'
                                  }}>{obj.remaningValue}
                                  </th>
                                </tr>
                              </>
                            )
                          })}


                        </thead>
                        <tbody>
                          {daysIdleNotInFrontBody?.map((obj: any, index: any) => {
                            return (
                              <tr key={index} style={{ width: '35px' }}>
                                <td
                                  style={{
                                    paddingTop: '7px',
                                    fontSize: '14px',
                                    textAlign: 'left',

                                  }}
                                >
                                  {obj.scanArea ? obj.scanArea : <span style={{ marginLeft: '15%' }}>-</span>}
                                </td>
                                <td
                                  style={{
                                    paddingTop: '7px',
                                    fontSize: '14px',
                                    textAlign: 'left',

                                  }}
                                >
                                  {obj.idleDays ? obj.idleDays : <span style={{ marginLeft: '15%' }}>-</span>}
                                </td>
                                <td
                                  style={{
                                    paddingTop: '7px',
                                    fontSize: '14px',
                                    textAlign: 'left',

                                  }}
                                >
                                  {obj.department ? obj.department : <span style={{ marginLeft: '15%' }}>-</span>}
                                </td>
                                <td
                                  style={{
                                    paddingTop: '7px',
                                    fontSize: '14px',
                                    textAlign: 'left',

                                  }}
                                >
                                  {obj.subDepartment ? obj.subDepartment : <span style={{ marginLeft: '15%' }}>-</span>}
                                </td>
                                <td
                                  style={{
                                    paddingTop: '7px',
                                    fontSize: '14px',
                                    textAlign: 'left',

                                  }}
                                >
                                  {obj.brand ? obj.brand : <span style={{ marginLeft: '15%' }}>-</span>}
                                </td>
                                <td
                                  style={{
                                    paddingTop: '7px',
                                    fontSize: '14px',
                                    textAlign: 'left',

                                  }}
                                >
                                  {obj.modelNumber ? obj.modelNumber : <span style={{ marginLeft: '15%' }}>-</span>}
                                </td>
                                <td
                                  style={{
                                    paddingTop: '7px',
                                    fontSize: '14px',
                                    textAlign: 'left',

                                  }}
                                >
                                  {obj.inventoryNumber ? obj.inventoryNumber : <span style={{ marginLeft: '15%' }}>-</span>}
                                </td>
                                <td
                                  style={{
                                    paddingTop: '7px',
                                    fontSize: '14px',
                                    textAlign: 'left',

                                  }}
                                >
                                  {obj.serialNumber ? obj.serialNumber : <span style={{ marginLeft: '15%' }}>-</span>}
                                </td>
                                <td
                                  style={{
                                    paddingTop: '7px',
                                    fontSize: '14px',
                                    textAlign: 'left',

                                  }}
                                >
                                  {obj.inventoryRevenue ? obj.inventoryRevenue : <span style={{ marginLeft: '15%' }}>-</span>}
                                </td>
                                <td
                                  style={{
                                    paddingTop: '7px',
                                    fontSize: '14px',
                                    textAlign: 'left',

                                  }}
                                >
                                  {obj.remainingValue ? obj.remainingValue : <span style={{ marginLeft: '15%' }}>-</span>}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                      <div>
                        {idleExceptionBody.AuditStatus != 'Open' ? <div style={{ marginLeft: "70px", width: "70%", marginTop: "150px" }}>
                          <p style={{ marginTop: "20px", width: "100%" }}>I have verified and resolved exceptions for this closed idle inventory audit</p>
                          <div style={{ width: "100%" }}>
                            <div style={{ width: "30%" }}><span>Notes</span></div>
                            <div style={{ width: "70%", "marginLeft": "50px" }}>
                              <div style={{ borderBottom: '1px solid #000' }}></div>
                              <div style={{ borderBottom: '1px solid #000', marginTop: '20px' }}></div>
                              <div style={{ borderBottom: '1px solid #000', marginTop: '20px' }}></div>
                              <div style={{ borderBottom: '1px solid #000', marginTop: '20px' }}></div>
                              <div style={{ borderBottom: '1px solid #000', marginTop: '20px' }}></div>
                            </div>
                          </div>
                        </div> : null}

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </td></tr>
          </tbody>
        </table>
      </div>


      <div id="finalizeprint" style={{ display: 'none' }}>
        <div >
          <Logo style={{ width: '60px', height: '60px' }}></Logo>
        </div>
        <table style={{ marginBottom: '35%' }} >
          <thead >
            <tr><td style={{ borderBottom: '1px solid black' }}>
              <div>

                <div
                  style={{
                    width: '20%',
                    float: 'left',
                    fontFamily: 'sans-serif',
                    marginTop: '66px',
                  }}
                >
                  <div style={{ width: '100%' }}>
                    <label>Rent-A-Center,Inc</label>
                  </div>
                  <div style={{ width: '100%' }}>
                    <label>  {idleExceptionBody?.StoreNumber ? idleExceptionBody?.StoreNumber : <span>-</span>} {currentStoreAddress ? currentStoreAddress : <span>-</span>}</label>
                  </div>
                </div>
                <div
                  style={{
                    textAlign: 'center',
                    width: '45%',
                    float: 'left',
                    fontFamily: 'sans-serif',
                    marginTop: '46px',
                    fontSize: 'bold'
                  }}
                >
                  <h3>Idle Inventory Audit Exception Report - Final</h3>
                </div>
                <div
                  style={{
                    width: '35%',
                    float: 'left',
                    textAlign: 'right',
                    fontFamily: 'sans-serif',
                    marginTop: '66px',
                  }}
                >
                  <div style={{ width: '100%' }}>{currentDay ? currentDay : <span>-</span>} {idleExceptionBody?.AuditDate ? datecheck3(idleExceptionBody.AuditDate) : <span>-</span>}</div>
                  <div style={{ width: '100%' }}>
                    Report requested by  {idleExceptionBody?.AuditBy ? idleExceptionBody?.AuditBy : <span>-</span>}
                  </div>
                </div>
              </div>
            </td></tr>
          </thead>
          <tbody>
            <tr><td>
              <div style={{ marginTop: '1%' }}>
                <div style={{ width: '100%' }}>
                  <div>
                    <div style={{ width: '100%', fontFamily: 'sans-serif' }}>
                      <p>
                        Idle Audit ID:
                        <span style={{ marginLeft: '20px' }}>{auditid ? auditid : <span>-</span>}</span>
                      </p>
                      <p>
                        Audit Opened:
                        <span style={{ marginLeft: '20px' }}>
                        {idleExceptionBody?.AuditDate ? datecheck1(idleExceptionBody.AuditDate) : <span>-</span>}
                        </span>{' '}
                      </p>
                      <p>
                        Audit Closed:
                        <span style={{ marginLeft: '20px' }}>
                          {idleExceptionBody?.ClosedBy ? idleExceptionBody?.ClosedBy : <span>-</span>}
                        </span>
                      </p>

                      <div>
                        Scan Started:
                        <span style={{ marginLeft: '20px' }}>
                        {idleExceptionBody?.ScanStarted ? datecheck2(idleExceptionBody?.ScanStarted): <span>-</span>}
                        </span>
                        <span style={{ marginLeft: '20px' }}>Scan Ended:</span>
                        <span style={{ marginLeft: '20px' }}>
                          {idleExceptionBody?.ScanEnded ? idleExceptionBody?.ScanEnded : <span>-</span>}
                        </span>
                      </div>

                      <p>
                        Time To Complete Scan:
                        <span style={{ marginLeft: '20px' }}>
                          {idleExceptionBody?.TotalScanHours ? idleExceptionBody?.TotalScanHours : <span>-</span>}
                        </span>
                      </p>
                      <p>
                        Employee:
                        <span style={{ marginLeft: '20px' }}>
                          {idleExceptionBody?.AuditBy ? idleExceptionBody?.AuditBy : <span>-</span>}
                        </span>
                      </p>
                    </div>
                    <div style={{ width: '110%', marginTop: '5px' }}>

                      <table style={{ borderCollapse: 'collapse', width: '100%', marginTop: '40px' }}>
                        <thead style={{ width: '100%' }}>

                          {ItemScannedPrintHeaderArray?.map((obj: any, index: any) => {
                            return (
                              <>
                                <tr>
                                  <td>
                                    <div
                                      style={{
                                        fontFamily: 'sans-serif',
                                        marginTop: '1%',
                                        width: '125%',
                                      }}
                                    >
                                      <h4
                                        style={{
                                          borderBottom: '.5px solid black',
                                          marginTop: '40px',
                                          width: '190%'
                                        }}
                                      >
                                        Exceptions- Item Scanned
                                      </h4>
                                    </div>
                                  </td>
                                </tr>

                                <tr>

                                  <th style={{

                                    color: '#000',
                                    textAlign: 'left',
                                    padding: '7px',
                                    fontSize: '14px',
                                    width: '7%',
                                  }}>{obj.scanArea}
                                  </th>

                                  <th style={{

                                    color: '#000',
                                    textAlign: 'left',
                                    padding: '7px',
                                    fontSize: '14px',
                                    width: '7%',
                                  }}>{obj.dep}
                                  </th>

                                  <th style={{

                                    color: '#000',
                                    textAlign: 'left',
                                    padding: '7px',
                                    fontSize: '14px',
                                    width: '9%',
                                  }}>{obj.subDep}
                                  </th>

                                  <th style={{

                                    color: '#000',
                                    textAlign: 'left',
                                    padding: '7px',
                                    fontSize: '14px',
                                    width: '7%',
                                  }}>{obj.brand}
                                  </th>

                                  <th style={{

                                    color: '#000',
                                    textAlign: 'left',
                                    padding: '7px',
                                    fontSize: '14px',
                                    width: '7%',
                                  }}>{obj.model}
                                  </th>

                                  <th style={{

                                    color: '#000',
                                    textAlign: 'left',
                                    padding: '7px',
                                    fontSize: '14px',
                                    width: '8%',
                                  }}>{obj.item}
                                  </th>

                                  <th style={{

                                    color: '#000',
                                    textAlign: 'left',
                                    padding: '7px',
                                    fontSize: '14px',
                                    width: '9%',
                                  }}>{obj.serial}
                                  </th>

                                  <th style={{

                                    color: '#000',
                                    textAlign: 'left',
                                    padding: '7px',
                                    fontSize: '14px',
                                    width: '7%',
                                  }}>{obj.barCodeContent}
                                  </th>
                                  <th
                                    colSpan={2}
                                    style={{
                                      borderBottom: '1px solid #000',
                                      color: '#000',
                                      padding: '1px',
                                      marginBottom: '30%',
                                      fontSize: '14px',
                                      fontFamily: 'sans-serif',
                                      textAlign: 'center',
                                    }}
                                  >
                                    {obj.scanned}
                                  </th>
                                  <th style={{

                                    color: '#000',
                                    textAlign: 'left',
                                    padding: '7px',
                                    fontSize: '14px',
                                    width: '8%'
                                  }}>{obj.exception}
                                  </th><th style={{

                                    color: '#000',
                                    textAlign: 'left',
                                    padding: '7px',
                                    fontSize: '14px',
                                    width: '7%',
                                  }}>{obj.information}
                                  </th>
                                  <th style={{

                                    color: '#000',
                                    textAlign: 'left',
                                    padding: '7px',
                                    fontSize: '14px',
                                    width: '7%',
                                  }}>{obj.explanation}
                                  </th></tr>
                                <tr style={{ marginTop: '10%' }}>
                                  <td rowSpan={8} style={{ borderBottom: '1px solid #000' }}></td>
                                  <td rowSpan={8} style={{ borderBottom: '1px solid #000' }}></td>
                                  <td rowSpan={8} style={{ borderBottom: '1px solid #000' }}></td>
                                  <td rowSpan={8} style={{ borderBottom: '1px solid #000' }}></td>
                                  <td rowSpan={8} style={{ borderBottom: '1px solid #000' }}></td>
                                  <td rowSpan={8} style={{ borderBottom: '1px solid #000' }}></td>
                                  <td rowSpan={8} style={{ borderBottom: '1px solid #000' }}></td>
                                  <td rowSpan={8} style={{ borderBottom: '1px solid #000' }}></td>
                                  <th
                                    style={{
                                      color: '#000',
                                      padding: '1px',
                                      fontSize: '14px',
                                      fontFamily: 'sans-serif',
                                      borderBottom: '1px solid #000',
                                      width: '3%',
                                      marginTop: '2%',
                                    }}
                                  >
                                    {obj.date}
                                  </th>
                                  <th
                                    style={{
                                      color: '#000',
                                      padding: '1px',
                                      fontSize: '14px',
                                      fontFamily: 'sans-serif',
                                      borderBottom: '1px solid #000',
                                      width: '3%',
                                      marginTop: '2%',
                                    }}
                                  >
                                    {obj.time}
                                  </th>
                                  <td rowSpan={8} style={{ borderBottom: '1px solid #000' }}></td>
                                  <td rowSpan={8} style={{ borderBottom: '1px solid #000' }}></td>
                                  <td rowSpan={8} style={{ borderBottom: '1px solid #000' }}></td>

                                </tr></>
                            )
                          })}
                        </thead>
                        <tbody style={{ width: '100%' }}>
                          {itemwithExceptionBody?.map((obj: any, index: any) => {
                            return (
                              <tr key={index} style={{ width: '35%' }}>
                                <td style={{
                                  paddingTop: '7px',
                                  fontSize: '14px',
                                  textAlign: 'left',

                                }}>{obj.scanArea ? obj.scanArea : <span style={{ marginLeft: '25%' }}>-</span>}
                                </td>

                                <td style={{
                                  paddingTop: '7px',
                                  fontSize: '14px',
                                  textAlign: 'left',

                                }}> {obj.department ? obj.department : <span style={{ marginLeft: '15%' }}>-</span>}
                                </td>

                                <td style={{
                                  paddingTop: '7px',
                                  fontSize: '14px',
                                  textAlign: 'left',

                                }}> {obj.subDepartment ? obj.subDepartment : <span style={{ marginLeft: '15%' }}>-</span>}
                                </td>

                                <td style={{
                                  paddingTop: '7px',
                                  fontSize: '14px',
                                  textAlign: 'left',

                                }}> {obj.brand ? obj.brand : <span style={{ marginLeft: '20%' }}>-</span>}
                                </td>

                                <td style={{
                                  paddingTop: '7px',
                                  fontSize: '14px',
                                  textAlign: 'left',

                                }}>  {obj.modelNumber ? obj.modelNumber : <span style={{ marginLeft: '15%' }}>-</span>}
                                </td>

                                <td style={{
                                  paddingTop: '7px',
                                  fontSize: '14px',
                                  textAlign: 'left',

                                }}> {obj.inventoryNumber ? obj.inventoryNumber : <span style={{ marginLeft: '20%' }}>-</span>}
                                </td>

                                <td style={{
                                  paddingTop: '7px',
                                  fontSize: '14px',
                                  textAlign: 'left',

                                }}>{obj.serialNumber ? obj.serialNumber : <span style={{ marginLeft: '15%' }}>-</span>}
                                </td>

                                <td style={{
                                  paddingTop: '7px',
                                  fontSize: '14px',
                                  textAlign: 'left',

                                }}> {obj.barcode ? obj.barcode : <span style={{ marginLeft: '25%' }}>-</span>}
                                </td>
                                <div>
                                  <td style={{
                                    paddingTop: '7px',
                                    fontSize: '14px',
                                    textAlign: 'left',

                                  }}> {obj.inventoryAuditDate ? reportDateFormation(obj.inventoryAuditDate) : <span style={{ marginLeft: '15%' }}>-</span>}
                                  </td>

                                  <td style={{
                                    paddingTop: '7px',
                                    fontSize: '14px',
                                    textAlign: 'left',

                                  }}> {obj.inventoryAuditDate ? reportTimeFormation(obj.inventoryAuditDate) : <span style={{ marginLeft: '15%' }}>-</span>}
                                  </td>
                                </div>

                                <td style={{
                                  paddingTop: '7px',
                                  fontSize: '14px',
                                  textAlign: 'left',

                                }}> {obj.exceptionReason ? obj.exceptionReason : <span style={{ marginLeft: '25%' }}>-</span>}
                                </td>
                                <td style={{
                                  paddingTop: '7px',
                                  fontSize: '14px',
                                  textAlign: 'left',

                                }}>   {obj.additionalInformation ? obj.additionalInformation : <span style={{ marginLeft: '30%' }}>-</span>}
                                </td>

                                <td style={{
                                  paddingTop: '7px',
                                  fontSize: '14px',
                                  textAlign: 'left',

                                }}>{obj.explanation ? obj.explanation : <span style={{ marginLeft: '25%' }}>-</span>}
                                </td>
                              </tr>

                            );
                          })}
                        </tbody>
                      </table>
                    </div>

                    <div style={{ width: '100%', float: 'left', marginTop: '5px' }}>

                      <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                        <thead>


                          {ItemNotScannedHeadArrayprint.map((obj: any, index: any) => {
                            return (
                              <>
                                <tr>
                                  <td>
                                    <div
                                      style={{
                                        fontFamily: 'sans-serif',
                                        marginTop: '1%',
                                        width: '125%',
                                      }}
                                    >
                                      <h4
                                        style={{
                                          borderBottom: '.5px solid black',
                                          marginTop: '40px',
                                          width: '150%'
                                        }}
                                      >
                                        Exceptions- Item  Not Scanned
                                      </h4>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <th style={{

                                    color: '#000',
                                    textAlign: 'left',
                                    padding: '7px',
                                    fontSize: '14px',
                                    width: '7%',
                                    borderBottom: '1px solid #000'
                                  }}>{obj.dep}
                                  </th>

                                  <th style={{

                                    color: '#000',
                                    textAlign: 'left',
                                    padding: '7px',
                                    fontSize: '14px',
                                    width: '7%',
                                    borderBottom: '1px solid #000'
                                  }}>{obj.subDep}
                                  </th>

                                  <th style={{

                                    color: '#000',
                                    textAlign: 'left',
                                    padding: '7px',
                                    fontSize: '14px',
                                    width: '9%',
                                    borderBottom: '1px solid #000'
                                  }}>{obj.brand}
                                  </th>

                                  <th style={{

                                    color: '#000',
                                    textAlign: 'left',
                                    padding: '7px',
                                    fontSize: '14px',
                                    width: '7%',
                                    borderBottom: '1px solid #000'
                                  }}>{obj.model}
                                  </th>

                                  <th style={{

                                    color: '#000',
                                    textAlign: 'left',
                                    padding: '7px',
                                    fontSize: '14px',
                                    width: '7%',
                                    borderBottom: '1px solid #000'
                                  }}>{obj.item}
                                  </th>

                                  <th style={{

                                    color: '#000',
                                    textAlign: 'left',
                                    padding: '7px',
                                    fontSize: '14px',
                                    width: '8%',
                                    borderBottom: '1px solid #000'
                                  }}>{obj.serial}
                                  </th>

                                  <th style={{

                                    color: '#000',
                                    textAlign: 'left',
                                    padding: '7px',
                                    fontSize: '14px',
                                    width: '9%',
                                    borderBottom: '1px solid #000'
                                  }}>{obj.exception}
                                  </th>

                                  <th style={{

                                    color: '#000',
                                    textAlign: 'left',
                                    padding: '7px',
                                    fontSize: '14px',
                                    width: '7%',
                                    borderBottom: '1px solid #000'
                                  }}>{obj.explanation}
                                  </th>
                                </tr>
                              </>
                            )
                          })}

                        </thead>
                        <tbody>
                          {itemNotScannedBody?.map((obj: any, index: any) => {
                            return (
                              <tr key={index} style={{ width: '35px' }}>
                                <td
                                  style={{
                                    paddingTop: '7px',
                                    fontSize: '14px',
                                    textAlign: 'left',

                                  }}
                                >
                                  {obj.department ? obj.department : <span style={{ marginLeft: '15%' }}>-</span>}
                                </td>
                                <td
                                  style={{
                                    paddingTop: '7px',
                                    fontSize: '14px',
                                    textAlign: 'left',

                                  }}
                                >
                                  {obj.subDepartment ? obj.subDepartment : <span style={{ marginLeft: '15%' }}>-</span>}
                                </td>
                                <td
                                  style={{
                                    paddingTop: '7px',
                                    fontSize: '14px',
                                    textAlign: 'left',

                                  }}
                                >
                                  {obj.brand ? obj.brand : <span style={{ marginLeft: '15%' }}>-</span>}
                                </td>
                                <td
                                  style={{
                                    paddingTop: '7px',
                                    fontSize: '14px',
                                    textAlign: 'left',

                                  }}
                                >
                                  {obj.modelNumber ? obj.modelNumber : <span style={{ marginLeft: '15%' }}>-</span>}
                                </td>
                                <td
                                  style={{
                                    paddingTop: '7px',
                                    fontSize: '14px',
                                    textAlign: 'left',

                                  }}
                                >
                                  {obj.inventoryNumber ? obj.inventoryNumber : <span style={{ marginLeft: '15%' }}>-</span>}
                                </td>
                                <td
                                  style={{
                                    paddingTop: '7px',
                                    fontSize: '14px',
                                    textAlign: 'left',

                                  }}
                                >
                                  {obj.serialNumber ? obj.serialNumber : <span style={{ marginLeft: '15%' }}>-</span>}
                                </td>
                                <td
                                  style={{
                                    paddingTop: '7px',
                                    fontSize: '14px',
                                    textAlign: 'left',

                                  }}
                                >
                                  {obj.exceptionReason ? obj.exceptionReason : <span style={{ marginLeft: '15%' }}>-</span>}
                                </td>
                                <td
                                  style={{
                                    paddingTop: '7px',
                                    fontSize: '14px',
                                    textAlign: 'left',

                                  }}
                                >
                                  {obj.explanation ? obj.explanation : <span style={{ marginLeft: '15%' }}>-</span>}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>


                    <div style={{ width: '100%', float: 'left', marginTop: '5px' }}>

                      <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                        <thead>


                          {ItemNeedAttentionHeadArrayPrint.map((obj: any, index: any) => {
                            return (
                              <>
                                <tr>
                                  <td>
                                    <div
                                      style={{
                                        fontFamily: 'sans-serif',
                                        marginTop: '1%',
                                        width: '125%',
                                      }}
                                    >
                                      <h4
                                        style={{
                                          borderBottom: '.5px solid black',
                                          marginTop: '40px',
                                          width: '250%'
                                        }}
                                      >
                                        Items Needing Attention
                                      </h4>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <th style={{

                                    color: '#000',
                                    textAlign: 'left',
                                    padding: '7px',
                                    fontSize: '14px',
                                    width: '7%',
                                    borderBottom: '1px solid #000'
                                  }}>{obj.scanArea}
                                  </th>

                                  <th style={{

                                    color: '#000',
                                    textAlign: 'left',
                                    padding: '7px',
                                    fontSize: '14px',
                                    width: '7%',
                                    borderBottom: '1px solid #000'
                                  }}>{obj.dep}
                                  </th>

                                  <th style={{

                                    color: '#000',
                                    textAlign: 'left',
                                    padding: '7px',
                                    fontSize: '14px',
                                    width: '9%',
                                    borderBottom: '1px solid #000'
                                  }}>{obj.subDep}
                                  </th>

                                  <th style={{

                                    color: '#000',
                                    textAlign: 'left',
                                    padding: '7px',
                                    fontSize: '14px',
                                    width: '7%',
                                    borderBottom: '1px solid #000'
                                  }}>{obj.brand}
                                  </th>

                                  <th style={{

                                    color: '#000',
                                    textAlign: 'left',
                                    padding: '7px',
                                    fontSize: '14px',
                                    width: '7%',
                                    borderBottom: '1px solid #000'
                                  }}>{obj.model}
                                  </th>

                                  <th style={{

                                    color: '#000',
                                    textAlign: 'left',
                                    padding: '7px',
                                    fontSize: '14px',
                                    width: '8%',
                                    borderBottom: '1px solid #000'
                                  }}>{obj.item}
                                  </th>

                                  <th style={{

                                    color: '#000',
                                    textAlign: 'left',
                                    padding: '7px',
                                    fontSize: '14px',
                                    width: '9%',
                                    borderBottom: '1px solid #000'
                                  }}>{obj.serial}
                                  </th>

                                  <th style={{

                                    color: '#000',
                                    textAlign: 'left',
                                    padding: '7px',
                                    fontSize: '14px',
                                    width: '7%',
                                    borderBottom: '1px solid #000'
                                  }}>{obj.barCodeContent}
                                  </th>
                                  <th style={{

                                    color: '#000',
                                    textAlign: 'left',
                                    padding: '7px',
                                    fontSize: '14px',
                                    width: '7%',
                                    borderBottom: '1px solid #000'
                                  }}>{obj.date}
                                  </th>
                                  <th style={{

                                    color: '#000',
                                    textAlign: 'left',
                                    padding: '7px',
                                    fontSize: '14px',
                                    width: '7%',
                                    borderBottom: '1px solid #000'
                                  }}>{obj.time}
                                  </th>
                                  <th style={{

                                    color: '#000',
                                    textAlign: 'left',
                                    padding: '7px',
                                    fontSize: '14px',
                                    width: '7%',
                                    borderBottom: '1px solid #000'
                                  }}>{obj.ActionNeeded}
                                  </th>
                                  <th style={{

                                    color: '#000',
                                    textAlign: 'left',
                                    padding: '7px',
                                    fontSize: '14px',
                                    width: '7%',
                                    borderBottom: '1px solid #000'
                                  }}>{obj.Information}
                                  </th>
                                </tr>
                              </>
                            )
                          })}

                        </thead>
                        <tbody>
                          {itemNeedAttentionBody?.map((obj: any, index: any) => {
                            return (
                              <tr key={index} style={{ width: '35px' }}>
                                <td
                                  style={{
                                    paddingTop: '7px',
                                    fontSize: '14px',
                                    textAlign: 'left',

                                  }}
                                >
                                  {obj.scanArea ? obj.scanArea : <span style={{ marginLeft: '15%' }}>-</span>}
                                </td>
                                <td
                                  style={{
                                    paddingTop: '7px',
                                    fontSize: '14px',
                                    textAlign: 'left',

                                  }}
                                >
                                  {obj.department ? obj.department : <span style={{ marginLeft: '15%' }}>-</span>}
                                </td>
                                <td
                                  style={{
                                    paddingTop: '7px',
                                    fontSize: '14px',
                                    textAlign: 'left',

                                  }}
                                >
                                  {obj.subDepartment ? obj.subDepartment : <span style={{ marginLeft: '15%' }}>-</span>}
                                </td>
                                <td
                                  style={{
                                    paddingTop: '7px',
                                    fontSize: '14px',
                                    textAlign: 'left',

                                  }}
                                >
                                  {obj.brand ? obj.brand : <span style={{ marginLeft: '15%' }}>-</span>}
                                </td>
                                <td
                                  style={{
                                    paddingTop: '7px',
                                    fontSize: '14px',
                                    textAlign: 'left',

                                  }}
                                >
                                  {obj.modelNumber ? obj.modelNumber : <span style={{ marginLeft: '15%' }}>-</span>}
                                </td>
                                <td
                                  style={{
                                    paddingTop: '7px',
                                    fontSize: '14px',
                                    textAlign: 'left',

                                  }}
                                >
                                  {obj.inventoryNumber ? obj.inventoryNumber : <span style={{ marginLeft: '15%' }}>-</span>}
                                </td>
                                <td
                                  style={{
                                    paddingTop: '7px',
                                    fontSize: '14px',
                                    textAlign: 'left',

                                  }}
                                >
                                  {obj.serialNumber ? obj.serialNumber : <span style={{ marginLeft: '15%' }}>-</span>}
                                </td>
                                <td
                                  style={{
                                    paddingTop: '7px',
                                    fontSize: '14px',
                                    textAlign: 'left',

                                  }}
                                >
                                  {obj.barcode ? obj.barcode : <span style={{ marginLeft: '15%' }}>-</span>}
                                </td>
                                <td
                                  style={{
                                    paddingTop: '7px',
                                    fontSize: '14px',
                                    textAlign: 'left',

                                  }}
                                >
                                  {obj.inventoryAuditDate ? reportDateFormation(obj.inventoryAuditDate) : <span style={{ marginLeft: '15%' }}>-</span>}
                                </td>
                                <td
                                  style={{
                                    paddingTop: '7px',
                                    fontSize: '14px',
                                    textAlign: 'right',

                                  }}
                                >
                                  {obj.inventoryAuditDate ? reportTimeFormation(obj.inventoryAuditDate) : <span>-</span>}
                                </td>
                                <td
                                  style={{
                                    paddingTop: '7px',
                                    fontSize: '14px',
                                    textAlign: 'left',

                                  }}
                                >
                                  {obj.actionNeeded ? obj.actionNeeded : <span style={{ marginLeft: '15%' }}>-</span>}
                                </td>
                                <td
                                  style={{
                                    paddingTop: '7px',
                                    fontSize: '14px',
                                    textAlign: 'left',

                                  }}
                                >
                                  {obj.additionalInformation ? obj.additionalInformation : <span style={{ marginLeft: '15%' }}>-</span>}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>

                    <div style={{ width: '100%', float: 'left', marginTop: '5px' }}>

                      <table style={{ borderCollapse: 'collapse', width: '100%', }}>
                        <thead>
                          {daysIdleNotInFrontHeadArrayPrint.map((obj: any, index: any) => {
                            return (
                              <>
                                <tr>
                                  <td>
                                    <div
                                      style={{
                                        fontFamily: 'sans-serif',
                                        marginTop: '20%',
                                        width: '125%',

                                      }}
                                    >
                                      <h4
                                        style={{
                                          borderBottom: '.5px solid black',
                                          marginTop: '40px',
                                          width: '250%'
                                        }}
                                      >
                                        90+Days(Not In ShowRoom)
                                      </h4>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <th style={{

                                    color: '#000',
                                    textAlign: 'left',
                                    padding: '7px',
                                    fontSize: '14px',
                                    borderBottom: '1px solid #000',
                                    width: '7%'
                                  }}>{obj.scanArea}
                                  </th>

                                  <th style={{

                                    color: '#000',
                                    textAlign: 'left',
                                    padding: '7px',
                                    fontSize: '14px',
                                    borderBottom: '1px solid #000'
                                  }}>{obj.daysIdle}
                                  </th>

                                  <th style={{

                                    color: '#000',
                                    textAlign: 'left',
                                    padding: '7px',
                                    fontSize: '14px',
                                    borderBottom: '1px solid #000'
                                  }}>{obj.dep}
                                  </th>

                                  <th style={{

                                    color: '#000',
                                    textAlign: 'left',
                                    padding: '7px',
                                    fontSize: '14px',
                                    borderBottom: '1px solid #000'
                                  }}>{obj.subDep}
                                  </th>

                                  <th style={{

                                    color: '#000',
                                    textAlign: 'left',
                                    padding: '7px',
                                    fontSize: '14px',
                                    borderBottom: '1px solid #000'
                                  }}>{obj.brand}
                                  </th>

                                  <th style={{

                                    color: '#000',
                                    textAlign: 'left',
                                    padding: '7px',
                                    fontSize: '14px',
                                    borderBottom: '1px solid #000'
                                  }}>{obj.model}
                                  </th>

                                  <th style={{

                                    color: '#000',
                                    textAlign: 'left',
                                    padding: '7px',
                                    fontSize: '14px',
                                    borderBottom: '1px solid #000'
                                  }}>{obj.serial}
                                  </th>

                                  <th style={{

                                    color: '#000',
                                    textAlign: 'left',
                                    padding: '7px',
                                    fontSize: '14px',
                                    borderBottom: '1px solid #000'
                                  }}>{obj.barCodeContent}
                                  </th>
                                  <th style={{

                                    color: '#000',
                                    textAlign: 'left',
                                    padding: '7px',
                                    fontSize: '14px',
                                    borderBottom: '1px solid #000'
                                  }}>{obj.Accumrent}
                                  </th>
                                  <th style={{

                                    color: '#000',
                                    textAlign: 'left',
                                    padding: '7px',
                                    fontSize: '14px',
                                    borderBottom: '1px solid #000'
                                  }}>{obj.remaningValue}
                                  </th>
                                </tr>
                              </>
                            )
                          })}


                        </thead>
                        <tbody>
                          {daysIdleNotInFrontBody?.map((obj: any, index: any) => {
                            return (
                              <tr key={index} style={{ width: '35px' }}>
                                <td
                                  style={{
                                    paddingTop: '7px',
                                    fontSize: '14px',
                                    textAlign: 'left',

                                  }}
                                >
                                  {obj.scanArea ? obj.scanArea : <span style={{ marginLeft: '15%' }}>-</span>}
                                </td>
                                <td
                                  style={{
                                    paddingTop: '7px',
                                    fontSize: '14px',
                                    textAlign: 'left',

                                  }}
                                >
                                  {obj.idleDays ? obj.idleDays : <span style={{ marginLeft: '15%' }}>-</span>}
                                </td>
                                <td
                                  style={{
                                    paddingTop: '7px',
                                    fontSize: '14px',
                                    textAlign: 'left',

                                  }}
                                >
                                  {obj.department ? obj.department : <span style={{ marginLeft: '15%' }}>-</span>}
                                </td>
                                <td
                                  style={{
                                    paddingTop: '7px',
                                    fontSize: '14px',
                                    textAlign: 'left',

                                  }}
                                >
                                  {obj.subDepartment ? obj.subDepartment : <span style={{ marginLeft: '15%' }}>-</span>}
                                </td>
                                <td
                                  style={{
                                    paddingTop: '7px',
                                    fontSize: '14px',
                                    textAlign: 'left',

                                  }}
                                >
                                  {obj.brand ? obj.brand : <span style={{ marginLeft: '15%' }}>-</span>}
                                </td>
                                <td
                                  style={{
                                    paddingTop: '7px',
                                    fontSize: '14px',
                                    textAlign: 'left',

                                  }}
                                >
                                  {obj.modelNumber ? obj.modelNumber : <span style={{ marginLeft: '15%' }}>-</span>}
                                </td>
                                <td
                                  style={{
                                    paddingTop: '7px',
                                    fontSize: '14px',
                                    textAlign: 'left',

                                  }}
                                >
                                  {obj.inventoryNumber ? obj.inventoryNumber : <span style={{ marginLeft: '15%' }}>-</span>}
                                </td>
                                <td
                                  style={{
                                    paddingTop: '7px',
                                    fontSize: '14px',
                                    textAlign: 'left',

                                  }}
                                >
                                  {obj.serialNumber ? obj.serialNumber : <span style={{ marginLeft: '15%' }}>-</span>}
                                </td>
                                <td
                                  style={{
                                    paddingTop: '7px',
                                    fontSize: '14px',
                                    textAlign: 'left',

                                  }}
                                >
                                  {obj.inventoryRevenue ? obj.inventoryRevenue : <span style={{ marginLeft: '15%' }}>-</span>}
                                </td>
                                <td
                                  style={{
                                    paddingTop: '7px',
                                    fontSize: '14px',
                                    textAlign: 'left',

                                  }}
                                >
                                  {obj.remainingValue ? obj.remainingValue : <span style={{ marginLeft: '15%' }}>-</span>}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                      <div></div>
                    </div>

                  </div>
                </div>
              </div>
            </td></tr>
          </tbody>
        </table>
      </div>

    </>
  );
}
