/* eslint-disable prettier/prettier */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable sonarjs/cognitive-complexity */
import React, { useEffect, useState } from 'react';
import { ReactComponent as SortdownIcon } from '../../../assets/images/sort-down-icon.svg';
import { ReactComponent as SortupIcon } from '../../../assets/images/sort-up-icon.svg';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useParams } from 'react-router-dom';
import {
  Grid,
  RACTable,
  RACTableCell,
  RACTableRow,
  CircularProgress,
} from '@rentacenter/racstrap';
import { getInventoryServiceSummmary } from '../../../api/user';
import { fixedGridStyles } from '../../componentstyles/fixedGridStyles';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
export interface dynamicProps {
  id?: string;
  items: any;
  orderBy: string;
  order: string;
  ColumnLink?: boolean;
  gridtype:
  | 'Normal'
  | 'withSubChild'
  | 'withCheckBox'
  | 'withRadioBtn'
  | 'endWithCheckBox';
  handleActionType?: (value: any, index: any) => void;
  throwStyle: any;
  loaderController?: boolean;
}
interface ParamType {
  inventoryNum: string;
}
export interface columns {
  columnId: string;
  sortOrder: string;
  columnTitle: string;
  IsSorted: boolean;
  IsSortable: boolean;
  columnType?: 'link';
  handleColumnClick?: () => void;

  subColumns?: columns[];
}
const sortDirection = {
  ASC: 'asc',
  DESC: 'desc',
};

export interface IDynamicPageState {
  gridValues: [];
  gridColumn: columns[] | [];
}

const packagePriceColumns: columns[] = [
  {
    columnId: 'serviceNumber',
    sortOrder: sortDirection.ASC,
    columnTitle: 'Service #',
    IsSorted: true,
    columnType: 'link',
    IsSortable: true,
  },
  {
    columnId: 'startDate',
    sortOrder: sortDirection.DESC,
    columnTitle: 'Begin Date',
    IsSorted: false,
    columnType: 'link',
    IsSortable: true,
  },
  {
    columnId: 'endDate',
    sortOrder: sortDirection.ASC,
    columnTitle: 'End Date',
    IsSorted: false,
    columnType: 'link',
    IsSortable: true,
  },
  {
    columnId: 'vendorName',
    sortOrder: sortDirection.DESC,
    columnTitle: 'Vendor',
    IsSorted: false,
    columnType: 'link',
    IsSortable: true,
  },
  {
    columnId: 'serviceCost',
    sortOrder: sortDirection.ASC,
    columnTitle: 'Cost',
    IsSorted: false,
    columnType: 'link',
    IsSortable: true,
  },
  {
    columnId: 'serviceStatus',
    sortOrder: sortDirection.DESC,
    columnTitle: 'Status',
    IsSorted: false,
    columnType: 'link',
    IsSortable: true,
  },
  {
    columnId: 'loanerAgreementNumber',
    sortOrder: sortDirection.DESC,
    columnTitle: 'Loaner Agreement#',
    IsSorted: false,
    columnType: 'link',
    IsSortable: true,
  }
];

const DynamicGridComponent = (props: dynamicProps) => {
  const classes = props.throwStyle;
  const FGS = fixedGridStyles();
  const history = useHistory()
  const { inventoryNum } = useParams<ParamType>();
  const [gridValues, setGridValues] = useState<any[]>([]);
  const [gridColumn, setGridColumn] = useState<columns[]>([]);
  const [scrollset, setScrollset] = useState<number>(1);
  const [loader, setloader] = useState<boolean>(false);
  const [hasMore, sethasMore] = useState<boolean>(true);

  useEffect(() => {
    const { order, orderBy, items } = props;
    /* eslint-disable no-console */
    console.log('In Grid Component', props.items);

    const sortedData =
      order == sortDirection.DESC ?
        (items.sort((a: any, b: any) => {
          const c: any = new Date(a[orderBy]);
          const d: any = new Date(b[orderBy]);
          return d - c
        })) : (items.sort((a: any, b: any) => {
          const c: any = new Date(a[orderBy]);
          const d: any = new Date(b[orderBy]);
          return c - d
        }));

    setGridValues(sortedData);
    setGridColumn(packagePriceColumns);
  }, [props]);

  const truncString = (str: any, max: number, add: any) => {
    add = add || '...';
    return typeof str === 'string' && str.length > max
      ? str.substring(0, max) + add
      : str;
  };

  const sortArray = (
    columnId: string,
    index: number,
    datacolumn: any,
    dataValues: any
  ) => {
    const sortedData =
      datacolumn[index].sortOrder == sortDirection.DESC
        ? dataValues.sort((a: any, b: any) => {
          if (a[columnId] === null) {
            return 1;
          }
          if (b[columnId] === null) {
            return -1;
          }
          if (a[columnId] === b[columnId]) {
            return 0;
          }
          return b[columnId] < a[columnId] ? -1 : 1;
        })
        : dataValues.sort((a: any, b: any) => {
          if (a[columnId] === null) {
            return 1;
          }
          if (b[columnId] === null) {
            return -1;
          }
          if (a[columnId] === b[columnId]) {
            return 0;
          }
          return a[columnId] < b[columnId] ? -1 : 1;
        });
    const reorderColumns: columns[] = datacolumn;
    reorderColumns[index].sortOrder =
      reorderColumns[index].sortOrder == sortDirection.ASC
        ? sortDirection.DESC
        : sortDirection.ASC;
    reorderColumns.map((value: any, index1: number) => {
      reorderColumns[index1].IsSorted = index == index1 ? true : false;
    });
    setGridValues([...sortedData]);
    setGridColumn([...reorderColumns]);
    // setState({ gridValues: sortedData, gridColumn: reorderColumns });
  };

  const sortArrayDate = (
    columnId: string,
    index: number,
    datacolumn: any,
    dataValues: any
  ) => {
    const sortedData =
      datacolumn[index].sortOrder == sortDirection.DESC
        ? dataValues.sort((a: any, b: any) => {
          const c: any = new Date(a[columnId]);
          const d: any = new Date(b[columnId]);
          return d - c;

        })
        : dataValues.sort((a: any, b: any) => {
          const c: any = new Date(a[columnId]);
          const d: any = new Date(b[columnId]);
          return c - d;

        })
    const reorderColumns: columns[] = datacolumn;
    reorderColumns[index].sortOrder =
      reorderColumns[index].sortOrder == sortDirection.ASC
        ? sortDirection.DESC
        : sortDirection.ASC;
    reorderColumns.map((value: any, index1: number) => {
      reorderColumns[index1].IsSorted = index == index1 ? true : false;
    });
    setGridValues([...sortedData]);
    setGridColumn([...reorderColumns]);
    // setState({ gridValues: sortedData, gridColumn: reorderColumns });
  };

  const renderTableHead = (dataColumns: any, dataValues: any) => {

    return (
      <>
        {gridColumn.map((value: any, index: any) => {
          return (
            <RACTableCell key={index} className={classes.p2}>
              <a
                style={{
                  color: 'black',
                  textDecoration: 'none',
                  cursor: 'pointer',
                }}
                id={value.columnId}
                onClick={() => {
                  value.columnTitle !== 'Date' ?
                  sortArray(value.columnId, index, dataColumns, dataValues) :
                  sortArrayDate(value.columnId, index, dataColumns, dataValues)
                }
                }
              >
                {value.columnTitle + '  '}
                {value.IsSorted === true ? (
                  value.sortOrder == sortDirection.DESC ? (
                    <SortupIcon className={classes.ms0} />
                  ) : (
                    <SortdownIcon className={classes.ms0} />
                  )
                ) : null}
              </a>
            </RACTableCell>
          );
        })}
        <RACTableCell></RACTableCell>
      </>
    );
  };

  // eslint-disable-next-line sonarjs/cognitive-complexity
  const renderTableContent = (gridBodyData: any, gridColumns: any) => {
    if (props.loaderController === true) {
      return (
        <RACTableRow className={classes.bgTransparent} key="0">
          <RACTableCell colSpan={8}>
            <Grid className={classes.GridLoader}>
              <CircularProgress />
            </Grid>
          </RACTableCell>
        </RACTableRow>
      );
    } else if (!!gridBodyData && gridBodyData.length > 0) {
      return gridBodyData.map((value: any, index1: any) => {
        return (
          <React.Fragment key={index1}>
            <RACTableRow className={classes.bgTransparent}>
              <RACTableCell
                className={`${classes.fs6} ${classes.fontSemiBold} ${classes.p2}`}
              //style={{ width: '20%' }}
              >
                <a className={classes.racpadLink} href={`/inventory/service/request/${value?.serviceNumber}?type=Y`} onClick={(e: any) => {
                  history.push(`/inventory/service/request/${value?.serviceNumber}?type=Y`);
                  e.preventDefault()
                }}>
                  {value?.serviceNumber ? value?.serviceNumber : '-'}
                </a>
              </RACTableCell>

              <RACTableCell
                className={`${classes.fs6} ${classes.fontSemiBold} ${classes.p2}`}
              //style={{ width: '30.33%' }}
              >
                {value?.startDate ? moment(value.startDate).format('MM/DD/YYYY') : '-'}
              </RACTableCell>
              <RACTableCell>{value?.endDate ? new Date(value?.endDate).getTime() > new Date().getTime() ? "-" : moment(value.endDate).format('MM/DD/YYYY') : '-'}</RACTableCell>
              {value?.vendorName ?
                <RACTableCell>
                  {value?.vendorName}
                </RACTableCell> : <RACTableCell>
                  -
                </RACTableCell>}

              <RACTableCell>
                $ {value?.serviceCost ? value?.serviceCost : '-'}
              </RACTableCell>

              <RACTableCell>
                {value?.serviceStatus ? value?.serviceStatus : '-'}
              </RACTableCell>
              {value?.loanerAgreementNumber ?
                <RACTableCell className={`${classes.fs6} ${classes.fontSemiBold} ${classes.p2}`}>
                  <a className={classes.racpadLink} href={`/agreement/info/details/${value?.customerId}/${value?.loanerAgreementId}`} onClick={(e: any) => {
                    history.push(`/agreement/info/details/${value?.customerId}/${value?.loanerAgreementId}`);
                    e.preventDefault()
                  }}>
                    {value?.loanerAgreementNumber ? value?.loanerAgreementNumber : '-'}
                  </a>
                </RACTableCell> :
                <RACTableCell>
                  -
                </RACTableCell>}
            </RACTableRow>
          </React.Fragment>
        );
      });
    }
  };
  const nextscroll = async () => {
    setScrollset(scrollset + 1);
    setloader(true);
    const request: any = {
      identifier: inventoryNum,
      offset: scrollset + 1,
      limit: '20',
      type: 'inv'

    }
    const response = await getInventoryServiceSummmary(request);
    setloader(false);
    const val = response.data.serviceDetails;
    console.log('valresponse', val);
    setGridValues([...gridValues, ...val]);
    if (val.length === 0) {
      sethasMore(false);
    }
    console.log('mainResponse', gridValues);
  };
  return (
    <Grid className={`${FGS.fixTableHead} ${FGS.fixTableHeight400}`}>
      {gridValues.length > 7 ? (
        <InfiniteScroll
          dataLength={gridValues.length} //This is important field to render the next data
          next={nextscroll}
          hasMore={hasMore}
          height={400}
          loader={
            loader ? (
              <Grid className={classes.GridLoader}>
                <CircularProgress></CircularProgress>
              </Grid>
            ) : null
          }
        >
          <RACTable
            renderTableHead={() => renderTableHead(gridColumn, gridValues)}
            renderTableContent={() =>
              renderTableContent(gridValues, gridColumn)
            }
            stickyHeader
          />
        </InfiniteScroll>
      ) : (
        <RACTable
          renderTableHead={() => renderTableHead(gridColumn, gridValues)}
          renderTableContent={() => renderTableContent(gridValues, gridColumn)}
        />
      )}
    </Grid>
  );
};

export default DynamicGridComponent;
