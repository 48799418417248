/* eslint-disable prettier/prettier */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable sonarjs/no-identical-functions */
/* eslint-disable no-debugger */
/* eslint-disable react/react-in-jsx-scope */
import {
    Grid,
    Card,
    CardContent,
    Typography,
    // RACBadge,
    RACTextbox,
    RACSelect,
    RACButton,
    RACRadio,
    RACDatePicker,
    RACModalCard,
    List,
    ListItem,
    CircularProgress,
} from '@rentacenter/racstrap';
import React, { useState, useEffect, useContext } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { getInventoryServicingDetails, PrintFaxSheet, postAppointment, GetCustomerByIdInfo, getCurrentUser, getCoworkerDetails, AppointmentAction, getTimeSlot, getEvent, InventoryServicing, CoworkerRequired, getServiceOptions, LoadInventoryInformation, LoadDeliveryreceipt, insertAgreementLog } from '../../../api/user';
import { globalStyles } from '../../componentstyles/globalstyles';
import { truncString } from "../../Shared/validateObject";
import { ContainerContext } from '../../../app/App';
import { CustomPropInterface } from '../../../index';
import NumberFormat from "react-number-format";
import TwoFactorComponent from '../../Shared/secondAuthen';
import { ReactComponent as AlertIcon } from '../../../assets/images/no-records-found.svg';
import { ReactComponent as Successimage } from '../../../assets/images/success-icon.svg';
import moment from 'moment'
import { CHARGED_OFF, HOLD, LOANER, NOT_RENT_READY, SERVICE_CHARGED_OFF, ON_RENT, PENDING_CHARGE_OFF, PENDING_LOANER_AGREEMENT, PENDING_RENTAL_AGREEMENT, RENT_READY, RESERVED, SERVICE_IDLE, SERVICE_ON_RENT, TRANSFER_IN_PROGRESS } from '../../../constants/constants';
import { validateObject } from '../../../components/Shared/validateObject'
import clsx from 'clsx'
import { ReactComponent as Racfont } from '../../../assets/images/logo.svg';
import { ReactComponent as Logo } from '../../../assets/images/RAC LOGO.svg';
import { ReactComponent as Images } from '../../../assets/images/pdf-icon.svg';
import { addDays } from 'date-fns';
//serviceDetails response
interface InventoryServicingDetails {
    inventoryNumber: string;
    inventoryDesc: string;
    inventoryStatus: string;
    inventoryStatusRefCode?: string;
    serviceCost?: number;
    inventoryId?: number;
    serviceStatus?: string;
    serviceTicketId?: number | null;
    serviceStatusRefCode?: string;
    storeNumber?: string;
    agreementId?: string | null;
    agreementNumber?: string | null;
    agreementDesc?: string | null;
    customerId?: string | null;
    customerFirstName: string | null;
    customerLastName?: string | null;
    loanerAgreementId?: string | null;
    loanerAgreementNumber?: string | null;
    loanerAgreementDesc?: string | null;
    serviceHistory?: ServiceHistory[];
    bplusAuthNumber?: string | null;
    isClubActive?: string;
    purchaseDate?: string;
    isLoanerProvided?: string;
    appointmentDetails?: string | null;
    firstRentedDate?: string | null;

}

interface ServiceHistory {
    serviceHistoryId: number;
    serviceStatus: string;
    serviceStatusRefCode: string;
    serviceCost: number;
    date: string;
    problem: string;
    solution: string | null;
}
interface ParamType {
    inventoryNum: string
}
// let BindtimeslotDate = '';
// eslint-disable-next-line sonarjs/cognitive-complexity
export default function InvService() {
    const storeDetails = sessionStorage.getItem('storeDetails') || '';
    const encodestore = Buffer.from(storeDetails, 'base64');
    const decodestore: any = JSON.parse(encodestore.toString('ascii'));
    console.log('decodestore', decodestore);
    const history = useHistory();
    const classes = globalStyles();
    const { inventoryNum } = useParams<ParamType>()
    const [DeliveryStatus, setDeliveryStatus] = useState<any>('');
    const [CanceldeliveryCheck, setCanceldeliveryCheck] = useState<boolean>(false);
    const [saveDisable, setsaveDisable] = useState<boolean>(true);
    const [statusLoader, setstatusLoader] = useState<boolean>(true);
    const [statusError, setstatusError] = useState<boolean>(false);
    const [invSummaryerr, setinvSummaryerr] = useState<boolean>(false);
    const [appoinment, setAppoinment] = useState(false);
    const [BindtimeslotDate, setBindtimeslotDate] = useState<any>('');
    const search = useLocation().search;
    const containerData = useContext(ContainerContext) as CustomPropInterface;
    const type = new URLSearchParams(search).get('type');
    const retention = new URLSearchParams(search).get('retention');
    const [globalLoader, setglobalLoader] = useState<boolean>(true);
    const [printObjvalue, setPrintObjvalue] = useState<any>({});
    const [openPrintPopup, setOpenPrintPopup] = useState<any>(false);
    const [secondLvlAuth, setsecondLvlAuth] = useState(false);
    const [DeliveryConfirmation, setDeliveryConfirmation] = useState(false);
    const [Delivery, setDelivery] = useState(true);
    const [Carryout, setCarryout] = useState(false);
    const [DeliverySchedule, setDeliverySchedule] = useState<any>(false);
    const [dateError, setdateError] = useState('');
    const [MaxDate, setMaxDate] = useState('');
    const [closeLoaner, setcloseLoaner] = useState<boolean>(false);
    const initialservicedetails: InventoryServicingDetails = {
        inventoryNumber: '',
        inventoryDesc: '',
        inventoryStatus: '',
        inventoryStatusRefCode: '',
        serviceCost: 0,
        serviceStatus: '',
        serviceTicketId: 0,
        serviceStatusRefCode: '0',
        storeNumber: '',
        agreementId: null,
        agreementNumber: null,
        agreementDesc: null,
        customerId: null,
        customerFirstName: '',
        customerLastName: '',
        loanerAgreementId: null,
        loanerAgreementNumber: null,
        loanerAgreementDesc: null,
        serviceHistory: [
            {
                serviceHistoryId: 0,
                serviceStatus: '',
                serviceStatusRefCode: '',
                serviceCost: 0,
                date: '',
                problem: '',
                solution: null
            }],
        bplusAuthNumber: '',
        isClubActive: '0',
        isLoanerProvided: '',
        appointmentDetails: null,
        firstRentedDate: null,
    }
    const [provideLoaner, setprovideLoaner] = useState(false);
    const [appointmentId, setappointmentId] = React.useState('');
    const [Apierror, setApierror] = React.useState(false);
    const [ApierrorContent, setApierrorContent] = React.useState('');
    const [servicedetails, setservicedetails]: any = useState<InventoryServicingDetails>(initialservicedetails);
    const [data, setData] = useState<any>({
        problem: '',
        solution: '',
        benefit: '',
        ServiceCost: '',
        StatusDropdown: '0',
        TotalCost: '0.00',
        AgreementId: '',
    });
    const [serviceHistory, setserviceHistory] = useState<ServiceHistory[]>([])
    const [serviceOptions, setserviceOptions] = useState<any>([]);
    const [timeSlot, setTimeSlot] = useState<any>(false);
    const [inHome, setInHome] = useState<any>(true);
    const [inStore, setInStore] = useState<any>(false);
    const [twoMoreRadios, setTwoMoreRadios] = useState<any>(false);
    const [pickup, setPickup] = useState<any>(true);
    const [carryin, setCarryin] = useState<any>(false);
    //timeslot state
    const [timeslotid, Settimeslotid] = useState('');
    const [IsScheduled, setIsScheduled] = useState(false);
    const [timeSlotData, SettimeSlotData]: any = useState([]);
    const [ContextStartDate, SetContextStartDate] = useState('');
    const [ContextEndDate, SetContextEndDate] = useState('');
    const [Time, SetTime] = useState('');
    const [CompleteStatus, setCompleteStatus] = useState<any>('');
    const [Instructions, SetInstructions] = useState('');
    const [BindtimeslotId, setBindtimeslotId] = useState('');
    const [currentCWRole, setcurrentCWRole] = useState('');
    const [employeeId, setemployeeId] = useState('');
    const [SaveTrigger, SetSaveTrigger] = useState(false);
    const [enableFinish, SetenableFinish] = useState(true);
    const [timeSlotenable, settimeSlotenable] = useState(false);
    const [coWorkerRequiredArray, setcoWorkerRequiredArray]: any = useState([]);
    const [conditionLoader, SetconditionLoader] = useState(true);
    const [Coworkers, SetCoworkers]: any = useState('');
    const [masterLoader, setmasterLoader] = React.useState(false);
    const [AppoinmentType, setAppoinmentType] = React.useState('S');
    const [errorMsg, setErrorMsg] = useState<boolean>(false)
    const [AppoinmentSource, setAppoinmentSource]: any = React.useState('');
    const [PrintAry, setPrintAry] = useState('');
    const [printInfo, setprintInfo] = useState<any>('');
    const [appointmentDate, setappointmentDate] = useState('');
    const [deliveryTime, setdeliveryTime] = useState('');
    const [AppointScheduleSucess, setAppointScheduleSucess] = useState(false);
    const [AppointConfirmSucess, setAppointConfirmSucess] = useState(false);
    const [EventResult, setEventResult] = useState<any>('');
    const [customerInfo, setCustomerInfo] = useState<any>('');
    const [phoneNumber, setphoneNumber] = useState<any>('')
    const [StoreNameFill, setStoreName] = useState<any>('')
    const [address, setAddress] = useState<any>()
    const [startDate, setStartDate] = useState(moment().format('YYYY-MM-DD'));
    const PaddingConst = '0px 8px 0px 8px';
    const Constinter = '1px solid  #545454';
    const borderRadiuss = '3px';
    const borderbotttom = '1px solid #545454';
    const borderrr = '1px solid #DFDFDF';
    const borderrbottommm = '1px dashed #545454';
    const borderalone = '1px solid   #545454';
    useEffect(() => {
        if (type == 'N') {
            InventoryInformation()
        }
        else {
            InvServDetails();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (containerData !== undefined) {
            console.log('containerData', containerData);
            console.log('containerDataRole', containerData.GetRole());
            setcurrentCWRole(containerData?.GetRole());
            setemployeeId(containerData?.GetEmployeeId());
            const roleObj = containerData?.GetRole()
        } else {
            getCoWorkerRole();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getCoWorkerRole = async () => {
        try {

            const currentUserRes = await getCurrentUser();
            console.log('getCurrentUser-apiResponse', currentUserRes);

            if (currentUserRes.status === 200) {
                const EmployeeId = currentUserRes.data.employeeId;
                setemployeeId(EmployeeId)
                console.log('EmployeeId', EmployeeId);
                const payload = {
                    coworkerId: EmployeeId,
                    storesRequired: true
                }
                console.log('menuandstores-payload', payload);
                const coWorkerRoleObj: any = await getCoworkerDetails(payload);
                if (
                    validateObject(coWorkerRoleObj) && coWorkerRoleObj.status == 200 &&
                    validateObject(coWorkerRoleObj.data.coworkerProfile) &&
                    validateObject(coWorkerRoleObj.data.coworkerProfile.role)
                ) {
                    const roleObj = coWorkerRoleObj.data.coworkerProfile.role;
                    console.log('roleObj', roleObj);
                    setcurrentCWRole(roleObj);
                }
            }

        } catch (e: any) {
            console.log('Error in co worker role');
        }
    };

    const normalizeinput = (phonenum: any) => {
        if (phonenum == '') return phonenum;
        if (!phonenum) return phonenum;
        const currentValue = phonenum.replace(/[^\d]/g, '');

        return `(${currentValue.slice(0, 3)}) ${currentValue.slice(
            3,
            6
        )}-${currentValue.slice(6, 10)}`;
    };
    useEffect(() => {
        if (timeslotid !== '') {
            SetenableFinish(false);
        }
        else {
            SetenableFinish(true);

        }
    }, [timeslotid]);

    const SIserviceStatusDecider = (serviceStatus?: string | null) => {
        switch (serviceStatus) {
            //problem reported 
            case 'PBR':
                return (['ISS', 'PO', 'RTI', 'WSS'])
            //problem reported
            case 'PO':
                return (['ISS', 'PR', 'RTI', 'WSS'])
            //parts received
            case 'PR':
                return (['ISS', 'PO', 'RTI', 'WSS'])
            //Waiting for service/shuttle 
            case 'WSS':
                return (['ISS', 'PO', 'RTI'])
            //In-Store Service
            case 'ISS':
                return (['ESR', 'PO', 'PR', 'RTI', 'WSS'])
            default:
                return ['']
        }
    }
    const SORserviceStatusDecider = (serviceStatus?: string | any) => {
        switch (serviceStatus) {
            //problem reported 
            case 'PBR':
                return (['IHS', 'IHT', 'ISS', 'PO', 'RTC', 'WSS'])
            //problem reported
            case 'PO':
                return (['IHS', 'IHT', 'ISS', 'PR', 'RTC', 'WSS'])
            //parts received
            case 'PR':
                return (['IHS', 'IHT', 'ISS', 'PO', 'RTC', 'WSS'])
            //Waiting for service/shuttle 
            case 'WSS':
                return (['IHS', 'IHT', 'ISS', 'PO', 'PR', 'RTC'])
            //In-home by store
            case 'IHS':
                return (['ESR', 'IHT', 'ISS', 'PO', 'RTC', 'WSS'])
            //In-home by third party
            case 'IHT':
                return (['ESR', 'ISS', 'PO', 'RTC', 'WSS'])
            //In-store service
            case 'ISS':
                return (['ESR', 'PO', 'PR', 'RTC', 'WSS'])
            default:
                return ['']
        }
    }
    const SCOserviceStatusDecider = (serviceStatus?: string | null) => {
        switch (serviceStatus) {
            //problem reported 
            case 'PBR':
                return (['ISS', 'PO', 'RO', 'WSS'])
            //problem reported
            case 'PO':
                return (['ISS', 'PR', 'RO', 'WSS'])
            //parts received
            case 'PR':
                return (['ISS', 'PO', 'RO', 'WSS'])
            //Waiting for service/shuttle 
            case 'WSS':
                return (['ISS', 'PO', 'RO'])
            //In-Store Service
            case 'ISS':
                return (['PO', 'PR', 'RO', 'WSS'])
            default:
                return ['']
        }
    }

    const createStatusDropdownOptions = (allOptions: any, invStaus: string, currentServStatus?: string | null) => {
        const optFilter = allOptions.filter((obj: any) => obj.referenceCode != 'ESR');
        if (invStaus == 'NRR' || invStaus == 'OR' || invStaus == 'CO') {
            return optFilter.filter((obj: any) => obj.referenceCode == 'PBR');
        }
        if (invStaus == 'SI') {
            return optFilter.filter((obj: any) => SIserviceStatusDecider(currentServStatus).includes(obj.referenceCode));
        }
        if (invStaus == 'SOR') {
            return optFilter.filter((obj: any) => SORserviceStatusDecider(currentServStatus)?.includes(obj.referenceCode));
        }
        if (invStaus == 'SCO') {
            return optFilter.filter((obj: any) => SCOserviceStatusDecider(currentServStatus).includes(obj.referenceCode));
        }
    }

    const GetServiceoptions = async (serviceDetails: any) => {
        const result = await getServiceOptions({
            storeNumber: '1',
            rmsItemMasterIds: [1, 1]
        });
        setstatusLoader(false);
        console.log('GetServiceoptions', result);
        if (result?.references) {
            const serviceOptionResponse = result.references[0].referenceDetails;
            console.log('serviceOptionResponse', serviceOptionResponse);
            console.log('serviceDetails', serviceDetails);
            const currentInvStatus = serviceDetails.inventoryStatusRefCode;
            const currentServiceStatus = type == 'N' ? null : serviceDetails.serviceStatusRefCode;
            const response = createStatusDropdownOptions(serviceOptionResponse, currentInvStatus, currentServiceStatus);
            console.log("createStatusDropdownOptions response", response);
            const setterserviceOptions = [
                { id: '0', description: 'Select', referenceCode: 'Select' },
                ...response,
            ];
            setserviceOptions(setterserviceOptions);
            if (type == 'N') {
                setData({ ...data, StatusDropdown: 'PBR' })
            }
        }
        else {
            setstatusError(true);
        }
    };

    const ServiceArrayFunction = () => {
        if (serviceOptions !== undefined) {
            return serviceOptions.map((value: any) => {
                return {
                    label: value.description,
                    value: value.referenceCode,
                };
            });
        } else {
            return [{ label: 'Select', value: 'Select' }];
        }
    };
    //ServiceDetails 
    // eslint-disable-next-line sonarjs/cognitive-complexity
    const InvServDetails = async (value?: any) => {
        const result = await getInventoryServicingDetails(inventoryNum);
        setglobalLoader(false);
        if (result.status == 200) {
            console.log('InvServDetails', result);
            const servResult = result.data;
            GetServiceoptions(servResult);
            if (servResult?.appointmentDetails != null) {
                const data = servResult?.appointmentDetails[0].appointmentId
                const lastElement = servResult?.appointmentDetails[servResult?.appointmentDetails.length - 1];
                console.log('lastElement', lastElement);
                // if (servResult?.serviceStatusRefCode == 'RTC') {
                //     setDeliveryConfirmation(true);
                //     setAppoinmentType('SD')
                // }
                setappointmentId(lastElement?.appointmentId);
                setmasterLoader(true)
                const result = await getEvent(lastElement?.appointmentId);
                console.log('getEvent', result);
                setmasterLoader(false)
                if (result.status == 200) {
                    setIsScheduled(true);
                    const Rescheduleresult = result.data
                    if (Rescheduleresult?.appointmentInfo?.appointmentType == 'S' && servResult?.serviceStatusRefCode == 'RTC') {
                        setDeliveryConfirmation(false);
                    }
                    else if (servResult?.serviceStatusRefCode == 'RTC') {
                        setDeliveryConfirmation(true);
                        setAppoinmentType('SD')
                    }
                    setDeliveryStatus(Rescheduleresult?.appointmentInfo?.appointmentStatusDesc)
                    if (Rescheduleresult?.appointmentInfo?.appointmentStatusDesc == 'Cancelled') {
                        setCanceldeliveryCheck(true)
                    }
                    setEventResult(Rescheduleresult?.appointmentInfo)
                    setCompleteStatus(Rescheduleresult?.appointmentInfo?.appointmentStatusDesc)
                }
                else if (result.status == 400) {
                    console.log('AppointmentActionjhihihih', result);
                    setApierror(true)
                    setApierrorContent(result.data.errors[0].error)
                }
                else {
                    setErrorMsg(true);
                }
            }
            setservicedetails(servResult);
            setserviceHistory(servResult.serviceHistory);
            const problem = servResult?.serviceHistory[0]?.problem
            const solution = servResult?.serviceHistory[0]?.solution === "null" ? '' : servResult?.serviceHistory[0]?.solution
            setData({ ...data, benefit: servResult?.bplusAuthNumber ? servResult?.bplusAuthNumber : '', TotalCost: Number(servResult?.serviceCost)?.toFixed(2), problem: problem, StatusDropdown: 'Select', solution: solution, AgreementId: servResult?.agreementId })
        }
        else if (result.status == 400) {
            console.log('AppointmentActionjhihihih', result);
            setApierror(true)
            setApierrorContent(result.data.errors[0].error)
        }
        else {
            setinvSummaryerr(true)
        }
    };
    //InventoryInformationAPICall
    const InventoryInformation = async () => {
        const result = await LoadInventoryInformation(inventoryNum);
        setglobalLoader(false);
        if (result.status == 200) {
            const InvResult = result.data
            console.log('InvResult', InvResult);

            const destructInvResults = {
                inventoryNumber: InvResult.inventoryHeader.itemNumber,
                inventoryId: InvResult.inventoryInformation.inventoryId,
                inventoryDesc: InvResult.inventoryHeader.description,
                inventoryStatus: InvResult.inventoryHeader.status,
                customerFirstName: InvResult?.currentAgreement?.customerName,
                agreementId: InvResult?.currentAgreement?.agreementId,
                isClubActive: InvResult?.inventoryInformation?.isClubActiveForCustomer
            }
            const tempReq = {
                serviceStatusRefCode: null,
                inventoryStatusRefCode: invStatusDescription(InvResult.inventoryHeader.status),
            }
            GetServiceoptions(tempReq);
            setservicedetails(destructInvResults);
        }
        else if (result.status == 400) {
            console.log('AppointmentActionjhihihih', result);
            setApierror(true)
            setApierrorContent(result.data.errors[0].error)
        }
        else {
            setinvSummaryerr(true)
        }
        console.log('InvServDetails', result);
    };

    const handleOnPrint_ = async () => {
        setmasterLoader(true);
        const result = await LoadInventoryInformation(servicedetails?.inventoryNumber);
        setmasterLoader(false);
        if (result.status == 200) {
            const InvResult = result.data
            console.log('InvResult', InvResult);
            setprintInfo(InvResult);
            handlePrint(document.getElementById('printService'));

        }
        else if (result.status == 400) {
            console.log('AppointmentActionjhihihih', result);
            setApierror(true)
            setApierrorContent(result.data.errors[0].error)
        }
        else {
            setinvSummaryerr(true)
        }
        console.log('InvServDetails', result);
    };

    const handleOnPrint = async () => {
        setmasterLoader(true);
        let InvInforesponse: any = {

        };

        let CustomerInforesponse: any = {

        };
        let storeNameResponse: any = {

        };
        const Apicallstack: any[] = [];
        if (servicedetails?.customerId) {
            [LoadInventoryInformation(servicedetails?.inventoryNumber),
            PrintFaxSheet(servicedetails?.storeNumber),
            GetCustomerByIdInfo(servicedetails?.customerId, servicedetails?.storeNumber)].forEach((el: any) => {
                Apicallstack.push(el)
            })
        }
        else{
            [LoadInventoryInformation(servicedetails?.inventoryNumber),
                PrintFaxSheet(servicedetails?.storeNumber)].forEach((el: any) => {
                    Apicallstack.push(el)
                })
        }
        await Promise.all(Apicallstack).then((resp) => {
            [InvInforesponse, storeNameResponse, CustomerInforesponse] = resp;
            console.log('InvInforesponse', InvInforesponse);
            console.log('CustomerInforesponse', CustomerInforesponse);
            if (InvInforesponse?.status == 200 && storeNameResponse?.status == 200) {
                setmasterLoader(false);
                const InvResult = InvInforesponse?.data
                const storeNameResult = storeNameResponse?.data
                setStoreName(storeNameResult?.response[0]?.storeName)
                console.log('InvResult', InvResult);
                console.log('storeNameResult', storeNameResult);
                setprintInfo(InvResult);
                if (CustomerInforesponse?.status == 200) {
                    const CustomerResult = CustomerInforesponse?.data
                    setCustomerInfo(CustomerResult?.GetCustomer.value)
                    const addressAry = CustomerResult?.GetCustomer?.value?.addresses
                    const filterValue = addressAry?.filter((v: any) => v.addressType == 'PRIM');
                    const phoneNumberAry = CustomerResult?.GetCustomer?.value?.phones
                    const filterphoneNum = phoneNumberAry?.filter((v: any) => v.primary == 'Y');
                    setphoneNumber(filterphoneNum[0].phoneNumber)
                    setAddress(filterValue[0]);
                }

                handlePrint(document.getElementById('printService'));

            }
            else if (InvInforesponse.status == 400) {
                console.log('AppointmentActionjhihihih', InvInforesponse);
                setApierror(true)
                setApierrorContent(InvInforesponse.data.errors[0].error)
            }
            else {
                setinvSummaryerr(true)
            }

        })
    }


    const invStatusDescription = (value: any) => {
        if (value == 'On Rent') {
            return 'OR'
        }
        else if (value == 'Not Rent Ready') {
            return 'NRR'
        }
        else if (value == 'Charged Off') {
            return 'CO'
        }
    }
    const ProblemOnChange = (e: any) => {
        if (e.target.value.length > 0) {
            setsaveDisable(false)
            setData({ ...data, problem: e.target.value });
        }
        else {
            setsaveDisable(true)
            setData({ ...data, problem: e.target.value });
        }
    };

    const handleInputChange = (e: any) => {
        setData({ ...data, [e.target.name]: e.target.value });
    };

    const handleServiceDropdownChange = (e: any) => {
        if (e.target.value == 'RTC') {
            setAppoinmentType('SD')
            setappointmentId('');
            SettimeSlotData([]);
            SetTime('');
            Settimeslotid('');
            setBindtimeslotId('');
            setDeliveryConfirmation(true);
            setData({ ...data, StatusDropdown: e.target.value });
        }
        else {
            setDeliveryConfirmation(false);
            setAppoinmentType('S')
            setData({ ...data, StatusDropdown: e.target.value });

        }
    };

    const handleAppoinment = (clicked: any) => {
        if (clicked == 'save') {
            setAppoinment(true);
        }
        if (clicked == 'closeIcon' || clicked == 'no') {
            setAppoinment(false);
        }
    };
    const handlePrint = (html: any) => {
        if (html !== undefined && html !== null) {
            const divContents = html.innerHTML;
            const a: any = window.open('');
            a?.document.write('<html>');
            a?.document.write(divContents);
            a?.document.write('</html>');
            a?.document.close();
            a?.print();
        }
    };
    const serviceTypePendingRadio = (value: any) => {
        switch (value) {
            case 'S':
                setInHome(true);
                setInStore(false);
                setTwoMoreRadios(false);
                setAppoinmentType('S')
                break;
            case 'SP':
                setInHome(false);
                setInStore(true);
                setTwoMoreRadios(true);
                setAppoinmentType('SP');
                setPickup(true);
                setCarryin(false);
                break;
            case 'SCI':
                setInHome(false);
                setInStore(true);
                setTwoMoreRadios(true);
                setCarryin(true);
                setPickup(false);
                setAppoinmentType('SCI')
                break;
            case 'SD':
                setDelivery(true);
                setCarryout(false);
                setAppoinmentType('SD')
                break;
            case 'SCO':
                setCarryout(true);
                setDelivery(false);
                setAppoinmentType('SCO')
                break;
            default:

        }


    }
    const handleRadio = (e: any) => {
        if (e.target.name == 'in Home') {
            setInHome(true);
            setInStore(false);
            setTwoMoreRadios(false);
            setAppoinmentType('S')

        }
        else if (e.target.name == 'in Store') {
            setInHome(false);
            setInStore(true);
            setTwoMoreRadios(true);
            setAppoinmentType('SP');
            //setAppoinmentType('P')
        }
        else if (e.target.name == 'pickup') {
            setPickup(true);
            setCarryin(false);
            setAppoinmentType('SP')
        }
        else if (e.target.name == 'carryIn') {
            setCarryin(true);
            setPickup(false);
            setAppoinmentType('SCI')
        }
        else if (e.target.name == 'delivery') {
            setDelivery(true);
            setCarryout(false);
            setAppoinmentType('SD')
        }
        else if (e.target.name == 'carryOut') {
            setCarryout(true);
            setDelivery(false);
            setAppoinmentType('SCO')
        }
    };

    const completeVerbage = () => {
        if (['SP', 'SCI'].includes(AppoinmentType)) {
            return 'Pickup'
        }
        else if (['SD', 'SCO'].includes(AppoinmentType)) {
            return 'Delivery'
        }
        else {
            return 'Appointment'
        }
    }
    const handlePrintChecklist = async () => {
        setmasterLoader(true);
        setOpenPrintPopup(false);
        const AgreementId = data.AgreementId;
        const printResponse = await LoadDeliveryreceipt(AgreementId);
        console.log('printResponseamain', printResponse);
        if (printResponse?.status == 200) {
            setmasterLoader(false);
            console.log('printResponse', printResponse?.data?.responseData[0]);
            // setPrintResult(printResponse.responseData[0]);
            const data = printResponse?.data?.responseData[0]
            const printObj = {
                inventory: data?.inventoryId,
                customerName: data?.customerName,
                addressLine1: data?.addressLine1,
                addressLine2: data?.addressLine2,
                agreementId: data?.agreementId,
                agreementNumber: data?.agreementNumber,
                customerCity: data?.customerCity,
                customerPhoneNumber: data?.customerPhoneNumber,
                customerPlus4: data?.customerPlus4,
                storeName: data?.storeName,
                storeAddressLine1: data?.storeAddressLine1,
                storeAddressLine2: data?.storeAddressLine2,
                storeCity: data?.storeCity,
                storeState: data?.storeState,
                storeZip: data?.storeZip,
                customerZip: data?.customerZip,
                inventoryDesc: data?.inventoryDesc,
                serialNumber: data?.serialNumber,
                storePhoneNumber: data?.storePhoneNumber,
                customerId: data?.customerId,
            };
            setPrintObjvalue(printObj);
            setPrintAry(printResponse?.data?.responseData)
            setOpenPrintPopup(true);
            console.log('printObjvalue1', printObj);
        }
        else {
            setinvSummaryerr(true)
        }
    };

    const formatDate = (dates: any) => {
        const dateFormat = new Date(dates);
        console.log(dateFormat, '<--- Date Format');
        console.log(dateFormat.toUTCString(), 'UTC');
        return (
            dateFormat.toUTCString().slice(0, 4) +
            dateFormat.toUTCString().slice(7, 12) + dateFormat.toUTCString().slice(5, 7)
        );
    };

    const GetTimeSlot = async (StartingDate: any) => {
        //const startingdate = new Date(StartingDate);
        const startingdate = StartingDate;
        const maximumdate = moment(StartingDate).add(3, 'months').format('YYYY-MM-DD');
        setMaxDate(maximumdate)
        //const endingdate = addDays(startingdate, 9);
        const endingdate = moment(StartingDate).add(9, 'days').format('YYYY-MM-DD')
        console.log(endingdate);
        const formattedstartingdate = moment(startingdate).format('YYYY-MM-DD');
        const formattedenddate = moment(endingdate).format('YYYY-MM-DD');
        // const formattedstartingdate = changeDateFormat(startingdate);
        // const formattedenddate = changeDateFormat(endingdate);
        console.log('DAtsssssssrefd', formattedenddate);
        const payload = {
            isLoaner: 'Y',
            customerId: '108348922',
            endDate: formattedenddate,
            eventSource: "STO",
            startDate: formattedstartingdate,
            storeId: window.sessionStorage.getItem('storeNumber')

        }
        const timeslotresponse = await getTimeSlot(payload);
        if (timeslotresponse.data !== undefined) {
            console.log('timeslotresponse', timeslotresponse);

            setAppoinmentSource(timeslotresponse.data.appointmentSource);
            // SetTimeslotReason(timeslotresponse);
            const TimeSlotsDetailsArrayValue: any = [];
            interface TimeSlotDetails {
                timeSlot: string;
                startTime: string;
                endTime: string;
                status: string;
            }

            interface TimeSlotDetailsArray {
                Date: string;
                TimeSlots: TimeSlotDetails;
            }

            const TimeSlotVariable = timeslotresponse.data.timeSlots;
            const sorted: any = Object.keys(TimeSlotVariable)
                .sort(function (a: any, b: any) {
                    const date1: any = new Date(a);
                    const date2: any = new Date(b);
                    return date1 - date2;
                })
                .reduce((accumulator: any, key: any) => {
                    accumulator[key] = TimeSlotVariable[key];

                    return accumulator;
                }, {});
            Object.keys(sorted).forEach(function (key) {
                const value = sorted[key];
                //  console.log(key + ':' + value);
                const Timeslots: any = [];

                value.map((value: any) => {
                    const timeslot_object = {} as TimeSlotDetails;
                    timeslot_object.timeSlot = value.timeSlot;
                    timeslot_object.startTime = value.startTime;
                    timeslot_object.endTime = value.endTime;
                    timeslot_object.status = value.status;
                    Timeslots.push(timeslot_object);
                });

                const TotalTimeSlots = {} as TimeSlotDetailsArray;
                TotalTimeSlots.Date = key;
                TotalTimeSlots.TimeSlots = Timeslots;
                TimeSlotsDetailsArrayValue.push(TotalTimeSlots);
            });
            SettimeSlotData(TimeSlotsDetailsArrayValue);
            // SetTime('');
            // console.log('beoefevcdvbh', BindtimeslotId);
            // setBindtimeslotId('');
            // eslint-disable-next-line no-console
            console.log(TimeSlotsDetailsArrayValue, 'timeslot converted array');
        }
        //  else {
        //   const StartingTime =
        //     props.reScheduleData.appointmentStartTime !== undefined &&
        //     props.reScheduleData.appointmentStartTime !== ''
        //       ? props.reScheduleData.appointmentStartTime
        //       : '';
        //   const EndingTime =
        //     props.reScheduleData.appointmentEndTime !== undefined &&
        //     props.reScheduleData.appointmentEndTime !== ''
        //       ? props.reScheduleData.appointmentEndTime
        //       : '';
        //   const slicedStartdate =
        //     StartingTime.slice(0, 5) + StartingTime.slice(8, StartingTime.length);

        //   const Selectedslot = slicedStartdate + '-' + EndingTime;
        //   SetTime(Selectedslot);
        //   //Timebind(event);
        // }
        // eslint-disable-next-line no-console
        console.log(timeslotresponse.data, 'testingresponsetimeslot');
    };

    const changeDateFormat = (date: Date) => {
        // eslint-disable-next-line prefer-const
        let d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            // eslint-disable-next-line prefer-const
            year = d.getFullYear();
        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;
        return [year, month, day].join('-');
    };

    const getslotdetails = (
        avail: any,
        modifiedStarttime: any,
        modifiedEndtime: any
    ) => {
        // if (props.triggertimeChanged !== undefined) {
        //   props.triggertimeChanged();
        // }
        console.log('ContextStart', modifiedStarttime);
        console.log('ContextEnd', modifiedEndtime);
        console.log('avail', avail);
        SetContextStartDate(modifiedStarttime);
        SetContextEndDate(modifiedEndtime);
        SetTime(avail.target.value);
        Settimeslotid(avail.target.id);
        setBindtimeslotId(avail.target.id);
        //BindtimeslotDate = avail.target.name;
        setBindtimeslotDate(avail.target.name)
        setStartDate(moment(avail.target.name).format('YYYY-MM-DD'));
        buildTimeSlot();
        SetSaveTrigger(false);
    };

    // eslint-disable-next-line sonarjs/cognitive-complexity
    const timeslotbind = (objdate: any) => {
        // var itr = 0;
        if (objdate.TimeSlots != undefined) {
            return objdate.TimeSlots.map((objtime: any) => {
                // itr += 1;
                const modifiedStarttime = moment(objtime.startTime, 'HH:mm:ss').format(
                    'LT'
                );
                const modifiedEndtime = moment(objtime.endTime, 'HH:mm:ss').format(
                    'LT'
                );
                const slicedTimeslot =
                    modifiedStarttime.slice(0, 5) +
                    modifiedStarttime.slice(8, modifiedStarttime.length);

                const Currently = new Date();
                const todayDate = String(Currently.getDate()).padStart(2, '0');
                const todayMonth = String(Currently.getMonth() + 1).padStart(2, '0'); //January is 0!
                const todayYear = Currently.getFullYear();

                const todaysDate = todayMonth + '/' + todayDate + '/' + todayYear;
                const TodayUpdatedDate = new Date(todaysDate);
                const ObjUpdatedDate = new Date(objdate.Date);
                if (
                    TodayUpdatedDate == ObjUpdatedDate ||
                    TodayUpdatedDate > ObjUpdatedDate
                ) {
                    const startTime: any = objtime.startTime;
                    const hoursdisplayed = startTime.split(':')[0];
                    const minutesdisplayed = startTime.split(':')[1];
                    const displayedTime = hoursdisplayed + ':' + minutesdisplayed;
                    console.log(displayedTime);

                    const Currently = new Date();
                    const TimeatPresent = new Date(Currently.getTime());
                    const currentHours = TimeatPresent.getHours();
                    console.log(currentHours);
                    const currentMinutes = TimeatPresent.getMinutes();
                    console.log(currentMinutes);
                    const Currenttime = currentHours + ':' + currentMinutes;
                    console.log(Currenttime);

                    if (objtime.status !== 'Available') {
                        let slotColor = '';

                        if (
                            BindtimeslotId !== '' &&
                            BindtimeslotId == objtime.timeSlot &&
                            BindtimeslotDate !== '' &&
                            BindtimeslotDate == objdate.Date
                        ) {
                            slotColor = classes.slotBooked;
                        } else {
                            slotColor = classes.slotBusy;
                        }
                        return (
                            //
                            <button
                                type="button"
                                name={objdate.Date}
                                value={slicedTimeslot + '-' + modifiedEndtime}
                                id={objtime.timeSlot}
                                className={slotColor}
                                onClick={(e) =>
                                    getslotdetails(e, modifiedStarttime, modifiedEndtime)
                                }
                                disabled={true}
                            >
                                {slicedTimeslot} - {modifiedEndtime}
                            </button>
                        );
                    } else if (
                        todaysDate > objdate.Date ||
                        (todaysDate == objdate.Date && displayedTime <= Currenttime)
                        // eslint-disable-next-line sonarjs/no-duplicated-branches
                    ) {
                        let slotColor = '';

                        if (
                            BindtimeslotId !== '' &&
                            BindtimeslotId == objtime.timeSlot &&
                            BindtimeslotDate !== '' &&
                            BindtimeslotDate == objdate.Date
                        ) {
                            slotColor = classes.slotBooked;
                        } else {
                            slotColor = classes.slotBusy;
                        }
                        return (
                            //
                            <button
                                type="button"
                                name={objdate.Date}
                                value={slicedTimeslot + '-' + modifiedEndtime}
                                id={objtime.timeSlot}
                                className={slotColor}
                                onClick={(e) =>
                                    getslotdetails(e, modifiedStarttime, modifiedEndtime)
                                }
                                disabled={true}
                            >
                                {slicedTimeslot} - {modifiedEndtime}
                            </button>
                        );
                    } else {
                        let slotColor = '';

                        if (
                            BindtimeslotId !== '' &&
                            BindtimeslotId == objtime.timeSlot &&
                            BindtimeslotDate !== '' &&
                            BindtimeslotDate == objdate.Date
                        ) {
                            slotColor = classes.slotBooked;
                        } else {
                            slotColor = classes.slotAvailable;
                        }
                        return (
                            //
                            <button
                                type="button"
                                name={objdate.Date}
                                value={slicedTimeslot + '-' + modifiedEndtime}
                                id={objtime.timeSlot}
                                className={slotColor}
                                onClick={(e) =>
                                    getslotdetails(e, modifiedStarttime, modifiedEndtime)
                                }
                            >
                                {slicedTimeslot} - {modifiedEndtime}
                            </button>
                        );
                    }
                } else {
                    if (objtime.status !== 'Available') {
                        return (
                            <button
                                type="button"
                                name={objdate.Date}
                                value={slicedTimeslot + '-' + modifiedEndtime}
                                id={objtime.timeSlot}
                                className={classes.slotBusy}
                                onClick={(e) =>
                                    getslotdetails(e, modifiedStarttime, modifiedEndtime)
                                }
                                disabled={true}
                            >
                                {slicedTimeslot} - {modifiedEndtime}
                            </button>
                        );
                    } else {
                        console.log("BindtimeslotId", BindtimeslotId);
                        console.log("objtime.timeSlotId", objtime.timeSlot);
                        console.log("BindtimeslotDate", BindtimeslotDate);
                        console.log("objdate.Date", objdate.Date);
                        let slotColor = '';
                        if (
                            BindtimeslotId !== '' &&
                            BindtimeslotId == objtime.timeSlot &&
                            BindtimeslotDate !== '' &&
                            BindtimeslotDate == objdate.Date
                        ) {
                            slotColor = classes.slotBooked;
                        } else {
                            slotColor = classes.slotAvailable;
                        }
                        return (
                            //
                            <button
                                type="button"
                                name={objdate.Date}
                                value={slicedTimeslot + '-' + modifiedEndtime}
                                id={objtime.timeSlot}
                                className={slotColor}
                                onClick={(e) =>
                                    getslotdetails(e, modifiedStarttime, modifiedEndtime)
                                }
                            >
                                {slicedTimeslot} - {modifiedEndtime}
                            </button>
                        );
                    }
                }
            });
        }
    };

    const buildTimeSlot = () => {
        if (timeSlotData != undefined && timeSlotData.length > 0) {
            let slotNumbers = 0;
            return timeSlotData.map((objdate: any) => {
                if (
                    objdate.TimeSlots != null &&
                    objdate.TimeSlots != '' &&
                    slotNumbers < 5
                ) {
                    slotNumbers++;
                    const formattedDate = formatDate(objdate.Date);
                    return (
                        // eslint-disable-next-line react/jsx-key
                        <Grid item className={classes.agrTimeSlot}>
                            <Typography
                                className={`${classes.semiBold} ${classes.font14} ${classes.MarginDate}`}
                            >
                                {formattedDate}
                            </Typography>
                            <Grid className={classes.agrListGroup}>
                                {timeslotbind(objdate)}
                            </Grid>
                        </Grid>
                    );
                }
            });
        } else {
            return (
                <div className={classes.Norecords}>
                    <CircularProgress />
                </div>
            );
        }
    };

    const CoworkerOptionsArray = () => {
        if (coWorkerRequiredArray !== undefined) {
            return coWorkerRequiredArray.map((entry: any) => {
                const some: any = entry.toString();
                if (entry == 'Select') {
                    return { label: entry, value: '1' };
                } else {
                    return { label: entry, value: some };
                }
            });
        } else {
            return [{ label: 'Select', value: '0' }];
        }
    };
    const Timebind = (event: any) => {
        SetTime(event.target.value);
        SetSaveTrigger(false);
    };

    const dates = async (selectedFormatdate: any) => {
        if (moment(selectedFormatdate).format('YYYY-MM-DD') >= moment(new Date()).format('YYYY-MM-DD')) {
            setdateError('')
            SettimeSlotData([]);
            setStartDate(moment(selectedFormatdate).format('YYYY-MM-DD'));
            const startDate = selectedFormatdate;
            // if (props.triggerdateChaged) {
            //   props.triggerdateChaged();
            // }
            GetTimeSlot(startDate);
            SetTime('');
            Settimeslotid('');
            console.log('beoefevcdvbh', BindtimeslotId);
            setBindtimeslotId('');
        }
        else {
            setStartDate(moment(new Date()).format('YYYY-MM-DD'));
            setdateError('Date must not be in the past')
        }
    };

    // eslint-disable-next-line sonarjs/cognitive-complexity
    const Complete_delivery_status = async () => {
        if (appointmentId == '') {
            try {
                settimeSlotenable(false);
                setmasterLoader(true);
                let NumberofCoworkers = '';
                console.log('Coworkersuuuu', Coworkers);

                if (Coworkers == '') {
                    NumberofCoworkers = '1';
                } else {
                    NumberofCoworkers = Coworkers;
                }
                const request = {
                    storeNumber: '04744',
                    eventInformation: {
                        partyId: '33925768',
                        agreementId: '9999217540403',
                        inventoryIds: [
                            '9999207382277'
                        ],
                        type: 'Delivery',
                        eventDate: '11/23/2021',
                        eventSource: 'Store',
                        timeSlot: {
                            timeSlotId: '9999100000392'
                        },
                        eventInstructions: 'created customer event',
                        requiredCoworkers: '2',
                        racdbSourced: true,
                        isLoaner: 'Y',
                        loanerCreate: {
                            storeNumber: window.sessionStorage.getItem('storeNumber'),
                            timeSlot: timeslotid,
                            customerId: servicedetails?.customerId?.toString(),
                            agreementNumber: servicedetails?.agreementNumber,
                            inventories: [
                                {
                                    inventoryNumber: servicedetails?.inventoryNumber,
                                    serviceTicketId: String(servicedetails?.serviceTicketId)
                                }
                            ],
                            // coworkerId: "654644",
                            appointmentInfo: {
                                appointmentType: AppoinmentType,
                                appointmentSource: AppoinmentSource,
                                appointmentDate: startDate,
                                requiredCoworkers: NumberofCoworkers,
                                instructions: Instructions
                            }
                        }
                    }
                };
                console.log('Complete event reuest', request);
                const Apiresult = await postAppointment(request);
                /* eslint-disable no-console */
                console.log(Apiresult.data);
                setmasterLoader(false);
                if (Apiresult.status == 200) {
                    setIsScheduled(true);
                    setappointmentId(Apiresult.data?.appointmentInfo?.appointmentId);
                    setDeliveryStatus(Apiresult.data?.appointmentInfo?.appointmentStatusDesc)
                    setAppointScheduleSucess(true);
                    SetSaveTrigger(true);
                }
                else if (Apiresult.status == 400) {
                    setApierror(true)
                    setApierrorContent(Apiresult.data.errors[0]?.error?.error)
                }
                else {
                    setErrorMsg(true)
                }
            } catch (e) {
                setmasterLoader(false);
                setErrorMsg(true)
            }
        }
        else {
            try {
                settimeSlotenable(false);
                setmasterLoader(true);
                let NumberofCoworkers = '';
                console.log('Coworkersuuuu', Coworkers);

                if (Coworkers == '') {
                    NumberofCoworkers = '1';
                } else {
                    NumberofCoworkers = Coworkers;
                }
                if (DeliveryStatus == 'Cancelled') {
                    const value = {
                        agreementId: '98989898',
                        isLoaner: 'Y',
                        loanerAction: {
                            appointmentId: String(appointmentId),
                            storeNumber: window.sessionStorage.getItem('storeNumber'),
                            actionName: "Reopen",
                            coWorkerId: employeeId,
                        }
                    }
                    const result = await AppointmentAction(value)
                    if (result.status == 200) {
                        setCanceldeliveryCheck(false)
                    }
                    console.log('result', result);

                }
                const request = {
                    storeNumber: '04744',
                    eventInformation: {
                        partyId: '33925768',
                        agreementId: '9999217540403',
                        inventoryIds: [
                            '9999207382277'
                        ],
                        type: 'Delivery',
                        eventDate: '11/23/2021',
                        eventSource: 'Store',
                        timeSlot: {
                            timeSlotId: '9999100000392'
                        },
                        eventInstructions: 'created customer event',
                        requiredCoworkers: '2',
                        racdbSourced: true,
                        isLoaner: 'Y',
                        loanerCreate: {
                            storeNumber: window.sessionStorage.getItem('storeNumber'),
                            timeSlot: timeslotid,
                            customerId: servicedetails?.customerId?.toString(),
                            agreementNumber: servicedetails?.agreementNumber,
                            inventories: [
                                {
                                    inventoryNumber: servicedetails?.inventoryNumber,
                                    serviceTicketId: String(servicedetails?.serviceTicketId)
                                }
                            ],
                            appointmentId: String(appointmentId),
                            // coworkerId: "654644",
                            appointmentInfo: {
                                appointmentType: AppoinmentType,
                                appointmentSource: AppoinmentSource,
                                appointmentDate: startDate,
                                requiredCoworkers: NumberofCoworkers,
                                instructions: Instructions
                            }
                        }
                    }
                };
                console.log('Complete event reuest', request);
                const Apiresult = await postAppointment(request);
                /* eslint-disable no-console */
                console.log(Apiresult.data);
                setmasterLoader(false);
                if (Apiresult.status == 200) {
                    setIsScheduled(true);
                    //setappointmentId(Apiresult.data?.appointmentInfo?.appointmentId);
                    setAppointScheduleSucess(true);
                    SetSaveTrigger(true);
                }
                else if (Apiresult.status == 400) {
                    setApierror(true)
                    setApierrorContent(Apiresult.data.errors[0]?.error?.error)
                }
                else {
                    setErrorMsg(true)
                }
            } catch (e) {
                setmasterLoader(false);
                setErrorMsg(true)
            }
        }
    };

    const secondLvlAuthRes = (e: any) => {
        if (e) {
            setsecondLvlAuth(false)
            RequestSave();
        }
    }
    const TimeSlotFunction = () => {
        return (
            <Grid style={{ marginLeft: '1.5%' }}>
                <Grid container>
                    {!DeliveryConfirmation ?
                        <Grid md={4}>
                            <Typography variant="h6">Service Type</Typography>
                            <Grid className={classes.formCheck}>
                                <RACRadio
                                    value={''}
                                    label="In Home"
                                    name="in Home"
                                    checked={inHome}
                                    onChange={(e) => {
                                        handleRadio(e);
                                    }}
                                />
                            </Grid>
                            <Grid className={classes.formCheck}>
                                <RACRadio
                                    value={''}
                                    name="in Store"
                                    label="In Store"
                                    checked={inStore}
                                    onChange={(e) => {
                                        handleRadio(e);
                                    }}
                                />
                            </Grid>
                        </Grid> :
                        <Grid md={4} style={{ marginLeft: '0%' }}>
                            <Typography variant="h6"> Delivery Type</Typography>
                            <Grid className={classes.formCheck}>
                                <RACRadio
                                    value={''}
                                    label="Delivery"
                                    name="delivery"
                                    checked={Delivery}
                                    onChange={(e) => {
                                        handleRadio(e);
                                    }}
                                />
                            </Grid>
                            <Grid className={classes.formCheck}>
                                <RACRadio
                                    value={''}
                                    label="CarryOut"
                                    name="carryOut"
                                    checked={Carryout}
                                    onChange={(e) => {
                                        handleRadio(e);

                                    }}
                                />
                            </Grid>
                        </Grid>}
                    {twoMoreRadios ? (
                        <Grid md={4} style={{ marginLeft: '0%' }}>
                            <Typography variant="h6">Type</Typography>
                            <Grid className={classes.formCheck}>
                                <RACRadio
                                    value={''}
                                    label="Pickup"
                                    name="pickup"
                                    checked={pickup}
                                    onChange={(e: any) => {
                                        handleRadio(e);
                                    }}
                                />
                            </Grid>
                            <Grid className={classes.formCheck}>
                                <RACRadio
                                    value={''}
                                    name="carryIn"
                                    label="Carry In"
                                    checked={carryin}
                                    onChange={(e: any) => {
                                        handleRadio(e);

                                    }}
                                />
                            </Grid>
                        </Grid>
                    ) : null}
                </Grid>
                <Grid container spacing={3}>
                    <Grid item md={3}>
                        <RACDatePicker
                            label="Service Date"
                            value={startDate}
                            classes={{ input: dateError !== '' ? classes.bgdateError : '' }}
                            errorMessage={dateError}
                            onChange={(e: any) => dates(e)}
                            inputProps={{
                                min: moment().format('YYYY-MM-DD'),
                                max: MaxDate
                            }}
                        />
                    </Grid>
                    <Grid item md={3}>
                        <RACTextbox
                            isCurrency={false}
                            id="a11y_time"
                            disabled
                            value={Time}
                            inputlabel="Service Time"
                            OnChange={Timebind}
                        />
                    </Grid>
                    <Grid item md={3}>
                        <RACSelect
                            inputLabel="# Co-workers Required"
                            loading={conditionLoader}
                            options={CoworkerOptionsArray()}
                            defaultValue={Coworkers !== '' ? Coworkers : '1'}
                            onChange={(
                                e: React.ChangeEvent<HTMLSelectElement>
                            ) => {
                                SetCoworkers(e.target.value);
                                SetSaveTrigger(false);
                            }}
                            {...(coWorkerRequiredArray.length == 1 &&
                                !conditionLoader && {
                                errorMessage: 'Unable to fetch Data',
                            })}
                        />
                    </Grid>
                </Grid>
                <Grid>
                    <Grid>
                        <h4>Instructions</h4>
                    </Grid>
                    <input
                        type="textarea"
                        value={Instructions}
                        name="textarea"
                        className={classes.commentText}
                        onChange={(e) => {
                            SetInstructions(e.target.value);
                            SetSaveTrigger(false);
                        }}
                    ></input>
                </Grid>
                <Grid container>
                    <Grid item md={8} className={classes.spacerMT8}>
                        <Grid className={classes.floatLeft}>
                            <Typography variant="h5" className={classes.subTitle}>
                                Select Available Timeslot
                            </Typography>
                            <Typography className={classes.deliveryEventDesc}>
                                Only 4 events can be added in one time slot.
                            </Typography>
                        </Grid>
                        <Grid className={classes.floatRight}>
                            <Grid className={`${classes.floatLeft} ${classes.me48}`}>
                                <span className={classes.circleAvailable} />
                                <span>Available</span>
                            </Grid>
                            <Grid
                                classes={{
                                    root: clsx(classes.floatLeft, classes.spacerMR4),
                                }}
                            >
                                <span className={classes.circleBusy} />
                                <span>Busy</span>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={12} className={classes.spacerMB5}>
                    <Grid className={`${classes.mt2} ${classes.me2}`}>
                        {buildTimeSlot()}
                    </Grid>
                </Grid>
                <Grid className={`${classes.floatRight}`}>
                    <RACButton
                        variant="outlined"
                        color="secondary"
                        className={`${classes.mx1} `}
                        size="large"
                        onClick={() => settimeSlotenable(false)}
                    >
                        Cancel
                    </RACButton>
                    <RACButton
                        variant="contained"
                        color="primary"
                        className={classes.mx1}
                        size="large"
                        disabled={enableFinish}
                        onClick={() => Complete_delivery_status()}
                    >
                        Save
                    </RACButton>
                </Grid>
            </Grid>
        )
    }

    const noRecordPopup = () => {
        return (
            <Grid>
                <Grid>
                    <Grid className={classes.textCenter}>
                        <AlertIcon />
                        <Typography >
                            {' '}
                            Something Went Wrong.
                        </Typography>

                    </Grid>
                    <Grid
                        item
                        md={12}
                        className={`${classes.mt3} ${classes.pb4} ${classes.textCenter}`}
                    >
                        <RACButton
                            className={classes.mx1}
                            variant="contained"
                            color="primary"
                            onClick={() => setErrorMsg(false)}
                        >
                            Ok
                        </RACButton>
                    </Grid>
                </Grid>
            </Grid>
        )
    }

    const CloseLoanerPopup = () => {
        return (
            <>
                <Grid item md={12} className={classes.textCenter}>
                    <Typography
                        className={`${classes.semiBold} ${classes.fs6}`}
                    >
                        Do you want to close the customer’s Loaner Agreement?
                    </Typography>
                </Grid>
                <Grid className={`${classes.py4} ${classes.textCenter}`}>
                    <RACButton
                        className={classes.mx1}
                        variant="outlined"
                        color="secondary"
                        onClick={() => {
                            setcloseLoaner(false);
                            ConfirmSave();
                            // history.push({ pathname: `/inventory/${servicedetails?.inventoryNumber}/service` })
                            // location.reload();
                        }
                        }
                    >
                        No
                    </RACButton>
                    <RACButton
                        className={classes.mx1}
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            setcloseLoaner(false);
                            history.push({
                                pathname: `/agreement/info/details/${servicedetails?.customerId}/${servicedetails?.loanerAgreementId}`,
                            }
                            )
                        }
                        }
                    >
                        Yes
                    </RACButton>
                </Grid>
            </>
        )
    }
    const ProvideLoanerPopup = () => {
        return (
            <>
                <Grid item md={12} className={classes.textCenter}>
                    <Typography
                        className={`${classes.semiBold} ${classes.fs6}`}
                    >
                        Do you want to create the loaner agreement?
                    </Typography>
                </Grid>
                <Grid className={`${classes.py4} ${classes.textCenter}`}>
                    <RACButton
                        className={classes.mx1}
                        variant="outlined"
                        color="secondary"
                        onClick={() => {
                            setprovideLoaner(false);
                            history.push({ pathname: `/inventory/${servicedetails?.inventoryNumber}/service` })
                            location.reload();
                        }
                        }
                    >
                        No
                    </RACButton>
                    <RACButton
                        className={classes.mx1}
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            setprovideLoaner(false);
                            history.push({
                                pathname: `/agreement/loaner/itemsearch/${servicedetails?.customerId}/${servicedetails?.agreementId}`,
                                search: `?inventoryNumber=${servicedetails?.inventoryNumber}`
                            }
                            )
                        }
                        }
                    >
                        Yes
                    </RACButton>
                </Grid>
            </>
        )
    }


    const RequestSave = () => {
        //appointment was scheduled and Reschedule Scnerio of Return to Customer State
        if (DeliveryConfirmation === false && IsScheduled && EventResult?.appointmentTypeDesc == 'Service Delivery' && ['ToDO', 'Cancelled'].includes(EventResult?.appointmentStatusDesc)) {
            ConfirmSave();
        }
        //Return to Customer Save to schedule the delivery
        else if (data.StatusDropdown == 'RTC' && DeliveryConfirmation && IsScheduled === false) {
            setDeliverySchedule(true);
        }
        else {
            ConfirmSave();
        }
    }

    const ConfirmAppointment = async () => {
        setmasterLoader(true)
        if (DeliveryStatus == 'Cancelled' && CanceldeliveryCheck) {
            const value = {
                agreementId: '98989898',
                isLoaner: 'Y',
                loanerAction: {
                    appointmentId: String(appointmentId),
                    storeNumber: window.sessionStorage.getItem('storeNumber'),
                    actionName: "Reopen",
                    coWorkerId: employeeId,
                }
            }
            const result = await AppointmentAction(value)
            console.log('result', result);

        }
        const value = {
            agreementId: '98989898',
            isLoaner: 'Y',
            loanerAction: {
                appointmentId: String(appointmentId),
                storeNumber: window.sessionStorage.getItem('storeNumber'),
                actionName: "Complete",
                coWorkerId: employeeId,
            }
        }

        const result = await AppointmentAction(value);
        setmasterLoader(false)
        if (result.status == 200) {
            setAppointConfirmSucess(true)
        }
        else if (result.status == 400) {
            console.log('AppointmentActionjhihihih', result);
            setApierror(true)
            setApierrorContent(result.data.errors[0].error)
        }
        else {
            setErrorMsg(true)
        }
        console.log('AppointmentActionjhihihih', result);


    }

    const AppointmentSchedulePopup = () => {
        return (
            <div>
                <Grid item md={12} className={classes.textCenter}>
                    <Successimage></Successimage>
                    <Typography className={`${classes.popupText} ${classes.mt3}`}>
                        {DeliveryConfirmation ? 'Delivery' : completeVerbage()} has been scheduled
                    </Typography>
                </Grid>
                <Grid item md={12} className={`${classes.py4} ${classes.textCenter}`}>
                    <RACButton
                        className={classes.mx1}
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            if (servicedetails?.loanerAgreementRefCode != 'ARTS') {
                                setcloseLoaner(true);
                            }

                            setAppointScheduleSucess(false)
                        }}
                    >
                        OK
                    </RACButton>
                </Grid>
            </div>
        );
    };
    const AppointmentConfirmPopup = () => {
        return (
            <div>
                <Grid item md={12} className={classes.textCenter}>
                    <Successimage></Successimage>
                    <Typography className={`${classes.popupText} ${classes.mt3}`}>
                        {DeliveryConfirmation ? 'Delivery' : completeVerbage()} has been confirmed
                    </Typography>
                </Grid>
                <Grid item md={12} className={`${classes.py4} ${classes.textCenter}`}>
                    <RACButton
                        className={classes.mx1}
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            setAppointConfirmSucess(false);
                            if (!DeliveryConfirmation && servicedetails?.isLoanerProvided == 0) {
                                setprovideLoaner(true);
                                //history.push({ pathname: `/inventory/${servicedetails?.inventoryNumber}/service` })
                            }
                            setCompleteStatus('Completed')
                        }}
                    >
                        OK
                    </RACButton>
                </Grid>
            </div>
        );
    };
    const ApiErrorPopup = () => {
        return (
            <div>
                <Grid item md={12} className={classes.textCenter}>
                    <AlertIcon></AlertIcon>
                    <Typography className={`${classes.popupText} ${classes.mt3}`}>
                        {ApierrorContent}
                    </Typography>
                </Grid>
                <Grid item md={12} className={`${classes.py4} ${classes.textCenter}`}>
                    <RACButton
                        className={classes.mx1}
                        variant="contained"
                        color="primary"
                        onClick={() => setApierror(false)}
                    >
                        OK
                    </RACButton>
                </Grid>
            </div>
        );
    };

    const RescheduleAppointmentOnClick = async () => {

        getCoworkerRequiredData();
        setmasterLoader(true)
        const result = await getEvent(appointmentId);
        console.log('getEvent', result);
        setmasterLoader(false)
        if (result.status == 200) {
            settimeSlotenable(true)
            const Rescheduleresult = result.data
            setDeliveryStatus(Rescheduleresult?.appointmentInfo?.appointmentStatusDesc)
            serviceTypePendingRadio(Rescheduleresult.appointmentInfo.appointmentType)
            setBindtimeslotId(Rescheduleresult.timeSlotInfo.timeSlot)
            Settimeslotid(Rescheduleresult.timeSlotInfo.timeSlot)
            setBindtimeslotDate(Rescheduleresult.appointmentInfo.appointmentDate)
            // BindtimeslotDate = Rescheduleresult.appointmentInfo.appointmentDate
            setStartDate(moment(Rescheduleresult.appointmentInfo.appointmentDate).format('YYYY-MM-DD'));

            const StartingTime = Rescheduleresult.timeSlotInfo.startTime;
            const EndingTime = Rescheduleresult.timeSlotInfo.endTime;
            const slicedStartdate =
                StartingTime.slice(0, 5) + StartingTime.slice(8, StartingTime.length);
            const slotDuration = moment(StartingTime, "HH:mm").format("hh:mm") + "-" + moment(EndingTime, "HH:mm").format("hh:mm A")
            const Selectedslot = slicedStartdate + '-' + EndingTime;
            SetTime(slotDuration);
            SetInstructions(Rescheduleresult.appointmentInfo.instructions);
            GetTimeSlot(new Date(Rescheduleresult.appointmentInfo.appointmentDate));

        }
        else if (result.status == 400) {
            console.log('AppointmentActionjhihihih', result);
            setApierror(true)
            setApierrorContent(result.data.errors[0].error)
        }
        else {
            setErrorMsg(true);
        }

    }

    const getCoworkerRequiredData = async () => {
        const bodyobject = {
            storeNumbers: [window.sessionStorage.getItem('storeNumber')],

            paramKeyNames: ['CoworkerRequired'],
        };
        const CoworkerNum = await CoworkerRequired(bodyobject);
        if (CoworkerNum.status === 200) {
            const value =
                CoworkerNum.data !== null &&
                    CoworkerNum.data.storeProfileResponse !== undefined &&
                    CoworkerNum.data.storeProfileResponse &&
                    CoworkerNum.data.storeProfileResponse.configDetails !== undefined &&
                    CoworkerNum.data.storeProfileResponse.configDetails.length > 0 &&
                    CoworkerNum.data.storeProfileResponse.configDetails[0].configDetails !==
                    undefined &&
                    CoworkerNum.data.storeProfileResponse.configDetails[0].configDetails
                        .length > 0
                    ? CoworkerNum.data.storeProfileResponse.configDetails[0]
                        .configDetails[0].paramValue
                    : 0;

            // eslint-disable-next-line no-console
            console.log(value);
            const integervalue = parseInt(value);
            const arrayNumber: any = [];

            for (let i = 1; i <= integervalue; i++) {
                arrayNumber.push(i);
            }
            // eslint-disable-next-line no-console
            console.log('ArrayNumber', arrayNumber);
            setcoWorkerRequiredArray(arrayNumber);
            SetconditionLoader(false);
        } else {
            const setterCondition = ['Select'];
            setcoWorkerRequiredArray(setterCondition);
            SetconditionLoader(false);
        }
    };

    const customerRententionCheck = () => {
        if (retention == '1') {
            const currentDate = new Date();
            const year = currentDate.getFullYear();
            const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
            const day = currentDate.getDate().toString().padStart(2, '0');
            const formattedDate = year + '-' + month + '-' + day;
            const agreementLogReq = {
                agreementActivities: [
                    {
                        activityDate: formattedDate,
                        agreementId: String(servicedetails?.agreementId),
                        activityDescription: 'Agr Retention Service Performed',
                        agreementActivityType: 'AGMRETSP',
                    },
                ],
            };
            insertAgreementLog(agreementLogReq);
        }
    }


    const ConfirmSave = async () => {
        customerRententionCheck();
        const request: any = {
            problem: data.problem,
            solution: data.solution == '' ? null : data.solution,
            serviceStatusType: data.StatusDropdown,
            serviceCost: data.ServiceCost == '' ? null : data.ServiceCost,
            serviceTicketId: servicedetails?.serviceTicketId == 0 ? null : servicedetails?.serviceTicketId?.toString(),
            inventoryId: String(servicedetails?.inventoryId),
            storeNumber: window.sessionStorage.getItem('storeNumber'),
            bplusAuthNumber: data?.benefit ? data?.benefit : null
        };
        setmasterLoader(true)
        try {
            const result = await InventoryServicing(request);
            setmasterLoader(false);
            if (result.status == 200) {
                const serviceNumber = result.data.serviceNumber
                history.push({ pathname: `/inventory/${servicedetails?.inventoryNumber}/service` })
                location.reload();
            }
            else if (result.status == 400) {
                console.log('AppointmentActionjhihihih', result);
                setApierror(true)
                setApierrorContent(result.data.errors[0].error)
            }
            else {
                setErrorMsg(true)
            }
        }
        catch {
            setErrorMsg(true)
        }
    }
    const DeliverySchedulePopup = () => {
        return (
            <div>
                <Grid item md={12} className={classes.textCenter}>
                    {/* <Successimage></Successimage> */}
                    <Typography className={`${classes.popupText} ${classes.mt3}`}>
                        Do you want to schedule the Delivery?
                    </Typography>
                </Grid>
                <Grid item md={12} className={`${classes.py4} ${classes.textCenter}`}>
                    <RACButton
                        className={classes.mx1}
                        variant='outlined'
                        color='secondary'
                        onClick={() => {
                            if (servicedetails?.loanerAgreementRefCode != 'ARTS') {
                                setcloseLoaner(true);
                            }
                            else {
                                ConfirmSave();
                            }
                            setDeliverySchedule(false)
                        }}
                    >
                        No
                    </RACButton>
                    <RACButton
                        className={classes.mx1}
                        variant="contained"
                        color="primary"
                        onClick={() => { DeliveryAppointment(); setDeliverySchedule(false); setappointmentId('') }}
                    >
                        Yes
                    </RACButton>
                </Grid>
            </div>
        );
    };

    const DeliveryAppointment = () => {
        settimeSlotenable(true);
        getCoworkerRequiredData();
        GetTimeSlot(new Date());
    }


    const Loanerprintpopupopen = () => {
        return (
            <div>
                <Grid container spacing={2} className={classes.p3}>
                    <Grid item md={12}>
                        <Images className={classes.me2}></Images>
                        <Typography variant="caption" className={classes.formLabel}>
                            Service Pickup Receipt
                        </Typography>
                        <RACButton
                            className={classes.agrPrintDoc}
                            onClick={() =>
                                handlePrint(document.getElementById('ServicePickupRecipt'))
                            }
                        >
                            View PDF
                        </RACButton>
                    </Grid>
                    <Grid item md={12}>
                        <Images className={classes.me2}></Images>
                        <Typography variant="caption" className={classes.formLabel}>
                            Service Pickup Checklist
                        </Typography>
                        <RACButton
                            className={classes.agrPrintDoc}
                            onClick={() =>
                                handlePrint(document.getElementById('ServicePickupChecklist'))
                            }
                        >
                            View PDF
                        </RACButton>
                    </Grid>
                    <Grid item md={12}>
                        <Images className={classes.me2}></Images>
                        <Typography variant="caption" className={classes.formLabel}>
                            Service Delivery Receipt
                        </Typography>
                        <RACButton
                            className={classes.agrPrintDoc}
                            onClick={() =>
                                handlePrint(document.getElementById('ServiceDeliveryReceipt'))}
                        >
                            View PDF
                        </RACButton>
                    </Grid>
                    <Grid item md={12}>
                        <Images className={classes.me2}></Images>
                        <Typography variant="caption" className={classes.formLabel}>
                            Service Delivery Checklist
                        </Typography>
                        <RACButton
                            className={classes.agrPrintDoc}
                            onClick={() =>
                                handlePrint(document.getElementById('ServiceDeliveryChecklist'))
                            }
                        >
                            View PDF
                        </RACButton>
                    </Grid>
                    <Grid
                        item
                        md={12}
                        className={`${classes.pb4} ${classes.px3} ${classes.textCenter}`}
                    >
                        <RACButton
                            className={classes.mx1}
                            variant="contained"
                            color="primary"
                            onClick={() => setOpenPrintPopup(false)}
                        >OK</RACButton>
                    </Grid>
                </Grid>
            </div>
        );

    };
    const dynamicStatusColor = (val: any) => {
        switch (val) {
            case ON_RENT: case SERVICE_ON_RENT: case LOANER: {
                return `${classes.statusBadge} ${classes.statusGreen}`
            }
            case RENT_READY: case NOT_RENT_READY: case PENDING_CHARGE_OFF: case HOLD: case SERVICE_IDLE: case RESERVED: case TRANSFER_IN_PROGRESS: {
                return `${classes.statusBadge} ${classes.statusWarning}`
            }
            case PENDING_RENTAL_AGREEMENT: case PENDING_LOANER_AGREEMENT: {
                return `${classes.statusBadge} ${classes.statusInfo}`
            }
            case CHARGED_OFF: case SERVICE_CHARGED_OFF: {
                return `${classes.statusBadge} ${classes.bgAsh} `
            }
            // default: {
            //   return `${classes.statusBadge}`
            // }
        }
    }
    const saveDisableFunction = () => {
        if (data.problem == '') {
            return true
        }
        else if (data.problem != '' && data.StatusDropdown == 'Select') {
            return true
        }
        else if (data.problem != '' && data.StatusDropdown != 'Select') {
            return false
        }

    }
    const roundOff = (num: any, places: number) => {
        const x = Math.pow(10, places);
        return Math.round(num * x) / x;
    }
    const bindChecklist = () => {
        if (PrintAry !== undefined && PrintAry !== null && PrintAry.length > 0) {
            const data: any = PrintAry;
            const uniquedesc = [
                ...new Map(
                    data.map((item: any) => [item.inventoryDesc, item])
                ).values(),
            ];
            return uniquedesc.map((value: any, index: any) => {
                return (
                    <tr key={index} style={{ width: '100%' }}>
                        <td
                            style={{
                                width: '15px',
                                paddingLeft: '12px',
                                paddingRight: '12px',
                                paddingTop: '5px',
                                paddingBottom: '5px',
                            }}
                        >
                            <span
                                style={{
                                    padding: PaddingConst,
                                    borderRadius: borderRadiuss,
                                    border: Constinter,
                                    marginRight: '10px',
                                }}
                            />
                        </td>
                        <td
                            style={{
                                width: '300px',
                                paddingLeft: '12px',
                                paddingRight: '12px',
                                paddingTop: '5px',
                                paddingBottom: '5px',
                            }}
                        >
                            {value !== undefined && value.inventoryDesc !== undefined
                                ? value.inventoryDesc
                                : ''}
                        </td>
                        <td
                            style={{
                                width: '300px',
                                paddingLeft: '12px',
                                paddingRight: '12px',
                                paddingTop: '5px',
                                paddingBottom: '5px',
                            }}
                        >
                            {value !== undefined &&
                                value.serialNumber !== undefined &&
                                value.serialNumber !== null &&
                                value.serialNumber != ''
                                ? value.serialNumber
                                : '-'}
                        </td>
                        <td
                            style={{
                                width: '300px',
                                paddingLeft: '12px',
                                paddingRight: '12px',
                                paddingTop: '5px',
                                paddingBottom: '5px',
                            }}
                        >
                            {value !== undefined && value.inventoryNumber !== undefined
                                ? value.inventoryNumber
                                : ''}
                        </td>
                    </tr>
                );
            });
        }
    };
    return (

        <React.Fragment>
            {masterLoader ? (
                <Grid
                    style={{
                        position: 'fixed',
                        background: '#f7f5f5',
                        width: '100%',
                        height: '100%',
                        top: 0,
                        left: 0,
                        opacity: 0.6,
                        zIndex: 1050,
                        textAlign: 'center',
                        margin: '0px 0px',
                    }}
                >
                    <Grid
                        style={{
                            display: 'block',
                            position: 'fixed',
                            zIndex: 9999999,
                            top: '40%',
                            right: '50%',
                        }}
                    >
                        <CircularProgress />
                    </Grid>
                </Grid>
            ) : null}
            {globalLoader ? (
                <div style={{ margin: '20% 20% 20% 48%' }}>
                    <CircularProgress size={30} />
                </div>
            ) :
                invSummaryerr ?
                    <div style={{ margin: '20% 20% 20% 48%' }}>
                        <AlertIcon />
                        Something Went Wrong.
                    </div>
                    : (
                        <React.Fragment>
                            <Grid>
                                <Grid>
                                    <List className={`${classes.breadcrumb}`}>
                                        <ListItem
                                            className={`${classes.breadcrumbItemActive}`}
                                            style={{ cursor: 'pointer' }}
                                            onClick={() => {
                                                history.push({
                                                    pathname: `/dashboard`,
                                                });
                                            }}
                                        >
                                            DashBoard
                                        </ListItem>
                                        <ListItem
                                            className={`${classes.breadcrumbItem} ${classes.breadcrumbArrow} ${classes.breadcrumbItemActive} `}
                                            style={{ cursor: 'pointer' }}
                                            onClick={() => {
                                                history.push({
                                                    pathname: `/inventory/${servicedetails?.inventoryNumber}/information`,
                                                });
                                                location.reload();
                                            }}
                                        >
                                            Inventory Information
                                        </ListItem>
                                        <ListItem
                                            className={`${classes.breadcrumbItem} ${classes.breadcrumbArrow} ${classes.racpadLinkCustomer}  `}
                                            style={{ cursor: 'pointer' }}
                                        >
                                            Inventory Service
                                        </ListItem>
                                    </List>
                                </Grid>
                                <Card className={classes.card}>
                                    <CardContent>
                                        <Grid container spacing={2}>
                                            <Grid item md={2}>
                                                <Typography variant="h5">Inventory Service</Typography>
                                            </Grid>
                                            <Grid item md={2}>
                                                <Typography variant="h6">Item #</Typography>
                                                <Grid>
                                                    <Typography style={{ color: '#779eb2' }}>
                                                        {servicedetails?.inventoryNumber ? servicedetails?.inventoryNumber : '-'}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid item md={2}>
                                                <Typography>Description</Typography>
                                                <Grid>
                                                    <Typography style={{ color: '#779eb2' }} title={servicedetails?.inventoryDesc.length > 27 ? servicedetails?.inventoryDesc : ''} >
                                                        {servicedetails?.inventoryDesc ? truncString(servicedetails?.inventoryDesc, 27, '...') : '-'}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid item style={{ width: 'auto' }}>
                                                <Typography>Status</Typography>
                                                <Typography className={dynamicStatusColor(validateObject(servicedetails?.inventoryStatus) ? servicedetails?.inventoryStatus : '-')}>{servicedetails?.inventoryStatus !== undefined ? servicedetails?.inventoryStatus : ' -'}</Typography>

                                                {/* <Grid>
                              <RACBadge
                                backgroundColor={dynamicStatusColor(servicedetails?.inventoryStatus)}
                                className={classes.paddingbadge}
                                color="white"
                                TextValue={servicedetails?.inventoryStatus}
                                OnclickNeed={false}
                                handleOnClick={(e) => {
                                  e;
                                }}
                              />
                            </Grid> */}
                                            </Grid>
                                            {["Not Rent Ready", 'Service Idle'].includes(servicedetails?.inventoryStatus) !== true ?
                                                <React.Fragment>
                                                    <Grid style={{ width: 'auto', padding: '8px', paddingRight: '40px' }}>
                                                        <Typography>Customer Name</Typography>
                                                        <Grid>
                                                            {type !== 'N' ?
                                                                <Typography style={{ color: '#779eb2' }}>
                                                                    {servicedetails?.customerFirstName ? (servicedetails?.customerFirstName + ' ' + servicedetails?.customerLastName) : '-'}
                                                                </Typography> :
                                                                <Typography style={{ color: '#779eb2' }}>
                                                                    {servicedetails?.customerFirstName ? servicedetails?.customerFirstName : '-'}
                                                                </Typography>
                                                            }
                                                        </Grid>
                                                    </Grid>
                                                    <Grid style={{ width: 'auto', padding: '8px', paddingRight: '40px' }}>
                                                        <Typography>Club Active</Typography>
                                                        <Grid>
                                                            <Typography style={{ color: '#779eb2' }}>{servicedetails?.isClubActive == '0' ? 'No' : "Yes"}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                    {servicedetails?.loanerAgreementNumber ?
                                                        <Grid style={{ width: 'auto', padding: '8px', paddingRight: '40px' }}>
                                                            <Typography>Loaner Agreement #</Typography>
                                                            <Grid>
                                                                <Typography className={classes.racpadLink} onClick={() => {
                                                                    history.push({
                                                                        pathname: `/agreement/info/details/${servicedetails?.customerId}/${servicedetails?.loanerAgreementId}`,

                                                                    });
                                                                }}>{servicedetails?.loanerAgreementNumber ? servicedetails?.loanerAgreementNumber : '-'}</Typography>
                                                            </Grid>
                                                        </Grid> : null}
                                                </React.Fragment>
                                                : null}
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid style={{ marginTop: '20px' }}>
                                <Card className={classes.card}>
                                    <CardContent>
                                        <Grid container spacing={2} style={{ marginBottom: '20px' }}>
                                            <Grid item md={6}>
                                                <label className={classes.label}>
                                                    Problem <span style={{ color: 'red' }}>*</span>
                                                </label>
                                                <Grid>
                                                    <input
                                                        type="textarea"
                                                        name="problem"
                                                        value={data.problem}
                                                        maxLength={200}
                                                        disabled={['RTC', 'RO', 'RTI']?.includes(servicedetails?.serviceStatusRefCode) ? true : false}
                                                        className={classes.problemtext}
                                                        onChange={(e: any) => {
                                                            ProblemOnChange(e);
                                                        }}
                                                    ></input>
                                                </Grid>
                                            </Grid>
                                            <Grid item md={6}>
                                                <label className={classes.label}>Solution</label>
                                                <Grid>
                                                    <input
                                                        type="textarea"
                                                        name="solution"
                                                        value={data.solution}
                                                        disabled={['RTC', 'RO', 'RTI']?.includes(servicedetails?.serviceStatusRefCode) ? true : false}
                                                        className={classes.problemtext}
                                                        maxLength={200}
                                                        onChange={(e: any) => {
                                                            handleInputChange(e);
                                                        }}
                                                    ></input>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={2}>
                                            <Grid item md={2}>
                                                <RACTextbox
                                                    inputlabel="Benefit Plus Auth#"
                                                    name="benefit"
                                                    disabled={servicedetails?.isClubActive == '0' ? true : false}
                                                    value={data?.benefit}
                                                    maxlength={20}
                                                    OnChange={(e: any) => {
                                                        handleInputChange(e);
                                                    }}

                                                />
                                            </Grid>
                                            <Grid item md={2}>
                                                <RACTextbox
                                                    inputlabel="Service Cost"
                                                    isCurrency={true}
                                                    type={'number'}
                                                    disabled={['RTC', 'RO', 'RTI']?.includes(servicedetails?.serviceStatusRefCode) ? true : false}
                                                    digitFormat={'currency'}
                                                    name="ServiceCost"
                                                    value={data.ServiceCost}
                                                    // Onblur={(e) => handleOnBlurService(e)}
                                                    dollarTextClassName={classes.amounttext}
                                                    className={classes.dollartext}
                                                    OnChange={(e: any) => {
                                                        handleInputChange(e);
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item md={2}>
                                                <RACSelect
                                                    inputLabel="Status"
                                                    loading={statusLoader}
                                                    isDisabled={['RTC', 'RO', 'RTI']?.includes(servicedetails?.serviceStatusRefCode) ? true : statusLoader}
                                                    options={ServiceArrayFunction()}
                                                    defaultValue={data.StatusDropdown}
                                                    onChange={(e: any) => {
                                                        e.target.name = 'StatusDropdown';
                                                        handleServiceDropdownChange(e);
                                                    }}
                                                    {...(statusError === true && {
                                                        errorMessage: 'Unable to fetch data!',
                                                    })}
                                                />
                                            </Grid>
                                            <Grid item md={2}>
                                                <RACTextbox
                                                    inputlabel="Total Cost"
                                                    isCurrency={true}
                                                    name="TotalCost"
                                                    disabled={true}
                                                    dollarTextClassName={classes.amounttext}
                                                    value={data.TotalCost}
                                                    className={classes.dollartext}
                                                    // dollarTextClassName={classes.dollarlogo}
                                                    OnChange={(e: any) => {
                                                        handleInputChange(e);
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>
                            {type !== 'N' ?
                                <Grid style={{ marginTop: '20px' }}>
                                    <Typography variant="h4">Service History</Typography>
                                    <Card className={classes.card} style={{ marginBottom: '3%' }}>
                                        {serviceHistory.map((value: any) => {
                                            { console.log('serviceHistoryValue', value); }
                                            return (
                                                <CardContent key={value?.serviceHistoryId} style={{ marginBottom: '3%' }}>
                                                    <Grid container>
                                                        <Grid style={{ marginLeft: '13px', marginTop: '6px' }}>
                                                            <List
                                                                style={{
                                                                    height: '15px',
                                                                    width: '15px',
                                                                    backgroundColor: '#0000a3',
                                                                    borderRadius: '50%',
                                                                    marginRight: '7px',
                                                                }}
                                                            ></List>
                                                            <List
                                                                style={{
                                                                    borderLeftWidth: '1px',
                                                                    borderLeftColor: 'silver',
                                                                    borderLeftStyle: 'groove',
                                                                    height: '100px',
                                                                    marginLeft: '33.5%',
                                                                }}
                                                            ></List>
                                                        </Grid>
                                                        <Grid style={{ width: '11%' }}>
                                                            <Typography style={{ color: '#0000a3' }}>
                                                                {value?.serviceStatus}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid container style={{ width: '13%', marginRight: '20px' }}>
                                                            <Grid style={{ width: '58%' }}>
                                                                <Typography style={{ color: '#0d6efd' }}>
                                                                    Service Cost :
                                                                </Typography>
                                                            </Grid>
                                                            <Grid>
                                                                <Typography
                                                                    style={{
                                                                        color: '#0d6efd',
                                                                        fontFamily: 'OpenSans-bold',
                                                                    }}
                                                                >
                                                                    {/* $ {value?.serviceCost} */}
                                                                    {validateObject(value?.serviceCost) && (value?.serviceCost !== "0" || value?.serviceCost !== "0.00") ?
                                                                        <NumberFormat
                                                                            thousandsGroupStyle="thousand"
                                                                            value={roundOff(value?.serviceCost, 3)?.toFixed(2)}
                                                                            prefix="$ "
                                                                            //className={classes.formLabelValue}
                                                                            displayType="text"
                                                                            type="text"
                                                                            thousandSeparator={true}
                                                                        /> : "$ 0.00"}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid container style={{ width: '11%' }}>
                                                            <Grid style={{ width: '28%' }}>
                                                                <Typography style={{ color: '#0d6efd' }}>
                                                                    Date :
                                                                </Typography>
                                                            </Grid>
                                                            <Grid>
                                                                <Typography
                                                                    style={{
                                                                        color: '#0d6efd',
                                                                        fontFamily: 'OpenSans-bold',
                                                                    }}
                                                                >
                                                                    {moment(value?.date).format('MM/DD/YYYY')}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container spacing={5} style={{ marginTop: '-105px' }}>
                                                        <Grid
                                                            style={{
                                                                width: '45%',
                                                                marginLeft: '4%',
                                                            }}
                                                        >
                                                            <Card
                                                                style={{
                                                                    paddingBottom: '2px',
                                                                    backgroundColor: '#f5f5f5',
                                                                    marginTop: '4%',
                                                                    borderLeftWidth: '5px',
                                                                    borderLeftColor: '#0000a3',
                                                                    borderLeftStyle: 'solid',
                                                                }}
                                                            >
                                                                <CardContent>
                                                                    <Typography>Problem</Typography>
                                                                    <Typography style={{ fontSize: '12px', color: '#8E8E8E' }}>{value?.problem}</Typography>
                                                                </CardContent>
                                                            </Card>
                                                        </Grid>
                                                        <Grid
                                                            style={{
                                                                width: '45%',
                                                                marginLeft: '3%',
                                                            }}
                                                        >
                                                            <Card
                                                                style={{
                                                                    paddingBottom: '2px',
                                                                    backgroundColor: '#f5f5f5',
                                                                    marginTop: '4%',
                                                                    borderLeftWidth: '5px',
                                                                    borderLeftColor: '#0000a3',
                                                                    borderLeftStyle: 'solid',
                                                                }}
                                                            >
                                                                <CardContent>
                                                                    <Typography>solution</Typography>
                                                                    <Typography style={{ fontSize: '12px', color: '#8E8E8E' }}>{value?.solution == 'null' ? ' ' : value?.solution}</Typography>
                                                                </CardContent>
                                                            </Card>
                                                        </Grid>
                                                    </Grid>
                                                </CardContent>)
                                        })}
                                    </Card>
                                </Grid> : null}
                            <Grid className={classes.fixedBottom}>
                                <Grid className={classes.floatLeft}>
                                    <RACButton
                                        color="primary"
                                        variant="outlined"
                                        onClick={() => {
                                            history.push({
                                                pathname: `/inventory/${servicedetails?.inventoryNumber}/information`,
                                            });
                                            location.reload();
                                        }}
                                    >
                                        Cancel
                                    </RACButton>
                                </Grid>
                                <Grid className={classes.floatRight}>
                                    <RACButton
                                        variant="contained"
                                        color="primary"
                                        //disabled={appointmentId ? false : true}
                                        disabled={type == 'Y' ? false : true}
                                        style={{ cursor: 'pointer' }}
                                        className={classes.mx}
                                        onClick={(e: any) => {
                                            handleOnPrint()
                                        }}
                                    >
                                        Print
                                    </RACButton>
                                    {CompleteStatus != 'Completed' ?
                                        <>
                                            <RACButton
                                                variant="contained"
                                                color="primary"
                                                className={classes.mx}
                                                disabled={appointmentId ? false : true}
                                                style={{ cursor: 'pointer' }}
                                                onClick={() => {
                                                    handlePrintChecklist();
                                                }}
                                            >
                                                Print Checklist
                                            </RACButton>
                                            {appointmentId == '' ?
                                                <RACButton
                                                    variant="contained"
                                                    color="primary"
                                                    style={{ cursor: 'pointer' }}
                                                    disabled={servicedetails?.inventoryStatus == 'Service On Rent' ? false : true}
                                                    className={classes.mx}
                                                    onClick={() => {
                                                        settimeSlotenable(true);
                                                        getCoworkerRequiredData();
                                                        GetTimeSlot(new Date());
                                                    }}
                                                >
                                                    Schedule {DeliveryConfirmation ? 'Delivery' : completeVerbage()}
                                                </RACButton> :
                                                <>
                                                    <RACButton
                                                        variant="contained"
                                                        color="primary"
                                                        style={{ cursor: 'pointer' }}
                                                        className={classes.mx}
                                                        onClick={() => {
                                                            RescheduleAppointmentOnClick()
                                                        }}
                                                    >
                                                        Reschedule {DeliveryConfirmation ? 'Delivery' : completeVerbage()}
                                                    </RACButton>
                                                    <RACButton
                                                        variant="contained"
                                                        color="primary"
                                                        className={classes.mx}
                                                        style={{ cursor: 'pointer' }}
                                                        onClick={() => {
                                                            ConfirmAppointment();
                                                        }}
                                                    >
                                                        Confirm {DeliveryConfirmation ? 'Delivery' : completeVerbage()}
                                                    </RACButton>
                                                </>}
                                        </> : null}
                                    <RACButton
                                        variant="contained"
                                        color="primary"
                                        className={classes.mx}
                                        disabled={servicedetails?.inventoryStatus == 'Service On Rent' && servicedetails?.isLoanerProvided == '0' ? false : true}
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => {
                                            history.push({
                                                pathname: `/agreement/loaner/itemsearch/${servicedetails?.customerId}/${servicedetails?.agreementId}`,
                                                search: `?inventoryNumber=${servicedetails?.inventoryNumber}`
                                            }
                                            )
                                        }}
                                    >
                                        Provide Loaner
                                    </RACButton>
                                    <RACButton
                                        variant="contained"
                                        color="primary"
                                        style={{ cursor: 'pointer' }}
                                        //disabled={saveDisable}
                                        disabled={saveDisableFunction()}
                                        className={classes.mx}
                                        onClick={() => {
                                            // RequestSave();
                                            setsecondLvlAuth(true);
                                        }}
                                    >
                                        Save
                                    </RACButton>
                                </Grid>
                            </Grid>
                            <Grid>
                                {secondLvlAuth === true ? (
                                    <TwoFactorComponent
                                        setTwoFactorCancelClick={() => { setsecondLvlAuth(false) }}
                                        setTwoFactorCompleted={(e: any) => secondLvlAuthRes(e)}
                                        moduleName={'ItemService'}
                                        CurrentRole={currentCWRole}
                                        employeeID={employeeId}
                                    ></TwoFactorComponent>
                                ) : null}
                                <RACModalCard
                                    isOpen={AppointScheduleSucess}
                                    closeIcon={false}
                                    maxWidth="xs"
                                    borderRadius={"9px !important"}
                                >

                                    {AppointmentSchedulePopup()}

                                </RACModalCard>
                                <RACModalCard
                                    isOpen={openPrintPopup}
                                    closeIcon={false}
                                    maxWidth="xs"
                                    borderRadius={"9px !important"}
                                >

                                    {Loanerprintpopupopen()}

                                </RACModalCard>
                                <RACModalCard
                                    isOpen={DeliverySchedule}
                                    closeIcon={false}
                                    maxWidth="xs"
                                    borderRadius={"9px !important"}
                                >

                                    {DeliverySchedulePopup()}

                                </RACModalCard>
                                <RACModalCard
                                    isOpen={AppointConfirmSucess}
                                    closeIcon={false}
                                    maxWidth="xs"
                                    borderRadius={"9px !important"}
                                >

                                    {AppointmentConfirmPopup()}

                                </RACModalCard>
                                <RACModalCard
                                    isOpen={Apierror}
                                    closeIcon={false}
                                    maxWidth="xs"
                                    borderRadius={"9px !important"}
                                >

                                    {ApiErrorPopup()}

                                </RACModalCard>
                                <RACModalCard
                                    isOpen={appoinment}
                                    maxWidth="xs"
                                    borderRadius="10px"
                                    title="Do you want to schedule the appoinment?"
                                    closeIcon={true}
                                    onClose={() => {
                                        handleAppoinment('closeIcon');
                                    }}
                                >
                                    <RACButton
                                        variant="outlined"
                                        color="secondary"
                                        style={{ marginLeft: '120px' }}
                                        onClick={() => {
                                            handleAppoinment('no');
                                        }}
                                    >
                                        No
                                    </RACButton>
                                    <RACButton
                                        variant="contained"
                                        color="primary"
                                        style={{ marginLeft: '20px' }}
                                        onClick={() => {
                                            handleAppoinment('yes');
                                        }}
                                    >
                                        Yes
                                    </RACButton>
                                </RACModalCard>
                            </Grid>
                        </React.Fragment>
                    )}
            <Grid>
                <RACModalCard
                    isOpen={timeSlotenable}
                    maxWidth="md"
                    borderRadius={15}
                    title={DeliveryConfirmation ? "Delivery Details" : "Service Details"}
                    closeIcon={true}
                    onClose={() => settimeSlotenable(false)}
                    className={classes.cardidle}
                    // eslint-disable-next-line react/no-children-prop
                    children={TimeSlotFunction()}
                />
                <RACModalCard
                    isOpen={errorMsg}
                    closeIcon={false}
                    maxWidth="xs"
                    borderRadius={"9px !important"}
                >

                    {noRecordPopup()}

                </RACModalCard>
                <RACModalCard
                    isOpen={provideLoaner}
                    closeIcon={false}
                    maxWidth="xs"
                    borderRadius={"9px !important"}
                >

                    {ProvideLoanerPopup()}

                </RACModalCard>
                <RACModalCard
                    isOpen={closeLoaner}
                    closeIcon={false}
                    maxWidth="xs"
                    borderRadius={"9px !important"}
                >

                    {CloseLoanerPopup()}

                </RACModalCard>
            </Grid>
            <div style={{ display: 'none' }}>
                <div
                    id="printService"
                    style={{
                        width: '100%',
                        display: 'none',
                        fontFamily: 'sans-serif',
                        marginTop: '5%',
                    }}
                >
                    <div
                        style={{ width: '100%', display: 'flex', fontFamily: 'sans-serif' }}
                    >
                        <div style={{ width: '50%' }}>
                            <h2 style={{ width: '30%', marginLeft: '45%' }}>RAC -</h2>
                        </div>
                        <div style={{ width: '50%' }}>
                            <p
                                style={{
                                    borderBottom: '2px solid black',
                                    width: '90%',
                                    marginLeft: '-38%',
                                    marginTop: '10%',
                                }}
                            ></p>
                        </div>
                    </div>
                    <div style={{ fontFamily: 'sans-serif', textAlign: 'center' }}>
                        <div >
                            {/* <span>1301 AMARILLO BLVD E AMARILLO, Texas 79107-5466</span> */}
                            <span>{decodestore?.addressLine1 ? decodestore?.addressLine1 : '-'} {decodestore?.addressLine2 ? decodestore?.addressLine2 : '-'} {decodestore?.city ? decodestore?.city : '-'} , {decodestore?.state ? decodestore?.state : '-'} {decodestore?.zip ? decodestore?.zip : '-'}</span>
                        </div>
                        <div>
                            <span>Phone # {decodestore?.workPhoneNumber ? decodestore?.workPhoneNumber : '-'}</span>
                        </div>{' '}
                        <div>
                            <span>Fax #</span>
                        </div>
                    </div>
                    <div
                        style={{
                            width: '50%',
                            marginLeft: '70%',
                            fontFamily: 'sans-serif',
                            marginTop: '1%',
                        }}
                    >
                        <span>Store {window.sessionStorage.getItem('storeNumber')}</span>
                    </div>
                    <div
                        style={{
                            width: '100%',
                            display: 'flex',
                            fontFamily: 'sans-serif',
                            marginTop: '1%',
                        }}
                    >
                        <span style={{ marginLeft: '12%' }}>Date {moment(new Date()).format('MM/DD/YYYY')}</span>
                        <span style={{ marginLeft: '43%' }}>StoreName {StoreNameFill} </span>
                    </div>
                    <div
                        style={{
                            width: '50%',
                            fontFamily: 'sans-serif',
                            marginLeft: '40%',
                            marginTop: '1%',
                        }}
                    >
                        <span>Idle Inventory</span>
                    </div>
                    <div
                        style={{
                            width: '50%',
                            fontFamily: 'sans-serif',
                            marginLeft: '33%',
                        }}
                    >
                        <h2>SERVICE REQUEST</h2>
                    </div>
                    <div
                        style={{
                            width: '100%',
                            fontFamily: 'sans-serif',
                            marginLeft: '12%',
                        }}
                    >
                        <h2>
                            Is this product covered by a Benefits Plus Extended Warranty for
                            the cost of repair or replacement?
                        </h2>
                    </div>
                    <div style={{ display: 'flex', marginTop: '1%' }}>
                        <div
                            style={{
                                display: 'flex',
                                fontFamily: 'sans-serif',
                                marginLeft: '32%',
                            }}
                        >
                            <div style={{ width: '50%' }}>
                                <h2 style={{ width: '50%', marginLeft: '45%' }}>Yes</h2>
                            </div>
                            <div style={{ width: '70%' }}>
                                <p
                                    style={{
                                        borderBottom: '2px solid black',
                                        width: '375%',
                                        marginLeft: '165%',
                                        marginTop: '164%',
                                    }}
                                ></p>
                            </div>
                        </div>

                        <div
                            style={{
                                display: 'flex',
                                fontFamily: 'sans-serif',
                                marginLeft: '15%',
                            }}
                        >
                            <div style={{ width: '50%', marginLeft: '10%' }}>
                                <h2 style={{ width: '50%', marginLeft: '65%' }}>No</h2>
                            </div>
                            <div style={{ width: '120%' }}>
                                <p
                                    style={{
                                        borderBottom: '2px solid black',
                                        width: '670%',
                                        marginLeft: '270%',
                                        marginTop: '284%',
                                    }}
                                ></p>
                            </div>
                        </div>
                    </div>
                    <div style={{ fontFamily: 'sans-serif', marginTop: '1%' }}>
                        <span style={{ marginLeft: '12%' }}>Customer Information:</span>
                    </div>
                    <div
                        style={{
                            width: '100%',
                            display: 'flex',
                            fontFamily: 'sans-serif',
                            marginTop: '1%',
                        }}
                    >
                        <span style={{ marginLeft: '12%' }}>Name: {customerInfo?.lastName ? customerInfo?.firstName + customerInfo?.lastName : customerInfo?.firstName}</span>
                        <span style={{ marginLeft: '53%' }}>Phone: {phoneNumber ? normalizeinput(phoneNumber) : ""}</span>
                    </div>
                    <div style={{ fontFamily: 'sans-serif', marginTop: '1%' }}>
                        <span style={{ marginLeft: '12%' }}>Address: {address?.addressLine2 ? address?.addressLine1 + address?.addressLine2 : address?.addressLine1}</span>
                    </div>
                    <div
                        style={{
                            width: '100%',
                            display: 'flex',
                            fontFamily: 'sans-serif',
                            marginTop: '1%',
                        }}
                    >
                        <span style={{ marginLeft: '12%' }}>City: {address?.city}</span>
                        <span style={{ marginLeft: '25%' }}>State: {address?.state}</span>
                        <span style={{ marginLeft: '25%' }}>Zip: {address?.postalCode}</span>
                    </div>
                    <div
                        style={{
                            width: '100%',
                            display: 'flex',
                            fontFamily: 'sans-serif',
                            marginTop: '1%',
                        }}
                    >
                        <span style={{ marginLeft: '12%' }}>Route: {customerInfo?.customerRoutes ? customerInfo?.customerRoutes[0].routeCodeDesc : null}</span>
                        <span style={{ marginLeft: '24%' }}>RA#: {servicedetails?.agreementNumber}</span>
                    </div>
                    <div style={{ fontFamily: 'sans-serif', marginTop: '1%' }}>
                        <span style={{ marginLeft: '12%' }}>Product Information:</span>
                    </div>
                    <div
                        style={{
                            width: '100%',
                            display: 'flex',
                            fontFamily: 'sans-serif',
                            marginTop: '1%',
                            marginLeft: '12%'
                        }}
                    ><div style={{ width: '40%' }}>
                            <div style={{ marginTop: '2%' }}>
                                <span >Item #:</span>{' '}
                                <span style={{ marginLeft: '2%' }}>{printInfo?.inventoryHeader?.itemNumber ? printInfo?.inventoryHeader?.itemNumber : '-'}</span>
                            </div>
                            <div style={{ marginTop: '2%' }}>
                                <span>Model #:</span>{' '}
                                <span style={{ marginLeft: '2%' }}>{printInfo?.inventoryInformation?.modelNumber ? printInfo?.inventoryInformation?.modelNumber : '-'}</span>
                            </div>
                            <div style={{ marginTop: '2%' }}>
                                <span >Brand:</span>{' '}
                                <span style={{ marginLeft: '2%' }}>{printInfo?.inventoryInformation?.brand ? printInfo?.inventoryInformation?.brand : '-'}</span>
                            </div>
                        </div>
                        <div style={{ width: '60%' }}>
                            <div style={{ marginTop: '2%' }}>
                                <span >Item Description: </span>
                                <span style={{ marginLeft: '1%' }}>
                                    {printInfo?.inventoryHeader?.description ? printInfo?.inventoryHeader?.description : '-'}
                                </span>
                            </div>
                            <div style={{ marginTop: '2%' }}>
                                <span >Serial #: </span>
                                <span style={{ marginLeft: '1%' }}>{printInfo?.inventoryInformation?.serialNumber ? printInfo?.inventoryInformation?.serialNumber : '-'}</span>
                            </div>
                            <div style={{ marginTop: '2%' }}>
                                <span >Date of Purchase: </span>
                                <span style={{ marginLeft: '1%' }}>{servicedetails?.purchaseDate ? moment(servicedetails?.purchaseDate).format('MM/DD/YYYY') : '-'}</span>
                            </div>
                        </div>

                    </div>
                    <div style={{ fontFamily: 'sans-serif', marginTop: '1%' }}>
                        <span style={{ marginLeft: '12%' }}>First Rented:</span>
                        <span style={{ marginLeft: '2%' }}>{servicedetails?.firstRentedDate ? moment(servicedetails?.firstRentedDate).format('MM/DD/YYYY') : '-'}</span>
                    </div>
                    <div
                        style={{
                            width: '100%',
                            display: 'flex',
                            fontFamily: 'sans-serif',
                            marginTop: '1%',
                        }}
                    >
                        <div style={{ width: '50%' }}>
                            <span style={{ width: '30%', marginLeft: '24%' }}>
                                Benefits Plus /Club Authorization No:
                            </span>
                        </div>
                        <div style={{ width: '50%' }}>
                            <p
                                style={{
                                    borderBottom: '1px solid black',
                                    width: '55%',
                                    marginLeft: '-13%',
                                    marginTop: '3%',
                                }}
                            ></p>
                        </div>
                    </div>
                    <div style={{ fontFamily: 'sans-serif' }}>
                        <span style={{ marginLeft: '12%' }}>Service Request:</span>
                    </div>
                    <div style={{ marginLeft: '12%', fontFamily: 'sans-serif' }}>
                        <span>{serviceHistory != undefined ? serviceHistory[0]?.problem : '-'}</span>
                    </div>
                    <div style={{ fontFamily: 'sans-serif', marginTop: '10%' }}>
                        <span style={{ marginLeft: '12%' }}>Solution:</span>
                    </div>
                    <div style={{ marginLeft: '12%', fontFamily: 'sans-serif' }}>
                        <span>{serviceHistory != undefined ? serviceHistory[0]?.solution == 'null' ? '' : serviceHistory[0]?.solution : ''}</span>
                    </div>
                    <div
                        style={{
                            width: '100%',
                            display: 'flex',
                            fontFamily: 'sans-serif',
                            marginTop: '10%',
                        }}
                    >
                        <span style={{ marginLeft: '12%' }}>Service Status:</span>{' '}
                        <span style={{ marginLeft: '2%' }}>{servicedetails?.serviceStatus ? servicedetails?.serviceStatus : ''}</span>
                    </div>
                    <div style={{ fontFamily: 'sans-serif' }}>
                        <p style={{ marginLeft: '12%', fontSize: '12px' }}>
                            Store must call 1-888-770-4123 to obtain authorization number
                            before shuttling the paid out unit to service. This authorization
                            will ensure your store is not charged for the repair
                        </p>
                    </div>
                </div>
            </div>

            {/* service Delivery Checklist inner html ends here */}
            <div style={{ display: 'none' }}>
                <div
                    style={{
                        width: '90%',
                        fontFamily: 'Arial',
                        margin: '10px auto 0px auto',
                    }}
                    id="ServiceDeliveryChecklist"
                >
                    {/*first part starts here*/}
                    <div style={{ width: '100%', float: 'left' }}>
                        <div style={{ width: '100%', fontFamily: 'Arial', float: 'left' }}>
                            <div style={{ width: '30%', float: 'left' }}>
                                <h5 style={{ textTransform: 'uppercase', fontSize: '15px' }}>
                                    Delivery Checklist
                                </h5>
                            </div>
                            <div
                                style={{
                                    width: '31%',
                                    marginLeft: '2%',
                                    marginRight: '2%',
                                    float: 'left',
                                    height: '100px',
                                    marginBottom: '10px',
                                }}
                            >
                                <Logo style={{ width: '100%', height: '100%' }}></Logo>
                            </div>
                            <div></div>
                            <table style={{ width: '35%', float: 'left', marginTop: '30px' }}>
                                <thead />
                                <tbody style={{ width: '100%', float: 'right' }}>
                                    {/*child-parent of first widget*/}
                                    <tr
                                        style={{ width: '42%', float: 'left', textAlign: 'left' }}
                                    >
                                        <td
                                            style={{
                                                marginBottom: '0.2rem',
                                                color: '#111111',
                                                fontSize: '13px',
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            Delivery Date:
                                        </td>
                                    </tr>
                                    <tr
                                        style={{ width: '58%', float: 'left', textAlign: 'left' }}
                                    >
                                        <td>
                                            <span
                                                style={{
                                                    marginTop: '5px',
                                                    color: '#656565',
                                                    borderBottom: borderbotttom,
                                                    fontSize: '13px',
                                                }}
                                            >
                                                {appointmentDate}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr
                                        style={{ width: '43%', float: 'left', textAlign: 'left' }}
                                    >
                                        <td
                                            style={{
                                                marginBottom: '0.2rem',
                                                color: '#111111',
                                                fontSize: '13px',
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            Delivery Time:
                                        </td>
                                    </tr>
                                    <tr
                                        style={{ width: '57%', float: 'left', textAlign: 'left' }}
                                    >
                                        <td>
                                            <span
                                                style={{
                                                    marginTop: '5px',
                                                    color: '#656565',
                                                    borderBottom: borderbotttom,
                                                    fontSize: '13px',
                                                }}
                                            >
                                                {deliveryTime}
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    {/*first part ends here*/}
                    {/*second part starts here */}
                    <div
                        style={{
                            width: '100%',
                            float: 'left',
                            borderBottom: borderrr,
                        }}
                    >
                        <div style={{ width: '100%', float: 'left', marginBottom: '10px' }}>
                            <div style={{ width: '33.33%', float: 'left' }}>
                                <h5 style={{ fontSize: '13px', marginBottom: '10px' }}>
                                    RAC Store
                                </h5>
                                <p
                                    style={{
                                        textTransform: 'uppercase',
                                        fontSize: '13px',
                                        marginTop: '10px',
                                        marginBottom: '10px',
                                    }}
                                >
                                    {printObjvalue.storeName}
                                </p>
                                <>
                                    {printObjvalue.storeAddressLine2 ? (
                                        <p
                                            style={{
                                                textTransform: 'uppercase',
                                                fontSize: '13px',
                                                marginTop: '10px',
                                                marginBottom: '10px',
                                            }}
                                        >
                                            {printObjvalue.storeAddressLine1 +
                                                ' , ' +
                                                printObjvalue.storeAddressLine2}{' '}                          </p>
                                    ) : (
                                        <p
                                            style={{
                                                textTransform: 'uppercase',
                                                fontSize: '13px',
                                                marginTop: '10px',
                                                marginBottom: '10px',
                                            }}
                                        >
                                            {printObjvalue.storeAddressLine1}
                                        </p>
                                    )}
                                </>
                                <p
                                    style={{
                                        textTransform: 'uppercase',
                                        fontSize: '13px',
                                        marginTop: '10px',
                                        marginBottom: '10px',
                                    }}
                                >
                                    {' '}
                                    {printObjvalue.storeCity + ',' + printObjvalue.storeZip}

                                </p>
                                <p
                                    style={{
                                        fontSize: '13px',
                                        marginTop: '10px',
                                        marginBottom: '10px',
                                    }}
                                >
                                    {normalizeinput(printObjvalue.storePhoneNumber)}{' '}

                                </p>
                            </div>
                            <div style={{ width: '30.33%', float: 'left' }}>
                                <h5 style={{ fontSize: '13px', marginBottom: '10px' }}>
                                    Customer
                                </h5>
                                <p
                                    style={{
                                        textTransform: 'uppercase',
                                        fontSize: '13px',
                                        marginTop: '10px',
                                        marginBottom: '10px',
                                    }}
                                >
                                    {printObjvalue?.customerName}{' '}
                                </p>
                                {printObjvalue?.addressLine2 ? (
                                    <p
                                        style={{
                                            textTransform: 'uppercase',
                                            fontSize: '13px',
                                            marginTop: '10px',
                                            marginBottom: '10px',
                                        }}
                                    >
                                        {printObjvalue?.addressLine1 +
                                            ',' +
                                            printObjvalue?.addressLine2}{' '}                         </p>
                                ) : (
                                    <p
                                        style={{
                                            textTransform: 'uppercase',
                                            fontSize: '13px',
                                            marginTop: '10px',
                                            marginBottom: '10px',
                                        }}
                                    >
                                        {printObjvalue?.addressLine1}
                                    </p>
                                )}
                                {printObjvalue?.customerPlus4 ? (
                                    <p
                                        style={{
                                            textTransform: 'uppercase',
                                            fontSize: '13px',
                                            marginTop: '10px',
                                            marginBottom: '10px',
                                        }}
                                    >
                                        {printObjvalue.customerCity +
                                            ',' +
                                            printObjvalue.customerZip +
                                            '-' +
                                            printObjvalue.customerPlus4}{' '}                         </p>
                                ) : (
                                    <p
                                        style={{
                                            textTransform: 'uppercase',
                                            fontSize: '13px',
                                            marginTop: '10px',
                                            marginBottom: '10px',
                                        }}
                                    >
                                        {printObjvalue.customerCity +
                                            ',' +
                                            printObjvalue.customerZip}
                                    </p>
                                )}
                                <p
                                    style={{
                                        fontSize: '13px',
                                        marginTop: '10px',
                                        marginBottom: '10px',
                                    }}
                                >
                                    {normalizeinput(printObjvalue.customerPhoneNumber)}
                                </p>
                            </div>
                            <div
                                style={{ width: '36.33%', float: 'left', marginTop: '12px' }}
                            >
                                <table>
                                    <thead />
                                    <tbody style={{ width: '100%', float: 'left' }}>
                                        <tr
                                            style={{ width: '50%', textAlign: 'left', float: 'left' }}
                                        >
                                            <td
                                                style={{
                                                    marginBottom: '0.2rem',
                                                    color: '#111111',
                                                    fontSize: '13px',
                                                    fontWeight: 'bold',
                                                }}
                                            >
                                                Delivery Date:
                                            </td>
                                        </tr>
                                        <tr
                                            style={{ width: '40%', textAlign: 'left', float: 'left' }}
                                        >
                                            <td
                                                style={{
                                                    marginTop: '5px',
                                                    color: '#656565',
                                                    fontSize: '13px',
                                                }}
                                            >
                                                {appointmentDate}
                                            </td>
                                        </tr>
                                        <tr
                                            style={{
                                                width: '50%',
                                                marginTop: '10px',
                                                textAlign: 'left',
                                                float: 'left',
                                            }}
                                        >
                                            <td
                                                style={{
                                                    marginBottom: '0.2rem',
                                                    color: '#111111',
                                                    fontSize: '13px',
                                                    fontWeight: 'bold',
                                                }}
                                            >
                                                Agreement # :
                                            </td>
                                        </tr>
                                        <tr
                                            style={{
                                                width: '50%',
                                                marginTop: '10px',
                                                textAlign: 'left',
                                                float: 'left',
                                            }}
                                        >
                                            <td
                                                style={{
                                                    marginTop: '5px',
                                                    color: '#656565',
                                                    fontSize: '13px',
                                                }}
                                            >
                                                {printObjvalue.agreementId}
                                            </td>
                                        </tr>
                                        <tr
                                            style={{
                                                width: '50%',
                                                marginTop: '10px',
                                                textAlign: 'left',
                                                float: 'left',
                                            }}
                                        >
                                            <td
                                                style={{
                                                    marginBottom: '0.2rem',
                                                    color: '#111111',
                                                    fontSize: '13px',
                                                    fontWeight: 'bold',
                                                }}
                                            >
                                                Customer # :
                                            </td>
                                        </tr>
                                        <tr
                                            style={{
                                                width: '40%',
                                                marginTop: '10px',
                                                textAlign: 'left',
                                                float: 'left',
                                            }}
                                        >
                                            <td
                                                style={{
                                                    marginTop: '5px',
                                                    color: '#656565',
                                                    fontSize: '13px',
                                                }}
                                            >
                                                {printObjvalue.customerId}
                                            </td>
                                        </tr>
                                        <tr
                                            style={{
                                                width: '50%',
                                                marginTop: '10px',
                                                textAlign: 'left',
                                                float: 'left',
                                            }}
                                        >
                                            <td
                                                style={{
                                                    marginBottom: '0.2rem',
                                                    color: '#111111',
                                                    fontSize: '13px',
                                                    fontWeight: 'bold',
                                                }}
                                            >
                                                Next Payment Due:
                                            </td>
                                        </tr>
                                        <tr
                                            style={{
                                                width: '40%',
                                                marginTop: '10px',
                                                textAlign: 'left',
                                                float: 'left',
                                            }}
                                        >
                                            <td
                                                style={{
                                                    marginTop: '5px',
                                                    color: '#656565',
                                                    fontSize: '13px',
                                                }}
                                            >
                                                {/* {nextpaymentinfo.nextamountdue !== undefined &&
                                    nextpaymentinfo.nextamountdue !== null
                                    ? '$' + ' ' + nextpaymentinfo.nextamountdue
                                    : ''} */}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    {/*second part ends here */}
                    {/*3rd part starts*/}
                    <div
                        style={{
                            width: '100%',
                            float: 'left',
                            borderBottom: borderrr,
                        }}
                    >
                        <div style={{ width: '100%', float: 'left', marginTop: '15px' }}>
                            <h5
                                style={{
                                    textTransform: 'uppercase',
                                    textAlign: 'center',
                                    fontSize: '14px',
                                    margin: '0px',
                                    marginBottom: '10px',
                                }}
                            >
                                Delivery Checklist
                            </h5>
                        </div>
                        <div style={{ width: '100%', float: 'left', marginBottom: '10px' }}>
                            <table style={{ marginBottom: '20px', fontSize: '12px' }}>
                                <thead>
                                    <tr style={{ width: '100%' }}>
                                        <th
                                            style={{
                                                width: '15px',
                                                textAlign: 'left',
                                                paddingLeft: '12px',
                                                paddingRight: '12px',
                                                paddingTop: '5px',
                                                paddingBottom: '5px',
                                            }}
                                        ></th>
                                        <th
                                            style={{
                                                width: '300px',
                                                textAlign: 'left',
                                                paddingLeft: '12px',
                                                paddingRight: '12px',
                                                paddingTop: '5px',
                                                paddingBottom: '5px',
                                            }}
                                        >
                                            Description of Property
                                        </th>
                                        <th
                                            style={{
                                                width: '300px',
                                                textAlign: 'left',
                                                paddingLeft: '12px',
                                                paddingRight: '12px',
                                                paddingTop: '5px',
                                                paddingBottom: '5px',
                                            }}
                                        >
                                            Serial #
                                        </th>
                                        <th
                                            style={{
                                                width: '300px',
                                                textAlign: 'left',
                                                paddingLeft: '12px',
                                                paddingRight: '12px',
                                                paddingTop: '5px',
                                                paddingBottom: '5px',
                                            }}
                                        >
                                            Item #
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>{bindChecklist()}</tbody>
                            </table>
                        </div>
                    </div>
                    {/*3rd part ends*/}
                    {/*fourth part starts*/}
                    <div
                        style={{
                            width: '100%',
                            float: 'left',
                            marginBottom: '400px',
                            marginTop: '10px',
                        }}
                    >
                        <h5
                            style={{
                                fontSize: '14px',
                                textDecoration: 'underline',
                                float: 'left',
                            }}
                        >
                            Delivery Information
                        </h5>
                        <table style={{ width: '100%', float: 'left' }}>
                            <thead />
                            <tbody
                                style={{ width: '100%', marginTop: '20px', float: 'left' }}
                            >
                                <tr
                                    style={{ width: '18%', float: 'left', whiteSpace: 'nowrap' }}
                                >
                                    <td
                                        style={{
                                            marginBottom: '0.2rem',
                                            color: '#111111',
                                            fontSize: '13px',
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        CoWorker Signature:
                                    </td>
                                </tr>
                                <tr
                                    style={{
                                        width: '28%',
                                        float: 'left',
                                        paddingRight: '25px',
                                        whiteSpace: 'nowrap',
                                        marginLeft: '8px',
                                    }}
                                >
                                    <td
                                        style={{
                                            marginTop: '18px',
                                            color: '#656565',
                                            borderBottom: borderbotttom,
                                            width: '100%',
                                            float: 'left',
                                        }}
                                    />
                                </tr>
                                <tr
                                    style={{ width: '8%', float: 'left', whiteSpace: 'nowrap' }}
                                >
                                    <td
                                        style={{
                                            marginBottom: '0.2rem',
                                            color: '#111111',
                                            fontSize: '13px',
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        Date:
                                    </td>
                                </tr>
                                <tr
                                    style={{ width: '32%', float: 'left', whiteSpace: 'nowrap' }}
                                >
                                    <td
                                        style={{
                                            marginTop: '15px',
                                            color: '#656565',
                                            borderBottom: borderbotttom,
                                            width: '100%',
                                            float: 'left',
                                        }}
                                    />
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    {/*fourth part ends*/}
                    {/*fifth part starts*/}
                    <div
                        style={{
                            width: '100%',
                            float: 'left',
                            borderBottom: borderrr,
                        }}
                    >
                        <div style={{ width: '100%', float: 'left' }}>
                            <h5
                                style={{
                                    textAlign: 'center',
                                    fontSize: '20px',
                                    marginTop: '0px',
                                    marginBottom: '10px',
                                }}
                            >
                                For Office Use Only
                            </h5>
                        </div>
                        <div style={{ width: '100%', float: 'left' }}>
                            <span
                                style={{
                                    fontSize: '14px',
                                    marginTop: '10px',
                                    marginBottom: '10px',
                                    fontStyle: 'italic',
                                }}
                            >
                                I acknowledge that Rent-A-Center has delivered, installed and
                                demonstrated, to my satisfaction, the rental property listed
                                above.
                            </span>
                        </div>
                        <div style={{ textAlign: 'center' }}>
                            <div style={{ width: '100%', float: 'left', marginTop: '20px' }}>
                                <span
                                    style={{
                                        fontSize: '14px',
                                        marginBottom: '10px',
                                        textDecoration: 'underline',
                                    }}
                                >
                                    Customer Call back survey
                                </span>
                            </div>
                        </div>
                        <div
                            style={{
                                width: '100%',
                                float: 'left',
                                marginTop: '20px',
                                marginBottom: '30px',
                            }}
                        >
                            <div style={{ width: '50%', float: 'left' }}>
                                <div style={{ width: '35%', float: 'left' }}>
                                    <span
                                        style={{
                                            marginBottom: '0.2rem',
                                            color: '#111111',
                                            fontSize: '14px',
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        Date of Call Back
                                    </span>
                                </div>
                                <div style={{ width: '65%', float: 'right' }}>
                                    <span
                                        style={{
                                            marginTop: '12px',
                                            color: '#656565',
                                            borderBottom: borderbotttom,
                                            width: '80%',
                                            float: 'left',
                                        }}
                                    />
                                </div>
                            </div>
                            <div style={{ width: '50%', float: 'left' }}>
                                <div style={{ width: '30%', float: 'left' }}>
                                    <span
                                        style={{
                                            marginBottom: '0.2rem',
                                            color: '#111111',
                                            fontSize: '14px',
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        Mgr/Asst Initials
                                    </span>
                                </div>
                                <div style={{ width: '70%', float: 'right' }}>
                                    <span
                                        style={{
                                            marginTop: '12px',
                                            color: '#656565',
                                            borderBottom: borderrbottommm,
                                            width: '100%',
                                            float: 'left',
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*fifth part ends*/}
                    {/*letter format starts*/}
                    <div style={{ width: '100%', fontSize: '14px', float: 'left' }}>
                        <p
                            style={{
                                fontSize: '14px',
                                marginBottom: '5px',
                                lineHeight: '26px',
                            }}
                        >
                            Hello Mr./Mrs./Ms., this is{' '}
                            <span
                                style={{
                                    marginTop: '12px',
                                    color: '#656565',
                                    borderBottom: borderrbottommm,
                                    paddingRight: '135px',
                                    marginLeft: '10px',
                                    marginRight: '10px',
                                }}
                            />
                            , the manager of the Rent-A-Center in{' '}
                            <span
                                style={{
                                    marginTop: '12px',
                                    color: '#656565',
                                    borderBottom: borderrbottommm,
                                    paddingRight: '135px',
                                    marginLeft: '10px',
                                    marginRight: '10px',
                                }}
                            />
                            I want to welcome you as a customer and ask you a few questions
                            about the delivery we made to you the other day. Is this a
                            convenient time for you to answer a few questions?
                        </p>
                        <p style={{ fontStyle: 'italic', marginTop: '5px' }}>
                            {' '}
                            (If yes, continue with survey. If no, ask for a convenient time to
                            call back)
                        </p>
                    </div>
                    {/*letter format ends*/}
                    {/*grid starts here*/}
                    <div style={{ width: '100%', float: 'left' }}>
                        <table style={{ marginBottom: '10px', fontSize: '14px' }}>
                            <tbody>
                                <tr style={{ width: '100%' }}>
                                    <td
                                        style={{
                                            width: '14%',
                                            paddingLeft: '12px',
                                            paddingRight: '12px',
                                            paddingTop: '5px',
                                            paddingBottom: '5px',
                                            whiteSpace: 'nowrap',
                                        }}
                                    >
                                        1. Was the delivery on time ?
                                    </td>
                                    <td
                                        style={{
                                            width: '14%',
                                            paddingLeft: '12px',
                                            paddingRight: '12px',
                                            paddingTop: '5px',
                                            paddingBottom: '5px',
                                        }}
                                    >
                                        Yes
                                    </td>
                                    <td
                                        style={{
                                            width: '14%',
                                            paddingLeft: '12px',
                                            paddingRight: '12px',
                                            paddingTop: '5px',
                                            paddingBottom: '5px',
                                        }}
                                    >
                                        No
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        style={{
                                            width: '14%',
                                            paddingLeft: '12px',
                                            paddingRight: '12px',
                                            paddingTop: '5px',
                                            paddingBottom: '5px',
                                            whiteSpace: 'nowrap',
                                        }}
                                    >
                                        2. Was the unit&apos; operation explained to your
                                        satisfaction ?
                                    </td>
                                    <td
                                        style={{
                                            width: '14%',
                                            paddingLeft: '12px',
                                            paddingRight: '12px',
                                            paddingTop: '5px',
                                            paddingBottom: '5px',
                                        }}
                                    >
                                        Yes
                                    </td>
                                    <td
                                        style={{
                                            width: '14%',
                                            paddingLeft: '12px',
                                            paddingRight: '12px',
                                            paddingTop: '5px',
                                            paddingBottom: '5px',
                                        }}
                                    >
                                        No
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        style={{
                                            width: '14%',
                                            paddingLeft: '12px',
                                            paddingRight: '12px',
                                            paddingTop: '5px',
                                            paddingBottom: '5px',
                                            whiteSpace: 'nowrap',
                                        }}
                                    >
                                        3. Have we answered all of your questions regarding the
                                        rental agreement?
                                    </td>
                                    <td
                                        style={{
                                            width: '14%',
                                            paddingLeft: '12px',
                                            paddingRight: '12px',
                                            paddingTop: '5px',
                                            paddingBottom: '5px',
                                        }}
                                    >
                                        Yes
                                    </td>
                                    <td
                                        style={{
                                            width: '14%',
                                            paddingLeft: '12px',
                                            paddingRight: '12px',
                                            paddingTop: '5px',
                                            paddingBottom: '5px',
                                        }}
                                    >
                                        No
                                    </td>
                                </tr>
                                <tr></tr>
                                <tr>
                                    <td
                                        style={{
                                            width: '14%',
                                            paddingLeft: '12px',
                                            paddingRight: '12px',
                                            paddingTop: '5px',
                                            paddingBottom: '5px',
                                            whiteSpace: 'nowrap',
                                        }}
                                    >
                                        3. Have we answered all of your questions regarding the
                                        rental agreement?
                                    </td>
                                    <td
                                        style={{
                                            width: '14%',
                                            paddingLeft: '12px',
                                            paddingRight: '12px',
                                            paddingTop: '5px',
                                            paddingBottom: '5px',
                                        }}
                                    >
                                        Yes
                                    </td>
                                    <td
                                        style={{
                                            width: '14%',
                                            paddingLeft: '12px',
                                            paddingRight: '12px',
                                            paddingTop: '5px',
                                            paddingBottom: '5px',
                                        }}
                                    >
                                        No
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        style={{
                                            width: '14%',
                                            paddingLeft: '12px',
                                            paddingRight: '12px',
                                            paddingTop: '5px',
                                            paddingBottom: '5px',
                                            whiteSpace: 'nowrap',
                                        }}
                                    >
                                        4. Can you tell me when your next renewal is due and the
                                        amount you will pay?
                                    </td>
                                    <td
                                        style={{
                                            width: '14%',
                                            paddingLeft: '12px',
                                            paddingRight: '12px',
                                            paddingTop: '5px',
                                            paddingBottom: '5px',
                                        }}
                                    >
                                        Yes
                                    </td>
                                    <td
                                        style={{
                                            width: '14%',
                                            paddingLeft: '12px',
                                            paddingRight: '12px',
                                            paddingTop: '5px',
                                            paddingBottom: '5px',
                                        }}
                                    >
                                        No
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        style={{
                                            width: '14%',
                                            paddingLeft: '12px',
                                            paddingRight: '12px',
                                            paddingTop: '5px',
                                            paddingBottom: '5px',
                                            whiteSpace: 'nowrap',
                                        }}
                                    >
                                        5. Did we explain our customer referral program?
                                    </td>
                                    <td
                                        style={{
                                            width: '14%',
                                            paddingLeft: '12px',
                                            paddingRight: '12px',
                                            paddingTop: '5px',
                                            paddingBottom: '5px',
                                        }}
                                    >
                                        Yes
                                    </td>
                                    <td
                                        style={{
                                            width: '14%',
                                            paddingLeft: '12px',
                                            paddingRight: '12px',
                                            paddingTop: '5px',
                                            paddingBottom: '5px',
                                        }}
                                    >
                                        No
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        style={{
                                            width: '14%',
                                            paddingLeft: '12px',
                                            paddingRight: '12px',
                                            paddingTop: '5px',
                                            paddingBottom: '5px',
                                            whiteSpace: 'nowrap',
                                        }}
                                    >
                                        6. Did you take advantage of our Benefits Plus program when
                                        you rented your item?
                                    </td>
                                    <td
                                        style={{
                                            width: '14%',
                                            paddingLeft: '12px',
                                            paddingRight: '12px',
                                            paddingTop: '5px',
                                            paddingBottom: '5px',
                                        }}
                                    >
                                        Yes
                                    </td>
                                    <td
                                        style={{
                                            width: '14%',
                                            paddingLeft: '12px',
                                            paddingRight: '12px',
                                            paddingTop: '5px',
                                            paddingBottom: '5px',
                                        }}
                                    >
                                        No
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        style={{
                                            paddingLeft: '30px',
                                            paddingRight: '12px',
                                            paddingTop: '5px',
                                            paddingBottom: '5px',
                                            whiteSpace: 'nowrap',
                                            marginRight: '20px',
                                        }}
                                    >
                                        If No explain to them the benefits &amp; close the deal.
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        style={{
                                            width: '14%',
                                            paddingLeft: '12px',
                                            paddingRight: '12px',
                                            paddingTop: '5px',
                                            paddingBottom: '5px',
                                            whiteSpace: 'nowrap',
                                        }}
                                    >
                                        7. Do you have any other questions or is there anything I
                                        can do for you?
                                    </td>
                                    <td
                                        style={{
                                            width: '14%',
                                            paddingLeft: '12px',
                                            paddingRight: '12px',
                                            paddingTop: '5px',
                                            paddingBottom: '5px',
                                        }}
                                    >
                                        Yes
                                    </td>
                                    <td
                                        style={{
                                            width: '14%',
                                            paddingLeft: '12px',
                                            paddingRight: '12px',
                                            paddingTop: '5px',
                                            paddingBottom: '5px',
                                        }}
                                    >
                                        No
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div style={{ width: '100%', float: 'left' }}>
                        <p style={{ fontSize: '14px', marginBottom: '5px' }}>Notes :</p>
                        <span
                            style={{
                                marginTop: '20px',
                                color: '#656565',
                                borderBottom: borderbotttom,
                                width: '100%',
                                float: 'left',
                            }}
                        />
                        <span
                            style={{
                                marginTop: '30px',
                                color: '#656565',
                                borderBottom: borderbotttom,
                                width: '100%',
                                float: 'left',
                            }}
                        />
                    </div>
                    <div style={{ textAlign: 'center' }}>
                        <div
                            style={{
                                width: '100%',
                                float: 'left',
                                marginTop: '20px',
                                marginBottom: '20px',
                            }}
                        >
                            <p style={{ fontSize: '14px', marginBottom: '5px' }}>
                                Thank you for your time and please do not hesitate to call us at
                                Rent-A-Center with any questions.
                            </p>
                        </div>
                    </div>
                    {/*grid ends here*/}
                </div>
            </div>
            {/* service Delivery Checklist inner html ends here */}

            {/* service Delivery receipt inner html ends here */}
            <div style={{ display: 'none' }}>
                <div
                    style={{
                        borderTop: '10px solid #2179FE',
                        margin: '0px',
                        width: '100%',
                        float: 'left',
                    }}
                    id="ServiceDeliveryReceipt"
                >
                    <div
                        style={{
                            width: '100%',
                            fontFamily: 'Arial',
                            margin: '10px auto 0px auto',
                        }}
                    >
                        {/*first part starts here*/}
                        <div style={{ width: '100%', float: 'left' }}>
                            <div
                                style={{ width: '100%', fontFamily: 'Arial', float: 'left' }}
                            >
                                <div style={{ width: '20.33%', float: 'left' }}>
                                    <h5 style={{ textTransform: 'uppercase', fontSize: '15px' }}>
                                        Service Delivery Receipt
                                    </h5>
                                </div>
                                <div
                                    style={{
                                        width: '13.33%',
                                        marginLeft: '2%',
                                        marginRight: '2%',
                                        float: 'left',
                                        height: '100px',
                                        marginBottom: '10px',
                                    }}
                                >
                                    <Logo style={{ width: '100%', height: '100%' }}></Logo>
                                </div>
                                <div></div>
                                <table
                                    style={{ width: '62.33%', float: 'left', marginTop: '30px' }}
                                >
                                    <thead />
                                    <tbody style={{ width: '100%', float: 'left' }}>
                                        {/*child-parent of first widget*/}
                                        <tr
                                            style={{ width: '24%', float: 'left', textAlign: 'left' }}
                                        >
                                            <td
                                                style={{
                                                    marginBottom: '0.2rem',
                                                    color: '#111111',
                                                    fontSize: '13px',
                                                    fontWeight: 'bold',
                                                }}
                                            >
                                                Delivery Date:
                                            </td>
                                        </tr>
                                        <tr
                                            style={{ width: '24%', float: 'left', textAlign: 'left' }}
                                        >
                                            <td>
                                                <span
                                                    style={{
                                                        marginTop: '5px',
                                                        color: '#656565',
                                                        borderBottom: borderbotttom,
                                                        fontSize: '13px',
                                                    }}
                                                >
                                                    {appointmentDate}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr
                                            style={{ width: '22%', float: 'left', textAlign: 'left' }}
                                        >
                                            <td
                                                style={{
                                                    marginBottom: '0.2rem',
                                                    color: '#111111',
                                                    fontSize: '13px',
                                                    fontWeight: 'bold',
                                                }}
                                            >
                                                Delivery Time:
                                            </td>
                                        </tr>
                                        <tr
                                            style={{ width: '30%', float: 'left', textAlign: 'left' }}
                                        >
                                            <td>
                                                <span
                                                    style={{
                                                        marginTop: '5px',
                                                        color: '#656565',
                                                        borderBottom: borderbotttom,
                                                        fontSize: '13px',
                                                    }}
                                                >
                                                    {deliveryTime}
                                                </span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            {/*first part ends here*/}
                            {/*second part starts here */}
                            <div
                                style={{
                                    width: '100%',
                                    float: 'left',
                                    borderBottom: borderrr,
                                }}
                            >
                                <div
                                    style={{ width: '100%', float: 'left', marginBottom: '10px' }}
                                >
                                    <div style={{ width: '33.33%', float: 'left' }}>
                                        <h5 style={{ fontSize: '13px', marginBottom: '10px' }}>
                                            RAC Store
                                        </h5>
                                        <p
                                            style={{
                                                textTransform: 'uppercase',
                                                fontSize: '13px',
                                                marginTop: '10px',
                                                marginBottom: '10px',
                                            }}
                                        >
                                            {printObjvalue.storeName}
                                        </p>
                                        <>
                                            {printObjvalue.storeAddressLine2 ? (
                                                <p
                                                    style={{
                                                        textTransform: 'uppercase',
                                                        fontSize: '13px',
                                                        marginTop: '10px',
                                                        marginBottom: '10px',
                                                    }}
                                                >
                                                    {printObjvalue.storeAddressLine1 +
                                                        ' , ' +
                                                        printObjvalue.storeAddressLine2}{' '}                        </p>
                                            ) : (
                                                <p
                                                    style={{
                                                        textTransform: 'uppercase',
                                                        fontSize: '13px',
                                                        marginTop: '10px',
                                                        marginBottom: '10px',
                                                    }}
                                                >
                                                    {printObjvalue.storeAddressLine1}
                                                </p>
                                            )}
                                        </>
                                        <p
                                            style={{
                                                textTransform: 'uppercase',
                                                fontSize: '13px',
                                                marginTop: '10px',
                                                marginBottom: '10px',
                                            }}
                                        >
                                            {printObjvalue.storeCity + ',' + printObjvalue.storeZip}
                                        </p>
                                        <p
                                            style={{
                                                fontSize: '13px',
                                                marginTop: '10px',
                                                marginBottom: '10px',
                                            }}
                                        >
                                            {normalizeinput(printObjvalue.storePhoneNumber)}{' '}
                                        </p>
                                    </div>
                                    <div style={{ width: '30.33%', float: 'left' }}>
                                        <h5 style={{ fontSize: '13px', marginBottom: '10px' }}>
                                            Customer
                                        </h5>
                                        <p
                                            style={{
                                                textTransform: 'uppercase',
                                                fontSize: '13px',
                                                marginTop: '10px',
                                                marginBottom: '10px',
                                            }}
                                        >
                                            {printObjvalue?.customerName}{' '}
                                        </p>
                                        {printObjvalue?.addressLine2 ? (
                                            <p
                                                style={{
                                                    textTransform: 'uppercase',
                                                    fontSize: '13px',
                                                    marginTop: '10px',
                                                    marginBottom: '10px',
                                                }}
                                            >
                                                {printObjvalue?.addressLine1 +
                                                    ',' +
                                                    printObjvalue?.addressLine2}{' '}                      </p>
                                        ) : (
                                            <p
                                                style={{
                                                    textTransform: 'uppercase',
                                                    fontSize: '13px',
                                                    marginTop: '10px',
                                                    marginBottom: '10px',
                                                }}
                                            >
                                                {printObjvalue?.addressLine1}

                                            </p>
                                        )}

                                        {printObjvalue?.customerPlus4 ? (
                                            <p
                                                style={{
                                                    textTransform: 'uppercase',
                                                    fontSize: '13px',
                                                    marginTop: '10px',
                                                    marginBottom: '10px',
                                                }}
                                            >
                                                {printObjvalue.customerCity +
                                                    ',' +
                                                    printObjvalue.customerZip +
                                                    '-' +
                                                    printObjvalue.customerPlus4}{' '}                      </p>
                                        ) : (
                                            <p
                                                style={{
                                                    textTransform: 'uppercase',
                                                    fontSize: '13px',
                                                    marginTop: '10px',
                                                    marginBottom: '10px',
                                                }}
                                            >
                                                {printObjvalue.customerCity +
                                                    ',' +
                                                    printObjvalue.customerZip}                      </p>
                                        )}
                                        <p
                                            style={{
                                                fontSize: '13px',
                                                marginTop: '10px',
                                                marginBottom: '10px',
                                            }}
                                        >
                                            {normalizeinput(printObjvalue.customerPhoneNumber)}

                                        </p>
                                    </div>
                                    <div
                                        style={{
                                            width: '36.33%',
                                            float: 'left',
                                            marginTop: '12px',
                                        }}
                                    >
                                        <table>
                                            <thead />
                                            <tbody style={{ width: '100%', float: 'left' }}>
                                                <tr
                                                    style={{
                                                        width: '50%',
                                                        textAlign: 'left',
                                                        float: 'left',
                                                    }}
                                                >
                                                    <td
                                                        style={{
                                                            marginBottom: '0.2rem',
                                                            color: '#111111',
                                                            fontSize: '13px',
                                                            fontWeight: 'bold',
                                                        }}
                                                    >
                                                        Delivery Date:
                                                    </td>
                                                </tr>
                                                <tr
                                                    style={{
                                                        width: '40%',
                                                        textAlign: 'left',
                                                        float: 'left',
                                                    }}
                                                >
                                                    <td
                                                        style={{
                                                            marginTop: '5px',
                                                            color: '#656565',
                                                            fontSize: '13px',
                                                        }}
                                                    >
                                                        {appointmentDate}
                                                    </td>
                                                </tr>
                                                <tr
                                                    style={{
                                                        width: '50%',
                                                        marginTop: '10px',
                                                        textAlign: 'left',
                                                        float: 'left',
                                                    }}
                                                >
                                                    <td
                                                        style={{
                                                            marginBottom: '0.2rem',
                                                            color: '#111111',
                                                            fontSize: '13px',
                                                            fontWeight: 'bold',
                                                        }}
                                                    >
                                                        Agreement # :
                                                    </td>
                                                </tr>
                                                <tr
                                                    style={{
                                                        width: '50%',
                                                        marginTop: '10px',
                                                        textAlign: 'left',
                                                        float: 'left',
                                                    }}
                                                >
                                                    <td
                                                        style={{
                                                            marginTop: '5px',
                                                            color: '#656565',
                                                            fontSize: '13px',
                                                        }}
                                                    >
                                                        {printObjvalue.agreementId}
                                                    </td>
                                                </tr>
                                                <tr
                                                    style={{
                                                        width: '50%',
                                                        marginTop: '10px',
                                                        textAlign: 'left',
                                                        float: 'left',
                                                    }}
                                                >
                                                    <td
                                                        style={{
                                                            marginBottom: '0.2rem',
                                                            color: '#111111',
                                                            fontSize: '13px',
                                                            fontWeight: 'bold',
                                                        }}
                                                    >
                                                        Customer # :
                                                    </td>
                                                </tr>
                                                <tr
                                                    style={{
                                                        width: '40%',
                                                        marginTop: '10px',
                                                        textAlign: 'left',
                                                        float: 'left',
                                                    }}
                                                >
                                                    <td
                                                        style={{
                                                            marginTop: '5px',
                                                            color: '#656565',
                                                            fontSize: '13px',
                                                        }}
                                                    >
                                                        {printObjvalue.customerId}
                                                    </td>
                                                </tr>
                                                <tr
                                                    style={{
                                                        width: '50%',
                                                        marginTop: '10px',
                                                        textAlign: 'left',
                                                        float: 'left',
                                                    }}
                                                >
                                                    <td
                                                        style={{
                                                            marginBottom: '0.2rem',
                                                            color: '#111111',
                                                            fontSize: '13px',
                                                            fontWeight: 'bold',
                                                        }}
                                                    >
                                                        Next Payment Due:
                                                    </td>
                                                </tr>
                                                <tr
                                                    style={{
                                                        width: '40%',
                                                        marginTop: '10px',
                                                        textAlign: 'left',
                                                        float: 'left',
                                                    }}
                                                >
                                                    <td
                                                        style={{
                                                            marginTop: '5px',
                                                            color: '#656565',
                                                            fontSize: '13px',
                                                        }}
                                                    >
                                                        {/* {nextpaymentinfo.nextamountdue !== undefined &&
                                nextpaymentinfo.nextamountdue !== null
                                  ? '$' + ' ' + nextpaymentinfo.nextamountdue
                                  : ''} */}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            {/*second part ends here */}
                            {/*3rd part starts*/}
                            <div
                                style={{
                                    width: '100%',
                                    float: 'left',
                                    borderBottom: borderrr,
                                }}
                            >
                                <div
                                    style={{ width: '100%', float: 'left', marginTop: '15px' }}
                                >
                                    <h5
                                        style={{
                                            textTransform: 'uppercase',
                                            textAlign: 'center',
                                            fontSize: '13px',
                                            margin: '0px',
                                            marginBottom: '10px',
                                        }}
                                    >
                                        Items
                                    </h5>
                                </div>
                                <div
                                    style={{ width: '100%', float: 'left', marginBottom: '10px' }}
                                >
                                    <table style={{ marginBottom: '20px', fontSize: '12px' }}>
                                        <thead>
                                            <tr style={{ width: '100%' }}>
                                                <th
                                                    style={{
                                                        width: '15px',
                                                        textAlign: 'left',
                                                        paddingLeft: '12px',
                                                        paddingRight: '12px',
                                                        paddingTop: '5px',
                                                        paddingBottom: '5px',
                                                    }}
                                                ></th>
                                                <th
                                                    style={{
                                                        width: '300px',
                                                        textAlign: 'left',
                                                        paddingLeft: '12px',
                                                        paddingRight: '12px',
                                                        paddingTop: '5px',
                                                        paddingBottom: '5px',
                                                    }}
                                                >
                                                    Description of Property
                                                </th>
                                                <th
                                                    style={{
                                                        width: '300px',
                                                        textAlign: 'left',
                                                        paddingLeft: '12px',
                                                        paddingRight: '12px',
                                                        paddingTop: '5px',
                                                        paddingBottom: '5px',
                                                    }}
                                                >
                                                    Serial #
                                                </th>
                                                <th
                                                    style={{
                                                        width: '300px',
                                                        textAlign: 'left',
                                                        paddingLeft: '12px',
                                                        paddingRight: '12px',
                                                        paddingTop: '5px',
                                                        paddingBottom: '5px',
                                                    }}
                                                >
                                                    Item #
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>{bindChecklist()}</tbody>
                                    </table>
                                </div>
                            </div>
                            {/*3rd part ends*/}
                            {/*fourth part starts*/}
                            <div
                                style={{
                                    width: '100%',
                                    float: 'left',
                                    borderBottom: borderrr,
                                    marginBottom: '10px',
                                }}
                            >
                                <div style={{ width: '100%', float: 'left' }}>
                                    <p
                                        style={{
                                            fontSize: '14px',
                                            fontWeight: 'bold',
                                            marginBottom: '10px',
                                            textAlign: 'center',
                                            textDecorationLine: 'underline',
                                        }}
                                    >
                                        Before merchandise is taken from the customer&apos; home,
                                        please check the condition of the following areas and note
                                        any damage:
                                    </p>
                                </div>
                                <div
                                    style={{
                                        width: '100%',
                                        float: 'left',
                                        marginBottom: '10px',
                                        marginTop: '30px',
                                    }}
                                >
                                    <div style={{ width: '15%', float: 'left' }}>
                                        <span
                                            style={{
                                                padding: PaddingConst,
                                                borderRadius: borderRadiuss,
                                                border: Constinter,
                                                marginRight: '10px',
                                            }}
                                        />
                                        <span style={{ fontSize: '12px', fontWeight: 'bold' }}>
                                            Wall(s)
                                        </span>
                                    </div>
                                    <div style={{ width: '15%', float: 'left' }}>
                                        <span
                                            style={{
                                                padding: PaddingConst,
                                                borderRadius: borderRadiuss,
                                                border: borderalone,
                                                marginRight: '10px',
                                            }}
                                        />
                                        <span style={{ fontSize: '12px', fontWeight: 'bold' }}>
                                            {' '}
                                            Floors
                                        </span>
                                    </div>
                                    <div style={{ width: '22%', float: 'left' }}>
                                        <span
                                            style={{
                                                padding: PaddingConst,
                                                borderRadius: borderRadiuss,
                                                border: borderalone,
                                                marginRight: '10px',
                                            }}
                                        />
                                        <span style={{ fontSize: '12px', fontWeight: 'bold' }}>
                                            Door(s)/Doorway(s)
                                        </span>
                                    </div>
                                    <div style={{ width: '17%', float: 'left' }}>
                                        <span
                                            style={{
                                                padding: PaddingConst,
                                                borderRadius: borderRadiuss,
                                                border: borderalone,
                                                marginRight: '10px',
                                            }}
                                        />
                                        <span style={{ fontSize: '12px', fontWeight: 'bold' }}>
                                            Stairs
                                        </span>
                                    </div>
                                    <div style={{ width: '8%', float: 'left' }}>
                                        <span
                                            style={{
                                                padding: PaddingConst,
                                                borderRadius: borderRadiuss,
                                                border: borderalone,
                                                marginRight: '10px',
                                            }}
                                        />
                                        <span style={{ fontSize: '12px', fontWeight: 'bold' }}>
                                            Other
                                        </span>
                                    </div>
                                    <div
                                        style={{
                                            width: '15%',
                                            float: 'left',
                                            marginTop: '12px',
                                            marginLeft: '5px',
                                        }}
                                    >
                                        <span
                                            style={{
                                                borderBottom: '1px solid#545454',
                                                width: '100%',
                                                float: 'left',
                                            }}
                                        />
                                    </div>
                                </div>
                                <div
                                    style={{
                                        width: '100%',
                                        float: 'left',
                                        marginTop: '10px',
                                        marginBottom: '10px',
                                    }}
                                >
                                    <div style={{ width: '40%', float: 'left' }}>
                                        <p
                                            style={{
                                                fontSize: '13px',
                                                marginTop: '10px',
                                                marginBottom: '10px',
                                            }}
                                        >
                                            If any boxes were checked, explain damage:
                                        </p>
                                    </div>
                                    <div
                                        style={{ width: '60%', float: 'right', marginTop: '20px' }}
                                    >
                                        <span
                                            style={{
                                                borderBottom: '1px solid#545454',
                                                width: '100%',
                                                float: 'left',
                                            }}
                                        />
                                    </div>
                                </div>
                                <div
                                    style={{
                                        width: '100%',
                                        float: 'left',
                                        marginTop: '10px',
                                        marginBottom: '20px',
                                    }}
                                >
                                    <div style={{ width: '100%', float: 'left' }}>
                                        <h5
                                            style={{
                                                textAlign: 'left',
                                                fontSize: '14px',
                                                marginTop: '0px',
                                                textDecoration: 'underline',
                                            }}
                                        >
                                            Accept / Decline (Check one)
                                        </h5>
                                    </div>
                                    <div
                                        style={{
                                            width: '100%',
                                            float: 'left',
                                            marginBottom: '10px',
                                        }}
                                    >
                                        <span
                                            style={{
                                                padding: PaddingConst,
                                                borderRadius: borderRadiuss,
                                                border: Constinter,
                                                marginRight: '10px',
                                            }}
                                        />
                                        <span
                                            style={{
                                                fontSize: '12px',
                                                marginTop: '10px',
                                                marginBottom: '10px',
                                            }}
                                        >
                                            I acknowledge that Rent-A-Center has delivered, installed
                                            and demonstrated, to my satisfaction, the rental property
                                            listed above.
                                        </span>
                                    </div>
                                    <div
                                        style={{ width: '100%', float: 'left', marginTop: '10px' }}
                                    >
                                        <span
                                            style={{
                                                padding: PaddingConst,
                                                borderRadius: borderRadiuss,
                                                border: Constinter,
                                                marginRight: '10px',
                                            }}
                                        />
                                        <span style={{ fontSize: '12px', marginBottom: '10px' }}>
                                            I decline delivery by Rent-A-Center of the rental property
                                            listed above.
                                        </span>
                                    </div>
                                </div>
                            </div>
                            {/*-fourth part ends*/}
                            {/*fifth part starts*/}
                            <div
                                style={{
                                    width: '100%',
                                    float: 'left',
                                    marginBottom: '20px',
                                    marginTop: '10px',
                                }}
                            >
                                <table style={{ width: '100%', float: 'left' }}>
                                    <thead />
                                    <tbody style={{ width: '100%', float: 'left' }}>
                                        <tr
                                            style={{
                                                width: '18%',
                                                float: 'left',
                                                whiteSpace: 'nowrap',
                                            }}
                                        >
                                            <td
                                                style={{
                                                    marginBottom: '0.2rem',
                                                    color: '#111111',
                                                    fontSize: '13px',
                                                    fontWeight: 'bold',
                                                }}
                                            >
                                                Customer Signature:
                                            </td>
                                        </tr>
                                        <tr
                                            style={{
                                                width: '28%',
                                                float: 'left',
                                                paddingRight: '25px',
                                                whiteSpace: 'nowrap',
                                                marginLeft: '8px',
                                            }}
                                        >
                                            <td
                                                style={{
                                                    marginTop: '18px',
                                                    color: '#656565',
                                                    borderBottom: borderbotttom,
                                                    width: '100%',
                                                    float: 'left',
                                                }}
                                            />
                                        </tr>
                                        <tr
                                            style={{
                                                width: '8%',
                                                float: 'left',
                                                whiteSpace: 'nowrap',
                                            }}
                                        >
                                            <td
                                                style={{
                                                    marginBottom: '0.2rem',
                                                    color: '#111111',
                                                    fontSize: '13px',
                                                    fontWeight: 'bold',
                                                }}
                                            >
                                                Date:
                                            </td>
                                        </tr>
                                        <tr
                                            style={{
                                                width: '32%',
                                                float: 'left',
                                                whiteSpace: 'nowrap',
                                            }}
                                        >
                                            <td
                                                style={{
                                                    marginTop: '15px',
                                                    color: '#656565',
                                                    borderBottom: borderbotttom,
                                                    width: '100%',
                                                    float: 'left',
                                                }}
                                            />
                                        </tr>
                                    </tbody>
                                </table>
                                <table
                                    style={{ width: '100%', float: 'left', marginTop: '25px' }}
                                >
                                    <thead />
                                    <tbody style={{ width: '100%', float: 'left' }}>
                                        <tr
                                            style={{
                                                width: '18%',
                                                float: 'left',
                                                whiteSpace: 'nowrap',
                                            }}
                                        >
                                            <td
                                                style={{
                                                    marginBottom: '0.2rem',
                                                    color: '#111111',
                                                    fontSize: '13px',
                                                    fontWeight: 'bold',
                                                }}
                                            >
                                                Co-Worker Signature:
                                            </td>
                                        </tr>
                                        <tr
                                            style={{
                                                width: '28%',
                                                float: 'left',
                                                paddingRight: '25px',
                                                whiteSpace: 'nowrap',
                                                marginLeft: '8px',
                                            }}
                                        >
                                            <td
                                                style={{
                                                    marginTop: '18px',
                                                    color: '#656565',
                                                    borderBottom: borderbotttom,
                                                    width: '100%',
                                                    float: 'left',
                                                }}
                                            />
                                        </tr>
                                        <tr
                                            style={{
                                                width: '8%',
                                                float: 'left',
                                                whiteSpace: 'nowrap',
                                            }}
                                        >
                                            <td
                                                style={{
                                                    marginBottom: '0.2rem',
                                                    color: '#111111',
                                                    fontSize: '13px',
                                                    fontWeight: 'bold',
                                                }}
                                            >
                                                Date:
                                            </td>
                                        </tr>
                                        <tr
                                            style={{
                                                width: '32%',
                                                float: 'left',
                                                whiteSpace: 'nowrap',
                                            }}
                                        >
                                            <td
                                                style={{
                                                    marginTop: '15px',
                                                    color: '#656565',
                                                    borderBottom: borderbotttom,
                                                    width: '100%',
                                                    float: 'left',
                                                }}
                                            />
                                        </tr>
                                    </tbody>
                                </table>
                                {/*fifth part ends*/}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* service Delivery receipt inner html ends here */}

            {/* service pickup checklist inner html starts here */}
            <div style={{ display: 'none' }}>
                <div
                    style={{
                        borderTop: '10px solid #2179FE',
                        margin: '0px',
                        width: '100%',
                        float: 'left',
                    }}
                    id="ServicePickupChecklist"
                >
                    <div
                        style={{
                            width: '100%',
                            fontFamily: 'Arial',
                            margin: '10px auto 0px auto',
                        }}
                    >
                        {/*first part starts here*/}
                        <div style={{ width: '100%', float: 'left' }}>
                            <div
                                style={{ width: '100%', fontFamily: 'Arial', float: 'left' }}
                            >
                                <div style={{ width: '20.33%', float: 'left' }}>
                                    <h5 style={{ textTransform: 'uppercase', fontSize: '15px' }}>
                                        Service Pickup Checklist
                                    </h5>
                                </div>
                                <div
                                    style={{
                                        width: '13.33%',
                                        marginLeft: '2%',
                                        marginRight: '2%',
                                        float: 'left',
                                        height: '100px',
                                        marginBottom: '10px',
                                    }}
                                >
                                    <Logo style={{ width: '100%', height: '100%' }}></Logo>
                                </div>
                                <div></div>
                                <table
                                    style={{ width: '62.33%', float: 'left', marginTop: '30px' }}
                                >
                                    <thead />
                                    <tbody style={{ width: '100%', float: 'left' }}>
                                        {/*child-parent of first widget*/}
                                        <tr
                                            style={{ width: '24%', float: 'left', textAlign: 'left' }}
                                        >
                                            <td
                                                style={{
                                                    marginBottom: '0.2rem',
                                                    color: '#111111',
                                                    fontSize: '13px',
                                                    fontWeight: 'bold',
                                                }}
                                            >
                                                Pickup Date:
                                            </td>
                                        </tr>
                                        <tr
                                            style={{ width: '24%', float: 'left', textAlign: 'left' }}
                                        >
                                            <td>
                                                <span
                                                    style={{
                                                        marginTop: '5px',
                                                        color: '#656565',
                                                        borderBottom: borderbotttom,
                                                        fontSize: '13px',
                                                    }}
                                                >
                                                    {appointmentDate}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr
                                            style={{ width: '22%', float: 'left', textAlign: 'left' }}
                                        >
                                            <td
                                                style={{
                                                    marginBottom: '0.2rem',
                                                    color: '#111111',
                                                    fontSize: '13px',
                                                    fontWeight: 'bold',
                                                }}
                                            >
                                                Pickup Time:
                                            </td>
                                        </tr>
                                        <tr
                                            style={{ width: '30%', float: 'left', textAlign: 'left' }}
                                        >
                                            <td>
                                                <span
                                                    style={{
                                                        marginTop: '5px',
                                                        color: '#656565',
                                                        borderBottom: borderbotttom,
                                                        fontSize: '13px',
                                                    }}
                                                >
                                                    {deliveryTime}
                                                </span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            {/*first part ends here*/}
                            {/*second part starts here */}
                            <div
                                style={{
                                    width: '100%',
                                    float: 'left',
                                    borderBottom: borderrr,
                                }}
                            >
                                <div
                                    style={{ width: '100%', float: 'left', marginBottom: '10px' }}
                                >
                                    <div style={{ width: '33.33%', float: 'left' }}>
                                        <h5 style={{ fontSize: '13px', marginBottom: '10px' }}>
                                            RAC Store
                                        </h5>
                                        <p
                                            style={{
                                                textTransform: 'uppercase',
                                                fontSize: '13px',
                                                marginTop: '10px',
                                                marginBottom: '10px',
                                            }}
                                        >
                                            {printObjvalue?.storeName}
                                        </p>
                                        <>
                                            {printObjvalue?.storeAddressLine2 ? (
                                                <p
                                                    style={{
                                                        textTransform: 'uppercase',
                                                        fontSize: '13px',
                                                        marginTop: '10px',
                                                        marginBottom: '10px',
                                                    }}
                                                >
                                                    {printObjvalue?.storeAddressLine1 +
                                                        ' , ' +
                                                        printObjvalue?.storeAddressLine2}                        </p>
                                            ) : (
                                                <p
                                                    style={{
                                                        textTransform: 'uppercase',
                                                        fontSize: '13px',
                                                        marginTop: '10px',
                                                        marginBottom: '10px',
                                                    }}
                                                >
                                                    {printObjvalue?.storeAddressLine1}
                                                </p>
                                            )}
                                        </>
                                        <p
                                            style={{
                                                textTransform: 'uppercase',
                                                fontSize: '13px',
                                                marginTop: '10px',
                                                marginBottom: '10px',
                                            }}
                                        >
                                            {printObjvalue?.storeCity + ',' + printObjvalue?.storeZip}
                                        </p>
                                        <p
                                            style={{
                                                fontSize: '13px',
                                                marginTop: '10px',
                                                marginBottom: '10px',
                                            }}
                                        >
                                            {normalizeinput(printObjvalue?.storePhoneNumber)}
                                        </p>
                                    </div>
                                    <div style={{ width: '30.33%', float: 'left' }}>
                                        <h5 style={{ fontSize: '13px', marginBottom: '10px' }}>
                                            Customer
                                        </h5>
                                        <p
                                            style={{
                                                textTransform: 'uppercase',
                                                fontSize: '13px',
                                                marginTop: '10px',
                                                marginBottom: '10px',
                                            }}
                                        >
                                            {printObjvalue?.customerName}
                                        </p>
                                        {printObjvalue?.addressLine2 ? (
                                            <p
                                                style={{
                                                    textTransform: 'uppercase',
                                                    fontSize: '13px',
                                                    marginTop: '10px',
                                                    marginBottom: '10px',
                                                }}
                                            >
                                                {printObjvalue?.addressLine1 +
                                                    ',' +
                                                    printObjvalue?.addressLine2}                      </p>
                                        ) : (
                                            <p
                                                style={{
                                                    textTransform: 'uppercase',
                                                    fontSize: '13px',
                                                    marginTop: '10px',
                                                    marginBottom: '10px',
                                                }}
                                            >
                                                {printObjvalue?.addressLine1}
                                            </p>
                                        )}
                                        {printObjvalue?.customerPlus4 ? (
                                            <p
                                                style={{
                                                    textTransform: 'uppercase',
                                                    fontSize: '13px',
                                                    marginTop: '10px',
                                                    marginBottom: '10px',
                                                }}
                                            >
                                                {printObjvalue.customerCity +
                                                    ',' +
                                                    printObjvalue.customerZip +
                                                    '-' +
                                                    printObjvalue.customerPlus4}                      </p>
                                        ) : (
                                            <p
                                                style={{
                                                    textTransform: 'uppercase',
                                                    fontSize: '13px',
                                                    marginTop: '10px',
                                                    marginBottom: '10px',
                                                }}
                                            >
                                                {printObjvalue.customerCity +
                                                    ',' +
                                                    printObjvalue.customerZip}
                                            </p>
                                        )}
                                        <p
                                            style={{
                                                fontSize: '13px',
                                                marginTop: '10px',
                                                marginBottom: '10px',
                                            }}
                                        >
                                            {normalizeinput(printObjvalue.customerPhoneNumber)}
                                        </p>
                                    </div>
                                    <div
                                        style={{
                                            width: '36.33%',
                                            float: 'left',
                                            marginTop: '12px',
                                        }}
                                    >
                                        <table>
                                            <thead />
                                            <tbody style={{ width: '100%', float: 'left' }}>
                                                <tr
                                                    style={{
                                                        width: '50%',
                                                        textAlign: 'left',
                                                        float: 'left',
                                                    }}
                                                >
                                                    <td
                                                        style={{
                                                            marginBottom: '0.2rem',
                                                            color: '#111111',
                                                            fontSize: '13px',
                                                            fontWeight: 'bold',
                                                        }}
                                                    >
                                                        Date:
                                                    </td>
                                                </tr>
                                                <tr
                                                    style={{
                                                        width: '40%',
                                                        textAlign: 'left',
                                                        float: 'left',
                                                    }}
                                                >
                                                    <td
                                                        style={{
                                                            marginTop: '5px',
                                                            color: '#656565',
                                                            fontSize: '13px',
                                                        }}
                                                    >
                                                        {moment(new Date()).format('MM/DD/YYYY')}
                                                    </td>
                                                </tr>
                                                <tr
                                                    style={{
                                                        width: '50%',
                                                        marginTop: '10px',
                                                        textAlign: 'left',
                                                        float: 'left',
                                                    }}
                                                >
                                                    <td
                                                        style={{
                                                            marginBottom: '0.2rem',
                                                            color: '#111111',
                                                            fontSize: '13px',
                                                            fontWeight: 'bold',
                                                        }}
                                                    >
                                                        Agreement # :
                                                    </td>
                                                </tr>
                                                <tr
                                                    style={{
                                                        width: '50%',
                                                        marginTop: '10px',
                                                        textAlign: 'left',
                                                        float: 'left',
                                                    }}
                                                >
                                                    <td
                                                        style={{
                                                            marginTop: '5px',
                                                            color: '#656565',
                                                            fontSize: '13px',
                                                        }}
                                                    >
                                                        {printObjvalue.agreementId}
                                                    </td>
                                                </tr>
                                                <tr
                                                    style={{
                                                        width: '50%',
                                                        marginTop: '10px',
                                                        textAlign: 'left',
                                                        float: 'left',
                                                    }}
                                                >
                                                    <td
                                                        style={{
                                                            marginBottom: '0.2rem',
                                                            color: '#111111',
                                                            fontSize: '13px',
                                                            fontWeight: 'bold',
                                                        }}
                                                    >
                                                        Customer # :
                                                    </td>
                                                </tr>
                                                <tr
                                                    style={{
                                                        width: '40%',
                                                        marginTop: '10px',
                                                        textAlign: 'left',
                                                        float: 'left',
                                                    }}
                                                >
                                                    <td
                                                        style={{
                                                            marginTop: '5px',
                                                            color: '#656565',
                                                            fontSize: '13px',
                                                        }}
                                                    >
                                                        {printObjvalue.customerId}
                                                    </td>
                                                </tr>
                                                <tr
                                                    style={{
                                                        width: '50%',
                                                        marginTop: '10px',
                                                        textAlign: 'left',
                                                        float: 'left',
                                                    }}
                                                >
                                                    <td
                                                        style={{
                                                            marginBottom: '0.2rem',
                                                            color: '#111111',
                                                            fontSize: '13px',
                                                            fontWeight: 'bold',
                                                        }}
                                                    >
                                                        Due Date:
                                                    </td>
                                                </tr>
                                                <tr
                                                    style={{
                                                        width: '40%',
                                                        marginTop: '10px',
                                                        textAlign: 'left',
                                                        float: 'left',
                                                    }}
                                                >
                                                    <td
                                                        style={{
                                                            marginTop: '5px',
                                                            color: '#656565',
                                                            fontSize: '13px',
                                                        }}
                                                    >
                                                        {/* {nextpaymentinfo.nextduedate !== undefined &&
                                nextpaymentinfo.nextduedate !== null
                                  ? nextpaymentinfo.nextduedate
                                  : ''} */}
                                                    </td>
                                                </tr>
                                                <tr
                                                    style={{
                                                        width: '50%',
                                                        marginTop: '10px',
                                                        textAlign: 'left',
                                                        float: 'left',
                                                    }}
                                                >
                                                    <td
                                                        style={{
                                                            marginBottom: '0.2rem',
                                                            color: '#111111',
                                                            fontSize: '13px',
                                                            fontWeight: 'bold',
                                                        }}
                                                    >
                                                        Back Rent:
                                                    </td>
                                                </tr>
                                                <tr
                                                    style={{
                                                        width: '40%',
                                                        marginTop: '10px',
                                                        textAlign: 'left',
                                                        float: 'left',
                                                    }}
                                                >
                                                    <td
                                                        style={{
                                                            marginTop: '5px',
                                                            color: '#656565',
                                                            fontSize: '13px',
                                                        }}
                                                    >
                                                        $ 0.00
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            {/*second part ends here */}
                            {/*3rd part starts*/}
                            <div
                                style={{
                                    width: '100%',
                                    float: 'left',
                                    borderBottom: borderrr,
                                }}
                            >
                                <div
                                    style={{ width: '100%', float: 'left', marginTop: '15px' }}
                                >
                                    <h5
                                        style={{
                                            textTransform: 'uppercase',
                                            textAlign: 'center',
                                            fontSize: '13px',
                                            margin: '0px',
                                            marginBottom: '10px',
                                        }}
                                    >
                                        PICKUP CHECKLIST
                                    </h5>
                                </div>
                                <div
                                    style={{ width: '100%', float: 'left', marginBottom: '10px' }}
                                >
                                    <table style={{ marginBottom: '20px', fontSize: '12px' }}>
                                        <thead>
                                            <tr style={{ width: '100%' }}>
                                                <th
                                                    style={{
                                                        width: '15px',
                                                        textAlign: 'left',
                                                        paddingLeft: '12px',
                                                        paddingRight: '12px',
                                                        paddingTop: '5px',
                                                        paddingBottom: '5px',
                                                    }}
                                                ></th>
                                                <th
                                                    style={{
                                                        width: '300px',
                                                        textAlign: 'left',
                                                        paddingLeft: '12px',
                                                        paddingRight: '12px',
                                                        paddingTop: '5px',
                                                        paddingBottom: '5px',
                                                    }}
                                                >
                                                    Description of Property
                                                </th>
                                                <th
                                                    style={{
                                                        width: '300px',
                                                        textAlign: 'left',
                                                        paddingLeft: '12px',
                                                        paddingRight: '12px',
                                                        paddingTop: '5px',
                                                        paddingBottom: '5px',
                                                    }}
                                                >
                                                    Serial #
                                                </th>
                                                <th
                                                    style={{
                                                        width: '300px',
                                                        textAlign: 'left',
                                                        paddingLeft: '12px',
                                                        paddingRight: '12px',
                                                        paddingTop: '5px',
                                                        paddingBottom: '5px',
                                                    }}
                                                >
                                                    Item #
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {bindChecklist()}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            {/*3rd part ends*/}
                            {/* fourth part starts here */}
                            <table>
                                <thead />
                                <tbody>
                                    <tr>
                                        <td>
                                            <div
                                                style={{
                                                    width: '100%',
                                                    float: 'left',
                                                    marginBottom: '250px',
                                                    marginTop: '10px',
                                                }}
                                            >
                                                <h5
                                                    style={{
                                                        fontSize: '14px',
                                                        textDecoration: 'underline',
                                                        float: 'left',
                                                    }}
                                                >
                                                    Pickup Information
                                                </h5>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            {/* fourth part ends here */}

                            {/*fifth part starts*/}
                            <div
                                style={{
                                    width: '100%',
                                    float: 'left',
                                    borderBottom: borderrr,
                                    marginBottom: '10px',
                                }}
                            >
                                <div style={{ width: '100%', float: 'left' }}>
                                    <p
                                        style={{
                                            fontSize: '14px',
                                            fontWeight: 'bold',
                                            marginBottom: '10px',
                                            textAlign: 'center',
                                            textDecorationLine: 'underline',
                                        }}
                                    >
                                        Before merchandise is taken from the customer&apos; home,
                                        please check the condition of the following areas and note
                                        any damage:
                                    </p>
                                </div>
                                <div
                                    style={{
                                        width: '100%',
                                        float: 'left',
                                        marginBottom: '10px',
                                        marginTop: '30px',
                                    }}
                                >
                                    <div style={{ width: '15%', float: 'left' }}>
                                        <span
                                            style={{
                                                padding: PaddingConst,
                                                borderRadius: borderRadiuss,
                                                border: Constinter,
                                                marginRight: '10px',
                                            }}
                                        />
                                        <span style={{ fontSize: '12px', fontWeight: 'bold' }}>
                                            Wall(s)
                                        </span>
                                    </div>
                                    <div style={{ width: '15%', float: 'left' }}>
                                        <span
                                            style={{
                                                padding: PaddingConst,
                                                borderRadius: borderRadiuss,
                                                border: borderalone,
                                                marginRight: '10px',
                                            }}
                                        />
                                        <span style={{ fontSize: '12px', fontWeight: 'bold' }}>
                                            {' '}
                                            Floors
                                        </span>
                                    </div>
                                    <div style={{ width: '22%', float: 'left' }}>
                                        <span
                                            style={{
                                                padding: PaddingConst,
                                                borderRadius: borderRadiuss,
                                                border: borderalone,
                                                marginRight: '10px',
                                            }}
                                        />
                                        <span style={{ fontSize: '12px', fontWeight: 'bold' }}>
                                            Door(s)/Doorway(s)
                                        </span>
                                    </div>
                                    <div style={{ width: '17%', float: 'left' }}>
                                        <span
                                            style={{
                                                padding: PaddingConst,
                                                borderRadius: borderRadiuss,
                                                border: borderalone,
                                                marginRight: '10px',
                                            }}
                                        />
                                        <span style={{ fontSize: '12px', fontWeight: 'bold' }}>
                                            Stairs
                                        </span>
                                    </div>
                                    <div style={{ width: '8%', float: 'left' }}>
                                        <span
                                            style={{
                                                padding: PaddingConst,
                                                borderRadius: borderRadiuss,
                                                border: borderalone,
                                                marginRight: '10px',
                                            }}
                                        />
                                        <span style={{ fontSize: '12px', fontWeight: 'bold' }}>
                                            Other
                                        </span>
                                    </div>
                                    <div
                                        style={{
                                            width: '15%',
                                            float: 'left',
                                            marginTop: '12px',
                                            marginLeft: '5px',
                                        }}
                                    >
                                        <span
                                            style={{
                                                borderBottom: '1px solid#545454',
                                                width: '100%',
                                                float: 'left',
                                            }}
                                        />
                                    </div>
                                </div>
                                <div
                                    style={{
                                        width: '100%',
                                        float: 'left',
                                        marginTop: '10px',
                                        marginBottom: '10px',
                                    }}
                                >
                                    <div style={{ width: '40%', float: 'left' }}>
                                        <p
                                            style={{
                                                fontSize: '13px',
                                                marginTop: '10px',
                                                marginBottom: '10px',
                                            }}
                                        >
                                            If any boxes were checked, explain damage:
                                        </p>
                                    </div>
                                    <div
                                        style={{ width: '60%', float: 'right', marginTop: '20px' }}
                                    >
                                        <span
                                            style={{
                                                borderBottom: '1px solid#545454',
                                                width: '100%',
                                                float: 'left',
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            {/*-fifth part ends*/}
                            {/*sixth part starts*/}
                            <div
                                style={{
                                    width: '100%',
                                    float: 'left',
                                    marginBottom: '20px',
                                    marginTop: '10px',
                                }}
                            >
                                <table style={{ width: '100%', float: 'left' }}>
                                    <thead />
                                    <tbody style={{ width: '100%', float: 'left' }}>
                                        <tr
                                            style={{
                                                width: '18%',
                                                float: 'left',
                                                whiteSpace: 'nowrap',
                                            }}
                                        >
                                            <td
                                                style={{
                                                    marginBottom: '0.2rem',
                                                    color: '#111111',
                                                    fontSize: '13px',
                                                    fontWeight: 'bold',
                                                }}
                                            >
                                                Customer Signature:
                                            </td>
                                        </tr>
                                        <tr
                                            style={{
                                                width: '28%',
                                                float: 'left',
                                                paddingRight: '25px',
                                                whiteSpace: 'nowrap',
                                                marginLeft: '8px',
                                            }}
                                        >
                                            <td
                                                style={{
                                                    marginTop: '18px',
                                                    color: '#656565',
                                                    borderBottom: borderbotttom,
                                                    width: '100%',
                                                    float: 'left',
                                                }}
                                            />
                                        </tr>
                                        <tr
                                            style={{
                                                width: '8%',
                                                float: 'left',
                                                whiteSpace: 'nowrap',
                                            }}
                                        >
                                            <td
                                                style={{
                                                    marginBottom: '0.2rem',
                                                    color: '#111111',
                                                    fontSize: '13px',
                                                    fontWeight: 'bold',
                                                }}
                                            >
                                                Date:
                                            </td>
                                        </tr>
                                        <tr
                                            style={{
                                                width: '32%',
                                                float: 'left',
                                                whiteSpace: 'nowrap',
                                            }}
                                        >
                                            <td
                                                style={{
                                                    marginTop: '15px',
                                                    color: '#656565',
                                                    borderBottom: borderbotttom,
                                                    width: '100%',
                                                    float: 'left',
                                                }}

                                            />
                                        </tr>
                                    </tbody>
                                </table>
                                <table
                                    style={{ width: '100%', float: 'left', marginTop: '25px' }}
                                >
                                    <thead />
                                    <tbody style={{ width: '100%', float: 'left' }}>
                                        <tr
                                            style={{
                                                width: '18%',
                                                float: 'left',
                                                whiteSpace: 'nowrap',
                                            }}
                                        >
                                            <td
                                                style={{
                                                    marginBottom: '0.2rem',
                                                    color: '#111111',
                                                    fontSize: '13px',
                                                    fontWeight: 'bold',
                                                }}
                                            >
                                                Co-Worker Signature:
                                            </td>
                                        </tr>
                                        <tr
                                            style={{
                                                width: '28%',
                                                float: 'left',
                                                paddingRight: '25px',
                                                whiteSpace: 'nowrap',
                                                marginLeft: '8px',
                                            }}
                                        >
                                            <td
                                                style={{
                                                    marginTop: '18px',
                                                    color: '#656565',
                                                    borderBottom: borderbotttom,
                                                    width: '100%',
                                                    float: 'left',
                                                }}
                                            />
                                        </tr>
                                        <tr
                                            style={{
                                                width: '8%',
                                                float: 'left',
                                                whiteSpace: 'nowrap',
                                            }}
                                        >
                                            <td
                                                style={{
                                                    marginBottom: '0.2rem',
                                                    color: '#111111',
                                                    fontSize: '13px',
                                                    fontWeight: 'bold',
                                                }}
                                            >
                                                Date:
                                            </td>
                                        </tr>
                                        <tr
                                            style={{
                                                width: '32%',
                                                float: 'left',
                                                whiteSpace: 'nowrap',
                                            }}
                                        >
                                            <td
                                                style={{
                                                    marginTop: '15px',
                                                    color: '#656565',
                                                    borderBottom: borderbotttom,
                                                    width: '100%',
                                                    float: 'left',
                                                }}
                                            />
                                        </tr>
                                    </tbody>
                                </table>
                                {/*sixth part ends*/}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* service pickup checklist inner html ends here */}

            {/* service pickup receipt innerhtml starts here */}
            <div style={{ display: 'none' }}>
                <div
                    style={{
                        borderTop: '10px solid #2179FE',
                        margin: '0px',
                        width: '100%',
                        float: 'left',
                    }}
                    id="ServicePickupRecipt"
                >
                    <div
                        style={{
                            width: '100%',
                            fontFamily: 'Arial',
                            margin: '10px auto 0px auto',
                        }}
                    >
                        {/*first part starts here*/}
                        <div style={{ width: '100%', float: 'left' }}>
                            <div
                                style={{ width: '100%', fontFamily: 'Arial', float: 'left' }}
                            >
                                <div style={{ width: '20.33%', float: 'left' }}>
                                    <h5 style={{ textTransform: 'uppercase', fontSize: '15px' }}>
                                        Service Pickup Receipt
                                    </h5>
                                </div>
                                <div
                                    style={{
                                        width: '13.33%',
                                        marginLeft: '2%',
                                        marginRight: '2%',
                                        float: 'left',
                                        height: '100px',
                                        marginBottom: '10px',
                                    }}
                                >
                                    <Logo style={{ width: '100%', height: '100%' }}></Logo>
                                </div>
                                <div></div>
                                <table
                                    style={{ width: '62.33%', float: 'left', marginTop: '30px' }}
                                >
                                    <thead />
                                    <tbody style={{ width: '100%', float: 'left' }}>
                                        {/*child-parent of first widget*/}
                                        <tr
                                            style={{ width: '24%', float: 'left', textAlign: 'left' }}
                                        >
                                            <td
                                                style={{
                                                    marginBottom: '0.2rem',
                                                    color: '#111111',
                                                    fontSize: '13px',
                                                    fontWeight: 'bold',
                                                }}
                                            >
                                                Pickup Date:
                                            </td>
                                        </tr>
                                        <tr
                                            style={{ width: '24%', float: 'left', textAlign: 'left' }}
                                        >
                                            <td>
                                                <span
                                                    style={{
                                                        marginTop: '5px',
                                                        color: '#656565',
                                                        borderBottom: borderbotttom,
                                                        fontSize: '13px',
                                                    }}
                                                >
                                                    {appointmentDate}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr
                                            style={{ width: '22%', float: 'left', textAlign: 'left' }}
                                        >
                                            <td
                                                style={{
                                                    marginBottom: '0.2rem',
                                                    color: '#111111',
                                                    fontSize: '13px',
                                                    fontWeight: 'bold',
                                                }}
                                            >
                                                Pickup Time:
                                            </td>
                                        </tr>
                                        <tr
                                            style={{ width: '30%', float: 'left', textAlign: 'left' }}
                                        >
                                            <td>
                                                <span
                                                    style={{
                                                        marginTop: '5px',
                                                        color: '#656565',
                                                        borderBottom: borderbotttom,
                                                        fontSize: '13px',
                                                    }}
                                                >
                                                    {deliveryTime}
                                                </span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            {/*first part ends here*/}
                            {/*second part starts here */}
                            <div
                                style={{
                                    width: '100%',
                                    float: 'left',
                                    borderBottom: borderrr,
                                }}
                            >
                                <div
                                    style={{ width: '100%', float: 'left', marginBottom: '10px' }}
                                >
                                    <div style={{ width: '33.33%', float: 'left' }}>
                                        <h5 style={{ fontSize: '13px', marginBottom: '10px' }}>
                                            RAC Store
                                        </h5>
                                        <p
                                            style={{
                                                textTransform: 'uppercase',
                                                fontSize: '13px',
                                                marginTop: '10px',
                                                marginBottom: '10px',
                                            }}
                                        >
                                            {printObjvalue?.storeName}
                                        </p>
                                        <>
                                            {printObjvalue?.storeAddressLine2 ? (
                                                <p
                                                    style={{
                                                        textTransform: 'uppercase',
                                                        fontSize: '13px',
                                                        marginTop: '10px',
                                                        marginBottom: '10px',
                                                    }}
                                                >
                                                    {printObjvalue?.storeAddressLine1 +
                                                        ' , ' +
                                                        printObjvalue?.storeAddressLine2}                        </p>
                                            ) : (
                                                <p
                                                    style={{
                                                        textTransform: 'uppercase',
                                                        fontSize: '13px',
                                                        marginTop: '10px',
                                                        marginBottom: '10px',
                                                    }}
                                                >
                                                    {printObjvalue?.storeAddressLine1}
                                                </p>
                                            )}
                                        </>

                                        <p
                                            style={{
                                                textTransform: 'uppercase',
                                                fontSize: '13px',
                                                marginTop: '10px',
                                                marginBottom: '10px',
                                            }}
                                        >
                                            {printObjvalue?.storeCity + ',' + printObjvalue?.storeZip}
                                        </p>
                                        <p
                                            style={{
                                                fontSize: '13px',
                                                marginTop: '10px',
                                                marginBottom: '10px',
                                            }}
                                        >
                                            {normalizeinput(printObjvalue?.storePhoneNumber)}
                                        </p>
                                    </div>
                                    <div style={{ width: '30.33%', float: 'left' }}>
                                        <h5 style={{ fontSize: '13px', marginBottom: '10px' }}>
                                            Customer
                                        </h5>
                                        <p
                                            style={{
                                                textTransform: 'uppercase',
                                                fontSize: '13px',
                                                marginTop: '10px',
                                                marginBottom: '10px',
                                            }}
                                        >
                                            {printObjvalue.customerName}
                                        </p>
                                        {printObjvalue?.addressLine2 ? (
                                            <p
                                                style={{
                                                    textTransform: 'uppercase',
                                                    fontSize: '13px',
                                                    marginTop: '10px',
                                                    marginBottom: '10px',
                                                }}
                                            >
                                                {printObjvalue.addressLine1 +
                                                    ',' +
                                                    printObjvalue.addressLine2}                      </p>
                                        ) : (
                                            <p
                                                style={{
                                                    textTransform: 'uppercase',
                                                    fontSize: '13px',
                                                    marginTop: '10px',
                                                    marginBottom: '10px',
                                                }}
                                            >
                                                {printObjvalue.addressLine1}
                                            </p>
                                        )}
                                        {printObjvalue.customerPlus4 ? (
                                            <p
                                                style={{
                                                    textTransform: 'uppercase',
                                                    fontSize: '13px',
                                                    marginTop: '10px',
                                                    marginBottom: '10px',
                                                }}
                                            >
                                                {printObjvalue.customerCity +
                                                    ',' +
                                                    printObjvalue.customerZip +
                                                    '-' +
                                                    printObjvalue.customerPlus4}                      </p>
                                        ) : (
                                            <p
                                                style={{
                                                    textTransform: 'uppercase',
                                                    fontSize: '13px',
                                                    marginTop: '10px',
                                                    marginBottom: '10px',
                                                }}
                                            >
                                                {printObjvalue.customerCity +
                                                    ',' +
                                                    printObjvalue.customerZip}                      </p>
                                        )}
                                        <p
                                            style={{
                                                fontSize: '13px',
                                                marginTop: '10px',
                                                marginBottom: '10px',
                                            }}
                                        >
                                            {normalizeinput(printObjvalue.customerPhoneNumber)}
                                        </p>
                                    </div>
                                    <div
                                        style={{
                                            width: '36.33%',
                                            float: 'left',
                                            marginTop: '12px',
                                        }}
                                    >
                                        <table>
                                            <thead />
                                            <tbody style={{ width: '100%', float: 'left' }}>
                                                <tr
                                                    style={{
                                                        width: '50%',
                                                        textAlign: 'left',
                                                        float: 'left',
                                                    }}
                                                >
                                                    <td
                                                        style={{
                                                            marginBottom: '0.2rem',
                                                            color: '#111111',
                                                            fontSize: '13px',
                                                            fontWeight: 'bold',
                                                        }}
                                                    >
                                                        Date:
                                                    </td>
                                                </tr>
                                                <tr
                                                    style={{
                                                        width: '40%',
                                                        textAlign: 'left',
                                                        float: 'left',
                                                    }}
                                                >
                                                    <td
                                                        style={{
                                                            marginTop: '5px',
                                                            color: '#656565',
                                                            fontSize: '13px',
                                                        }}
                                                    >
                                                        {appointmentDate}
                                                    </td>
                                                </tr>
                                                <tr
                                                    style={{
                                                        width: '50%',
                                                        marginTop: '10px',
                                                        textAlign: 'left',
                                                        float: 'left',
                                                    }}
                                                >
                                                    <td
                                                        style={{
                                                            marginBottom: '0.2rem',
                                                            color: '#111111',
                                                            fontSize: '13px',
                                                            fontWeight: 'bold',
                                                        }}
                                                    >
                                                        Agreement # :
                                                    </td>
                                                </tr>
                                                <tr
                                                    style={{
                                                        width: '50%',
                                                        marginTop: '10px',
                                                        textAlign: 'left',
                                                        float: 'left',
                                                    }}
                                                >
                                                    <td
                                                        style={{
                                                            marginTop: '5px',
                                                            color: '#656565',
                                                            fontSize: '13px',
                                                        }}
                                                    >
                                                        {printObjvalue.agreementId}
                                                    </td>
                                                </tr>
                                                <tr
                                                    style={{
                                                        width: '50%',
                                                        marginTop: '10px',
                                                        textAlign: 'left',
                                                        float: 'left',
                                                    }}
                                                >
                                                    <td
                                                        style={{
                                                            marginBottom: '0.2rem',
                                                            color: '#111111',
                                                            fontSize: '13px',
                                                            fontWeight: 'bold',
                                                        }}
                                                    >
                                                        Customer # :
                                                    </td>
                                                </tr>
                                                <tr
                                                    style={{
                                                        width: '40%',
                                                        marginTop: '10px',
                                                        textAlign: 'left',
                                                        float: 'left',
                                                    }}
                                                >
                                                    <td
                                                        style={{
                                                            marginTop: '5px',
                                                            color: '#656565',
                                                            fontSize: '13px',
                                                        }}
                                                    >
                                                        {printObjvalue.customerId}
                                                    </td>
                                                </tr>
                                                <tr
                                                    style={{
                                                        width: '50%',
                                                        marginTop: '10px',
                                                        textAlign: 'left',
                                                        float: 'left',
                                                    }}
                                                >
                                                    <td
                                                        style={{
                                                            marginBottom: '0.2rem',
                                                            color: '#111111',
                                                            fontSize: '13px',
                                                            fontWeight: 'bold',
                                                        }}
                                                    >
                                                        Back Rent:
                                                    </td>
                                                </tr>
                                                <tr
                                                    style={{
                                                        width: '40%',
                                                        marginTop: '10px',
                                                        textAlign: 'left',
                                                        float: 'left',
                                                    }}
                                                >
                                                    <td
                                                        style={{
                                                            marginTop: '5px',
                                                            color: '#656565',
                                                            fontSize: '13px',
                                                        }}
                                                    >
                                                        {/* {'$' + ' ' + NextPaymentDate} */}$ 0.00
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            {/*second part ends here */}
                            {/*3rd part starts*/}
                            <div
                                style={{
                                    width: '100%',
                                    float: 'left',
                                    borderBottom: borderrr,
                                }}
                            >
                                <div
                                    style={{ width: '100%', float: 'left', marginTop: '15px' }}
                                >
                                    <h5
                                        style={{
                                            textTransform: 'uppercase',
                                            textAlign: 'center',
                                            fontSize: '13px',
                                            margin: '0px',
                                            marginBottom: '10px',
                                        }}
                                    >
                                        ITEMS
                                    </h5>
                                </div>
                                <div
                                    style={{
                                        width: '100%',
                                        float: 'left',
                                        marginBottom: '100px',
                                    }}
                                >
                                    <table style={{ marginBottom: '20px', fontSize: '12px' }}>
                                        <thead>
                                            <tr style={{ width: '100%' }}>
                                                <th
                                                    style={{
                                                        width: '15px',
                                                        textAlign: 'left',
                                                        paddingLeft: '12px',
                                                        paddingRight: '12px',
                                                        paddingTop: '5px',
                                                        paddingBottom: '5px',
                                                    }}
                                                ></th>
                                                <th
                                                    style={{
                                                        width: '300px',
                                                        textAlign: 'left',
                                                        paddingLeft: '12px',
                                                        paddingRight: '12px',
                                                        paddingTop: '5px',
                                                        paddingBottom: '5px',
                                                    }}
                                                >
                                                    Description of Property
                                                </th>
                                                <th
                                                    style={{
                                                        width: '300px',
                                                        textAlign: 'left',
                                                        paddingLeft: '12px',
                                                        paddingRight: '12px',
                                                        paddingTop: '5px',
                                                        paddingBottom: '5px',
                                                    }}
                                                >
                                                    Serial #
                                                </th>
                                                <th
                                                    style={{
                                                        width: '300px',
                                                        textAlign: 'left',
                                                        paddingLeft: '12px',
                                                        paddingRight: '12px',
                                                        paddingTop: '5px',
                                                        paddingBottom: '5px',
                                                    }}
                                                >
                                                    Item #
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {bindChecklist()}

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            {/*3rd part ends*/}

                            {/*fourth part starts*/}
                            <div
                                style={{
                                    width: '100%',
                                    float: 'left',
                                    marginBottom: '20px',
                                    marginTop: '10px',
                                }}
                            >
                                <table style={{ width: '100%', float: 'left' }}>
                                    <thead />
                                    <tbody style={{ width: '100%', float: 'left' }}>
                                        <tr
                                            style={{
                                                width: '18%',
                                                float: 'left',
                                                whiteSpace: 'nowrap',
                                            }}
                                        >
                                            <td
                                                style={{
                                                    marginBottom: '0.2rem',
                                                    color: '#111111',
                                                    fontSize: '15px',
                                                    fontWeight: 'bold',
                                                }}
                                            >
                                                Employee Signature:
                                            </td>
                                        </tr>
                                        <tr
                                            style={{
                                                width: '28%',
                                                float: 'left',
                                                paddingRight: '25px',
                                                whiteSpace: 'nowrap',
                                                marginLeft: '8px',
                                            }}
                                        >
                                            <td
                                                style={{
                                                    marginTop: '18px',
                                                    color: '#656565',
                                                    borderBottom: borderbotttom,
                                                    width: '100%',
                                                    float: 'left',
                                                }}
                                            />
                                        </tr>
                                        <tr
                                            style={{
                                                width: '8%',
                                                float: 'left',
                                                whiteSpace: 'nowrap',
                                            }}
                                        >
                                            <td
                                                style={{
                                                    marginBottom: '0.2rem',
                                                    color: '#111111',
                                                    fontSize: '15px',
                                                    fontWeight: 'bold',
                                                }}
                                            >
                                                Date:
                                            </td>
                                        </tr>
                                        <tr
                                            style={{
                                                width: '32%',
                                                float: 'left',
                                                whiteSpace: 'nowrap',
                                            }}
                                        >
                                            <td
                                                style={{
                                                    marginTop: '15px',
                                                    color: '#656565',
                                                    borderBottom: borderbotttom,
                                                    width: '100%',
                                                    float: 'left',
                                                }}
                                            />
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div
                                style={{
                                    width: '13.33%',
                                    marginLeft: '2%',
                                    marginRight: '2%',
                                    float: 'left',
                                    height: '100px',
                                    marginBottom: '10px',
                                }}
                            >
                                {/* /> */}
                                <Racfont style={{ width: '700%', height: '75%' }}></Racfont>
                            </div>
                            <div>{/* 5th part ends */}</div>
                        </div>
                    </div>
                    {/* pickup receipt Inner html ends here */}
                </div>
            </div>
            {/* service pickup receipt innerhtml end here */}
        </React.Fragment>
    );
}
