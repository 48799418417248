/* eslint-disable prettier/prettier */
/* eslint-disable sonarjs/prefer-single-boolean-return */
import moment from 'moment';

//to validate each values inside response
export const validateData = (value: any) => {
  if (value !== undefined && value !== null && value !== '') {
    return true;
  } else {
    return false;
  }
};

//to change price format
export const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2,
});

/**function for dateConvertion */
export const convertDate = (date: any, toFormat: any) => {
  return moment(date).format(toFormat);
};

export function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

